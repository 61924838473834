import React from 'react';
import ResetPasswordWrapper from 'components/ResetPassword/ResetPasswordWrapper';
import ForgotPasswordForm from 'components/ResetPassword/Forms/ForgotPasswordForm';

const ForgotPasswordPage = () => (
  <ResetPasswordWrapper>
    <ForgotPasswordForm />
  </ResetPasswordWrapper>
);

export default ForgotPasswordPage;
