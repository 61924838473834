import React from 'react';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { formatDate } from 'utils/helper/FormatDate';
import { hasNoSellerRole } from 'utils/helper/UserRoleValidation';
import { admin } from 'utils/constants/Routes';
import { allUsersTab, appliedTab, approvedTab } from 'utils/constants/portals';
import {
  DOCUMENT_REQUESTED,
  REQUESTED_VERIFICATION,
  UNVERIFIED,
  VERIFIED,
} from 'utils/constants/userStatus';
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/BaseComponents/Table';
import LoadingSpinner from 'components/BaseComponents/LoadingSpinner';
import Icon from 'components/BaseComponents/Icon';
import { BUYER, OWNER, SELLER, SUBSCRIPTION_MEMBER, userRoleLabels } from 'utils/constants/userRole';
import TableColumn from '../../TableColumn';

const formattedUserStatus = {
  [UNVERIFIED]: t('admin_user_table.unapproved_tab'),
  [VERIFIED]: t('admin_user_table.approved_tab'),
  [REQUESTED_VERIFICATION]: t('admin_user_table.applied_tab'),
  [DOCUMENT_REQUESTED]: t('admin_user_table.document_requested_tab'),
};

const generateDateColumnContent = (tabKey, company) => {
  switch (tabKey) {
    case appliedTab:
      return formatDate(company.requestedVerificationAt);
    case approvedTab:
      return formatDate(company.verifiedAt);
    default:
      return null;
  }
};

const generateUserStatusColumnContent = (tabKey, company) => {
  if (tabKey === allUsersTab) {
    return formattedUserStatus[company.status];
  }

  return null;
};

const HEADERS_KEYS = {
  'User name': 'first_name',
  Company: 'company.name',
  Email: 'email',
  Status: 'company.status',
};

const generateCompanyPermissionsContent = (company, roles) => {
  if (company.status !== VERIFIED || hasNoSellerRole({ roles })) {
    return t('admin_user_table.not_applicable');
  }

  const {
    companyPermissions: {
      tradeKernel,
      tradeDriedNutInShell,
    }
  } = company;

  return [
    tradeKernel && t('admin_user_table.kernel'),
    tradeDriedNutInShell && t('admin_user_table.dried_nut_in_shell'),
  ].filter((e) => e).join(', ');
};

export const generateUserRolesContent = (roles) => (
  roles.map((role) => (
    <div key={role} className={classNames('admin-portal__table--role', {
      'admin-portal__table--role-green-text': role === SELLER,
      'admin-portal__table--role-blue-text': role === BUYER,
      'admin-portal__table--role-gray-text': role === SUBSCRIPTION_MEMBER,
      'admin-portal__table--role-light-text': role === OWNER,
    })}>
      {userRoleLabels[role]}
    </div>
  ))
);

const UserTable = ({ tabKey, tableHeaders, users, fetchingAllUsers, sortParams }) => {
  const {
    sortField,
    setSortField,
    sortDirection,
    setSortDirection,
  } = sortParams;

  const handleColumnClick = (column) => {
    setSortDirection(sortDirection === 'up' ? 'down' : 'up');
    setSortField(column);
  };

  return (
    <Table className="admin-portal__table">
      <TableHead>
        <TableRow isHeaderRow={true}>
          {tableHeaders.map((title) => (
            <TableHeader
              key={title}
              textCase="capitalize"
              unavailableText={t('admin_user_table.no_data_available')}
              onClick={() => HEADERS_KEYS[title] && handleColumnClick(HEADERS_KEYS[title])}
            >
              {title}
              {sortField === HEADERS_KEYS[title] && (sortDirection === 'up' ? ' ↑' : ' ↓')}
            </TableHeader>
          ))}
        </TableRow>
      </TableHead>

      {fetchingAllUsers ? (
        <TableBody className="position-relative padding-y-32px">
          <LoadingSpinner />
        </TableBody>
      ) : (
        <TableBody>
          {users.map(({ id, firstName, lastName, company, roles, email }) => {
            const dateColumnContent = generateDateColumnContent(tabKey, company);
            const userStatusColumnContent = generateUserStatusColumnContent(tabKey, company);
            const userCompanyPermissionsContent = generateCompanyPermissionsContent(company, roles);
            const userRolesContent = generateUserRolesContent(roles);

            return (
              <TableRow key={id}>
                <TableColumn content={`${firstName} ${lastName}`} />
                <TableColumn content={company.name} />
                <TableColumn content={userRolesContent} />
                <TableColumn content={email} />
                <TableColumn content={userCompanyPermissionsContent} />
                {dateColumnContent && <TableColumn content={dateColumnContent} />}
                {userStatusColumnContent && <TableColumn content={userStatusColumnContent} />}
                <TableColumn
                  content={
                    <Link to={admin.userProfile(id)}>
                      <Icon name="show" size="normal" className="admin-portal__table--icon" />
                    </Link>
                  }
                />
              </TableRow>
            );
          })}
        </TableBody>
      )}
    </Table>
  );
};
UserTable.defaultProps = {
  users: [],
};

UserTable.propTypes = {
  tabKey: PropTypes.string.isRequired,
  fetchingAllUsers: PropTypes.bool.isRequired,
  tableHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      company: PropTypes.shape({
        name: PropTypes.string,
        verifiedAt: PropTypes.string,
        requestedVerificationAt: PropTypes.string,
      }).isRequired,
      roles: PropTypes.arrayOf(PropTypes.string.isRequired),
      email: PropTypes.string.isRequired,
    }),
  ),
  sortParams: PropTypes.shape({
    sortField: PropTypes.string,
    sortDirection: PropTypes.string,
    setSortField: PropTypes.func,
    setSortDirection: PropTypes.func,
  }).isRequired,
};

export default UserTable;
