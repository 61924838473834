import React from 'react';
import PropTypes from 'prop-types';
import emptyContent from 'assets/images/EmptyContent.png';
import './styles.scss';

const EmptyContentPlaceholder = ({ heading, description }) => (
  <div className="empty-content flex flex-col items-center padding-y-32px">
    <img src={emptyContent} alt="No data found" />
    <h6 className="empty-content__heading ">{heading}</h6>
    <p className="empty-content__description">{description}</p>
  </div>
);

EmptyContentPlaceholder.defaultProps = {
  heading: null,
  description: null,
};

EmptyContentPlaceholder.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
};

export default EmptyContentPlaceholder;
