import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { useGetContractQuery } from 'api/contracts';
import classNames from 'classnames';
import Button from 'components/BaseComponents/Button';
import Icon from 'components/BaseComponents/Icon';

const RequestAssistanceFormFooter = ({
  handleModalClose,
  isAnyCheckBoxChecked,
  onContactModalOpen,
}) => {
  const { id: contractId } = useParams();
  const { data: contractDetails = {} } = useGetContractQuery({
    id: contractId,
  });
  const { requestAssistances = [] } = contractDetails;

  const getFormFooterContent = () => {
    if (!requestAssistances.length) {
      return (
        <>
          <Button
            label={t('signup.header.button_content')}
            onClick={() => handleModalClose()}
            className="request-assistance__modal--form-container-bottom-content-buttons-request-assistance"
          />
          <Button
            type="success"
            className="request-assistance__modal--form-container-bottom-content-buttons-request-assistance"
            label={t(
              'shared.bidding_process.contract_details.request_assistance.form.submit_button_label',
            )}
            submitType="submit"
            disabled={!isAnyCheckBoxChecked}
          />
        </>
      );
    }

    return (
      <div className="flex gap-16px items-start">
        <Icon name="help" size="medium" />
        <div>
          <p className="margin-none request-assistance__text-content">
            {t('shared.bidding_process.contract_details.contact_support.header')}
          </p>
          <Button
            label={t(
              'shared.bidding_process.contract_details.contact_support.button.contact_support_label',
            )}
            className="request-assistance__modal--form-container-bottom-content-contact-support margin-t-8px"
            onClick={() => {
              handleModalClose();
              onContactModalOpen();
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div
      className={classNames(
        'flex gap-16px margin-t-16px request-assistance__modal--form-container-bottom-content-buttons',
        {
          'request-assistance__modal--form-container-bottom-content': requestAssistances.length,
        },
      )}
    >
      {getFormFooterContent()}
    </div>
  );
};

RequestAssistanceFormFooter.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  onContactModalOpen: PropTypes.func.isRequired,
  isAnyCheckBoxChecked: PropTypes.bool.isRequired,
};

export default RequestAssistanceFormFooter;
