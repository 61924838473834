import React from 'react';
import { t } from 'i18next';
import { Text, View } from '@react-pdf/renderer';
import { styles } from '../styles';
import SignatureBox from '../SignatureBox';

const PaymentInstructions = () => (
  <View wrap={false}>
    <Text style={styles.boldText}>{t('contract_pdf.payment_instruction')}</Text>
    <SignatureBox text="" />
  </View>
);

export default PaymentInstructions;
