import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useDebounce from 'hooks/useDebounce';
import { useValidateFoodSafetyTestFieldMutation } from 'api/products';
import Input from 'components/BaseComponents/Input';

const TestCountInput = ({
  input,
  error,
  touched,
  required,
  disabled,
  placeholder,
  hint,
  substanceValue,
  testMethodValue,
}) => {
  const DEBOUNCE_DELAY = 500; // time in ms
  const [fieldTestCountError, setFieldTestCountError] = useState({});
  const [validateFoodSafetyTestField] = useValidateFoodSafetyTestFieldMutation();

  const handleTestInputChange = async (substanceNameValue, testMethodNameValue, testCountValue) => {
    const requestBody = {
      name: substanceNameValue,
      method: testMethodNameValue,
      value: testCountValue,
    };

    return validateFoodSafetyTestField({ payload: requestBody })
      .unwrap()
      .then(() => {
        setFieldTestCountError({
          ...fieldTestCountError,
          [substanceNameValue]: null,
        });
      })
      .catch((data) => {
        const errorMessage = data.data.message.value;

        setFieldTestCountError({
          ...fieldTestCountError,
          [substanceNameValue]: errorMessage,
        });

        return data.value;
      });
  };

  const [debouncedHandleTestInputChange] = useDebounce(handleTestInputChange, DEBOUNCE_DELAY);

  return (
    <Input
      placeholder={placeholder}
      value={input.value}
      error={error || fieldTestCountError[substanceValue]}
      touched={touched}
      type="number"
      onChange={(inputValue) => {
        input.onChange(inputValue);
        if (inputValue !== '') {
          debouncedHandleTestInputChange(substanceValue, testMethodValue, inputValue);
        }
      }}
      required={required}
      disabled={disabled}
      hint={hint}
    />
  );
};

TestCountInput.defaultProps = {
  testMethodValue: null,
};

TestCountInput.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  error: PropTypes.string.isRequired,
  touched: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
  hint: PropTypes.string.isRequired,
  substanceValue: PropTypes.string.isRequired,
  testMethodValue: PropTypes.string,
};

export default TestCountInput;
