// comments are due to business logics
import React from 'react';
import PropTypes from 'prop-types';
import { Document, Page, View } from '@react-pdf/renderer';
import Header from 'components/BiddingProcess/ContractPDF/Header';
import Footer from 'components/BiddingProcess/ContractPDF/Footer';
// import WaterMark from 'components/BiddingProcess/ContractPDF/WaterMark';
import Remarks from 'components/BiddingProcess/ContractPDF/Remarks';
import UserCards from 'components/BiddingProcess/ContractPDF/UserCards';
import Platforms from 'components/BiddingProcess/ContractPDF/Platforms';
import ContractSale from 'components/BiddingProcess/ContractPDF/ContractSale';
import ProductInfoTable from 'components/BiddingProcess/ContractPDF/ProductInfoTable';
import TermsConditionsTable from 'components/BiddingProcess/ContractPDF/TermsConditions';
import PaymentInstructions from 'components/BiddingProcess/ContractPDF/PaymentInstructions';
import LogisticsInformation from 'components/BiddingProcess/ContractPDF/LogisticsInformation';
import SignatureAuthorization from 'components/BiddingProcess/ContractPDF/SignatureAuthorization';
import { styles } from 'components/BiddingProcess/ContractPDF/styles';

const ContractPDFComponent = ({ contractInformation }) => (
  <Document>
    <Page size="A4">
      <Header />
      <View style={styles.body}>
        {/* Turned off due to business policy */}
        {/* <WaterMark /> */}
        <ContractSale
          serialNumber={contractInformation.serialNumber}
          creationDate={contractInformation.creationDate}
          creationTime={contractInformation.creationTime}
        />
        <UserCards
          sellerDetails={contractInformation.sellerDetails}
          buyerDetails={contractInformation.buyerDetails}
        />
        <Platforms listingDetails={contractInformation.listingDetails} />
        <ProductInfoTable productDetails={contractInformation.productDetails} />
        <LogisticsInformation />
        <Remarks buyerDetails={contractInformation.buyerDetails} />
        <PaymentInstructions />
        <TermsConditionsTable />
        <SignatureAuthorization
          sellerDetails={contractInformation.sellerDetails}
          buyerDetails={contractInformation.buyerDetails}
        />
      </View>
      <Footer />
    </Page>
  </Document>
);

ContractPDFComponent.propTypes = {
  contractInformation: PropTypes.shape({
    buyerDetails: PropTypes.shape({
      companyName: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    creationDate: PropTypes.string,
    creationTime: PropTypes.string,
    listingDetails: PropTypes.shape({
      serialNumber: PropTypes.string,
      category: PropTypes.string,
    }),
    productDetails: PropTypes.shape({
      offerVolume: PropTypes.string,
      boxesQuantity: PropTypes.number,
      packaging: PropTypes.string,
      containerLoad: PropTypes.string,
      description: PropTypes.string,
      nutStyle: PropTypes.string,
      seasonHarvested: PropTypes.string,
      offerPrice: PropTypes.string,
      fullBatchPrice: PropTypes.string,
      msmCommissionOnProduct: PropTypes.string,
      type: PropTypes.string,
      size: PropTypes.string,
    }),
    kernelProductDetails: PropTypes.shape({
      offerVolume: PropTypes.string,
      packaging: PropTypes.string,
      containerLoad: PropTypes.string,
      description: PropTypes.string,
      nutStyle: PropTypes.string,
      seasonHarvested: PropTypes.string,
      offerPrice: PropTypes.string,
      fullBatchPrice: PropTypes.string,
      msmCommissionOnProduct: PropTypes.string,
    }),
    driedNutInShellProductDetails: PropTypes.shape({
      offerVolume: PropTypes.string,
      packaging: PropTypes.string,
      containerLoad: PropTypes.string,
      description: PropTypes.string,
      nutSize: PropTypes.string,
      soundKernelRecovery: PropTypes.string,
      unsoundKernelRecovery: PropTypes.string,
      treeVariant: PropTypes.string,
      moistureContent: PropTypes.string,
      seasonHarvested: PropTypes.string,
      offerPrice: PropTypes.string,
      fullBatchPrice: PropTypes.string,
      msmCommissionOnProduct: PropTypes.string,
    }),
    sellerDetails: PropTypes.shape({
      companyName: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    serialNumber: PropTypes.string,
  }).isRequired,
};

export default ContractPDFComponent;
