import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Stars from 'assets/images/stars.svg';
import MSMLogo from 'assets/images/msm-logo.svg';
import { contactUs, landingPage } from 'utils/constants/Routes';
import Icon from 'components/BaseComponents/Icon';
import './style.scss';

const LeftBanner = ({ title, message }) => {
  const { t } = useTranslation();

  return (
    <div className="banner-container flex flex-col justify-between">
      <Link to={landingPage}>
        <img src={MSMLogo} alt="" className="banner-container__logo" />
      </Link>
      <div>
        <img src={Stars} alt="" />
        {title && <h1 className="banner-container__title margin-t-32px">{title}</h1>}
        {message && <p className="banner-container__message margin-t-24px">{message}</p>}
      </div>
      <Link to={contactUs} className="flex banner-container__contact-support">
        <Icon name="help" color="neutral" className="banner-container__contact-support--icon" />
        <p className="banner-container__contact-support--text margin-l-8px">
          {t('signup.left_banner.contact')}
        </p>
      </Link>
    </div>
  );
};

LeftBanner.defaultProps = {
  title: null,
  message: null,
};

LeftBanner.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
};

export default LeftBanner;
