import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { requiredField } from 'utils/helper/Validations';
import Button from 'components/BaseComponents/Button';
import Input from 'components/BaseComponents/Input';
import { useInviteCompanyMemberMutation } from 'api/companies';
import { generateInviteCompanyMemberBody } from 'utils/helper/RequestBody';
import CustomToastify from 'components/Shared/ToastNotification/CustomToastify';
import './styles.scss';
import IconButton from 'components/BaseComponents/IconButton';
import Icon from 'components/BaseComponents/Icon';

const InvitationModal = ({ companyId, onModalClose, toastRef }) => {
  const { t } = useTranslation();

  const [intiteCompanyMember] = useInviteCompanyMemberMutation()

  const onSubmit = (params) => {
    const requestBody = generateInviteCompanyMemberBody(params);

    intiteCompanyMember({ companyId, payload: requestBody })
      .unwrap()
      .then(() => {
        toastRef?.current?.notify(
          <CustomToastify type="success" message={t('bidding_process.retract_bid.success')} />,
        );
        onModalClose();
      })
      .catch(() => {
        toastRef?.current?.notify(
          <CustomToastify type="error" message={t('bidding_process.something_went_wrong')} />,
        );
      });
  };

  return (
    <div className="invitation-modal__modal">
      <div className="invitation-modal__header">
        <h4 className="invitation-modal__header--title">{t('profile.company_management.invite')}</h4>
        <IconButton icon={<Icon name="close" />} size="tiny" onClick={onModalClose} />
      </div>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, invalid, submitting }) => (
          <form onSubmit={handleSubmit} className="margin-t-16px">
            <Field name="first_name" validate={requiredField}>
              {({ input, meta }) => (
                <Input
                  label={t('signup.step2_form.first_name.label')}
                  placeholder={t('signup.step2_form.first_name.placeholder')}
                  required
                  size="small"
                  className="sign-up-form__first-name margin-b-20px"
                  error={meta?.error || meta?.submitError}
                  touched={meta.touched}
                  value={input.value}
                  onChange={input.onChange}
                />
              )}
            </Field>
            <Field name="last_name" validate={requiredField}>
              {({ input, meta }) => (
                <Input
                  label={t('signup.step2_form.last_name.label')}
                  placeholder={t('signup.step2_form.last_name.placeholder')}
                  required
                  size="small"
                  className="sign-up-form__last-name"
                  error={meta?.error || meta?.submitError}
                  touched={meta.touched}
                  value={input.value}
                  onChange={input.onChange}
                />
              )}
            </Field>
            <Field name="email" validate={requiredField}>
              {({ input, meta }) => (
                <Input
                  label={t('profile.company_management.email_label')}
                  placeholder={t('signup.step2_form.user_email.placeholder')}
                  required
                  size="small"
                  className="margin-t-20px"
                  error={meta?.error || meta?.submitError}
                  touched={meta.touched}
                  value={input.value}
                  onChange={input.onChange}
                />
              )}
            </Field>

            <Button
              className="invitation-modal__submit-btn"
              type="success"
              label={t('profile.company_management.submit_invite')}
              submitType="submit"
              disabled={submitting || invalid}
            />
          </form>
        )}
      />
    </div>
  );
};


InvitationModal.defaultProps = {
  toastRef: null,
};

InvitationModal.propTypes = {
  companyId: PropTypes.number.isRequired,
  onModalClose: PropTypes.func.isRequired,
  toastRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

export default InvitationModal;
