import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import IconMapping from './registry';
import getErrorMessage from './searchIcon';

const Icon = (props) => {
  const {
    name,
    fill,
    className,
    iconLabel,
    showLabel,
    showTooltip,
    size,
    showBGColor,
    color,
    rotate,
  } = props;

  const MappedIcon = IconMapping[name];
  const overWriteStyle = fill ? { fill } : {};
  const rotateIcon = rotate ? { transform: `rotate(${rotate}deg)` } : {};
  const styles = {
    display: 'block',
    height: '1em',
    width: '1em',
    strokeWidth: 0,
  };
  const iconList = Object.keys(IconMapping);
  const charToSearch = name && name.charCodeAt(0);

  try {
    if (!MappedIcon) {
      throw new TypeError(getErrorMessage(iconList, charToSearch));
    }
  } catch (e) {
    return null;
  }

  return (
    <div
      className={classNames(
        'icon',
        `icon--${color}`,
        { 'icon--with-label': iconLabel && showLabel, [`icon--${size}`]: size },
        className,
      )}
    >
      {showBGColor ? (
        <div
          className={classNames('icon__content', `icon__content--bg-${color}`, {
            icon__rotate: rotate,
          })}
          style={{ ...rotateIcon }}
        >
          <MappedIcon style={{ ...styles, ...overWriteStyle }} />
        </div>
      ) : (
        <MappedIcon
          className={classNames({
            icon__rotate: rotate,
          })}
          style={{ ...styles, ...overWriteStyle, ...rotateIcon }}
        />
      )}
      {iconLabel && showTooltip ? (
        <div className={`icon__tooltip icon__tooltip-${color}`}>{iconLabel}</div>
      ) : null}
      {iconLabel && showLabel ? <div className="icon__label">{iconLabel}</div> : null}
    </div>
  );
};

Icon.defaultProps = {
  className: null,
  iconLabel: null,
  showLabel: false,
  showTooltip: false,
  showBGColor: false,
  color: 'default',
  fill: null,
  rotate: null,
  size: null,
};

Icon.propTypes = {
  className: PropTypes.string,
  iconLabel: PropTypes.node,
  showLabel: PropTypes.bool,
  showTooltip: PropTypes.bool,
  showBGColor: PropTypes.bool,
  color: PropTypes.oneOf([
    'tertiary',
    'success',
    'warning',
    'danger',
    'default',
    'neutral',
    'gray',
    'none',
  ]),
  name: PropTypes.string.isRequired,
  fill: PropTypes.string,
  rotate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOf([
    'xs',
    'tiny',
    'small',
    'normal',
    'medium',
    'large',
    'huge',
    'xl',
    'xxl',
    null,
  ]),
};

export default Icon;
