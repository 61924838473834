import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const SubHeading = ({ subheadings }) => (
  <Fragment>
    {
      subheadings.map((subheading) => <div key={subheading}>{subheading}</div>)
    }
  </Fragment>
);

SubHeading.defaultProps = {
  subheadings: [],
};

SubHeading.propTypes = {
  subheadings: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
};

export default SubHeading;
