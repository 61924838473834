// comments are due to business logics
import { StyleSheet, Font } from '@react-pdf/renderer';
import InterRegularFont from 'assets/fonts/Inter-Regular.ttf';
import InterBoldFont from 'assets/fonts/Inter-Bold.ttf';

const FONT_WEIGHT_BOLD = 'bold';
const FONT_WEIGHT_NORMAL = 'normal';
const FONT_FAMILY_INTER = 'Inter';
const COLOR_BOLD_TEXT = '#00162d';
const COLOR_NORMAL_TEXT = '#475467';
// const COLOR_NEON_GREEN = '#4dec20';
const COLOR_GRAY_100 = '#f2f4f7';
const COLOR_GRAY_200 = '#eaecf0';
const COLOR_GRAY_500 = '#667085';
const LINE_HEIGHT_20 = 1.25;
const LINE_HEIGHT_24 = 1.5;
const DEFAULT_BORDER_STYLE = `1 solid ${COLOR_GRAY_200}`;

Font.register({
  family: FONT_FAMILY_INTER,
  fonts: [
    { src: InterRegularFont, fontWeight: FONT_WEIGHT_NORMAL },
    { src: InterBoldFont, fontWeight: FONT_WEIGHT_BOLD },
  ],
});

const sharedStylings = {
  boldText: {
    color: COLOR_BOLD_TEXT,
    fontFamily: FONT_FAMILY_INTER,
    fontSize: 10,
    fontWeight: FONT_WEIGHT_BOLD,
    lineHeight: LINE_HEIGHT_24,
    margin: 0,
  },
  normalText: {
    color: COLOR_NORMAL_TEXT,
    fontFamily: FONT_FAMILY_INTER,
    fontSize: 10,
    fontWeight: FONT_WEIGHT_NORMAL,
    margin: 0,
  },
  listText: {
    color: COLOR_NORMAL_TEXT,
    fontFamily: FONT_FAMILY_INTER,
    fontSize: 10,
    fontWeight: FONT_WEIGHT_NORMAL,
    padding: 3,
  },
  flexRow: {
    flexDirection: 'row',
  },
  lineBreak: {
    backgroundColor: COLOR_GRAY_200,
    height: 1,
    margin: '20 0',
    width: '100%',
  },
  signatureBox: {
    alignItems: 'flex-start',
    border: DEFAULT_BORDER_STYLE,
    color: COLOR_NORMAL_TEXT,
    fontSize: 10,
    height: 30,
    lineHeight: LINE_HEIGHT_24,
    margin: 0,
    padding: '0 8',
    justifyContent: 'center',
  },
};

const tableStylings = {
  table: {
    borderTop: DEFAULT_BORDER_STYLE,
    display: 'table',
    marginBottom: 20,
    marginTop: 8,
    width: 'auto',
  },
  tableHeader: {
    ...sharedStylings.boldText,
    flexDirection: 'row',
    margin: 'auto',
  },
  tableRow: {
    ...sharedStylings.normalText,
    flexDirection: 'row',
    margin: 'auto',
  },
  tableCol: {
    borderBottom: DEFAULT_BORDER_STYLE,
    borderRight: DEFAULT_BORDER_STYLE,
    padding: 6,
    width: '12.5%',
  },
  tableColWide: {
    alignItems: 'flex-start',
    borderBottom: DEFAULT_BORDER_STYLE,
    borderRight: DEFAULT_BORDER_STYLE,
    padding: 6,
    width: '37.5%',
  },
  tableColLeftMost: {
    borderLeft: DEFAULT_BORDER_STYLE,
  },
  tableColSpan: {
    borderBottom: DEFAULT_BORDER_STYLE,
    borderRight: DEFAULT_BORDER_STYLE,
    padding: 6,
    width: '37.5%',
  },
  tableCell: {
    fontSize: 10,
    margin: 'auto',
    marginTop: 5,
  },
};

const stylings = {
  ...tableStylings,
  ...sharedStylings,
  body: {
    color: COLOR_NORMAL_TEXT,
    padding: '0 30',
    position: 'relative',
  },
  pageBackground: {
    left: '20%',
    marginTop: '28%',
    position: 'absolute',
    width: '70%',
    zIndex: -1,
  },
  header: {
    // backgroundColor: '#012b67',
    // color: COLOR_NEON_GREEN,
    // flexDirection: 'row',
    // gap: 20,
    marginBottom: 30,
    // padding: 30,
    // paddingBottom: 20,
  },
  headerTextContainer: {
    flexDirection: 'column',
  },
  msmLogo: {
    height: 32,
    width: 45,
  },
  title: {
    fontFamily: FONT_FAMILY_INTER,
    fontSize: 14,
    fontWeight: FONT_WEIGHT_BOLD,
    lineHeight: LINE_HEIGHT_24,
  },
  subtitle: {
    fontFamily: FONT_FAMILY_INTER,
    fontSize: 8,
    fontWeight: FONT_WEIGHT_BOLD,
  },
  contractSaleNo: {
    flexDirection: 'row',
    fontSize: 14,
    margin: 0,
  },
  contractSaleNoBoldText: {
    ...sharedStylings.boldText,
    fontSize: 14,
  },
  contractSaleDateText: {
    ...sharedStylings.boldText,
    fontSize: 10,
    margin: 0,
  },
  contractSaleDate: {
    flexDirection: 'row',
    gap: 20,
  },
  userCardContainer: {
    flexDirection: 'row',
    gap: 20,
    justifyContent: 'space-between',
  },
  userCard: {
    flexDirection: 'column',
    fontSize: 10,
    gap: 8,
    width: '50%',
  },
  userCardTitle: {
    ...sharedStylings.boldText,
    textTransform: 'uppercase',
  },
  userCardCompany: {
    ...sharedStylings.boldText,
    fontSize: 12,
    lineHeight: LINE_HEIGHT_20,
  },
  userCardRegistration: {
    margin: 0,
  },
  userCardList: {
    color: COLOR_GRAY_500,
    lineHeight: LINE_HEIGHT_20,
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  logisticsCard: {
    border: DEFAULT_BORDER_STYLE,
    flexDirection: 'row',
    fontSize: 9,
    gap: 8,
    marginBottom: 20,
    marginTop: 8,
    padding: 8,
  },
  logisticsCardLeft: {
    width: '35%',
  },
  logisticsCardRight: {
    alignItems: 'flex-start',
    flexDirection: 'row',
    gap: 4,
    width: 250,
  },
  logisticsCardText: {
    fontSize: 9,
    margin: 0,
  },
  logisticsCardBoldText: {
    ...sharedStylings.boldText,
    display: 'inline-block',
    fontSize: 9,
    lineHeight: LINE_HEIGHT_24,
    width: 74,
  },
  remarksCard: {
    border: DEFAULT_BORDER_STYLE,
    marginBottom: 20,
    marginTop: 8,
    padding: 8,
  },
  remarksCardText: {
    ...sharedStylings.normalText,
    marginBottom: 12,
  },
  productInfoTable: {
    borderCollapse: 'collapse',
    fontSize: 9,
    marginTop: 8,
    marginBottom: 20,
    width: '100%',
  },
  productInfoTableHeader: {
    border: DEFAULT_BORDER_STYLE,
    color: COLOR_BOLD_TEXT,
    padding: 8,
    textAlign: 'left',
  },
  productInfoTableRow: {
    border: DEFAULT_BORDER_STYLE,
    color: COLOR_NORMAL_TEXT,
    padding: 8,
    textAlign: 'left',
  },
  termsTitle: {
    ...sharedStylings.boldText,
    marginTop: 20,
  },
  termsDescription: {
    ...sharedStylings.normalText,
    margin: '8 0',
  },
  termsCard: {
    border: DEFAULT_BORDER_STYLE,
    flexDirection: 'row',
    fontSize: 9,
    lineHeight: LINE_HEIGHT_24,
    marginTop: 16,
  },
  termsCardLeft: {
    ...sharedStylings.boldText,
    color: COLOR_BOLD_TEXT,
    padding: 8,
    width: '25%',
  },
  termsCardRight: {
    ...sharedStylings.normalText,
    borderLeft: DEFAULT_BORDER_STYLE,
    padding: 8,
    width: '75%',
  },
  signatureTitle: {
    ...sharedStylings.boldText,
    marginBottom: 20,
  },
  signatureContractTitle: {
    ...sharedStylings.boldText,
    marginBottom: 12,
  },
  signatureBoxLarge: {
    ...sharedStylings.signatureBox,
    height: 60,
  },
  signatureBoxContainer: {
    flexDirection: 'row',
    marginBottom: 280,
  },
  signatureBoxContainerLeft: {
    borderRight: DEFAULT_BORDER_STYLE,
    paddingRight: 20,
    width: '50%',
  },
  signatureBoxContainerRight: {
    paddingLeft: 20,
    width: '50%',
  },
  signatureBoxLabel: {
    ...sharedStylings.normalText,
    color: COLOR_BOLD_TEXT,
  },
  signatureBoxField: {
    marginBottom: 16,
  },
  footer: {
    alignItems: 'center',
    backgroundColor: COLOR_GRAY_100,
    color: COLOR_NORMAL_TEXT,
    flexDirection: 'row',
    fontSize: '8',
    justifyContent: 'space-between',
    marginTop: 12,
    padding: '12 30',
  },
  footerList: {
    flexDirection: 'row',
    fontSize: 8,
    gap: 13,
    listStyle: 'none',
    padding: 0,
  },
  footerListItem: {
    flexDirection: 'row',
    gap: 4,
  },
};

export const styles = StyleSheet.create(stylings);
