import React, { useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import './style.scss';

const CustomCheckbox = (props) => {
  const { name, iconName, label, containerClassName, inputId, checked, onChange, disabled } = props;
  const inputRef = useRef();

  const customCheckboxWrapperClass = classNames('custom-checkbox', containerClassName, {
    'custom-checkbox-selected': checked,
    'custom-checkbox-not-selected': !checked,
    'custom-checkbox-disabled': disabled,
  });

  return (
    <div className={customCheckboxWrapperClass} onClick={() => inputRef.current.click()}>
      <div className="flex items-center gap-14px">
        <Icon name={iconName} color="none" size="huge" />
        <label className="custom-checkbox__label" htmlFor={name}>
          {label}
        </label>
      </div>
      {checked && <Icon name="checkCircle" color="success" />}
      <input
        type="checkbox"
        id={inputId}
        name={name}
        onChange={onChange}
        ref={inputRef}
        className="custom-checkbox__input-field--hidden"
        checked={checked}
        disabled={disabled}
      />
    </div>
  );
};

CustomCheckbox.defaultProps = {
  label: null,
  containerClassName: null,
  inputId: 'customcheckbox',
  checked: false,
  disabled: false,
  onChange: () => {},
  onClick: () => {},
};

CustomCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  label: PropTypes.string,
  containerClassName: PropTypes.string,
  inputId: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

export default CustomCheckbox;
