import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/BaseComponents/Input';
import Icon from 'components/BaseComponents/Icon';
import './styles.scss';
import Button from 'components/BaseComponents/Button';

const PasswordInput = ({
  label,
  placeholder,
  value,
  error,
  touched,
  size,
  onChange,
  required,
  className,
}) => {
  const [isInputTypePassword, setIsInputTypePassword] = useState(true);

  const toggleVisibility = () => {
    setIsInputTypePassword((prev) => !prev);
  };

  const icon = (
    <Button
      label={<Icon name="show" />}
      onClick={toggleVisibility}
      className="password-visibility-icon__button"
    />
  );

  return (
    <Input
      label={label}
      placeholder={placeholder}
      value={value}
      type={isInputTypePassword ? 'password' : 'text'}
      error={error}
      touched={touched}
      size={size}
      onChange={onChange}
      required={required}
      postIcon={value !== '' && icon}
      className={className}
    />
  );
};

PasswordInput.defaultProps = {
  value: '',
  error: null,
  touched: false,
  size: 'small',
  onChange: () => {},
  required: true,
  className: null,
};

PasswordInput.propTypes = {
  label: PropTypes.node.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  touched: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'tiny', 'normal', 'large', 'huge']),
  onChange: PropTypes.func,
  required: PropTypes.bool,
  className: PropTypes.string,
};

export default PasswordInput;
