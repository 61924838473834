import { useEffect, useState } from 'react';

const useScroll = (threshold, delay) => {
  const [showElement, setShowElement] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    let timeout;

    const handleScrollElementVisibility = () => {
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        if (window.scrollY > threshold) {
          setShowElement(true);
        } else {
          setShowElement(false);
        }
      }, delay);
    };

    window.addEventListener('scroll', handleScrollElementVisibility);

    return () => {
      window.removeEventListener('scroll', handleScrollElementVisibility);
      clearTimeout(timeout);
    };
  }, [threshold, delay]);

  return { showElement, scrollToTop };
};

export default useScroll;
