import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Button from 'components/BaseComponents/Button';
import Icon from 'components/BaseComponents/Icon';
import './styles.scss';

const ReInviteBuyerModalBody = ({ userId, onModalClose, onSendInvitation }) => (
  <div className="re-invite-modal">
    <Icon className="re-invite-modal__icon" name="info" size="normal" />
    <h3 className="re-invite-modal__title">
      {t('affiliate_partners.affiliations_table.re_invite_modal_body.title')}
    </h3>
    <p className="re-invite-modal__description">
      {t('affiliate_partners.affiliations_table.re_invite_modal_body.description')}
    </p>
    <div className="re-invite-modal__buttons-container">
      <Button
        className="re-invite-modal__buttons-container--button"
        label={t('affiliate_partners.affiliations_table.re_invite_modal_body.cancel_button_label')}
        onClick={onModalClose}
      />
      <Button
        className="re-invite-modal__buttons-container--button"
        label={t('affiliate_partners.affiliations_table.re_invite_modal_body.submit_button_label')}
        type="success"
        onClick={() => {
          onSendInvitation(userId);
          onModalClose();
        }}
      />
    </div>
  </div>
);

ReInviteBuyerModalBody.propTypes = {
  userId: PropTypes.number.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onSendInvitation: PropTypes.func.isRequired,
};

export default ReInviteBuyerModalBody;
