import { t } from 'i18next';
import moment from 'moment';
import {
  COMPLETED,
  IN_PROGRESS,
  CONCLUDED,
  getContractStatusLabel,
  DISSOLVED,
} from 'utils/constants/offerListings';

export const isContractCompleted = (status) => status === COMPLETED;
export const isContractInProgress = (status) => status === IN_PROGRESS;
export const isContractConcluded = (status) => status === CONCLUDED;
export const isContractDissolved = (status) => status === DISSOLVED;

export const getContractStatus = (status) => status;

export const getContractClassName = (contractStatus) => {
  if (contractStatus === DISSOLVED) {
    return 'contract-status__not-uploaded';
  }
  if (contractStatus === COMPLETED) {
    return 'contract-status__uploaded ';
  }

  return 'contract-status';
};

export const getContractInformation = (contractCreatedAt, status) => [
  {
    label: t('shared.bidding_process.contract_details.label.created'),
    value: moment.parseZone(contractCreatedAt).locale('en').format('HH:mm UTC, DD MMM YYYY'),
    className: '',
    isDisabled: false,
  },
  {
    label: t('shared.bidding_process.contract_details.label.status'),
    value: `${getContractStatusLabel[status]}`,
    className: '',
    isDisabled: false,
  },
];

export const getStatusHeading = (contractStatus, contractDeadline) =>
  t(`shared.bidding_process.contract_details.contract_status.${contractStatus}`, {
    contractDeadline,
  });

export const getStatusDescription = (
  contractStatus,
  contractDeadline,
  uploadedAt,
  signedContractFileName,
  listingSerialNumber,
) => {
  switch (contractStatus) {
    case CONCLUDED:
      return [
        t('shared.bidding_process.contract_details.contract_status.description.concluded.text1', {
          contractDeadline,
        }),
        t('shared.bidding_process.contract_details.contract_status.description.concluded.text2'),
      ];

    case IN_PROGRESS:
      return [t('shared.bidding_process.contract_details.contract_status.description.in_progress')];

    case COMPLETED:
      return [
        t('shared.bidding_process.contract_details.contract_status.description.signed', {
          uploadedAt,
          signedContractFileName,
        }),
      ];

    default:
      return [
        t('shared.bidding_process.contract_details.contract_status.description.dissolved', {
          listingSerialNumber,
        }),
      ];
  }
};
