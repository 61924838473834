import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { affiliatePartner, productOffer, productOrder } from 'utils/constants/Routes';
import { hasAnyRole, isVerified, hasCompanyPermissions } from 'utils/helper/UserRoleValidation';
import Button from 'components/BaseComponents/Button';
import { OFFER, ORDER } from 'utils/constants/offerListings';
import { BUYER_ABLITY_SUBJECT, Can, newListingSubject } from 'ability';
import { BUYER, SELLER } from 'utils/constants/userRole';
import PendingVerification from '../PendingVerification';
import NeedProductPermissionsIcon from '../NeedProductPermissionsIcon';
import './styles.scss';

const AvatarCard = ({ className, userDetails, companyDetails, nutsTab }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { first_name: firstName, last_name: lastName, roles } = userDetails;
  const { name: companyName } = companyDetails;

  const avatarClassName = classNames(
    'avatar-container flex flex-col items-center justify-center',
    className,
  );

  const nutsType = nutsTab.replace('Tab','');
  const createProductEnabled = hasCompanyPermissions(nutsType, companyDetails);

  return (
    <div className={avatarClassName}>
      <div className="avatar-container__card flex justify-between items-end">
        <div>
          <p className="avatar-container__card--welcome-text">
            {t('shared.bidding_process.avatar.welcome')}
          </p>
          <h2 className="avatar-container__card--user-name">{`${firstName} ${lastName}`}</h2>
          <p className="avatar-container__card--company-name">{companyName}</p>
        </div>

        <div className="flex flex-col items-end">
          <div className='flex flex-row'>
            <Can I="create" a={newListingSubject({ category: OFFER })}>
              <Button
                onClick={() => navigate(productOffer)}
                label={t("shared.bidding_process.avatar.create_listing.seller")}
                disabled={!createProductEnabled}
                className="avatar-container__card--redirect-button justify-center flex"
              />
            </Can>

            <Can I="create" a={newListingSubject({ category: ORDER })}>
              <Button
                onClick={() => navigate(productOrder)}
                label={t("shared.bidding_process.avatar.create_listing.buyer")}
                className="avatar-container__card--redirect-button justify-center flex"
              />
            </Can>
          </div>

          {hasAnyRole(userDetails, [SELLER, BUYER]) && (
            <div className="avatar-container__card--welcome-text padding-t-16px flex gap-4px">
              {t('shared.bidding_process.avatar.view_listing')}

              <Can I="create" a={newListingSubject({ category: OFFER })}>
                {!createProductEnabled && <NeedProductPermissionsIcon type={nutsType} />}
              </Can>
            </div>
          )}

          <Can I="invite" a={BUYER_ABLITY_SUBJECT}>
            <Button
              onClick={() => navigate(affiliatePartner.inviteBuyer)}
              label={t("shared.bidding_process.avatar.create_listing.affiliate_partner")}
              className="avatar-container__card--redirect-button justify-center flex"
            />
          </Can>
        </div>
      </div>
      {!isVerified(companyDetails) && <PendingVerification className="margin-t-24px" roles={roles} />}
    </div>
  );
};

AvatarCard.defaultProps = {
  className: '',
  nutsTab: '',
};

AvatarCard.propTypes = {
  className: PropTypes.string,
  userDetails: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  companyDetails: PropTypes.shape({
    name: PropTypes.string,
    company_permissions: PropTypes.shape({
      trade_kernel: PropTypes.bool,
      trade_dried_nut_in_shell: PropTypes.bool,
    }),
  }).isRequired,
  nutsTab: PropTypes.string,
};

export default AvatarCard;
