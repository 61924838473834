import { apiSlice } from 'api';
import routes from 'utils/routes';

const affiliatePartnerApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    inviteBuyer: builder.mutation({
      query: ({ payload }) => ({
        url: routes.affiliatePartner.inviteBuyer(),
        method: 'POST',
        body: payload,
      }),
    }),
    setInvitedBuyerPassword: builder.mutation({
      query: (payload) => ({
        url: routes.users.completeSignUp(),
        method: 'POST',
        body: payload,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useInviteBuyerMutation, useSetInvitedBuyerPasswordMutation } = affiliatePartnerApi;
