import { apiSlice } from 'api';
import routes from 'utils/routes';
import { USER } from 'utils/tagTypes';

const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    signup: builder.mutation({
      query: ({ payload }) => ({
        url: routes.auth.signup(),
        method: 'POST',
        body: payload,
      }),
    }),
    login: builder.mutation({
      query: ({ payload }) => ({
        url: routes.auth.login(),
        method: 'POST',
        body: payload,
      }),
    }),
    forgotPassword: builder.mutation({
      query: ({ payload }) => ({
        url: routes.auth.forgotPassword(),
        method: 'PUT',
        body: payload,
      }),
    }),
    resetPassword: builder.mutation({
      query: ({ payload }) => ({
        url: routes.auth.resetPassword(),
        method: 'PUT',
        body: payload,
      }),
    }),
    verifyEmail: builder.mutation({
      query: (token) => ({
        url: routes.auth.verifyEmail({ token }),
        method: 'PUT',
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: routes.auth.logout(),
        method: 'DELETE',
        invalidatesTags: [USER],
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useSignupMutation,
  useLoginMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useVerifyEmailMutation,
  useLogoutMutation,
} = authApi;
