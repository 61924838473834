import { apiSlice } from 'api';
import { toSnakecaseKeys } from 'utils/helper/ObjectKeyConverter';
import { foodSafetyResponseBody } from 'utils/helper/ResponseConverter';
import routes from 'utils/routes';
import { FOOD_SAFETY, PROFILE_COMPLETENESS } from 'utils/tagTypes';

const userApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFoodSafetyDocuments: builder.query({
      query: (params) => routes.foodSafety.entity(params),
      transformResponse: (response) => {
        if (response) {
          return foodSafetyResponseBody(toSnakecaseKeys(response));
        }
        return null;
      },
      providesTags: [FOOD_SAFETY],
    }),
    updateFoodSafetyDocuments: builder.mutation({
      query: ({ companyId, payload }) => ({
        url: routes.foodSafety.entity({ id: companyId }),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [FOOD_SAFETY, PROFILE_COMPLETENESS],
    }),
    deleteFoodSafetyDocuments: builder.mutation({
      query: ({ companyId, attachmentId }) => ({
        url: routes.foodSafety.attachmentDelete({ id: companyId, attachment_id: attachmentId }),
        method: 'DELETE',
      }),
      invalidatesTags: [FOOD_SAFETY, PROFILE_COMPLETENESS],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetFoodSafetyDocumentsQuery,
  useUpdateFoodSafetyDocumentsMutation,
  useDeleteFoodSafetyDocumentsMutation,
} = userApi;
