import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Button from 'components/BaseComponents/Button';
import Icon from 'components/BaseComponents/Icon';
import './styles.scss';

const DeleteConfirmationModalBody = ({ onModalClose, handleDelete }) => (
  <div className="delete-comfirmation-modal">
    <Icon className="delete-comfirmation-modal__icon" name="info" size="normal" />
    <h3 className="delete-comfirmation-modal__title">{t('admin.delete_confirmation_modal_body.title')}</h3>
    <p className="delete-comfirmation-modal__description">
      {t('admin.delete_confirmation_modal_body.description')}
    </p>
    <div className="delete-comfirmation-modal__buttons-container">
      <Button
        className="delete-comfirmation-modal__buttons-container--button"
        label={t('admin.delete_confirmation_modal_body.cancel_button_label')}
        onClick={onModalClose}
      />
      <Button
        className="delete-comfirmation-modal__buttons-container--button"
        label={t('admin.delete_confirmation_modal_body.delete_button_label')}
        type="danger"
        onClick={() => {
          handleDelete();
          onModalClose();
        }}
      />
    </div>
  </div>
);

DeleteConfirmationModalBody.propTypes = {
  onModalClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default DeleteConfirmationModalBody;
