import React, { useContext, useState } from 'react';
import { t } from 'i18next';
import classNames from 'classnames';
import { formatPrice } from 'utils/helper/Charts';
import { hasCompanyPermissions, hasNoRoles } from 'utils/helper/UserRoleValidation';
import Modal from 'components/BaseComponents/Modal';
import Tooltip from 'components/BaseComponents/Tooltip';
import ListingDetailsContext from 'components/BiddingProcess/ListingDetails/ListingDetailsContext';
import AcceptOfferOrderModal from 'components/BiddingProcess/ListingDetails/ListingInformationBody/ActionPanel/AcceptOfferOrderModal';
import AdjustOrderPriceModal from 'components/BiddingProcess/ListingDetails/ListingInformationBody/ActionPanel/AdjustOfferOrderPriceModal';
import NeedProductPermissionsIcon from 'components/Shared/BiddingProcess/NeedProductPermissionsIcon';
import './styles.scss';

const AskingPrice = () => {
  const {
    isUserAdmin,
    isUserBuyer,
    isUserSeller,
    isUserSubscriptionMember,
    currentUserInformation,
    isListingCreator,
    isUserCompanyOwner,
    listingInformation,
    isOrderListing,
    isListingUnPublished,
    isListingConcluded,
    productInformation,
    companyDetails,
  } = useContext(ListingDetailsContext);

  const [isAcceptOfferModalVisible, setIsAcceptOfferModalVisible] = useState(false);
  const [isAdjustOfferModalVisible, setIsAdjustOfferModalVisible] = useState(false);

  const onAdjustOfferModalOpen = () => setIsAdjustOfferModalVisible(true);
  const onAdjustOfferModalClose = () => setIsAdjustOfferModalVisible(false);

  const onAcceptOfferModalOpen = () => setIsAcceptOfferModalVisible(true);
  const onAcceptOfferModalClose = () => setIsAcceptOfferModalVisible(false);

  const cannotAct = isUserSubscriptionMember || hasNoRoles(currentUserInformation);
  const { type: productType } = productInformation;
  const askingPrice = `USD ${formatPrice(productInformation.offerPrice)}`;
  const fullBatchPriceText = `USD ${formatPrice(productInformation?.fullBatchPrice)}`;
  const isUserListingCreator = (isListingCreator || isUserCompanyOwner) && !isListingConcluded && !isUserAdmin;
  const isNonCreatorBuyer = isUserBuyer && !isUserSeller && !isListingConcluded && !isUserAdmin;
  const canUserAcceptOfferOrder = !isListingConcluded && !isUserAdmin && !cannotAct;
  const hasPermissionToAcceptOffer = hasCompanyPermissions(productType, companyDetails);
  const isAcceptOfferOrderButtonDisabled = isListingUnPublished ||
    (isUserBuyer && !isUserSeller && isOrderListing) || !hasPermissionToAcceptOffer;

  const modalTitle = isListingConcluded
    ? t('bidding_process.price')
    : t('bidding_process.asking_price');
  const buttonTitle = isOrderListing
    ? t('bidding_process.accept_order')
    : t('bidding_process.accept_offer');

  const renderActionButton = () => {
    if (isUserListingCreator) {
      return (
        <button
          type="button"
          onClick={onAdjustOfferModalOpen}
          className="asking-price__body__adjust-offer-button"
        >
          {t('bidding_process.adjust_order_price')}
        </button>
      );
    }

    if (isNonCreatorBuyer) {
      return (
        <Tooltip
          content={t('bidding_process.you_are_registered_as_a_buyer')}
          showArrow={true}
          type="midnight-blue"
          size="small"
        >
          <button
            type="button"
            className="asking-price__body-accept-order-button asking-price__body-accept-order-button--disabled"
            disabled="true"
          >
            {buttonTitle}
          </button>
        </Tooltip>
      );
    }

    if (canUserAcceptOfferOrder) {
      return (
        <button
          type="button"
          className={classNames('asking-price__body-accept-order-button', {
            'asking-price__body-accept-order-button--disabled': isAcceptOfferOrderButtonDisabled,
          })}
          disabled={isAcceptOfferOrderButtonDisabled}
          onClick={onAcceptOfferModalOpen}
        >
          <div>{buttonTitle}</div>
          {!hasPermissionToAcceptOffer &&
            <NeedProductPermissionsIcon type={productType} />
          }
        </button>
      );
    }

    return null;
  };

  return (
    <div className="asking-price">
      <div className="asking-price__title">{modalTitle}</div>
      <div className="asking-price__body">
        <div className="asking-price__body-data">
          <span className="asking-price__body-data--currency">{askingPrice}</span>
          <span className="asking-price__body-data--unit">
            /
            {t('bidding_process.per_kg')}
          </span>
          {productInformation.fullBatchPrice && (
            <div>
              <span className="asking-price__body-data--full-batch-price">
                {fullBatchPriceText}
              </span>
              <span className="asking-price__body-data--full-batch-text">
                {t('bidding_process.full_batch')}
              </span>
            </div>
          )}
        </div>

        {renderActionButton()}
      </div>

      <Modal
        isVisible={isAcceptOfferModalVisible}
        onOutsideClick={onAcceptOfferModalClose}
        className="progress-card-modal"
      >
        <Modal.Content>
          <AcceptOfferOrderModal
            productInformation={productInformation}
            isOrder={isOrderListing}
            onModalClose={onAcceptOfferModalClose}
          />
        </Modal.Content>
      </Modal>

      <Modal
        isVisible={isAdjustOfferModalVisible}
        onOutsideClick={onAdjustOfferModalClose}
        className="progress-card-modal"
      >
        <Modal.Content>
          <AdjustOrderPriceModal
            highestBidPrice={listingInformation?.highestBidPrice}
            onModalClose={onAdjustOfferModalClose}
            offerVolume={productInformation.offerVolume}
          />
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default AskingPrice;
