import React, { Fragment, useMemo } from 'react';
import { t } from 'i18next';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { useGetCurrentUserQuery } from 'api/users';
import { useGetListingDetailsQuery } from 'api/listings';
import useCustomNavigate from 'hooks/useCustomNavigate';
import PortalsNavbar from 'components/NavBar/PortalsNavBar';
import LoadingSpinner from 'components/BaseComponents/LoadingSpinner';
import EmptyContentPlaceholder from 'components/Shared/EmptyContentPlaceholder';
import { useGetFoodSafetyTestQuery, useGetProductDetailsQuery } from 'api/products';
import { useGetCompanyQuery } from 'api/companies';
import computeListingFlags from 'components/BiddingProcess/ListingDetails/computeListingFlags';
import ListingDetailsContext from 'components/BiddingProcess/ListingDetails/ListingDetailsContext';
import ListingInformationBody from 'components/BiddingProcess/ListingDetails/ListingInformationBody';
import ListingDetailsBreadCrumb from 'components/BiddingProcess/ListingDetails/ListingDetailsBreadCrumb';
import PortalsFooter from 'components/Shared/PortalsFooter';
import { AbilityContext, Can, listingSubject } from 'ability';
import buildListingsAbility from 'ability/listings';
import './styles.scss';

const ListingDetails = () => {
  const { id: productId } = useParams();
  const navigate = useCustomNavigate();

  const { data: currentUserInformation = {}, isFetching: isFetchingUserInfo } =
    useGetCurrentUserQuery();

  const { data: companyDetails = {}, isFetching: isFetchingCompanyDetails } =
    useGetCompanyQuery({ id: currentUserInformation?.company_id }, { skip: !currentUserInformation?.id });

  const ability = buildListingsAbility({ userDetails: currentUserInformation, companyDetails });

  const {
    data: productInformation = {},
    isFetching: isFetchingProductInfo,
    error: productDetailsError,
  } = useGetProductDetailsQuery({
    product_id: productId,
  });

  const { data: foodSafetyInformation = {}, isLoading: isFetchingFoodSafetyInfo } =
    useGetFoodSafetyTestQuery({
      productId,
    });

  const { data: listingInformation = {}, isFetching: isFetchingListingInfo } =
    useGetListingDetailsQuery(
      {
        listing_id: productInformation?.listingId,
      },
      { skip: !productInformation?.listingId },
    );

  const {
    isListingCreator,
    isUserAdmin,
    isUserSeller,
    isUserBuyer,
    isUserSubscriptionMember,
    isUserCompanyOwner,
    isListingPublished,
    isListingInContract,
    isListingConcluded,
    isListingUnPublished,
    isListingAccepted,
    isOfferListing,
    isOrderListing,
    isUserInContract,
  } = computeListingFlags(currentUserInformation, listingInformation);

  const contextValue = useMemo(
    () => ({
      currentUserInformation,
      companyDetails,
      listingInformation,
      productInformation,
      foodSafetyInformation,
      isListingCreator,
      isUserCompanyOwner,
      isUserAdmin,
      isUserSeller,
      isUserBuyer,
      isUserSubscriptionMember,
      isListingPublished,
      isListingInContract,
      isListingConcluded,
      isListingUnPublished,
      isOfferListing,
      isOrderListing,
    }),
    [productInformation, listingInformation, currentUserInformation, foodSafetyInformation, companyDetails],
  );

  const shouldForwardToContractDetailsPage = isListingAccepted && !isUserInContract && !isUserAdmin;
  const isLoading =
    isFetchingListingInfo ||
    isFetchingUserInfo ||
    isFetchingProductInfo ||
    isFetchingFoodSafetyInfo ||
    isFetchingCompanyDetails;
  const hasNoResultToShow = productDetailsError ||
    (isListingUnPublished && !(isListingCreator || isUserCompanyOwner));

  if (shouldForwardToContractDetailsPage) {
    navigate.listings();
  }

  const renderListingDetails = () => {
    if (hasNoResultToShow) {
      return (
        <div className="listing-details-container__empty">
          <EmptyContentPlaceholder heading={t('bidding_process.no_listing_found')} />
        </div>
      );
    }

    return (
      <AbilityContext.Provider value={ability}>
        <ListingDetailsContext.Provider value={contextValue}>
          <div className="listing-details-container__breadcrumb">
            <ListingDetailsBreadCrumb />
          </div>
          <div className="listing-details-container__body">
            <ListingInformationBody />
          </div>
        </ListingDetailsContext.Provider>

        <Can I="viewPortalsFooter" on={listingSubject(listingInformation)} >
          <PortalsFooter/>
        </Can>
      </AbilityContext.Provider>
    );
  };

  return (
    <Fragment>
      <PortalsNavbar />
      <div
        className={classNames('listing-details', {
          'listing-details--nothing-found': hasNoResultToShow,
        })}
      >
        <div className="listing-details-container">
          {isLoading ? <LoadingSpinner /> : renderListingDetails()}
        </div>
      </div>
    </Fragment>
  );
};

export default ListingDetails;
