import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useCreateProductMutation } from 'api/products';
import { useGetCurrentUserQuery } from 'api/users';
import { useGetCompanyQuery } from 'api/companies';
import { generateDriedNutInShellProductBody, generateKernelProductBody } from 'utils/helper/RequestBody';
import { handleServerValidationErrors } from 'utils/helper/Validations';
import { offerOrderPageIndex } from 'utils/constants/listingCreation';
import useCustomNavigate from 'hooks/useCustomNavigate';
import DriedNutInShellProductDetailsForm from 'components/Shared/BiddingProcess/DriedNutInShellProductDetailsForm';
import KernelProductDetailsForm from 'components/Shared/BiddingProcess/KernelProductDetailsForm';
import ToastNotification from 'components/Shared/ToastNotification';
import { DRIED_NUT_IN_SHELL_PRODUCT, KERNEL_PRODUCT } from 'utils/constants/product';
import CreateListingLayout from '../Layout';

const ProductDetails = ({ category }) => {
  const toastRef = useRef();
  const navigate = useCustomNavigate();
  const [createProduct] = useCreateProductMutation();
  const { data: user = {} } = useGetCurrentUserQuery();
  const { data: companyDetails = {} } = useGetCompanyQuery({ id: user?.company_id });
  const currentPageIndex = offerOrderPageIndex.productDetails;

  const saveKernelProduct = (payload) => {
    const kernelProductPayload = { ...payload, type: KERNEL_PRODUCT };
    const requestBody = generateKernelProductBody(kernelProductPayload, category);

    return createProduct(requestBody)
      .unwrap()
      .then(({ id: productId }) => {
        navigate.reviewListingById(productId);
      })
      .catch(({ data, status }) => handleServerValidationErrors(toastRef, status, data?.message));
  };

  const saveDriedNutInShellProduct = (payload) => {
    const driedNutInShellProductPayload = { ...payload, type: DRIED_NUT_IN_SHELL_PRODUCT };
    const requestBody = generateDriedNutInShellProductBody(driedNutInShellProductPayload, category);
    return createProduct(requestBody)
      .unwrap()
      .then(({ id: productId }) => {
        navigate.reviewListingById(productId);
      })
      .catch(({ data, status }) => handleServerValidationErrors(toastRef, status, data?.message));
  };

  return (
    <CreateListingLayout activeStep={currentPageIndex} category={category}>
      { localStorage.getItem('nutsTab') === DRIED_NUT_IN_SHELL_PRODUCT && (
        <DriedNutInShellProductDetailsForm
          onProductSave={saveDriedNutInShellProduct}
          companyDetails={companyDetails}
          category={category}
        />
      )}
      { localStorage.getItem('nutsTab') === KERNEL_PRODUCT && (
        <KernelProductDetailsForm
          onProductSave={saveKernelProduct}
          companyDetails={companyDetails}
          category={category}
        />
      )}
      <ToastNotification toastRef={toastRef} />
    </CreateListingLayout>
  );
};

ProductDetails.defaultProps = {
  category: null,
};

ProductDetails.propTypes = {
  category: PropTypes.string,
};

export default ProductDetails;
