import React from 'react';
import MSMRoutes from 'components/Routes';
import useScrollToTopOnRouteChange from 'hooks/useScrollToTopOnRouteChange';
import SmallerScreenPopup from 'components/DevelopmentGoing/SmallerScreenPopup';
import './App.scss';

const App = () => {
  useScrollToTopOnRouteChange();

  return (
    <div className="app__main">
      <MSMRoutes />
      <SmallerScreenPopup />
    </div>
  );
};

export default App;
