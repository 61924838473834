import React, { useRef } from 'react';
import classNames from 'classnames';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import ReactFlagsSelect from 'react-flags-select';
import { useSignupMutation } from 'api/auth';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { labelWithHints } from 'utils/helper/LabelWithHints';
import { generateSignupBody } from 'utils/helper/RequestBody';
import { handleServerValidationErrors, requiredField } from 'utils/helper/Validations';
import Input from 'components/BaseComponents/Input';
import Button from 'components/BaseComponents/Button';
import CheckBox from 'components/BaseComponents/CheckBox';
import PasswordInput from 'components/Shared/PasswordInput';
import ToastNotification from 'components/Shared/ToastNotification';
import FormFooter from 'components/SignUp/FormFooter';
import { AFFILIATE_PARTNER, OWNER } from 'utils/constants/userRole';
import TermsSection from './TermsSection';
import './styles.scss';

const AffiliatePartnerSignUpForm = () => {
  const { t } = useTranslation();
  const toastRef = useRef();
  const [signup] = useSignupMutation();
  const navigate = useCustomNavigate();

  const handleSignup = async (params) => {
    if (params.user_password !== params.confirm_password) {
      return {
        user_password: t('signup.step2_form.validation_error.password_not_matched'),
        confirm_password: t('signup.step2_form.validation_error.password_not_matched'),
      };
    }

    const requestBody = generateSignupBody(params);
    const response = await signup({
      payload: {
        company_attributes: requestBody,
        roles: [AFFILIATE_PARTNER, OWNER]
      }
    });

    if (response.error) {
      const {
        error: {
          data: { message },
          status,
        },
      } = response;

      const errors = handleServerValidationErrors(toastRef, status, message);

      if (errors.user_base) {
        return {
          user_email: errors.user_base,
        };
      }

      return errors;
    }

    navigate.affiliatePartnerRegistrationSuccess(params.user_email);

    return null;
  };

  return (
    <div className="sign-up-form">
      <h2 className="sign-up-form__title">{t('signup.step2_form.title')}</h2>
      <p className="sign-up-form__message">{t('signup.step2_form.message')}</p>
      <h5 className="sign-up-form__form-title">
        {t('signup.step2_form.form_header')}
        <span className="sign-up-form__form-title--required">*</span>
      </h5>
      <Form
        onSubmit={handleSignup}
        render={({ handleSubmit, invalid, dirtySinceLastSubmit, submitting }) => (
          <form onSubmit={handleSubmit} className="margin-t-16px">
            <Field name="user_first_name" validate={requiredField}>
              {({ input, meta }) => (
                <Input
                  label={t('signup.step2_form.first_name.label')}
                  placeholder={t('signup.step2_form.first_name.placeholder')}
                  required
                  size="small"
                  className="sign-up-form__first-name margin-b-20px"
                  error={meta?.error || meta?.submitError}
                  touched={meta.touched}
                  value={input.value}
                  onChange={input.onChange}
                />
              )}
            </Field>
            <Field name="user_last_name" validate={requiredField}>
              {({ input, meta }) => (
                <Input
                  label={t('signup.step2_form.last_name.label')}
                  placeholder={t('signup.step2_form.last_name.placeholder')}
                  required
                  size="small"
                  className="sign-up-form__last-name"
                  error={meta?.error || meta?.submitError}
                  touched={meta.touched}
                  value={input.value}
                  onChange={input.onChange}
                />
              )}
            </Field>
            <Field name="name" validate={requiredField}>
              {({ input, meta }) => (
                <Input
                  label={labelWithHints(
                    t('signup.step2_form.company_name.label'),
                    t('affiliate_partners.signup.tooltip.company_name'),
                  )}
                  placeholder={t('signup.step2_form.company_name.placeholder')}
                  required
                  size="small"
                  className="margin-t-20px"
                  error={meta?.error || meta?.submitError}
                  touched={meta.touched}
                  value={input.value}
                  onChange={input.onChange}
                />
              )}
            </Field>
            <Field name="country_code_of_registration" validate={requiredField}>
              {({ input, meta }) => (
                <div className="margin-t-20px">
                  <p className="sign-up-form__country--title">
                    {labelWithHints(
                      t('signup.step2_form.country.label'),
                      t('affiliate_partners.signup.tooltip.country'),
                    )}
                    <span className="sign-up-form__country--title--required">*</span>
                  </p>
                  <ReactFlagsSelect
                    selectButtonClassName={classNames('sign-up-form__country--dropdown', {
                      'sign-up-form__country--dropdown--error': meta?.touched && meta?.error,
                    })}
                    searchable
                    placeholder={t('signup.step2_form.country.placeholder')}
                    selected={input.value}
                    onSelect={(code) => input.onChange(code)}
                  />
                </div>
              )}
            </Field>
            <Field name="registration_number" validate={requiredField}>
              {({ input, meta }) => (
                <Input
                  label={labelWithHints(
                    t('signup.step2_form.company_registration_number.label'),
                    t('affiliate_partners.signup.tooltip.registration_number'),
                  )}
                  placeholder={t('signup.step2_form.company_registration_number.placeholder')}
                  required
                  size="small"
                  className="margin-t-20px"
                  error={meta?.error || meta?.submitError}
                  touched={meta.touched}
                  value={input.value}
                  onChange={input.onChange}
                />
              )}
            </Field>
            <Field name="user_email" validate={requiredField}>
              {({ input, meta }) => (
                <Input
                  label={t('signup.step2_form.user_email.label')}
                  placeholder={t('signup.step2_form.user_email.placeholder')}
                  required
                  size="small"
                  className="margin-t-20px"
                  error={meta?.error || meta?.submitError}
                  touched={meta.touched}
                  value={input.value}
                  onChange={input.onChange}
                />
              )}
            </Field>
            <Field name="user_password" validate={requiredField}>
              {({ input, meta }) => (
                <PasswordInput
                  label={t('signup.step2_form.password.label')}
                  placeholder={t('signup.step2_form.password.placeholder')}
                  required
                  size="small"
                  className="margin-t-20px margin-b-32px"
                  error={meta?.error || meta?.submitError}
                  touched={meta.touched}
                  value={input.value}
                  onChange={input.onChange}
                />
              )}
            </Field>
            <Field name="confirm_password" validate={requiredField}>
              {({ input, meta }) => (
                <PasswordInput
                  label={t('signup.step2_form.confirm_password.label')}
                  placeholder={t('signup.step2_form.confirm_password.placeholder')}
                  required
                  size="small"
                  className="margin-t-20px margin-b-32px"
                  error={meta?.error || meta?.submitError}
                  touched={meta.touched}
                  value={input.value}
                  onChange={input.onChange}
                />
              )}
            </Field>
            <h5 className="sign-up-form__form-title">
              {t('signup.step2_form.checkbox_card_title')}
              <span className="sign-up-form__form-title--required">*</span>
            </h5>
            <Field name="is_accept_terms" type="checkbox" validate={requiredField}>
              {({ input, meta }) => (
                <CheckBox
                  id="terms&condition"
                  label={<TermsSection />}
                  className="sign-up-form__policy-content margin-t-16px"
                  hintText={meta.touched && meta.error}
                  isChecked={input.checked}
                  onChange={(checked) => {
                    input.onChange(checked);
                  }}
                />
              )}
            </Field>
            <Button
              className="margin-b-20px margin-t-32px sign-up-form__submit-btn"
              type="success"
              label={t('signup.step2_form.submit_button')}
              submitType="submit"
              disabled={(!dirtySinceLastSubmit && invalid) || submitting}
            />
          </form>
        )}
      />
      <FormFooter />
      <ToastNotification toastRef={toastRef} />
    </div>
  );
};

export default AffiliatePartnerSignUpForm;
