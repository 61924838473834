import React from 'react';
import { useTranslation } from 'react-i18next';
import useCustomNavigate from 'hooks/useCustomNavigate';
import Button from 'components/BaseComponents/Button';
import './style.scss';

const FormHeader = () => {
  const { t } = useTranslation();
  const navigate = useCustomNavigate();

  return (
    <div className="form-header">
      <div className="flex justify-between items-center margin-b-32px">
        <h4 className="form-header__title">
          {t('signup.header.title', { currentStep: 1, totalSteps: 1 })}
        </h4>
        <Button
          className="form-header__cancel-button"
          label={t('signup.header.button_content')}
          onClick={() => navigate.affiliatePartnerPortal()}
        />
      </div>
      <div className="flex gap-12px">
        <div className="form-header__steps form-header__steps--completed-full" />
      </div>
    </div>
  );
};

export default FormHeader;
