import React, { useEffect, useRef, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import { v4 as uuidv4 } from 'uuid';
import Icon from '../Icon';
import Tooltip from '../Tooltip';
import SelectBox from '../SelectBox';
import { isOutsideRange } from './utils';
import addTransformToCalender from './addTransformToCalender';

const DatePicker = props => {
  const {
    className,
    focused,
    locale,
    size,
    onChange,
    onFocusChange,
    hideMonthsAndYearsWithNoActiveDates,
    pastYearsCount,
    futureYearsCount,
    minDate,
    maxDate,
    enablePastDates,
    disableFutureDates,
    appendToBody,
    customInputIcon,
    hideKeyboardShortcutsPanel,
    navPrev,
    navNext,
    numberOfMonths,
    showDefaultInputIcon,
    showClearDate,
    label,
    displayFormat,
    id,
    date,
    firstDayOfWeek,
    required,
    disabled,
    fullWidth,
    placeholder,
    error,
    hideNavButtons,
    onPrevMonthClick,
    onNextMonthClick,
    onClose,
    callBackBtn,
    verticalSpacing,
    openDirection
  } = props;

  const memoizedUUID = id || uuidv4();
  const callbackPortionRef = useRef(null);
  const inputDateId = `date-input-${memoizedUUID}`;

  const momentizedDate = useMemo(() => {
    moment.locale(locale);
    if (!date) {
      return null;
    }


    return moment(date, 'YYYY-MM-DD');
  }, [date, locale]);

  useEffect(() => {
    addTransformToCalender(size, inputDateId);
  });

  const onDateChange = selectedDate => {
    if (!selectedDate) {
      onChange('');
      return;
    }

    const dateStr = moment(selectedDate).format('YYYY/MM/DD');
    onChange(dateStr);
  };

  const onFocusChangeHandler = ({ focused: focusedStateFromProp }) => {
    onFocusChange(focusedStateFromProp);
  };

  const getFilteredYears = useMemo(() => {
    const years = [];
    let startYear;
    let endYear;

    startYear = moment().subtract(pastYearsCount, 'y').year();

    endYear = moment().add(futureYearsCount, 'y').year();

    if (hideMonthsAndYearsWithNoActiveDates) {
      const currentYear = moment().year();

      /*
      |   enablePastDates  |   disableFutureDates  |  startYear  |  endYear
      -------------------------------------------------------------------------
      |        false       |          false        | currentYear |  default
      -------------------------------------------------------------------------
      |        false       |          true         | currentYear |  currentYear
      -------------------------------------------------------------------------
      |        true        |          false        | default     |  default
      -------------------------------------------------------------------------
      |        true        |          true         | default     |  currentYear
      */

      if (minDate) {
        startYear = moment(minDate).year();
      } else if (
        !(enablePastDates || disableFutureDates) ||
        (!enablePastDates && disableFutureDates)
      ) {
        startYear = currentYear;
      }

      if (maxDate) {
        endYear = moment(maxDate).year();
      } else if (disableFutureDates) {
        endYear = currentYear;
      }
    }

    for (let year = startYear; year <= endYear; year += 1) {
      years.push({ label: year, value: year });
    }

    return years;
  }, [
    hideMonthsAndYearsWithNoActiveDates,
    pastYearsCount,
    futureYearsCount,
    minDate,
    maxDate,
    enablePastDates,
    disableFutureDates,
  ]);

  const getMonths = useCallback(
    activeYear => {
      moment.locale(locale);
      let monthsList = moment.months();

      if (hideMonthsAndYearsWithNoActiveDates) {
        const currentYear = moment().year();
        const currentMonthIndex = moment().month();
        let startMonthIndex = 0;
        let endMonthIndex = 11;

        if (minDate) {
          if (activeYear === moment(minDate).year()) {
            startMonthIndex = moment(minDate).month();
          }
        } else if (activeYear === currentYear) {
          startMonthIndex = !enablePastDates ? currentMonthIndex : 0;
        }

        if (maxDate) {
          if (activeYear === moment(maxDate).year()) {
            endMonthIndex = moment(maxDate).month();
          }
        } else if (activeYear === currentYear) {
          endMonthIndex = disableFutureDates ? currentMonthIndex : 11;
        }

        monthsList = monthsList.filter(
          (month, index) => index >= startMonthIndex && index <= endMonthIndex,
        );
      }

      return monthsList.map(monthName => ({ label: monthName, value: monthName }));
    },
    [hideMonthsAndYearsWithNoActiveDates, minDate, maxDate, enablePastDates, disableFutureDates],
  );

  return (
    <div
      className={classNames('date-picker', className, `date-picker--${size}`, {
        'date-picker--full-width': fullWidth,
        'date-picker--error': error,
        'date-picker--no-nav-buttons': hideNavButtons,
      })}
    >
      {label && (
        <span
          className={classNames('date-picker__label', {
            'date-picker__label--required': required,
            'date-picker__label--disabled': disabled,
          })}
        >
          {label}
        </span>
      )}
      <SingleDatePicker
        isOutsideRange={day =>
          isOutsideRange(day, minDate, maxDate, enablePastDates, disableFutureDates)
        }
        id={inputDateId}
        appendToBody={appendToBody}
        customInputIcon={customInputIcon}
        date={momentizedDate}
        daySize={38}
        firstDayOfWeek={firstDayOfWeek}
        displayFormat={displayFormat}
        focused={focused}
        hideKeyboardShortcutsPanel={hideKeyboardShortcutsPanel}
        inputIconPosition="after"
        numberOfMonths={numberOfMonths}
        navPrev={navPrev}
        navNext={navNext}
        onDateChange={onDateChange}
        onFocusChange={onFocusChangeHandler}
        onPrevMonthClick={onPrevMonthClick}
        onNextMonthClick={onNextMonthClick}
        onClose={onClose}
        showDefaultInputIcon={showDefaultInputIcon}
        showClearDate={showClearDate}
        required={false}
        disabled={disabled}
        placeholder={placeholder}
        navPosition="navPositionBottom"
        verticalSpacing={verticalSpacing}
        openDirection={openDirection}
        renderWeekHeaderElement={day => (
          <small
            style={{
              display: 'block',
              marginTop: `${callBackBtn ? callbackPortionRef.current?.offsetHeight + 20 : 0}px`,
            }}
          >
            {day}
          </small>
        )}
        renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
          <>
            {callBackBtn && (
              <div className="date-picker__call-back-btn" ref={callbackPortionRef}>
                {callBackBtn}
              </div>
            )}
            <div className="date-picker__dropdown-navigation">
              <SelectBox
                className="date-picker__dropdown-navigation-select-box"
                onChange={selectedValue => {
                  onMonthSelect(month, selectedValue.value);
                }}
                value={{ label: month.format('MMMM'), value: month.format('MMMM') }}
                options={getMonths(month.year())}
                size="tiny"
                width="full"
                isClearable={false}
              />
              <SelectBox
                className="date-picker__dropdown-navigation-select-box"
                onChange={newSelectedYear => {
                  onYearSelect(month, newSelectedYear.value);
                }}
                value={{ label: month.year(), value: month.year() }}
                options={getFilteredYears}
                size="tiny"
                width="full"
                isClearable={false}
              />
            </div>
          </>
        )}
      />
      {error && (
        <div
          className={classNames(
            'date-picker__tooltip-wrapper',
            'date-picker__tooltip-wrapper--error',
            { 'date-picker__tooltip-wrapper--error-with-clear-btn': date && showClearDate },
          )}
        >
          <Tooltip
            className="date-picker__tooltip"
            content={error}
            position="bottom-right"
            type="danger"
            gap={0}
          >
            <Icon name="invalid" color="danger" />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

DatePicker.defaultProps = {
  id: null,
  className: null,
  focused: false,
  appendToBody: false,
  label: '',
  displayFormat: 'LL',
  customInputIcon: <Icon name="today" />,
  navPrev: <Icon name="arrowBackAlt" />,
  navNext: <Icon name="arrowForwardAlt" />,
  hideKeyboardShortcutsPanel: true,
  numberOfMonths: 1,
  showDefaultInputIcon: true,
  showClearDate: false,
  date: null,
  locale: 'en',
  minDate: null,
  maxDate: null,
  required: false,
  disabled: false,
  firstDayOfWeek: 1,
  onChange: () => {},
  onFocusChange: () => {},
  onPrevMonthClick: () => {},
  onNextMonthClick: () => {},
  onClose: () => {},
  enablePastDates: false,
  disableFutureDates: false,
  hideMonthsAndYearsWithNoActiveDates: false,
  size: 'small',
  fullWidth: false,
  placeholder: 'Select Date',
  error: null,
  pastYearsCount: 100,
  futureYearsCount: 5,
  hideNavButtons: true,
  callBackBtn: null,
  verticalSpacing: 22,
  openDirection: 'down',
};

DatePicker.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  focused: PropTypes.bool,
  appendToBody: PropTypes.bool,
  label: PropTypes.string,
  displayFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  customInputIcon: PropTypes.node,
  navPrev: PropTypes.node,
  navNext: PropTypes.node,
  hideKeyboardShortcutsPanel: PropTypes.bool,
  numberOfMonths: PropTypes.number,
  showDefaultInputIcon: PropTypes.bool,
  showClearDate: PropTypes.bool,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),
  locale: PropTypes.string,
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  firstDayOfWeek: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6]),
  onChange: PropTypes.func,
  onFocusChange: PropTypes.func,
  onPrevMonthClick: PropTypes.func,
  onNextMonthClick: PropTypes.func,
  onClose: PropTypes.func,
  enablePastDates: PropTypes.bool,
  disableFutureDates: PropTypes.bool,
  hideMonthsAndYearsWithNoActiveDates: PropTypes.bool,
  fullWidth: PropTypes.bool,
  size: PropTypes.oneOf(['tiny', 'small', 'large', 'huge']),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  pastYearsCount: PropTypes.number,
  futureYearsCount: PropTypes.number,
  hideNavButtons: PropTypes.bool,
  callBackBtn: PropTypes.node,
  verticalSpacing: PropTypes.number,
  openDirection: PropTypes.oneOf(['up', 'down']),
};

export default DatePicker;
