import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useGetSimilarProductsQuery } from 'api/products';
import LoadingSpinner from 'components/BaseComponents/LoadingSpinner';
import EmptyContentPlaceholder from 'components/Shared/EmptyContentPlaceholder';
import Icon from 'components/BaseComponents/Icon';
import Tooltip from 'components/BaseComponents/Tooltip';
import { OFFER } from 'utils/constants/offerListings';
import ProductCard from '../ProductCard';

const SimilarListings = ({ productDetails }) => {
  const { t } = useTranslation();

  const { id: productId, category } = productDetails;
  const { data: similarListings, isFetching } = useGetSimilarProductsQuery(
    { product_id: productId },
    { skip: !productId },
  );
  
  const isOffer = category === OFFER;
  const priceLabel = !isOffer && t('shared.product_card.per_kg');
  const batchLabel = isOffer
    ? t('shared.product_card.per_kg')
    : t('shared.product_card.batch_label');

  if (isFetching) {
    return <LoadingSpinner className="loading-spinner__relative margin-y-32px" />;
  }

  const productIdTitle = t(
    `offer_creation.product_details.review.similar_product.product_id.${category}`,
  );
  const similarListingsCount = similarListings.length;
  const isListingsExists = similarListingsCount > 0;

  return (
    <div className="listing-card margin-t-32px">
      <h3 className="listing-card__title flex justify-between items-center">
        {t(`offer_creation.product_details.review.similar_product.${category}`, {
          total: similarListingsCount,
        })}
        <Tooltip
          content={t(`offer_creation.product_details.review.tooltip.${category}`)}
          size="tiny"
        >
          <Icon name="help" color="gray" size="normal" className="listing-card__help-icon" />
        </Tooltip>
      </h3>
      <div className="padding-24px flex flex-col gap-12px">
        {similarListings.map(
          ({
            id,
            offerVolume,
            nutGrade,
            nutStyle,
            size,
            type,
            soundKernelRecovery,
            unsoundKernelRecovery,
            countryOfOrigin,
            offerPrice,
            estimatedShippingDate,
            bestBeforeDate,
            fullBatchPrice,
            listing: { serialNumber } = {},
          }) => (
            <ProductCard
              key={id}
              className="listing-card__product-card"
              quantity={offerVolume}
              quality={nutGrade}
              style={nutStyle}
              size={size}
              type={type}
              soundKernelRecovery={soundKernelRecovery}
              unsoundKernelRecovery={unsoundKernelRecovery}
              soundKernelRecoveryLabel={t('shared.product_card.sound_kernel_recovery_label')}
              unsoundKernelRecoveryLabel={t('shared.product_card.unsound_kernel_recovery_label')}
              country={countryOfOrigin}
              priceLabel={priceLabel}
              perKgPrice={offerPrice}
              isIdVisible={true}
              idLabel={productIdTitle}
              id={serialNumber}
              esdLabel={t('shared.product_card.esd_label')}
              esdDate={estimatedShippingDate}
              bestBeforeLabel={t('shared.product_card.best_before_label')}
              bestBeforeDate={bestBeforeDate}
              batchLabel={batchLabel}
              currency={t('shared.product_card.currency_usd')}
              batchPrice={!isOffer && fullBatchPrice}
              redirectLink={`/listings/${id}`}
            />
          ),
        )}
      </div>
      {!isFetching && !isListingsExists && (
        <EmptyContentPlaceholder
          heading={t('shared.bidding_process.empty_content_placeholder.heading')}
        />
      )}
    </div>
  );
};

SimilarListings.propTypes = ({
  productDetails: PropTypes.shape({
    id: PropTypes.number,
    category: PropTypes.string,
  }).isRequired,
});

export default SimilarListings;
