import React from 'react';
import { Outlet } from 'react-router-dom';
import { useGetCurrentUserQuery } from 'api/users';
import { hasPartnerRole } from 'utils/helper/UserRoleValidation';
import { login } from 'utils/constants/Routes';

const ProtectedRoutePartner = () => {
  const { data: currentUserDetails, isSuccess } = useGetCurrentUserQuery();

  if (isSuccess) {
    if (hasPartnerRole(currentUserDetails)) {
      return <Outlet />;
    }

    window.location.replace(login);
    return null;
  }

  return null;
};

export default ProtectedRoutePartner;
