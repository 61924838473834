import React from 'react';
import useAuth from 'hooks/useAuth';
import LoadingSpinner from 'components/BaseComponents/LoadingSpinner';
import LoginForm from './LoginForm';
import './styles.scss';

const Login = () => {
  const { isFetching } = useAuth();

  if (isFetching) {
    return <LoadingSpinner />;
  }

  return (
    <div className="login flex">
      <div className="login__left-container" />
      <div className="login__right-container">
        <LoginForm />
      </div>
    </div>
  );
};

export default Login;
