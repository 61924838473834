import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { useVerifyEmailMutation } from 'api/auth';

const VerifyEmail = () => {
  const [verifyEmail] = useVerifyEmailMutation();
  const [searchParams] = useSearchParams();
  const navigate = useCustomNavigate();
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    if (!isVerified) {
      verifyEmail(searchParams.get('token'))
        .unwrap()
        .then(() => {
          setIsVerified(true);
          navigate.loginPage();
        })
        .catch(() => {
          navigate.signupPage();
        });
    }
  }, [isVerified]);

  return null;
};

export default VerifyEmail;
