// Temporary Component
// Will be removed once mobile responsiveness is achieved
// i18n is not included intentionally as it's a temporary component.
import React, { useState, useEffect } from 'react';
import { t } from 'i18next';
import Icon from 'components/BaseComponents/Icon';
import Modal from 'components/BaseComponents/Modal';
import Button from 'components/BaseComponents/Button';
import './styles.scss';

const SmallerScreenPopup = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [showModal, setShowModal] = useState(width < 700);

  useEffect(() => {
    const handleResize = () => {
      const currentWidth = window.innerWidth;
      setWidth(currentWidth);
      setShowModal(currentWidth < 1200);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <Modal
      isVisible={showModal}
      onOutsideClick={handleModalClose}
      className="smaller-screen-warning-modal"
    >
      <Modal.Content>
        <Icon name="info" size="normal" className="smaller-screen-warning-modal__icon" />
        <h3 className="smaller-screen-warning-modal__title">
          {t('shared.development_ongoing.recommended_screen_size_warning')}
        </h3>
        <p className="smaller-screen-warning-modal__description">
          {t('shared.development_ongoing.recommended_screen_size')}
        </p>
        <Button
          label="Continue"
          type="success"
          className="smaller-screen-warning-modal__button"
          onClick={handleModalClose}
        />
      </Modal.Content>
    </Modal>
  );
};

export default SmallerScreenPopup;
