import React, { useState } from 'react';
import { t } from 'i18next';
import useAuth from 'hooks/useAuth';
import LoadingSpinner from 'components/BaseComponents/LoadingSpinner';
import FormHeader from './FormHeader';
import Step1Form from './Step1Form';
import Step2Form from './Step2Form';
import LeftBanner from './LeftBanner';
import './style.scss';

const SignUp = () => {
  const [userRoles, setUserRoles] = useState([]);
  const { isFetching } = useAuth();

  const onStep1FormSubmit = (values) => setUserRoles(Object.keys(values).filter((role) => values[role]))
  const isUserRolesPresent = userRoles.length > 0

  if (isFetching) {
    return <LoadingSpinner />;
  }

  let registrationForm;
  if (isUserRolesPresent) {
    registrationForm = <Step2Form userRoles={userRoles} />;
  } else {
    registrationForm = <Step1Form onFormSubmit={onStep1FormSubmit} />;
  }

  const step1 = 1;
  const step2 = 2;
  const currentStep = isUserRolesPresent ? step2 : step1;

  return (
    <div className="signup flex">
      <div className="signup__left-container">
        <LeftBanner
          title={t('signup.left_banner.title')}
          message={t('signup.left_banner.message')}
        />
      </div>
      <div className="signup__right-container">
        <FormHeader currentStep={currentStep} />
        {registrationForm}
      </div>
    </div>
  );
};

export default SignUp;
