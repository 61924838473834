import i18n from 'i18n';
import { hasAnyRole, hasBuyerRole, hasNoRoles, hasPartnerRole, hasSellerRole } from 'utils/helper/UserRoleValidation';
import {
  affiliatePartnerStaticPage,
  retailsPage,
  newsPage,
  landingPage,
  aboutUs,
  services,
  contactUs,
  support,
} from './Routes';
import { BUYER, SELLER, SUBSCRIPTION_MEMBER } from './userRole';

export const primaryNavContents = [
  {
    id: 1,
    name: () => i18n.t('navbar.primary.links.home_link'),
    link: () => landingPage,
    dropDownContents: null,
  },
  {
    id: 2,
    name: () => i18n.t('navbar.primary.links.about_link'),
    link: () => aboutUs,
    dropDownContents: null,
  },
  {
    id: 3,
    name: () => i18n.t('navbar.primary.links.services_link'),
    link: () => services,
    dropDownContents: null,
  },
  {
    id: 4,
    name: () => i18n.t('navbar.primary.links.affiliate_partner_link'),
    link: () => affiliatePartnerStaticPage,
    dropDownContents: null,
  },
  // { Commenting since MSM wanted to hide this for the time being, might need iun the future.
  //   id: 5,
  //   name: () => i18n.t('navbar.primary.links.esg_link'),
  //   link: () => '/esg',
  //   dropDownContents: null,
  //   isDisabled: true,
  // },
  {
    id: 6,
    name: () => i18n.t('navbar.primary.links.contact_link'),
    link: () => contactUs,
    dropDownContents: null,
  },
  {
    id: 7,
    name: () => i18n.t('navbar.primary.links.news'),
    link: () => newsPage,
    dropDownContents: null,
  },
];

export const defaultNavContents = (roles) => ([
  ... hasAnyRole(roles, [BUYER, SELLER, SUBSCRIPTION_MEMBER]) || hasNoRoles(roles) ? [{
    id: 1,
    name: () => i18n.t('navbar.primary.links.offer_listings'),
    link: () => `/listings`,
    dropDownContents: null,
  }] : [],
  ... hasPartnerRole(roles) ? [{
    id: 1,
    name: () => i18n.t('navbar.primary.links.my_affiliations'),
    link: () => '/partner/portal',
    dropDownContents: null,
  }] : [],
  ... hasAnyRole(roles, [BUYER, SELLER]) ? [{
    id: 2,
    name: () => i18n.t('navbar.default.links.my_business'),
    link: () => null,
    dropDownContents: [
      ... hasBuyerRole(roles) ? [{
        id: 1,
        name: () => i18n.t(`navbar.default.links.orders`),
        link: () => ('/orders'),
        isDisabled: false,
      }] : [],
      ... hasSellerRole(roles) ? [{
        id: 2,
        name: () => i18n.t(`navbar.default.links.offers`),
        link: () => ('/offers'),
        isDisabled: false,
      }] : [],
      {
        id: 3,
        name: () => i18n.t('navbar.default.dropdown_links.my_business.my_contacts'),
        link: () => `/contracts`,
      },
      {
        id: 4,
        name: () => i18n.t('navbar.default.dropdown_links.my_business.my_bids'),
        link: () => `/bids`,
      }
    ],
  }] : [],
  {
    id: 3,
    name: () => i18n.t('navbar.default.links.resources'),
    link: () => null,
    dropDownContents: [
      {
        id: 1,
        name: () => i18n.t('navbar.primary.links.retail_link'),
        link: () => retailsPage,
      },
      {
        id: 2,
        name: () => i18n.t('navbar.default.dropdown_links.resources.support'),
        link: () => support,
        isDisabled: true,
      },
      {
        id: 3,
        name: () => i18n.t('navbar.primary.links.contact_link'),
        link: () => contactUs,
        isDisabled: true,
      },
    ],
  },
  ... hasAnyRole(roles, [BUYER, SELLER]) ? [{
    id: 4,
    name: () => i18n.t('navbar.default.links.company_business'),
    link: () => null,
    dropDownContents: [
      ... hasBuyerRole(roles) ? [{
        id: 1,
        name: () => i18n.t(`navbar.default.links.company.orders`),
        link: () => '/company/orders',
      }] : [],
      ... hasSellerRole(roles) ? [{
        id: 2,
        name: () => i18n.t(`navbar.default.links.company.offers`),
        link: () => '/company/offers',
      }] : [],
      {
        id: 3,
        name: () => i18n.t(`navbar.default.links.company.contracts`),
        link: () => '/company/contracts',
      },
      {
        id: 4,
        name: () => i18n.t(`navbar.default.links.company.bids`),
        link: () => '/company/bids',
      },
    ],
  }] : [],
]);

export const subscriptionMemberNavContents = [
  {
    id: 1,
    name: () => i18n.t('navbar.primary.links.offer_listings'),
    link: () => `/listings`,
    dropDownContents: null,
  },
  {
    id: 2,
    name: () => i18n.t('navbar.default.links.resources'),
    link: () => null,
    dropDownContents: [
      {
        id: 1,
        name: () => i18n.t('navbar.primary.links.retail_link'),
        link: () => retailsPage,
      },
      {
        id: 2,
        name: () => i18n.t('navbar.default.dropdown_links.resources.support'),
        link: () => support,
        isDisabled: true,
      },
      {
        id: 3,
        name: () => i18n.t('navbar.primary.links.contact_link'),
        link: () => contactUs,
        isDisabled: true,
      },
    ],
  },
]

export const avatarDropdownContent = [
  {
    id: 1,
    icon: 'person',
    name: () => i18n.t('navbar.avatar.profile'),
  },
];

export const navContentByRoles = (roles) => defaultNavContents({ roles });
