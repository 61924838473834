import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useGetProfileCompletenessQuery, useGetCurrentUserQuery } from 'api/users';
import { useGetCompanyQuery } from 'api/companies';
import { HUNDRED_PERCENT } from 'utils/constants/progressCardDetails';
import { getProgressCardTexts } from 'utils/helper/ProgressCard';
import { REQUESTED_VERIFICATION, VERIFIED } from 'utils/constants/userStatus';
import Icon from 'components/BaseComponents/Icon';
import Tooltip from 'components/BaseComponents/Tooltip';
import Button from 'components/BaseComponents/Button';
import ProgressBar from 'components/BaseComponents/ProgressBar';
import Modal from 'components/BaseComponents/Modal';
import SubHeading from './SubHeading';
import ProgressCardList from './ProgressCardList';
import ProgressCardModalBody from './ModalBody';
import './styles.scss';

const ProgressCard = ({ userId, companyId, details }) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const onModalOpen = () => setIsModalVisible(true);
  const onModalClose = () => setIsModalVisible(false);

  const { data: user } = useGetCurrentUserQuery();
  const { data: profileCompleteness = {} } = useGetProfileCompletenessQuery(userId);
  const { data: companyDetails, isFetching: fetchingCompanyDetails } = useGetCompanyQuery({
    id: companyId,
  });

  const { status: companyVerificationStatus } = companyDetails || {};
  const {
    personal_details: personalDetailsProgressPercentage,
    business_details: businessDetailsProgressPercentage,
    business_contacts: businessContactProgressPercentage,
    food_safety: foodSafetyProgressPercentage,
    banking_detail: bankingDetailsProgressPercentage,
    total_percentage: totalPercentage,
  } = profileCompleteness;

  const {
    heading: progressCardHeading,
    subheadings,
    hintText,
  } = getProgressCardTexts(companyVerificationStatus, user?.roles);

  const isVerified = companyVerificationStatus === VERIFIED;
  const hasRequested = companyVerificationStatus === REQUESTED_VERIFICATION;
  const showProgressCardList = totalPercentage < HUNDRED_PERCENT && !isVerified;
  const showSubmitButton = totalPercentage === HUNDRED_PERCENT && !isVerified && !hasRequested;

  const progressBarCompletionText = t('profile.progress_card.completion_text');
  const progressBarHint =
    totalPercentage < HUNDRED_PERCENT ? t('profile.progress_card.hint_incomplete') : hintText;

  const cardIcon = isVerified ? (
    <Icon name="checkCircleOutline" size="medium" className="progress-card__icon--success" />
  ) : (
    <Icon name="info" size="medium" className="progress-card__icon" />
  );

  const cardTooltip = (
    <Tooltip type="inverse" content={t('profile.progress_card.tooltip')}>
      <Icon name="help" size="medium" className="progress-card__tooltip" />
    </Tooltip>
  );

  return (
    <Fragment>
      <div className="progress-card__container">
        <div className="progress-card__container--head">
          {cardIcon}
          <div className="progress-card__container--head-text">
            <h4 className="progress-card__container--head-text-title">{progressCardHeading}</h4>
            <SubHeading subheadings={subheadings} />

            {showSubmitButton && (
              <Button
                label={t('profile.progress_card.submit_button')}
                className="progress-card__button-verification"
                onClick={onModalOpen}
                disabled={fetchingCompanyDetails}
              />
            )}
          </div>
          {cardTooltip}
        </div>
        {!isVerified && (
          <div className="progress-card__container--progress">
            <div className="progress-card__container--progress-bar">
              <ProgressBar
                size="small"
                hintText={progressBarHint}
                completed={totalPercentage}
                bgColor="#4dec20"
                showText
                completedText={progressBarCompletionText}
              />
            </div>
            {showProgressCardList && (
              <ul className="progress-card__container--progress-steps">
                {details({
                  personalDetailsProgressPercentage,
                  businessDetailsProgressPercentage,
                  businessContactProgressPercentage,
                  foodSafetyProgressPercentage,
                  bankingDetailsProgressPercentage,
                }).map(({ id, title, progressPercentage }) => (
                  <ProgressCardList
                    key={id}
                    id={id}
                    title={title}
                    completePercentage={progressPercentage}
                  />
                ))}
              </ul>
            )}
          </div>
        )}
      </div>
      <Modal
        isVisible={isModalVisible}
        onOutsideClick={onModalClose}
        className="progress-card-modal"
      >
        <Modal.Content>
          <ProgressCardModalBody userId={userId} handleModalClose={onModalClose} />
        </Modal.Content>
      </Modal>
    </Fragment>
  );
};

ProgressCard.propTypes = {
  userId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  details: PropTypes.func.isRequired,
};

export default ProgressCard;
