import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { requiredText } from 'utils/helper/LabelWithHints';
import { listingOrganicCertificates } from 'utils/constants/listingCreation';
import { Field, useField } from 'react-final-form';
import { requiredField } from 'utils/helper/Validations';
import Icon from 'components/BaseComponents/Icon';
import Tooltip from 'components/BaseComponents/Tooltip';
import Radio from 'components/BaseComponents/Radio';
import { useGetCurrentUserQuery } from 'api/users';
import { useGetFoodSafetyDocumentsQuery } from 'api/FoodSafety';
import TestCertificates from '../TestCertificates';
import './styles.scss';

const OrganicCertificateDocuments = () => {
  const {
    input: { value: organic },
  } = useField('organic');

  const { data: user = {} } = useGetCurrentUserQuery();

  const { data: foodSafetyDocuments } = useGetFoodSafetyDocumentsQuery(
    { id: user?.company_id },
    {
      skip: !user?.company_id,
    },
  );

  const isOrganicCertificatePresent =
    Object.keys(foodSafetyDocuments?.organic_certificate || {}).length > 0;

  return (
    <div className="offer-food-safety-padding">
      <h5 className="business-details__section-header">
        { t('offer_creation.food_safety.organic_certificate_title') }
      </h5>

      <div className="flex flex-col">
        <div className="col-6 col-bleed margin-t-20px">
          <Field
            type="radio"
            name="organic"
            validate={requiredField}
            className="col-6 col-bleed margin-t-20px"
          >
            {({ meta }) => (
              <div className="flex items-center gap-8px business-details__delivery-option batch-details__radio">
                {requiredText(t('offer_creation.food_safety.organic.radio_input_label'))}
                {meta.touched && (meta?.error || meta?.submitError) && (
                  <Tooltip
                    content={meta?.error || meta?.submitError}
                    type="danger"
                    position="bottom-right"
                    gap={0}
                  >
                    <Icon name="invalid" color="danger" />
                  </Tooltip>
                )}
              </div>
            )}
          </Field>
          <div className="batch-details__radio--options">
            <Field type="radio" value="true" name="organic" validate={requiredField}>
              {({ input }) => (
                <Radio
                  id="organic_certificate_yes"
                  label={t('offer_creation.food_safety.organic.radio_input_option_yes')}
                  name={input.name}
                  size="large"
                  isChecked={input.checked}
                  withCheckIcon={input.checked}
                  onChange={() => input.onChange(input.value)}
                />
              )}
            </Field>
            <Field type="radio" value="false" name="organic" validate={requiredField}>
              {({ input }) => (
                <Radio
                  id="organic_certificat_no"
                  label={t('offer_creation.food_safety.organic.radio_input_option_no')}
                  name={input.name}
                  size="large"
                  isChecked={input.checked}
                  withCheckIcon={input.checked}
                  onChange={() => input.onChange(input.value)}
                />
              )}
            </Field>
          </div>
        </div>
        <TestCertificates
          testType={listingOrganicCertificates}
          isOptional={organic === 'false' || isOrganicCertificatePresent}
        />
      </div>
    </div>
  );
};

OrganicCertificateDocuments.propTypes = {
  form: PropTypes.shape({
    batch: PropTypes.func,
    change: PropTypes.func,
    getState: PropTypes.func,
  }).isRequired,
};

export default OrganicCertificateDocuments;
