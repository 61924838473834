import React from 'react';
import { t } from 'i18next';
import { useGetCurrentUserQuery } from 'api/users';
import LoadingSpinner from 'components/BaseComponents/LoadingSpinner';
import LeftBanner from 'components/SignUp/LeftBanner';
import SignUpForm from 'components/AffiliatePartner/Registration/SignUpForm';
import FormHeader from 'components/AffiliatePartner/Registration/FormHeader';
import UnauthorizedSignUp from 'components/AffiliatePartner/Registration/UnauthorizedSignUp';

const AffiliatePartnerSignUp = () => {
  const { data: user, isLoading: isLoadingUserInfo } = useGetCurrentUserQuery();

  if (isLoadingUserInfo) return <LoadingSpinner />;

  return (
    <div className="signup flex">
      <div className="signup__left-container">
        <LeftBanner title={t('affiliate_partners.left_banner.title')} />
      </div>
      <div className="signup__right-container">
        {user ? (
          <UnauthorizedSignUp user={user} />
        ) : (
          <>
            <FormHeader />
            <SignUpForm />
          </>
        )}
      </div>
    </div>
  );
};

export default AffiliatePartnerSignUp;
