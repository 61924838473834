import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Button from 'components/BaseComponents/Button';
import Icon from 'components/BaseComponents/Icon';
import './styles.scss';

const ActionConfirmationModalBody = ({ onModalClose, changeStatus, action }) => (
  <div className="action-confirmation-modal">
    <Icon className="action-confirmation-modal__icon" name="info" size="normal" />
    <h3 className="action-confirmation-modal__title">{t('admin.action_confirmation_modal_body.title')}</h3>
    <p className="action-confirmation-modal__description">
      {t('admin.action_confirmation_modal_body.description', {
        action,
      })}
    </p>
    <div className="action-confirmation-modal__buttons-container">
      <Button
        className="action-confirmation-modal__buttons-container--button"
        label={t('admin.action_confirmation_modal_body.cancel_button_label')}
        onClick={onModalClose}
      />
      <Button
        className="action-confirmation-modal__buttons-container--button"
        label={t('admin.action_confirmation_modal_body.action_button_label', {
          action,
        })}
        type={action === 'Dissolve' ? 'danger' : 'success'}
        onClick={() => {
          changeStatus();
          onModalClose();
        }}
      />
    </div>
  </div>
);

ActionConfirmationModalBody.propTypes = {
  onModalClose: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
};

export default ActionConfirmationModalBody;
