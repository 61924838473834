import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { requiredCheckbox } from 'utils/helper/Validations';
import { requiredSectionHeading } from 'utils/helper/LabelWithHints';
import CheckBox from 'components/BaseComponents/CheckBox';

const DeclarationSubSection = ({ title, declarationContents, isInputDisabled }) => (
  <div className="offer-declaration__subsection">
    <span>{requiredSectionHeading(title)}</span>
    {declarationContents.map(({ fieldName, fieldText }) => (
      <Field name={fieldName} type="checkbox" key={fieldName} validate={requiredCheckbox}>
        {({ meta, input }) => (
          <div className="flex items-start margin-t-16px">
            <CheckBox
              id={fieldName}
              isChecked={input.checked}
              onChange={input.onChange}
              className="margin-t-2px"
              disabled={isInputDisabled}
            />
            <div>
              <p className="offer-declaration__text margin-none">{fieldText}</p>
              <p className="offer-declaration__text--error margin-none">
                {meta?.touched && meta?.error}
              </p>
            </div>
          </div>
        )}
      </Field>
    ))}
  </div>
);

DeclarationSubSection.defaultProps = {
  isInputDisabled: false,
};

DeclarationSubSection.propTypes = {
  title: PropTypes.string.isRequired,
  declarationContents: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string.isRequired,
      fieldText: PropTypes.node.isRequired,
    }),
  ).isRequired,
  isInputDisabled: PropTypes.bool,
};

export default DeclarationSubSection;
