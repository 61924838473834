import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { useGetCurrentUserQuery } from 'api/users';
import { requiredField, validateOptionalPortalDocuments } from 'utils/helper/Validations';
import { BUSINESS_CONTACTS_AUTHORIZED_PERSON_CHECKBOX } from 'utils/constants/portals';
import { documentShape } from 'utils/constants/PropTypes';
import Input from 'components/BaseComponents/Input';
import Icon from 'components/BaseComponents/Icon';
import Tooltip from 'components/BaseComponents/Tooltip';
import ChooseFile from 'components/Shared/ChooseFile';
import CheckBox from 'components/BaseComponents/CheckBox';
import { labelWithHints } from 'utils/helper/LabelWithHints';

const CompanyProxyForm = ({ form, totalUploadedDocument, isEditable, files }) => {
  const { data: userDetails = {} } = useGetCurrentUserQuery();
  const { t } = useTranslation();

  const usePersonalInformation = (formElement) => {
    const {
      values: { is_using_personal_information },
    } = formElement.getState();

    if (!is_using_personal_information) {
      return;
    }

    formElement.batch(() => {
      formElement.change('authorized_person.first_name', userDetails.first_name);
      formElement.change('authorized_person.last_name', userDetails.last_name);
    });
  };

  const uncheckAuthorizedPersonCheckbox = (formElement) => {
    formElement.change(BUSINESS_CONTACTS_AUTHORIZED_PERSON_CHECKBOX, false);
  };

  return (
    <div className="margin-b-40px padding-b-40px padding-x-24px business-contacts__common-border">
      <div className="flex items-center gap-4px margin-t-40px padding-b-12px business-contacts__title-container">
        <span className="business-contacts__title-container--header">
          {t('profile.business_contacts.form.proxy_person_form_title.label')}
        </span>
        <Tooltip
          content={t('profile.business_contacts.form.proxy_person_form_title.tooltip')}
          position="top"
          showArrow
          size="small"
          type="midnight-blue"
        >
          <Icon name="help" color="default" />
        </Tooltip>
        <span className="business-contacts__title-container--required">*</span>
      </div>
      <Field name={BUSINESS_CONTACTS_AUTHORIZED_PERSON_CHECKBOX} type="checkbox">
        {({ input }) => (
          <CheckBox
            id="authorizedPerson"
            label={t('profile.business_contacts.form.proxy_checkbox_label')}
            className="flex items-center margin-t-16px margin-b-20px business-details__checkbox"
            size="tiny"
            isChecked={input.checked}
            onChange={(event) => {
              input.onChange(event);
              usePersonalInformation(form, event);
            }}
            disabled={!isEditable}
          />
        )}
      </Field>
      <div className="grid">
        <Field name="authorized_person.first_name" validate={requiredField}>
          {({ input, meta }) => (
            <Input
              label={t('profile.business_contacts.form.first_name.label')}
              placeholder={t('profile.business_contacts.form.first_name.placeholder')}
              required
              className="col-6 col-bleed padding-r-10px margin-t-16px"
              error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
              touched={meta.touched}
              value={input.value}
              onChange={(event) => {
                input.onChange(event);
                uncheckAuthorizedPersonCheckbox(form);
              }}
              disabled={!isEditable}
            />
          )}
        </Field>
        <Field name="authorized_person.last_name" validate={requiredField}>
          {({ input, meta }) => (
            <Input
              label={t('profile.business_contacts.form.last_name.label')}
              placeholder={t('profile.business_contacts.form.last_name.placeholder')}
              required
              size="small"
              className="col-6 col-bleed padding-l-10px margin-t-16px"
              error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
              touched={meta.touched}
              value={input.value}
              onChange={(event) => {
                input.onChange(event);
                uncheckAuthorizedPersonCheckbox(form);
              }}
              disabled={!isEditable}
            />
          )}
        </Field>
      </div>
      <Field name="authorized_person.identification_number" validate={requiredField}>
        {({ input, meta }) => (
          <Input
            label={t('profile.business_contacts.form.identification_number.label')}
            placeholder={t('profile.business_contacts.form.identification_number.placeholder')}
            required={true}
            size="small"
            className="margin-t-16px"
            error={meta?.error || meta?.submitError}
            touched={meta.touched}
            value={input.value}
            onChange={input.onChange}
            disabled={!isEditable}
          />
        )}
      </Field>
      <div className="margin-t-32px padding-b-12px flex justify-between business-contacts__title-container">
        <div className="flex items-center gap-4px padding-b-12px">
          <span className="business-contacts__title-container--header">
            {labelWithHints(
              t('profile.business_contacts.form.documents.proxy_doc_title'),
              t('shared.incorrect_document_tooltip'),
            )}
          </span>
        </div>
        <span className="business-contacts__uploaded-file">
          {t('profile.business_contacts.form.documents.company_proxy_documents', {
            totalUploadedDocument,
          })}
        </span>
      </div>
      <div className="margin-t-20px margin-b-16px padding-b-16px business-contacts__upload-file">
        <Field name="authorized_person.company_resolution" validate={validateOptionalPortalDocuments}>
          {({ input, meta }) => (
            <ChooseFile
              fileName={input.value?.name}
              fileUrl={files?.companyResolution?.url}
              label={t('profile.business_contacts.form.documents.company_resolution')}
              hint={t('profile.file.upload')}
              onChange={(file) => {
                input.onChange(file);
              }}
              touched={meta?.touched}
              errorMessage={meta?.error || meta?.submitError}
              isEditable={isEditable}
            />
          )}
        </Field>
      </div>
      <div className="margin-t-16px padding-b-16px business-contacts__upload-file">
        <Field name="authorized_person.id_document" validate={validateOptionalPortalDocuments}>
          {({ input, meta }) => (
            <ChooseFile
              fileName={input.value?.name}
              fileUrl={files?.idDocument?.url}
              label={t('profile.business_contacts.form.documents.proxy_passport')}
              hint={t('profile.file.upload')}
              onChange={(file) => {
                input.onChange(file);
              }}
              touched={meta?.touched}
              errorMessage={meta?.error || meta?.submitError}
              isEditable={isEditable}
            />
          )}
        </Field>
      </div>
      <div className="margin-t-16px padding-b-16px business-contacts__upload-file">
        <Field name="authorized_person.signed_letter" validate={validateOptionalPortalDocuments}>
          {({ input, meta }) => (
            <ChooseFile
              fileName={input.value?.name}
              fileUrl={files?.signedLetter?.url}
              label={t('profile.business_contacts.form.documents.letter_by_proxy')}
              hint={t('profile.file.upload')}
              onChange={(file) => {
                input.onChange(file);
              }}
              touched={meta?.touched}
              errorMessage={meta?.error || meta?.submitError}
              isEditable={isEditable}
            />
          )}
        </Field>
      </div>
    </div>
  );
};

CompanyProxyForm.defaultProps = {
  totalUploadedDocument: 0,
  isEditable: true,
  files: null,
  form: {},
};

CompanyProxyForm.propTypes = {
  totalUploadedDocument: PropTypes.number,
  isEditable: PropTypes.bool,
  files: PropTypes.shape({
    companyResolution: documentShape,
    idDocument: documentShape,
    signedLetter: documentShape,
  }),
  form: PropTypes.shape({
    batch: PropTypes.func,
    change: PropTypes.func,
    getState: PropTypes.func,
  }),
};

export default CompanyProxyForm;
