import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useGetCurrentUserQuery } from 'api/users';
import { useTranslation } from 'react-i18next';
import BreadCrumbComponent from 'components/BreadCrumbComponent';
import Tooltip from 'components/BaseComponents/Tooltip';
import SideNavBar from '../SideNavBar';
import AvatarDropdown from '../AvatarDropdown';
import './styles.scss';

const AdminNavBar = ({ breadcrumbs, children }) => {
  const [isDropDownTooltipVisible, setIsDropDownTooltipVisible] = useState(true);
  const { data: user } = useGetCurrentUserQuery();
  const { t } = useTranslation();

  return (
    <Fragment>
      <SideNavBar />
      <div className="admin-navbar__main">
        <div className="admin-navbar__header">
          <div className="admin-navbar__header--container">
            <div className="admin-navbar__header--left">
              <BreadCrumbComponent breadcrumbs={breadcrumbs} />
            </div>
            <div className="navbar__header--right">
              <Tooltip
                position="bottom"
                type="midnight-blue"
                content={t('shared.avatar_tooltip')}
                isActive={isDropDownTooltipVisible}
              >
                <AvatarDropdown
                  firstName={user?.first_name}
                  lastName={user?.last_name}
                  description={t('shared.role.admin')}
                  roles={user?.roles}
                  setIsDropDownTooltipVisible={setIsDropDownTooltipVisible}
                />
              </Tooltip>
            </div>
          </div>
        </div>
        {children}
      </div>
    </Fragment>
  );
};

AdminNavBar.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      breadcrumb: PropTypes.node,
    }),
  ).isRequired,
  children: PropTypes.node.isRequired,
};

export default AdminNavBar;
