import React from 'react';
import ContractDetailsHeader from 'components/BiddingProcess/Contract/ContractDetails/ContractDetailsHeader';
import PortalsNavbar from 'components/NavBar/PortalsNavBar';
import './styles.scss';
import AttachedDocuments from './AttachedDocuments';

const ContractDetails = () => (
  <div className="contract-details-header-content">
    <PortalsNavbar />
    <ContractDetailsHeader />
    <AttachedDocuments />
  </div>
);

export default ContractDetails;
