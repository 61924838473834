import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Tooltip from '../Tooltip';
import Icon from '../Icon';

const Textarea = props => {
  const {
    touched,
    error,
    dark,
    label,
    size,
    hint,
    disabled,
    placeholder,
    width,
    height,
    required,
    className,
    ...other
  } = props;
  const errorText = (touched && error) || undefined;

  const style = {
    width: width && `${width}%`,
    height: height && `${height}%`,
  };

  return (
    <div className={classNames("text-field", className)}>
      <div
        className={classNames('text-field__label', `text-field__label--${size}`, {
          'text-field__label--disabled': disabled,
          'text-field__label--required': required,
        })}
      >
        {label}
      </div>
      <div className={classNames('text-field-holder', `text-field-holder--${size}`)}>
        <textarea
          {...other}
          placeholder={placeholder}
          className={classNames('text-field__box', `text-field--${size}`, {
            'text-field--disabled': disabled,
            'text-field--error': errorText,
          })}
          style={style}
          required={false}
        />
      </div>
      {errorText && (
        <div className="text-field__box-tooltip-wrapper">
          <Tooltip
            className="text-field__box-tooltip"
            content={errorText}
            position="bottom-right"
            type="danger"
            gap={0}
          >
            <Icon name="invalid" color="danger" />
          </Tooltip>
        </div>
      )}
      {!errorText && hint && (
        <div className="text-field__box-tooltip-wrapper">
          <Tooltip
            className="text-field__box-tooltip"
            content={hint}
            position="top-right"
            type="accent"
            gap={0}
          >
            <Icon name="invalid" />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

Textarea.defaultProps = {
  touched: false,
  error: null,
  dark: false,
  size: 'small',
  hint: null,
  disabled: false,
  label: null,
  placeholder: 'Placeholder',
  width: null,
  height: null,
  required: false,
};

Textarea.propTypes = {
  touched: PropTypes.bool,
  dark: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.oneOf(['tiny', 'small', 'large', 'huge']),
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  required: PropTypes.bool,
};

export default Textarea;
