import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCaption,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/BaseComponents/Table';
import './styles.scss';

const DetailsInformationTable = ({ headerData, data, captionText }) => (
  <Table className="details-information-table" disablePadding={false} disableShadow={false}>
    <TableCaption className="details-information-table__caption">
      <h2>{captionText}</h2>
    </TableCaption>
    <colgroup>
      {headerData.map((item) => (
        <col key={item?.id} style={{ width: '25%' }} />
      ))}
    </colgroup>
    <TableHead>
      <TableRow isHeaderRow={false}>
        {headerData.map((header) => (
          <TableHeader key={header.id} align={header.align} className={header.className}>
            {header.title}
          </TableHeader>
        ))}
      </TableRow>
    </TableHead>
    <TableBody>
      {data.map((item) => (
        <TableRow key={item?.id} isHeaderRow={false}>
          <TableData className="details-information-table__data--bold">{item.testItem}</TableData>
          <TableData>{item.testMethod}</TableData>
          <TableData>{item.testCount}</TableData>
          <TableData>{item.maxLimit}</TableData>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

DetailsInformationTable.propTypes = {
  data: PropTypes.arrayOf({}).isRequired,
  headerData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      align: PropTypes.string,
      className: PropTypes.string,
    }),
  ).isRequired,
  captionText: PropTypes.string.isRequired,
};

export default DetailsInformationTable;
