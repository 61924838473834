import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useGetProductDetailsQuery, useUpdateProductMutation } from 'api/products';
import { useGetCurrentUserQuery } from 'api/users';
import { useGetCompanyQuery } from 'api/companies';
import {
  generateEditDriedNutInShellProductBody,
  generateKernelProductBody,
} from 'utils/helper/RequestBody';
import { handleServerValidationErrors } from 'utils/helper/Validations';
import { offerOrderPageIndex } from 'utils/constants/listingCreation';
import useCustomNavigate from 'hooks/useCustomNavigate';
import LoadingSpinner from 'components/BaseComponents/LoadingSpinner';
import KernelProductDetailsForm from 'components/Shared/BiddingProcess/KernelProductDetailsForm';
import DriedNutInShellProductDetailsForm from 'components/Shared/BiddingProcess/DriedNutInShellProductDetailsForm';
import ToastNotification from 'components/Shared/ToastNotification';
import { DRIED_NUT_IN_SHELL_PRODUCT, KERNEL_PRODUCT } from 'utils/constants/product';
import CreateListingAuthorizer from '../Layout/CreateListingAuthorizer';
import CreateListingLayout from '../Layout';

const EditProduct = () => {
  const toastRef = useRef();
  const { productId } = useParams();
  const { data: user = {} } = useGetCurrentUserQuery();
  const { data: companyDetails = {} } = useGetCompanyQuery({ id: user?.company_id });
  const {
    data: productDetails,
    isFetching: isFetchingProductDetails,
    isSuccess: isProductDetailsFetched,
  } = useGetProductDetailsQuery({ product_id: productId }, { skip: !productId });
  const [updateProduct] = useUpdateProductMutation();
  const navigate = useCustomNavigate();
  const currentPageIndex = offerOrderPageIndex.productDetails;

  useEffect(() => {
    if (!isFetchingProductDetails && !productDetails) {
      navigate.createProduct();
    }
  }, [isProductDetailsFetched, isFetchingProductDetails]);

  const updateKernelProductDetails = (payload) => {
    const kernelProductPayload = { ...payload, type: KERNEL_PRODUCT };

    const requestBody = generateKernelProductBody(kernelProductPayload);

    return updateProduct({ productId, payload: requestBody })
      .unwrap()
      .then(({ id: kernelProductId }) => {
        navigate.reviewListingById(kernelProductId);
      })
      .catch(({ data, status }) => handleServerValidationErrors(toastRef, status, data?.message));
  };

  const updateDriedNutInShellProduct = (payload) => {
    const driedNutInShellProductPayload = { ...payload, type: DRIED_NUT_IN_SHELL_PRODUCT };

    const requestBody = generateEditDriedNutInShellProductBody(driedNutInShellProductPayload);

    return updateProduct({ productId, payload: requestBody })
      .unwrap()
      .then(({ id: dnisProductId }) => {
        navigate.reviewListingById(dnisProductId);
      })
      .catch(({ data, status }) => handleServerValidationErrors(toastRef, status, data?.message));
  };

  const renderResult = () => (
    <CreateListingAuthorizer>
      <CreateListingLayout activeStep={currentPageIndex} category={productDetails?.category}>
        {productDetails?.type === DRIED_NUT_IN_SHELL_PRODUCT && (
          <DriedNutInShellProductDetailsForm
            productDetails={productDetails}
            companyDetails={companyDetails}
            onProductSave={updateDriedNutInShellProduct}
            category={productDetails.category}
          />
        )}
        {productDetails?.type === KERNEL_PRODUCT && (
          <KernelProductDetailsForm
            productDetails={productDetails}
            companyDetails={companyDetails}
            onProductSave={updateKernelProductDetails}
            category={productDetails.category}
          />
        )}
        <ToastNotification toastRef={toastRef} />
      </CreateListingLayout>
    </CreateListingAuthorizer>
  );

  return <div>{isFetchingProductDetails ? <LoadingSpinner /> : renderResult()}</div>;
};

export default EditProduct;
