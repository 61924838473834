import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { hasPartnerRole } from 'utils/helper/UserRoleValidation';
import './styles.scss';

const UnauthorizedSignUp = ({ user }) => {
  const navigate = useCustomNavigate();
  let descriptionText = t('affiliate_partners.unauthorized_signup');
  let buttonLabel = t('affiliate_partners.go_to_affliate_partner_page');
  let buttonLinkFunc = navigate.affiliatePartnerStaticPage;

  if (hasPartnerRole(user)) {
    descriptionText = t('affiliate_partners.unauthorized_signup_affiliate');
    buttonLabel = t('shared.bidding_process.avatar.create_listing.affiliate_partner');
    buttonLinkFunc = navigate.affiliatePartnerInviteBuyer;
  }

  return (
    <div className="unauthorized-sign-up">
      <div className="unauthorized-sign-up__text">{descriptionText}</div>
      <button className="unauthorized-sign-up__button" onClick={buttonLinkFunc} type="button">
        {buttonLabel}
      </button>
    </div>
  );
};

UnauthorizedSignUp.propTypes = {
  user: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.string.isRequired),
  }).isRequired,
};

export default UnauthorizedSignUp;
