import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';

const TradingCard = ({ className, heading, description, listItems }) => (
  <div className={classNames('trading-card', className)}>
    <h4 className="trading-card__heading">{heading}</h4>
    <p className="trading-card__body">{description}</p>
    <ul className="trading-card__list-items">
      {listItems.map(({ id, text }) => (
        <li key={id}>{text}</li>
      ))}
    </ul>
  </div>
);

TradingCard.defaultProps = {
  className: '',
  listItems: [],
};

TradingCard.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      text: PropTypes.string,
    }),
  ),
};

export default TradingCard;
