import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DatePicker from 'components/BaseComponents/DatePicker';
import { bestBeforePastYearsCount } from 'utils/constants/product';

const BestBeforeFilter = ({ date, onDateChange }) => {
  const { t } = useTranslation();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const handleDateChange = (selectedDate) => {
    if (selectedDate === '') {
      return onDateChange(null);
    }

    return onDateChange(selectedDate);
  };

  return (
    <div className="aggregate-data-filter__box">
      <h4 className="aggregate-data-filter__box--title margin-b-16px">
        {t('offer_listing.filter.best_before.title')}
      </h4>
      <DatePicker
        id="bestBeforeDate"
        focused={isDatePickerOpen}
        date={date}
        enablePastDates={true}
        onFocusChange={(isFocues) => setIsDatePickerOpen(isFocues)}
        displayFormat="LL"
        showClearDate={true}
        pastYearsCount={bestBeforePastYearsCount}
        onChange={handleDateChange}
      />
    </div>
  );
};

BestBeforeFilter.defaultProps = {
  date: null,
};

BestBeforeFilter.propTypes = {
  date: PropTypes.string,
  onDateChange: PropTypes.func.isRequired,
};

export default BestBeforeFilter;
