import React, { Fragment, useRef } from 'react';
import classNames from 'classnames';
import PortalsNavbar from 'components/NavBar/PortalsNavBar';
import usePortal from 'hooks/usePortal';
import ToastNotification from 'components/Shared/ToastNotification';
import MyAffiliationsTable from 'components/AffiliatePartner/AffiliationsTable';
import { useGetUserQuery } from 'api/users';
import { useGetCompanyQuery } from 'api/companies';
import { isVerified } from 'utils/helper/UserRoleValidation';
import LoadingSpinner from 'components/BaseComponents/LoadingSpinner';
import AvatarCard from './AvatarCard';
import './styles.scss';

const AffiliatePartnerPortal = () => {
  const toastRef = useRef();
  const isAdmin = false;
  const { userId } = usePortal(isAdmin);

  const {
    data: user = {},
    isSuccess: userDataFetched,
    isFetching,
  } = useGetUserQuery({ id: userId }, { skip: !userId });
  const { data: companyDetails = {} } = useGetCompanyQuery(
    { id: user?.company_id },
    { skip: !userDataFetched },
  );

  const { first_name: userFirstName, last_name: userLastName, role: userRole } = user;
  const userName = `${userFirstName} ${userLastName}`;

  const { name: companyName } = companyDetails;
  const isUserVerified = isVerified(companyDetails);

  if (isFetching) {
    return <LoadingSpinner />;
  }

  return (
    <Fragment>
      <PortalsNavbar />

      <div className="portal-profile affiliations">
        <AvatarCard
          className="border-y-1 border-gray-200 border-y-solid padding-y-32px"
          userName={userName}
          companyName={companyName}
          isVerified={isUserVerified}
          userRole={userRole}
          user={user}
        />
        <div
          className={classNames('affiliations__table-container', {
            'affiliations__table-container--verified': isUserVerified,
          })}
        >
          {isUserVerified && <MyAffiliationsTable />}
        </div>
      </div>
      <ToastNotification toastRef={toastRef} />
    </Fragment>
  );
};

export default AffiliatePartnerPortal;
