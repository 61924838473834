import { t } from 'i18next';

export const prepareContractInfoData = (
  isListingInContract,
  listingInformation,
  shouldShowSellerName,
  sellerName,
  shouldShowBuyerName,
  buyerName,
  dateTimeModifier,
) => {
  const data = [];
  const greenTextClass = 'contract-details-header-information__value--green-text';

  if (isListingInContract) {
    data.push({
      label: t('admin.contract_details.reference'),
      value: listingInformation.serialNumber,
      className: greenTextClass,
    });
    const statusText = t(`admin.contract_details.${listingInformation.contract.status}`)
    const statusSince = listingInformation.contract[`${listingInformation.contract.status}At`]

    data.push({
      label: t('admin.contract_details.contract_status'),
      value: `${statusText} (${statusSince ? dateTimeModifier(statusSince) : t('admin.contract_details.unkown_date')})`,
    });
  } else {
    const statusText = t(`admin.contract_details.${listingInformation.status}`)
    const statusSince = listingInformation[`${listingInformation.status}At`]
    data.push({
      label: t('admin.contract_details.status'),
      value: `${statusText} (${statusSince ? dateTimeModifier(statusSince) : t('admin.contract_details.unkown_date')})`,
    });
  }

  data.push({
    label: t('admin.contract_details.created_at'),
    value: dateTimeModifier(isListingInContract ? listingInformation.contract.createdAt : listingInformation.createdAt),
  });

  if (shouldShowSellerName) {
    data.push({
      label: t('admin.contract_details.seller'),
      value: sellerName,
      className: greenTextClass,
    });
  }

  if (shouldShowBuyerName) {
    data.push({
      label: t('admin.contract_details.buyer'),
      value: buyerName,
      className: greenTextClass,
    });
  }

  return data;
};
