import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';

const LoadingSpinner = ({ className }) => (
  <div className={classNames('loading-spinner', className)}>
    <span />
  </div>
);

LoadingSpinner.defaultProps = {
  className: '',
};

LoadingSpinner.propTypes = {
  className: PropTypes.string,
};

export default LoadingSpinner;
