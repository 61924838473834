const FRACTION_DIGIT = 2;
const TONS_FRACTION_DIGIT = 3;
const CURRENCY_USD = 'USD';

export const formatPrice = (value) => {
  const formattedValue = new Intl.NumberFormat('fr-FR', {
    currency: CURRENCY_USD,
    minimumFractionDigits: FRACTION_DIGIT,
    maximumFractionDigits: FRACTION_DIGIT,
  }).format(value);

  return formattedValue.replace(',', '.');
};

export const formatVolume = (value) => {
  const formattedValue = new Intl.NumberFormat('fr-FR', {
    maximumFractionDigits: FRACTION_DIGIT,
  }).format(value);

  return formattedValue.replace(',', '.');
};

export const formatTonsVolume = (value) => {
  const formattedValue = new Intl.NumberFormat('fr-FR', {
    minimumFractionDigits: TONS_FRACTION_DIGIT,
    maximumFractionDigits: TONS_FRACTION_DIGIT,
  }).format(value);

  return formattedValue.replace(',', '.');
};

export const priceWithCurrency = (price, currency = CURRENCY_USD) =>
  `${currency} ${formatPrice(price)}`;
