import React, { Fragment } from 'react';
import { t } from 'i18next';
import { Text, View } from '@react-pdf/renderer';
import { contractPDFtermsAndConditions } from 'components/BiddingProcess/ContractPDF/TermsConditions/constants';
import { styles } from '../styles';

const TermsConditionsTable = () => (
  <Fragment>
    <Text style={styles.termsDescription}>{t('contract_pdf.terms_and_condition_text')}</Text>
    {contractPDFtermsAndConditions.map((row) => (
      <View style={styles.termsCard} key={row.title}>
        <Text style={styles.termsCardLeft}>{row.title}</Text>
        <View style={styles.termsCardRight}>
          {row.description.map((item) => (
            <Text style={styles.listText}>{item}</Text>
          ))}
        </View>
      </View>
    ))}
  </Fragment>
);

export default TermsConditionsTable;
