import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { affiliatePartner } from 'utils/constants/Routes';
import Button from 'components/BaseComponents/Button';
import { AFFILIATE_PARTNER } from 'utils/constants/userRole';
import PendingVerification from 'components/Shared/BiddingProcess/PendingVerification';
import './styles.scss';

const AvatarCard = ({ className, userName, companyName, isVerified }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const avatarClassName = classNames(
    'avatar-container flex flex-col items-center justify-center',
    className,
  );
  const buttonLabel = t('shared.bidding_process.avatar.create_listing.affiliate_partner');
  const redirectLink = affiliatePartner.inviteBuyer;

  return (
    <div className={avatarClassName}>
      <div className="avatar-container__card flex justify-between items-end">
        <div>
          <p className="avatar-container__card--welcome-text">
            {t('shared.bidding_process.avatar.welcome')}
          </p>
          <h2 className="avatar-container__card--user-name">{userName}</h2>
          <p className="avatar-container__card--company-name">{companyName}</p>
        </div>
        {isVerified && (
          <div className="flex flex-col items-end">
            <Button
              onClick={() => navigate(redirectLink)}
              label={buttonLabel}
              className="avatar-container__card--redirect-button justify-center flex"
            />
          </div>
        )}
      </div>
      {!isVerified && <PendingVerification className="margin-t-24px" userRole={AFFILIATE_PARTNER} />}
    </div>
  );
};

AvatarCard.defaultProps = {
  className: '',
  userName: '',
  companyName: '',
  isVerified: false,
};

AvatarCard.propTypes = {
  className: PropTypes.string,
  userName: PropTypes.string,
  companyName: PropTypes.string,
  isVerified: PropTypes.bool,
};

export default AvatarCard;
