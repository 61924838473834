import React from 'react';
import { t } from 'i18next';
import { Field } from 'react-final-form';
import { optionalSectionHeading, requiredText } from 'utils/helper/LabelWithHints';
import { requiredField } from 'utils/helper/Validations';
import { maxResidueLevelsTestCertificate } from 'utils/constants/listingCreation';
import Input from 'components/BaseComponents/Input';
import TestCertificates from '../TestCertificates';

const MaxResidueLevels = () => (
  <div className="offer-food-safety-padding">
    {optionalSectionHeading(t('offer_creation.food_safety.max_residue_levels_title'))}
    <TestCertificates testType={maxResidueLevelsTestCertificate} isOptional={true} />
    <div className="test-certificates">
      <div className="test-certificates__header">
        {requiredText(t('offer_creation.food_safety.max_residue_levels_comply_title'))}
      </div>
      <div className="margin-t-16px padding-b-16px test-certificates__form">
        <Field name="compliance_institute" validate={requiredField}>
          {({ input, meta }) => (
            <Input
              placeholder={t('offer_creation.food_safety.max_residue_levels_comply_placeholder')}
              value={input.value}
              error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
              touched={meta.touched}
              onChange={input.onChange}
              required
            />
          )}
        </Field>
      </div>
    </div>
  </div>
);

export default MaxResidueLevels;
