import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';
import { useRevokeUserMutation, useVerifyUserMutation, useRejectUserMutation } from 'api/admin';
import useUserManagement from 'hooks/useUserManagement';
import breadcrumbRoutes from 'utils/breadcrumb-helper/route-names';
import { PERSONAL_DETAILS_FORM_KEY } from 'utils/constants/portals';
import Modal from 'components/BaseComponents/Modal';
import Icon from 'components/BaseComponents/Icon';
import Button from 'components/BaseComponents/Button';
import { RTabs } from 'components/BaseComponents/RTabs';
import AvatarCard from 'components/Shared/Portals/Avatar';
import AdminNavBar from 'components/NavBar/AdminNavBar';
import MyAffiliationsTable from 'components/AffiliatePartner/AffiliationsTable';
import ToastNotification from 'components/Shared/ToastNotification';
import { getProfileTabs } from 'utils/helper/Profile';
import { AbilityContext, Can, userSubject } from 'ability';
import buildAdminAbility from 'ability/admin';
import CustomToastify from 'components/Shared/ToastNotification/CustomToastify';
import UserAdditionalDocumentModal from './AdditionalDocModalBody';
import AggregateCSVModal from './AggregateCSVModalBody';
import ActionConfirmationModalBody from './ActionConfirmationModal';
import './styles.scss';

const UserProfile = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const userId = Number(id);
  const [showProfile, setShowProfile] = useState(true);
  const toastRef = useRef();

  const {
    isAdmin,
    admin,
    adminId,
    companyId,
    companyDetails,
    canEditForm,
    userType,
    userDetails,
  } = useUserManagement(userId);
  const [verifyUser, { isLoading: isVerifyUserSubmitting }] = useVerifyUserMutation();
  const [revokeUser, { isLoading: isRevokeUserSubmitting }] = useRevokeUserMutation();
  const [rejectUser, { isLoading: isRejectUserSubmitting }] = useRejectUserMutation();

  const [isDocModalVisible, setIsDocModalVisible] = useState(false);
  const onDocModalOpen = () => setIsDocModalVisible(true);
  const onDocModalClose = () => setIsDocModalVisible(false);

  const [isCSVModalVisible, setIsCSVModalVisible] = useState(false);
  const onCSVModalOpen = () => setIsCSVModalVisible(true);
  const onCSVModalClose = () => setIsCSVModalVisible(false);

  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const onConfirmModalOpen = () => setIsConfirmModalVisible(true);
  const onConfirmModalClose = () => setIsConfirmModalVisible(false);

  const handleToggleProfileAndAffiliations = useCallback(() => {
    setShowProfile((prevState) => !prevState);
  }, []);

  const ability = buildAdminAbility(admin, { userDetails, companyDetails });

  const adminPortalTabs = getProfileTabs({
    isAdmin,
    userId,
    companyId,
    companyDetails,
    isEditable: canEditForm,
    userType,
    userDetails,
    toastRef,
  }, ability);

  const onRevokeUser = () =>
    revokeUser({ userId })
      .unwrap()
      .catch(({ data: { error } }) => toastRef?.current?.notify(<CustomToastify type="error" message={error}/>));

  const onVerifyUser = () =>
    verifyUser({ userId })
      .unwrap()
      .catch(({ data: { error } }) => toastRef?.current?.notify(<CustomToastify type="error" message={error}/>));

  const onRejectUser = () =>
    rejectUser({ userId })
      .unwrap()
      .then(() => {
        window.location.href = '/admin/users';
      })
      .catch(({ data: { error } }) => toastRef?.current?.notify(<CustomToastify type="error" message={error}/>));

  return (
    <AbilityContext.Provider value={ability}>
      <div className="portal-profile">
        <div className="flex justify-between portal-profile__container border-b-1 border-gray-200 border-b-solid">
          <AvatarCard
            userId={userId}
            adminId={adminId}
            isEditable={canEditForm}
            containerClassName="portal-profile__avatar-container"
            canUploadAvatar={false}
          />
          <div className="portal-profile__verification-buttons flex items-center gap-12px">
            <Can I='swithcProfileView' on={userSubject(userDetails)}>
              <Button
                label={
                  showProfile
                    ? t('admin.affiliate_partners.affiliations_button_label')
                    : t('admin.affiliate_partners.profile_button_label')
                }
                onClick={handleToggleProfileAndAffiliations}
                className="portal-profile__verification-buttons--request"
              />
            </Can>

            <Can I='requestDocument' on={userSubject(userDetails)}>
              <Button
                label={t('admin.request_modal_body.title')}
                onClick={onDocModalOpen}
                className="portal-profile__verification-buttons--request"
              />

              <Modal
                isVisible={isDocModalVisible}
                onOutsideClick={onDocModalClose}
                className="progress-card-modal"
              >
                <Modal.Content>
                  <UserAdditionalDocumentModal adminId={adminId} handleModalClose={onDocModalClose} />
                </Modal.Content>
              </Modal>
            </Can>

            <Can I='verify' a={userSubject(userDetails)}>
              <Button
                label={t('admin.request_modal_body.approve_button_label')}
                type="success"
                onClick={() => onVerifyUser({ userId })}
                disabled={isVerifyUserSubmitting}
                className="portal-profile__verification-buttons--approve"
              />
            </Can>

            <Can I='reject' a={userSubject(userDetails)}>
              <Button
                label={t('admin.request_modal_body.reject_button_label')}
                type="danger"
                onClick={onConfirmModalOpen}
                disabled={isRejectUserSubmitting}
                className="portal-profile__verification-buttons--reject"
              />

              <Modal
                isVisible={isConfirmModalVisible}
                onOutsideClick={onConfirmModalClose}
                className="progress-card-modal"
              >
                <Modal.Content>
                  <ActionConfirmationModalBody
                    onModalClose={onConfirmModalClose}
                    action={onRejectUser}
                    actionText={t('admin.action_confirmation_modal_body.reject_action')}
                    type="danger"
                  />
                </Modal.Content>
              </Modal>
            </Can>

            <Can I='revoke' a={userSubject(userDetails)}>
              <Button
                label={t('admin.request_modal_body.revoke_button_label')}
                type="danger"
                onClick={() => onRevokeUser({ userId })}
                disabled={isRevokeUserSubmitting}
                className="portal-profile__verification-buttons--approve"
              />
            </Can>

            <Can I='importCSV' on={userSubject(userDetails)}>
              <Button
                label={t('admin.csv_modal_body.import_aggregated_csv_button_label')}
                type="success"
                icon={<Icon name="cloudUpload" size="normal" />}
                onClick={onCSVModalOpen}
                className="portal-profile__verification-buttons--approve"
              />

              <Modal
                isVisible={isCSVModalVisible}
                onOutsideClick={onCSVModalClose}
                className="progress-card-modal"
              >
                <Modal.Content>
                  <AggregateCSVModal userId={userId} />
                </Modal.Content>
              </Modal>
            </Can>
          </div>
        </div>

        {showProfile ? (
          <RTabs
            containerClass="portal-profile__tabs margin-x-auto margin-t-24px"
            isVertical={true}
            items={adminPortalTabs}
            selectedTabKey={PERSONAL_DETAILS_FORM_KEY}
          />
        ) : (
          <div className="portal-profile__table">
            <MyAffiliationsTable />
          </div>
        )}

        <ToastNotification toastRef={toastRef} />
      </div>
    </AbilityContext.Provider>
  );
};

const UserProfilePage = () => {
  const breadcrumbs = useReactRouterBreadcrumbs(breadcrumbRoutes);

  return (
    <AdminNavBar breadcrumbs={breadcrumbs}>
      <section>
        <UserProfile />
      </section>
    </AdminNavBar>
  );
};

export default UserProfilePage;
