import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { requiredSectionHeading } from 'utils/helper/LabelWithHints';
import {
  microbiologicalTestCertificate,
  microbiologicalTestTableContents,
  microbiologicalTestTableHeaders,
} from 'utils/constants/listingCreation';
import TestResultsTable from '../TestResultsTable';
import TestCertificates from '../TestCertificates';

const MicrobiologicalTest = ({ form }) => (
  <div className="microbiological-test offer-food-safety-padding">
    {requiredSectionHeading(
      t('offer_creation.food_safety.test_result_title', {
        testName: t('offer_creation.food_safety.microbiological_test_title'),
      }),
    )}
    <TestResultsTable
      tableHeaders={microbiologicalTestTableHeaders}
      tableContentCollection={microbiologicalTestTableContents}
      form={form}
      isOptional={false}
    />
    <TestCertificates testType={microbiologicalTestCertificate} />
  </div>
);

MicrobiologicalTest.propTypes = {
  form: PropTypes.shape({
    batch: PropTypes.func,
    change: PropTypes.func,
    getState: PropTypes.func,
  }).isRequired,
};

export default MicrobiologicalTest;
