import React from 'react';
import PropTypes from 'prop-types';
import NewMessageToast from 'components/BaseComponents/Toast/customToasts/NewMessageToast';

const CustomToastify = ({ type, message }) => <NewMessageToast heading={message} type={type} />;

CustomToastify.propTypes = {
  type: PropTypes.oneOf(['success', 'error']).isRequired,
  message: PropTypes.string.isRequired,
};

export default CustomToastify;
