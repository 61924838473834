import React, { useContext } from 'react';
import ListingDetailsContext from 'components/BiddingProcess/ListingDetails/ListingDetailsContext';
import Divider from 'components/BiddingProcess/ListingDetails/ListingInformationBody/ActionPanel/Divider';
import CurrentBid from 'components/BiddingProcess/ListingDetails/ListingInformationBody/ActionPanel/CurrentBid';
import AskingPrice from 'components/BiddingProcess/ListingDetails/ListingInformationBody/ActionPanel/AskingPrice';
import OfferedPrice from 'components/BiddingProcess/ListingDetails/ListingInformationBody/ActionPanel/OfferedPrice';
import './styles.scss';

const ActionPanel = () => {
  const {
    isUserAdmin,
    isOrderListing,
    isListingPublished,
    isListingInContract,
    isListingUnPublished,
    isListingConcluded,
  } = useContext(ListingDetailsContext);

  const isDividerVisible = !isUserAdmin && !isListingUnPublished && !isListingInContract;
  const canViewAskingPrice = isOrderListing && isListingPublished;

  return (
    <div className="action-panel">
      {canViewAskingPrice ? <AskingPrice /> : !isListingUnPublished && <OfferedPrice />}
      {isDividerVisible && <Divider />}
      {!isListingConcluded && <CurrentBid />}
    </div>
  );
};

export default ActionPanel;
