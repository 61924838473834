import React from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Input from 'components/BaseComponents/Input';
import Icon from 'components/BaseComponents/Icon';
import Tooltip from 'components/BaseComponents/Tooltip';
import { requiredField } from 'utils/helper/Validations';
import classNames from 'classnames';

const TradeReference = ({
  idTradeReference,
  tradeReferenceIndex,
  reference,
  contactPersonName,
  phoneNumber,
  email,
  isEditable,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames('padding-x-24px margin-t-40px')}>
      <div className="flex justify-between business-contacts__title-container">
        <div className="flex items-center gap-4px padding-b-12px">
          <span className="business-contacts__title-container--header">
            {t('profile.business_contacts.trade_reference_form.title.label', {
              tradeReferenceIndex,
            })}
          </span>
          <Tooltip
            content={t('profile.business_contacts.trade_reference_form.tooltip')}
            position="top"
            showArrow
            size="small"
            type="midnight-blue"
          >
            <Icon name="help" color="default" />
          </Tooltip>
          <span className="business-contacts__title-container--required">*</span>
        </div>
      </div>
      <Field name={idTradeReference}>
        {({ input }) => (
          <Input
            required={false}
            size="small"
            className="business-contacts__hidden-field"
            value={input.value}
            disabled={!isEditable}
          />
        )}
      </Field>
      <Field name={reference} validate={requiredField}>
        {({ input, meta }) => (
          <Input
            label={t('profile.business_contacts.trade_reference_form.company_name.label')}
            placeholder={t(
              'profile.business_contacts.trade_reference_form.company_name.placeholder',
            )}
            required
            size="small"
            className="margin-t-20px"
            error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
            touched={meta.touched}
            value={input.value}
            onChange={input.onChange}
            disabled={!isEditable}
          />
        )}
      </Field>
      <Field name={contactPersonName} validate={requiredField}>
        {({ input, meta }) => (
          <Input
            label={t('profile.business_contacts.trade_reference_form.contact_person.label')}
            placeholder={t(
              'profile.business_contacts.trade_reference_form.contact_person.placeholder',
            )}
            required
            size="small"
            className="margin-t-20px"
            error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
            touched={meta.touched}
            value={input.value}
            onChange={input.onChange}
            disabled={!isEditable}
          />
        )}
      </Field>
      <div className="grid">
        <Field name={phoneNumber} validate={requiredField}>
          {({ input, meta }) => (
            <Input
              label={t('profile.business_contacts.trade_reference_form.telephone_number.label')}
              placeholder={t(
                'profile.business_contacts.trade_reference_form.telephone_number.placeholder',
              )}
              required
              className="col-6 col-bleed padding-r-10px margin-t-20px"
              error={meta?.error || meta?.submitError}
              touched={meta.touched}
              value={input.value}
              onChange={input.onChange}
              disabled={!isEditable}
            />
          )}
        </Field>
        <Field name={email} validate={requiredField}>
          {({ input, meta }) => (
            <Input
              label={t('profile.business_contacts.trade_reference_form.email.label')}
              placeholder={t('profile.business_contacts.trade_reference_form.email.placeholder')}
              required
              size="small"
              className="col-6 col-bleed padding-l-10px margin-t-20px"
              error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
              touched={meta.touched}
              value={input.value}
              onChange={input.onChange}
              disabled={!isEditable}
            />
          )}
        </Field>
      </div>
    </div>
  );
};

TradeReference.defaultProps = {
  isEditable: true,
};

TradeReference.propTypes = {
  idTradeReference: PropTypes.string.isRequired,
  tradeReferenceIndex: PropTypes.number.isRequired,
  reference: PropTypes.string.isRequired,
  contactPersonName: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  isEditable: PropTypes.bool,
};

export default TradeReference;
