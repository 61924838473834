import React from 'react';
import { t } from 'i18next';
import Icon from 'components/BaseComponents/Icon';

const ContactSupportModalSuccess = () => (
  <div className="contact-admin-modal-success">
    <Icon
      name="checkCircleOutline"
      color="success"
      size="medium"
      className="contact-admin-modal-success__icon"
    />
    <p className="contact-admin-modal-success__message">
      {t('offer_creation.declaration.contact_support_modal.success_message')}
    </p>
  </div>
);

export default ContactSupportModalSuccess;
