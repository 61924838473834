import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/BaseComponents/Icon';
import './styles.scss';

const ListingNotification = ({ notificationHeader, notificationSubHeader }) => (
  <div className="listing-notification flex justify-between items-center">
    <div className="flex items-center gap-14px">
      <Icon name="neutralOutline" className="listing-notification__warning-icon" />
      <div>
        <p className="listing-notification__heading">{notificationHeader}</p>
        <p className="listing-notification__description">{notificationSubHeader}</p>
      </div>
    </div>
  </div>
);

ListingNotification.propTypes = {
  notificationHeader: PropTypes.string.isRequired,
  notificationSubHeader: PropTypes.string.isRequired,
};

export default ListingNotification;
