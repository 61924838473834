import { t } from 'i18next';
import moment from 'moment';

const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;

export const currentYear = new Date().getFullYear();

export const formatDate = (inputDate) => {
  if (!inputDate) return t('shared.date.invalid');

  const date = new Date(inputDate);
  const options = { year: 'numeric', month: 'short', day: '2-digit' };
  const formattedDate = date.toLocaleDateString('en-GB', options);

  return formattedDate;
};

export function getDatePriorDays(inputDate, numberOfDaysPrior) {
  const baseDate = new Date(inputDate);
  const priorDate = new Date(baseDate.getTime() - numberOfDaysPrior * DAY_IN_MILLISECONDS);

  const formattedDate = formatDate(priorDate);

  return formattedDate;
}

export const getYearFromDate = (inputdate) => moment.parseZone(inputdate).locale('en').year();
