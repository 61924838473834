import { apiSlice } from 'api';
import routes from 'utils/routes';
import { toSnakecaseKeys } from 'utils/helper/ObjectKeyConverter';
import { convertOfferFoodSafetyResponse } from 'utils/helper/ResponseConverter';
import { LISTINGS, OFFER_FOOD_SAFETY, PRODUCT_DETAILS, PRODUCTS } from 'utils/tagTypes';

const productsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query({
      query: (params) => routes.products.entity(params),
      transformResponse: (response) => response,
      providesTags: [PRODUCTS],
    }),
    getProductDetails: builder.query({
      query: (params) => routes.products.details(params),
      transformResponse: (response) => response,
      providesTags: [PRODUCT_DETAILS],
    }),
    createProduct: builder.mutation({
      query: (payload) => ({
        url: routes.products.createProduct(),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [PRODUCTS, LISTINGS],
    }),
    updateProduct: builder.mutation({
      query: ({ productId, payload }) => ({
        url: routes.products.details({ product_id: productId }),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (result, error) => (error ? [] : [PRODUCT_DETAILS]),
    }),
    getFoodSafetyTest: builder.query({
      query: ({ productId }) => routes.products.foodSafety({ productId }),
      transformResponse: (response) => {
        if (!response) {
          return convertOfferFoodSafetyResponse({});
        }
        return convertOfferFoodSafetyResponse(toSnakecaseKeys(response));
      },
      providesTags: [OFFER_FOOD_SAFETY],
    }),
    submitFoodSafetyTest: builder.mutation({
      query: ({ productId, payload }) => ({
        url: routes.products.foodSafety({ productId }),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [OFFER_FOOD_SAFETY],
    }),
    updateFoodSafetyTest: builder.mutation({
      query: ({ productId, payload }) => ({
        url: routes.products.foodSafety({ productId }),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [OFFER_FOOD_SAFETY],
    }),
    validateFoodSafetyTestField: builder.mutation({
      query: ({ payload }) => ({
        url: routes.products.foodSafetyTestValidate(),
        method: 'POST',
        body: payload,
      }),
    }),
    deleteFoodSafetyTestCertificate: builder.mutation({
      query: ({ attachmentId }) => ({
        url: routes.products.attachmentDelete({ attachmentId }),
        method: 'DELETE',
      }),
      invalidatesTags: [OFFER_FOOD_SAFETY],
    }),
    getSimilarProducts: builder.query({
      query: (params) => routes.products.similarProducts(params),
      transformResponse: (response) => response,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetProductsQuery,
  useGetProductDetailsQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useGetFoodSafetyTestQuery,
  useSubmitFoodSafetyTestMutation,
  useUpdateFoodSafetyTestMutation,
  useValidateFoodSafetyTestFieldMutation,
  useDeleteFoodSafetyTestCertificateMutation,
  useGetSimilarProductsQuery,
} = productsApi;
