import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SelectBox from 'components/BaseComponents/SelectBox';

const SelectFilter = ({ className, title, placeholderText, options, selectedOption, onChange }) => (
  <div className={classNames('aggregate-data-filter__box', className)}>
    <h4 className="aggregate-data-filter__box--title margin-b-16px">{title}</h4>
    <SelectBox
      width="full"
      placeholderText={placeholderText}
      options={options}
      isClearable={false}
      isSearchable={false}
      value={selectedOption}
      onChange={onChange}
    />
  </div>
);

SelectFilter.defaultProps = {
  className: '',
  title: null,
  placeholderText: 'Select...',
  options: [],
  selectedOption: null,
  onChange: () => {},
};

SelectFilter.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  placeholderText: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  selectedOption: PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  onChange: PropTypes.func,
};

export default SelectFilter;
