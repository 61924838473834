import React from 'react';
import { t } from 'i18next';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { useGetCurrentUserQuery } from 'api/users';
import { useGetCompanyQuery } from 'api/companies';
import { isVerified } from 'utils/helper/UserRoleValidation';
import LoadingSpinner from 'components/BaseComponents/LoadingSpinner';
import LeftBanner from 'components/SignUp/LeftBanner';
import FormBody from 'components/AffiliatePartner/InviteBuyer/InviteForm/FormBody';
import FormHeader from 'components/AffiliatePartner/InviteBuyer/InviteForm/FormHeader';

const InviteBuyer = () => {
  const navigate = useCustomNavigate();
  const { data: user = {}, isFetching: isFetchingUserInfo } = useGetCurrentUserQuery();
  const { data: companyDetails = {}, isFetching: isFetchingCompanyInfo } = useGetCompanyQuery(
    { id: user?.company_id },
    { skip: !user?.id },
  );
  const isLoading = isFetchingUserInfo || isFetchingCompanyInfo;
  const isUserVerified = isVerified(companyDetails);

  if (isLoading) return <LoadingSpinner />;

  if (!isLoading && !isUserVerified) navigate.unauthorizedPage();

  return (
    <div className="signup flex">
      <div className="signup__left-container">
        <LeftBanner title={t('affiliate_partners.invite_buyer.left_banner')} />
      </div>
      <div className="signup__right-container">
        <FormHeader />
        <FormBody />
      </div>
    </div>
  );
};

export default InviteBuyer;
