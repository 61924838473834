import i18n from 'i18n';
import { t } from 'i18next';

export const wholeSaleProductCard = [
  {
    id: 1,
    offerVolume: '12000',
    nutGrade: 'Premium',
    countryOfOrigin: 'South Africa',
    offerPrice: '3.1',
    estimatedShippingDate: '09 Jan, 2023',
    bestBeforeDate: '10 feb, 2023',
    fullBatchPrice: '44800.0',
  },
  {
    id: 2,
    offerVolume: '33000',
    nutGrade: 'Premium',
    countryOfOrigin: 'South Africa',
    offerPrice: '5.5',
    estimatedShippingDate: '26 May, 2023',
    bestBeforeDate: '03 July, 2023',
    fullBatchPrice: '38100.0',
  },
  {
    id: 3,
    offerVolume: '22000',
    nutGrade: 'Premium',
    countryOfOrigin: 'South Africa',
    offerPrice: '5',
    estimatedShippingDate: '18 Sep, 2023',
    bestBeforeDate: '29 Sep, 2023',
    fullBatchPrice: '63200.0',
  },
  {
    id: 4,
    offerVolume: '19000',
    nutGrade: 'Premium',
    countryOfOrigin: 'South Africa',
    offerPrice: '4.87',
    estimatedShippingDate: '18 Dec, 2022',
    bestBeforeDate: '2 Aug, 2023',
    fullBatchPrice: '43400.0',
  },
];

export const wholeSaleOfferCTA = {
  heading: t('wholesale_marketplace.listings.offer_card.heading'),
  subHeadings: [t('wholesale_marketplace.listings.offer_card.subheading')],
  buttonText: t('wholesale_marketplace.listings.offer_card.singup'),
};

export const wholeSaleTradingCards = [
  {
    heading: i18n.t('wholesale_marketplace.trading_cards.card1.heading'),
    description: i18n.t('wholesale_marketplace.trading_cards.card1.description'),
  },
  {
    heading: i18n.t('wholesale_marketplace.trading_cards.card2.heading'),
    description: i18n.t('wholesale_marketplace.trading_cards.card2.description.title'),
    list: [
      {
        id: 1,
        text: i18n.t('wholesale_marketplace.trading_cards.card2.description.list_items.item1'),
      },
      {
        id: 2,
        text: i18n.t('wholesale_marketplace.trading_cards.card2.description.list_items.item2'),
      },
      {
        id: 3,
        text: i18n.t('wholesale_marketplace.trading_cards.card2.description.list_items.item3'),
      },
    ],
  },
  {
    heading: i18n.t('wholesale_marketplace.trading_cards.card3.heading'),
    description: i18n.t('wholesale_marketplace.trading_cards.card3.description'),
  },
  {
    heading: i18n.t('wholesale_marketplace.trading_cards.card5.heading'),
    description: i18n.t('wholesale_marketplace.trading_cards.card5.description.title'),
    list: [
      {
        id: 1,
        text: i18n.t('wholesale_marketplace.trading_cards.card5.description.list_items.item1'),
      },
      {
        id: 2,
        text: i18n.t('wholesale_marketplace.trading_cards.card5.description.list_items.item2'),
      },
      {
        id: 3,
        text: i18n.t('wholesale_marketplace.trading_cards.card5.description.list_items.item3'),
      },
      {
        id: 4,
        text: i18n.t('wholesale_marketplace.trading_cards.card5.description.list_items.item4'),
      },
      {
        id: 5,
        text: i18n.t('wholesale_marketplace.trading_cards.card5.description.list_items.item5'),
      },
    ],
  },
  {
    heading: i18n.t('wholesale_marketplace.trading_cards.card6.heading'),
    description: i18n.t('wholesale_marketplace.trading_cards.card6.description.title'),
    list: [
      {
        id: 1,
        text: i18n.t('wholesale_marketplace.trading_cards.card6.description.list_items.item1'),
      },
      {
        id: 2,
        text: i18n.t('wholesale_marketplace.trading_cards.card6.description.list_items.item2'),
      },
      {
        id: 3,
        text: i18n.t('wholesale_marketplace.trading_cards.card6.description.list_items.item3'),
      },
      {
        id: 4,
        text: i18n.t('wholesale_marketplace.trading_cards.card6.description.list_items.item4'),
      },
      {
        id: 5,
        text: i18n.t('wholesale_marketplace.trading_cards.card6.description.list_items.item5'),
      },
      {
        id: 6,
        text: i18n.t('wholesale_marketplace.trading_cards.card6.description.list_items.item6'),
      },
    ],
  },
];

export const WholeSaleTradingSteps = [
  {
    cardNumber: i18n.t('wholesale_marketplace.trading_steps.card1.card_number'),
    heading: i18n.t('wholesale_marketplace.trading_steps.card1.heading'),
    description: i18n.t('wholesale_marketplace.trading_steps.card1.description'),
  },
  {
    cardNumber: i18n.t('wholesale_marketplace.trading_steps.card2.card_number'),
    heading: i18n.t('wholesale_marketplace.trading_steps.card2.heading'),
    description: i18n.t('wholesale_marketplace.trading_steps.card2.description'),
  },
  {
    cardNumber: i18n.t('wholesale_marketplace.trading_steps.card3.card_number'),
    heading: i18n.t('wholesale_marketplace.trading_steps.card3.heading'),
    description: i18n.t('wholesale_marketplace.trading_steps.card3.description'),
  },
];
