import { DRIED_NUT_IN_SHELL_PRODUCT, KERNEL_PRODUCT } from 'utils/constants/product';
import { ADMIN, BUYER, SELLER, AFFILIATE_PARTNER, SUBSCRIPTION_MEMBER, OWNER } from 'utils/constants/userRole';
import { DOCUMENT_REQUESTED, REQUESTED_VERIFICATION, VERIFIED } from 'utils/constants/userStatus';

const isVerified = (company) => company?.status === VERIFIED;
const hasRequestedAdditionalDoc = (company) => company?.status === DOCUMENT_REQUESTED;
const userRequestedVerification = (company) => company?.status === REQUESTED_VERIFICATION;

const hasRole = (user, role) => user?.roles?.includes(role);
const hasNoRole = (user, role) => !hasRole(user, role);

const hasNoRoles = (user) => user?.roles && !(user.roles.length > 0);
const hasAllRoles = (user, rolesToCheck) => rolesToCheck.every((role) => hasRole(user, role));
const hasAnyRole = (user, rolesToCheck) => rolesToCheck.some((role) => hasRole(user, role));

const hasSellerRole = (user) => hasRole(user, SELLER);
const hasBuyerRole = (user) => hasRole(user, BUYER);
const hasAdminRole = (user) => hasRole(user, ADMIN);
const hasPartnerRole = (user) => hasRole(user, AFFILIATE_PARTNER);
const hasSubscriptionMemberRole = (user) => hasRole(user, SUBSCRIPTION_MEMBER);
const isCompanyOwner = (user, company) => hasRole(user, OWNER) && user.company_id === company?.id;

const hasNoSellerRole = (user) => hasNoRole(user, SELLER);
const hasNoBuyerRole = (user) => hasNoRole(user, BUYER);
const hasNoAdminRole = (user) => hasNoRole(user, ADMIN);
const hasNoPartnerRole = (user) => hasNoRole(user, AFFILIATE_PARTNER);
const hasNoSubscriptionMemberRole = (user) => hasNoRole(user, SUBSCRIPTION_MEMBER);

const hasCompanyPermissions = (type, company) => (
  (type === KERNEL_PRODUCT && company?.company_permissions?.trade_kernel) ||
  (type === DRIED_NUT_IN_SHELL_PRODUCT && company?.company_permissions?.trade_dried_nut_in_shell)
);

export {
  hasRequestedAdditionalDoc,
  userRequestedVerification,
  isVerified,
  hasSellerRole,
  hasBuyerRole,
  hasAdminRole,
  hasPartnerRole,
  hasSubscriptionMemberRole,
  isCompanyOwner,
  hasAllRoles,
  hasAnyRole,
  hasNoRoles,
  hasNoSellerRole,
  hasNoBuyerRole,
  hasNoAdminRole,
  hasNoPartnerRole,
  hasNoSubscriptionMemberRole,
  hasCompanyPermissions,
};
