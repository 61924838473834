import React, { useState } from 'react';
import { useGetCurrentUserQuery } from 'api/users';
import { useGetCompanyQuery } from 'api/companies';
import PortalsNavbar from 'components/NavBar/PortalsNavBar';
import AvatarCard from 'components/Shared/BiddingProcess/Avatar';
import { DRIED_NUT_IN_SHELL_PRODUCT, KERNEL_PRODUCT } from 'utils/constants/product';
import PortalsFooter from 'components/Shared/PortalsFooter';
import { AbilityContext } from 'ability';
import buildListingsAbility from 'ability/listings';
import ListingsContainer from './Container';
import './styles.scss';

const OfferListings = () => {
  const storedNutsTab = localStorage.getItem('nutsTab');
  const initNutsTab = [DRIED_NUT_IN_SHELL_PRODUCT, KERNEL_PRODUCT].includes(storedNutsTab)
    ? storedNutsTab
    : DRIED_NUT_IN_SHELL_PRODUCT;

  const [nutsTab, setNutsTab] = useState(initNutsTab);
  localStorage.setItem('nutsTab', nutsTab); // add nutsTab to localStorage to catch it on 'Create Product' page

  const { data: userDetails = {}, isLoading: isUserDetailsLoading } = useGetCurrentUserQuery();
  const { data: companyDetails = {}, isLoading: isCompanyDetailsLoading } = useGetCompanyQuery(
    { id: userDetails?.company_id },
    { skip: !userDetails?.id },
  );

  const ability = buildListingsAbility({ userDetails, companyDetails });

  return (
    <AbilityContext.Provider value={ability}>
      <PortalsNavbar />
      {!isUserDetailsLoading && !isCompanyDetailsLoading && (
        <AvatarCard
          className="border-y-1 border-gray-200 border-y-solid padding-y-32px"
          userDetails={userDetails}
          companyDetails={companyDetails}
          nutsTab={nutsTab}
        />
      )}
      <div className="offer-listing padding-y-32px">
        <ListingsContainer companyId={companyDetails.id} roles={userDetails.roles} nutsTab={nutsTab} setNutsTab={setNutsTab} />
      </div>
      <PortalsFooter/>
    </AbilityContext.Provider>
  );
};

export default OfferListings;
