import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { useResetPasswordMutation } from 'api/auth';
import { Form, Field } from 'react-final-form';
import { requiredField } from 'utils/helper/Validations';
import Button from 'components/BaseComponents/Button';
import Icon from 'components/BaseComponents/Icon';
import PasswordInput from 'components/Shared/PasswordInput';
import FormFooter from 'components/ResetPassword/Forms/FormFooter';
import '../styles.scss';

const ResetPasswordForm = () => {
  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const [searchParams] = useSearchParams();
  const [resetPassword] = useResetPasswordMutation();
  const queryParams = searchParams.get('token');

  useEffect(() => {
    if (!queryParams) {
      navigate.forgotPassword();
    }
  }, []);

  const handleResetSubmit = async (params) => {
    if (params.password !== params.confirm_password) {
      return {
        confirm_password: t('reset_password.error_password_not_matched'),
      };
    }

    const resetPasswordBody = {
      verify_token: searchParams.get('token'),
      password: params.password,
      confirmed_password: params.confirm_password,
    };

    return resetPassword({ payload: resetPasswordBody })
      .unwrap()
      .then(() => {
        navigate.resetPasswordSuccess(queryParams);
      })
      .catch((response) => {
        if (response.status === 422) {
          const {
            data: { errors },
          } = response;

          const error = errors.password;
          return { password: error, confirm_password: error };
        }
        return null;
      });
  };

  return (
    <div className="reset-password-form__container">
      <div className="reset-password-form__container--header">
        <Icon name="keyLine" size="xl" color="none" className="reset-form-icon" />

        <div className="reset-password-form__container--header-texts">
          <h2 className="reset-password-form__container--header-texts-title">
            {t('reset_password.title')}
          </h2>

          <p className="reset-password-form__container--header-texts-message">
            {t('reset_password.welcome_message')}
          </p>
        </div>
      </div>

      <div className="reset-password-form__container--form">
        <Form
          onSubmit={handleResetSubmit}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} className="margin-t-16px">
              <Field name="password" validate={requiredField}>
                {({ input, meta }) => (
                  <PasswordInput
                    className="margin-t-20px"
                    label={t('reset_password.password_label')}
                    placeholder={t('reset_password.password_placeholder')}
                    required
                    error={meta?.error || meta?.submitError}
                    touched={meta.touched}
                    size="small"
                    value={input.value}
                    onChange={input.onChange}
                  />
                )}
              </Field>

              <Field name="confirm_password" validate={requiredField}>
                {({ input, meta }) => (
                  <>
                    <PasswordInput
                      className="margin-t-20px margin-b-16px"
                      label={t('reset_password.confirm_password_label')}
                      placeholder={t('reset_password.confirm_password_placeholder')}
                      required
                      error={meta?.error || meta?.submitError}
                      touched={meta.touched}
                      size="small"
                      value={input.value}
                      onChange={input.onChange}
                    />
                    <span className="submit-error">{meta?.submitError}</span>
                  </>
                )}
              </Field>

              <Button
                className="reset-password-form__container--submit-btn"
                type="success"
                label={t('reset_password.submit_button_label')}
                submitType="submit"
                disabled={submitting}
              />
            </form>
          )}
        />
      </div>

      <FormFooter />
    </div>
  );
};

export default ResetPasswordForm;
