import { Fragment } from 'react';
import PropTypes from 'prop-types';
import './linechart.styles.scss';
import { PRICE_CHART_TOTAL_AVG_DATALINE_KEY } from 'utils/constants/Charts';

export const LineChartCustomTooltip = ({ active, payload, label, formatter }) => {
  if (active && payload && payload.length) {
    return (
      <div className="line-chart__custom-tooltip">
        <p className="line-chart__custom-tooltip--label">{label}</p>
        {payload.map(({ name, value, color, dataKey, payload: pld }) => {
          if (dataKey === PRICE_CHART_TOTAL_AVG_DATALINE_KEY) {
            return;
          }
          return <Fragment key={`${dataKey}-${pld.name}`} >
            <div>
              <span style={{ color: color }} className="line-chart__custom-tooltip--legend">
                {`${name}:`}
              </span>
              <span>{formatter(value)}</span>
            </div>
          </Fragment>
          })
        }
      </div>
    );
  }

  return;
};

LineChartCustomTooltip.propTypes = {
  formatter: PropTypes.func.isRequired,
};
