// Its a temporary component added to display development-ongoing info at the top for time being
// Once client resolves their legal issues we will delete it
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import developmentIcon from 'assets/images/development-ongoing.png';
import { Accordion, AccordionItem } from 'components/BaseComponents/Accordion';
import './styles.scss';

const AccordionHeader = () => {
  const { t } = useTranslation();

  return (
    <div className="accordion-container__heading-container flex justify-center items-center gap-16px">
      <div>
        <img src={developmentIcon} alt="" height={35} width={35} />
      </div>
      <h3 className="accordion-container__heading-container--title">
        {t('shared.development_ongoing.heading')}
      </h3>
    </div>
  );
};

const DevelopmentAccordion = () => {
  const { t } = useTranslation();
  const [isAccordionActive, setIsAccordionActive] = useState(true);

  const toggleAccordion = () => setIsAccordionActive((prev) => !prev);

  return (
    <div className="accordion-container">
      <Accordion>
        <AccordionItem
          isActive={isAccordionActive}
          key={1}
          onClick={toggleAccordion}
          header={<AccordionHeader />}
        >
          {t('shared.development_ongoing.description')}
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default DevelopmentAccordion;
