import React, { useRef } from 'react';
import { t } from 'i18next';
import { useParams } from 'react-router-dom';
import { Form } from 'react-final-form';
import useCustomNavigate from 'hooks/useCustomNavigate';
import useIsListingUnpublished from 'hooks/useIsListingUnpublished';
import useGetDeclarationContents from 'hooks/useGetDeclarationContents';
import { useGetProductDetailsQuery } from 'api/products';
import { usePublishListingMutation } from 'api/listings';
import { handleServerValidationErrors } from 'utils/helper/Validations';
import { processPortalFormSuccess } from 'utils/helper/FormHandlers';
import { offerOrderPageIndex } from 'utils/constants/listingCreation';
import { errorToastConfig } from 'utils/constants/ToastConfig';
import SubmitButtons from 'components/Shared/BiddingProcess/StepperButtons/SubmitButtons';
import ToastNotification from 'components/Shared/ToastNotification';
import Toastify from 'components/Shared/ToastNotification/Toastify';
import { OFFER } from 'utils/constants/offerListings';
import CreateListingAuthorizer from '../Layout/CreateListingAuthorizer';
import DeclarationSubSection from './SubSection';
import DeclarationFooter from './Footer';
import CreateOfferLayout from '../Layout';
import './styles.scss';

const DeclarationOfferCreation = () => {
  const toastRef = useRef();
  const navigate = useCustomNavigate();

  const { productId } = useParams();
  const [publishListing] = usePublishListingMutation();
  const { data: productDetails = {} } = useGetProductDetailsQuery(
    { product_id: productId },
    { skip: !productId },
  );
  const listingId = productDetails?.listingId;
  const isListingUnpublished = useIsListingUnpublished({ listingId });
  const { category } = productDetails;
  const isOffer = category === OFFER;
  const currentPageIndex = offerOrderPageIndex.declaration(isOffer);

  const [declarationSubSectionContents, declarationContentsAuthorization] =
    useGetDeclarationContents({ productId });

  const handlePreviousButtonClick = () => (
    isOffer ? navigate.foodSafety(productId) : navigate.reviewListingById(productId)
  );

  const handlePublishListing = async () =>
    publishListing({ listing_id: listingId })
      .then(() => {
        processPortalFormSuccess(toastRef);

        navigate.listings();
      })
      .catch(({ data: { message }, status }) =>
        handleServerValidationErrors(toastRef, status, message),
      );

  return (
    <CreateListingAuthorizer>
      <CreateOfferLayout activeStep={currentPageIndex} category={category}>
        <Form
          onSubmit={handlePublishListing}
          render={({ handleSubmit, submitting, hasValidationErrors }) => (
            <form
              onSubmit={(event) => {
                if (hasValidationErrors) {
                  toastRef?.current?.notify(<Toastify type="error" />, errorToastConfig);
                }

                handleSubmit(event);
              }}
            >
              <SubmitButtons
                isSaveAsDraftVisible={false}
                buttonLabel={t('offer_creation.declaration.submit_button_label')}
                currentStepIndex={currentPageIndex}
                onPrevButtonClick={handlePreviousButtonClick}
                isSubmitButtonDisabled={submitting || !isListingUnpublished}
              />
              <div className="offer-declaration">
                <h2 className="offer-declaration__heading">Declaration</h2>
                <div className="offer-declaration__body">
                  <DeclarationSubSection
                    title="Declaration"
                    declarationContents={declarationSubSectionContents}
                    isInputDisabled={!isListingUnpublished}
                  />
                  <DeclarationSubSection
                    title="Authorization"
                    declarationContents={declarationContentsAuthorization}
                    isInputDisabled={!isListingUnpublished}
                  />
                </div>
                <DeclarationFooter />
              </div>
            </form>
          )}
        />
        <ToastNotification toastRef={toastRef} />
      </CreateOfferLayout>
    </CreateListingAuthorizer>
  );
};

export default DeclarationOfferCreation;
