import { AbilityBuilder, createMongoAbility } from "@casl/ability";
import { hasAdminRole } from "utils/helper/UserRoleValidation";
import defineProfileTabsAbility from "./profileTabs";

const buildAdminAbility = (admin, { userDetails, companyDetails }) => {
  const { can, cannot, build } = new AbilityBuilder(createMongoAbility);

  if (hasAdminRole(admin)) {
    defineProfileTabsAbility({ can, cannot, admin, userDetails, companyDetails })
  }

  return build();
};

export default buildAdminAbility;
