import React from 'react';
import ReviewListingCard from 'components/Shared/BiddingProcess/ReviewListing';
import CreateListingAuthorizer from './Layout/CreateListingAuthorizer';

const ReviewListing = () => (
  <CreateListingAuthorizer>
    <ReviewListingCard />
  </CreateListingAuthorizer>
);

export default ReviewListing;
