import React from 'react';
import {
  allUsersTabTableHeader,
  appliedTabTableHeader,
  approvedTabTableHeader,
  unapprovedTabTableHeader,
  contractsTabTableHeader,
  offersTableHeader,
  dnisOffersTableHeader,
  offersTab,
  ordersTab,
  contractsTab,
  allUsersTab,
  approvedTab,
  appliedTab,
  documentRequestedTab,
  unapprovedTab,
  onboardedTab,
  invitedTab,
  archivedTab,
  oboardedTabTableHeader,
  invitedTabTableHeader,
  archivedTabTableHeader,
  appliedPartnerTableHeader,
  approvedPartnerTableHeader,
  inProgressTab,
  completedTab,
  concludedTab,
  activeOffersTab,
  inDraftTab,
} from 'utils/constants/portals';
import {
  AFFILIATE_PARTNER_BG_COLOR,
  BUYER_AVATAR_BG_COLOR,
  SELLER_AVATAR_BG_COLOR,
} from 'utils/constants/user';
import ProductTable from 'components/Portals/Admin/Products/ListingManagementTable/ProductTable';
import UserTable from 'components/Portals/Admin/UserManagement/Table/UserTable';
import OfferCardsContainer from 'components/BiddingProcess/Listings/OfferCardsContainer';
import PartnerTable from 'components/Portals/Admin/AffiliatePartnerManagement/Table/PartnerTable';
import MyAffiliationsTableSkeleton from 'components/AffiliatePartner/AffiliationsTable/Table';
import { KERNEL_PRODUCT } from 'utils/constants/product';
import { hasBuyerRole, hasSellerRole } from './UserRoleValidation';

export const getAvatarBgColorCode = (userDetails) => {
  if (hasSellerRole(userDetails)) {
    return SELLER_AVATAR_BG_COLOR;
  }
  if (hasBuyerRole(userDetails)) {
    return BUYER_AVATAR_BG_COLOR;
  }

  return AFFILIATE_PARTNER_BG_COLOR;
};

export const getAdminUserTableTabs = (fetchingAllUsers, sortParams, users = []) => [
  {
    title: allUsersTab,
    key: allUsersTab,
    getContent: () => (
      <UserTable
        tabKey={allUsersTab}
        tableHeaders={allUsersTabTableHeader}
        users={users}
        fetchingAllUsers={fetchingAllUsers}
        sortParams={sortParams}
      />
    ),
  },
  {
    key: appliedTab,
    title: appliedTab,
    getContent: () => (
      <UserTable
        tabKey={appliedTab}
        tableHeaders={appliedTabTableHeader}
        users={users}
        fetchingAllUsers={fetchingAllUsers}
        sortParams={sortParams}
      />
    ),
  },
  {
    key: approvedTab,
    title: approvedTab,
    getContent: () => (
      <UserTable
        tabKey={approvedTab}
        tableHeaders={approvedTabTableHeader}
        users={users}
        fetchingAllUsers={fetchingAllUsers}
        sortParams={sortParams}
      />
    ),
  },
  {
    key: documentRequestedTab,
    title: documentRequestedTab,
    getContent: () => (
      <UserTable
        tabKey={documentRequestedTab}
        tableHeaders={unapprovedTabTableHeader}
        users={users}
        fetchingAllUsers={fetchingAllUsers}
        sortParams={sortParams}
      />
    ),
  },
  {
    key: unapprovedTab,
    title: unapprovedTab,
    getContent: () => (
      <UserTable
        tabKey={unapprovedTab}
        tableHeaders={unapprovedTabTableHeader}
        users={users}
        fetchingAllUsers={fetchingAllUsers}
        sortParams={sortParams}
      />
    ),
  },
];

export const getProductsTableTabs = (
  handleColumnClick,
  fetchingProducts,
  productType,
  productData = {},
  contractsData = {},
) => [
  {
    key: contractsTab,
    title: contractsTab,
    getContent: () => (
      <ProductTable
        tabKey={contractsTab}
        tableHeaders={contractsTabTableHeader}
        products={contractsData.collection}
        fetchingProducts={fetchingProducts}
        onColumnClick={handleColumnClick}
      />
    ),
  },
  {
    key: offersTab,
    title: offersTab,
    getContent: () => (
      <ProductTable
        tabKey={offersTab}
        tableHeaders={
          productType === KERNEL_PRODUCT ? offersTableHeader : dnisOffersTableHeader
        }
        products={productData.collection}
        fetchingProducts={fetchingProducts}
        onColumnClick={handleColumnClick}
      />
    ),
  },
  {
    key: ordersTab,
    title: ordersTab,
    getContent: () => (
      <ProductTable
        tabKey={ordersTab}
        tableHeaders={
          productType === KERNEL_PRODUCT ? offersTableHeader : dnisOffersTableHeader
        }
        products={productData.collection}
        fetchingProducts={fetchingProducts}
        onColumnClick={handleColumnClick}
      />
    ),
  },
];

export const getEditStatus = (isAdmin, isVerified, hasRequestedVerification) =>
  !(isAdmin || (isVerified && !isAdmin) || (hasRequestedVerification && !isAdmin));

export const getMyAffiliationsTable = (isFetching, isAdmin, usersData = {}) => [
  {
    key: onboardedTab,
    title: onboardedTab,
    getContent: () => (
      <MyAffiliationsTableSkeleton
        tabKey={onboardedTab}
        tableHeaders={oboardedTabTableHeader}
        users={usersData}
        isFetching={isFetching}
      />
    ),
  },
  {
    key: invitedTab,
    title: invitedTab,
    getContent: () => (
      <MyAffiliationsTableSkeleton
        tabKey={invitedTab}
        tableHeaders={invitedTabTableHeader(isAdmin)}
        users={usersData}
        isFetching={isFetching}
      />
    ),
  },
  {
    key: archivedTab,
    title: archivedTab,
    getContent: () => (
      <MyAffiliationsTableSkeleton
        tabKey={archivedTab}
        tableHeaders={archivedTabTableHeader}
        users={usersData}
        isFetching={isFetching}
      />
    ),
  },
];

export const getAdminaffiliationsTable = (isUsersFetching, users = []) => [
  {
    key: appliedTab,
    title: appliedTab,
    getContent: () => (
      <PartnerTable
        tabKey={appliedTab}
        tableHeaders={appliedPartnerTableHeader}
        users={users}
        isUsersFetching={isUsersFetching}
      />
    ),
  },
  {
    key: approvedTab,
    title: approvedTab,
    getContent: () => (
      <PartnerTable
        tabKey={approvedTab}
        tableHeaders={approvedPartnerTableHeader}
        users={users}
        isUsersFetching={isUsersFetching}
      />
    ),
  },
];

export const getTabTitle = (tabKey, productCount) =>
  `${tabKey}${productCount ? ` (${productCount})` : ''}`;

export const getProducts = ({
  activeProductCount,
  draftProductCount,
  activeOfferList,
  darftOfferList,
  activeListingsLoading,
  draftListingsLoading,
  productStatus,
  category,
}) => [
  {
    key: activeOffersTab,
    title: getTabTitle(activeOffersTab, activeProductCount),
    getContent: () => (
      <OfferCardsContainer
        listings={activeOfferList}
        isLoading={activeListingsLoading}
        productStatus={productStatus}
        category={category}
      />
    ),
  },
  {
    key: inDraftTab,
    title: getTabTitle(inDraftTab, draftProductCount),
    getContent: () => (
      <OfferCardsContainer
        listings={darftOfferList}
        isLoading={draftListingsLoading}
        productStatus="draft"
        category={category}
      />
    ),
  },
];

export const getContracts = ({
  completedContractCount,
  concludedContractCount,
  inProgressContractCount,
  completedContractList,
  concludedContractList,
  inProgressContractList,
  completedContractsLoading,
  concludedContractsLoading,
  inProgressContractsLoading,
  productStatus,
}) => [
  {
    key: completedTab,
    title: getTabTitle(completedTab, completedContractCount),
    getContent: () => (
      <OfferCardsContainer
        listings={completedContractList}
        isLoading={completedContractsLoading}
        productStatus={productStatus}
      />
    ),
  },
  {
    key: concludedTab,
    title: getTabTitle(concludedTab, concludedContractCount),
    getContent: () => (
      <OfferCardsContainer
        listings={concludedContractList}
        isLoading={concludedContractsLoading}
        productStatus={productStatus}
      />
    ),
  },
  {
    key: inProgressTab,
    title: getTabTitle(inProgressTab, inProgressContractCount),
    getContent: () => (
      <OfferCardsContainer
        listings={inProgressContractList}
        isLoading={inProgressContractsLoading}
        productStatus={productStatus}
      />
    ),
  },
];
