import React, { useState } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { requiredField } from 'utils/helper/Validations';
import Input from 'components/BaseComponents/Input';
import SelectBox from 'components/BaseComponents/SelectBox';
import {
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/BaseComponents/Table';
import TestCountInput from './TestCountInput';
import './styles.scss';

const TestResultsTable = ({ tableHeaders, tableContentCollection, form, isOptional }) => {
  const [fieldTestCountError, setFieldTestCountError] = useState({});
  const extractTestMethodMaxLimitValue = (formData, testMethodFieldName) => {
    const { values } = formData.getState();
    const maxLimit = values[testMethodFieldName]?.maxLimit;

    return maxLimit === null ? '0' : maxLimit;
  };

  return (
    <Table className="test-results-table">
      <TableHead>
        <TableRow isHeaderRow={true}>
          {tableHeaders.map((title) => (
            <TableHeader key={title} unavailableText={t('admin_user_table.no_data_available')}>
              {title}
            </TableHeader>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {tableContentCollection.map(
          ({
            substance,
            substanceValue,
            testMethodFieldName,
            testValueFieldName,
            testMethods,
            cclCheckboxFieldName,
            cclMaxLimitFieldName,
          }) => (
            <TableRow key={substance}>
              <TableData>{substance}</TableData>
              <TableData>
                <Field
                  name={testMethodFieldName}
                  validate={isOptional ? null : (value) => requiredField(value)}
                  // validate={requiredField}
                >
                  {({ input, meta }) => (
                    <SelectBox
                      options={testMethods}
                      value={input.value}
                      errorMsg={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      onChange={(e) => {
                        form.mutators.setFormAttribute(testValueFieldName, null);
                        setFieldTestCountError({
                          ...fieldTestCountError,
                          [substanceValue]: null,
                        });
                        input.onChange(e);
                      }}
                      isClearable={isOptional}
                      required={!isOptional}
                      isSearchable={false}
                      className="test-results-table__select-box"
                    />
                  )}
                </Field>
              </TableData>
              <TableData>
                <Field
                  name={testValueFieldName}
                  validate={isOptional ? null : (value) => requiredField(value)}
                >
                  {({ input, meta }) => {
                    const testMethod = form.getState().values[testMethodFieldName];

                    return (
                      <TestCountInput
                        input={input}
                        error={meta?.error || (!meta?.modifiedSinceLastSubmit && meta?.submitError)}
                        touched={meta.dirty}
                        required={!isOptional}
                        disabled={!testMethod}
                        placeholder={t('offer_creation.food_safety.test_value_placeholder')}
                        hint={
                          !testMethod && t('offer_creation.food_safety.test_method_selection_hint')
                        }
                        substanceValue={substanceValue}
                        testMethodValue={testMethod?.value}
                      />
                    );
                  }}
                </Field>
              </TableData>
              <TableData>
                {form.getState().values[cclCheckboxFieldName] ? (
                  <Field
                    name={cclMaxLimitFieldName}
                    validate={isOptional ? null : (value) => requiredField(value)}
                  >
                    {({ input, meta }) => (
                      <Input
                        placeholder={t('offer_creation.food_safety.test_value_placeholder')}
                        value={input.value}
                        error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                        touched={meta.touched}
                        type="number"
                        onChange={input.onChange}
                        required
                      />
                    )}
                  </Field>
                ) : (
                  extractTestMethodMaxLimitValue(form, testMethodFieldName)
                )}
              </TableData>

              {/* Currently turned off due to soem business policy,
              but might be enabled in future */}
              {/* <TableData>
                {cclCheckboxFieldName && (
                  <Field name={cclCheckboxFieldName} type="checkbox">
                    {({ input }) => (
                      <CheckBox
                        id={cclCheckboxFieldName}
                        label="Use CCL"
                        isChecked={input.checked}
                        onChange={(checked) => {
                          input.onChange(checked);
                        }}
                      />
                    )}
                  </Field>
                )}
              </TableData> */}
            </TableRow>
          ),
        )}
      </TableBody>
    </Table>
  );
};

TestResultsTable.defaultProps = {
  isOptional: false,
};

TestResultsTable.propTypes = {
  tableHeaders: PropTypes.node.isRequired,
  tableContentCollection: PropTypes.arrayOf(
    PropTypes.shape({
      substance: PropTypes.string.isRequired,
      testMethods: PropTypes.node.isRequired,
      testMethodFieldName: PropTypes.string.isRequired,
      testValueFieldName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  form: PropTypes.shape({
    batch: PropTypes.func,
    change: PropTypes.func,
    getState: PropTypes.func,
    mutators: PropTypes.shape({
      setFormAttribute: PropTypes.func,
    }),
  }).isRequired,
  isOptional: PropTypes.bool,
};

export default TestResultsTable;
