import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { documentShape } from 'utils/constants/PropTypes';
import { requiredField, validatePortalDocuments } from 'utils/helper/Validations';
import Input from 'components/BaseComponents/Input';
import Icon from 'components/BaseComponents/Icon';
import ChooseFile from 'components/Shared/ChooseFile';
import IconButton from 'components/BaseComponents/IconButton';
import { labelWithHints } from 'utils/helper/LabelWithHints';

const DirectorForm = ({
  directorIndex,
  idDirector,
  firstName,
  lastName,
  identificationNumber,
  idDocuments,
  totalUploadedDocument,
  onRemoveDirector,
  isEditable,
  directorFile,
}) => {
  const { t } = useTranslation();
  const deleteIcon = <Icon name="deleteLine" size="medium" color="danger" />;

  return (
    <div className="padding-x-24px">
      <div className="flex justify-between items-center margin-b-0 business-contacts__form-title">
        <p className="margin-t-0 margin-b-0">
          {t('profile.business_contacts.form.director_form_title', { directorIndex })}
          <span className="business-contacts__form-title--required">*</span>
        </p>
        {directorIndex !== 1 && isEditable && (
          <IconButton
            icon={deleteIcon}
            size="large"
            color="danger"
            isIconOnly={true}
            onClick={onRemoveDirector}
          />
        )}
      </div>
      <Field name={idDirector}>
        {({ input }) => (
          <Input
            required={false}
            size="small"
            className="business-contacts__hidden-field"
            value={input.value}
            disabled={!isEditable}
          />
        )}
      </Field>
      <div className="grid">
        <Field name={firstName} validate={requiredField}>
          {({ input, meta }) => (
            <Input
              label={t('profile.business_contacts.form.first_name.label')}
              placeholder={t('profile.business_contacts.form.first_name.placeholder')}
              required
              className="col-6 col-bleed padding-r-10px margin-t-20px"
              error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
              touched={meta.touched}
              value={input.value}
              onChange={input.onChange}
              disabled={!isEditable}
            />
          )}
        </Field>
        <Field name={lastName} validate={requiredField}>
          {({ input, meta }) => (
            <Input
              label={t('profile.business_contacts.form.last_name.label')}
              placeholder={t('profile.business_contacts.form.last_name.placeholder')}
              required
              size="small"
              className="col-6 col-bleed padding-l-10px margin-t-20px"
              error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
              touched={meta.touched}
              value={input.value}
              onChange={input.onChange}
              disabled={!isEditable}
            />
          )}
        </Field>
      </div>
      <Field name={identificationNumber} validate={requiredField}>
        {({ input, meta }) => (
          <Input
            label={t('profile.business_contacts.form.identification_number.label')}
            placeholder={t('profile.business_contacts.form.identification_number.placeholder')}
            required
            size="small"
            className="margin-t-20px"
            error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
            touched={meta.touched}
            value={input.value}
            onChange={input.onChange}
            disabled={!isEditable}
          />
        )}
      </Field>
      <div
        className="flex
      justify-between items-center padding-t-32px
       padding-b-12px business-contacts__title-container"
      >
        <div className="flex items-center gap-4px">
          <span className="business-contacts__title-container--header">
            {labelWithHints(
              t('profile.business_contacts.form.documents.title.label', { directorIndex }),
              t('shared.incorrect_document_tooltip'),
            )}
          </span>
          <span className="business-contacts__title-container--required">*</span>
        </div>
        <p className="business-contacts__uploaded-file">
          {t('profile.business_contacts.form.documents.director_documents', {
            totalUploadedDocument,
          })}
        </p>
      </div>
      <div className="margin-t-14px margin-b-38px padding-b-16px">
        <Field name={idDocuments} validate={validatePortalDocuments}>
          {({ input, meta }) => (
            <ChooseFile
              fileName={input.value?.name}
              fileUrl={directorFile?.url}
              label={t('profile.business_contacts.form.documents.director_passport', {
                directorIndex,
              })}
              hint={t('profile.file.upload')}
              onChange={(file) => {
                input.onChange(file);
              }}
              touched={meta?.touched}
              errorMessage={meta?.error || meta?.submitError}
              isEditable={isEditable}
            />
          )}
        </Field>
      </div>
    </div>
  );
};

DirectorForm.defaultProps = {
  totalUploadedDocument: 0,
  onRemoveDirector: () => {},
  isEditable: true,
  directorFile: null,
};

DirectorForm.propTypes = {
  idDirector: PropTypes.string.isRequired,
  directorIndex: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  identificationNumber: PropTypes.string.isRequired,
  idDocuments: PropTypes.string.isRequired,
  totalUploadedDocument: PropTypes.number,
  onRemoveDirector: PropTypes.func,
  isEditable: PropTypes.bool,
  directorFile: documentShape,
};

export default DirectorForm;
