import React, { useState } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'components/BaseComponents/Button';
import { useChangeContractStatusMutation } from 'api/contracts';
import { Link } from 'react-router-dom';
import { admin } from 'utils/constants/Routes';
import Icon from 'components/BaseComponents/Icon';
import { formatDate } from 'utils/helper/FormatDate';
import { formatPrice, formatVolume } from 'utils/helper/Charts';
import TableColumn from 'components/Portals/Admin/UserManagement/TableColumn';
import {
  formattedProductStatus,
  PUBLISHED,
  COMPLETED,
  getContractStatusLabel,
} from 'utils/constants/offerListings';
import Modal from 'components/BaseComponents/Modal';
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/BaseComponents/Table';
import {
  NOT_APPLICABLE,
  contractsTab,
  contractsTabTableHeader,
  offersTab,
  ordersTab,
} from 'utils/constants/portals';
import LoadingSpinner from 'components/BaseComponents/LoadingSpinner';
import { KERNEL_PRODUCT } from 'utils/constants/product';
import ActionConfirmationModalBody from './ActionConfirmationModal';

// contract headers
const CONTRACTS_HEADERS_KEYS = {
  'Contract ID': 'serial_number',
  'Created At': 'created_at',
  Seller: 'seller',
  Buyer: 'buyer',
  ESD: 'esd',
  'Amount (kg)': 'offer_volume',
  'Price (per kg)': 'offer_price',
  Status: 'status',
};

const ORDERS_AND_OFFERS_HEADERS_KEYS = {
  'Listing ID': 'serial_number',
  'Listed At': 'created_at',
  'Listed By': 'creator',
  Style: 'style',
  ESD: 'estimated_shipping_date',
  'Amount (kg)': 'offer_volume',
  'Price (per kg)': 'offer_price',
  Status: 'status',
  Size: 'size',
};

const generateListedByColumnContent = (tabKey, creator, contract) => {
  if (tabKey === contractsTab) {
    return `${contract?.seller.firstName} ${contract?.seller.lastName}`;
  }

  return `${creator.firstName} ${creator.lastName}`;
};

const generateBuyerColumnContent = (tabKey, contract) => {
  if (tabKey === contractsTab) {
    return `${contract?.buyer.firstName} ${contract?.buyer.lastName}`;
  }
  return null;
};

const generateStatusColumnContent = (tabKey, contractStatus, status) => {
  if (tabKey === contractsTab) {
    return getContractStatusLabel[contractStatus];
  }

  return formattedProductStatus[status];
};

const generateSerialNumberColumnContent = (tabKey, contract, serialNumber) => {
  if (tabKey === contractsTab) {
    return contract?.serialNumber;
  }

  return serialNumber;
};

const generateDetailsPageRoute = (tabKey, productId, contractId) => {
  if (tabKey === offersTab || tabKey === ordersTab) {
    return admin.offerOderDetails(productId);
  }
  return admin.contractDetails(contractId);
};

const generateShippingDateContent = (estimatedShippingDate) => {
  if (estimatedShippingDate) {
    return formatDate(estimatedShippingDate);
  }

  return NOT_APPLICABLE;
};

const ProductTable = ({ fetchingProducts, tabKey, tableHeaders, products, onColumnClick }) => {
  const [handleChangeContractStatus] = useChangeContractStatusMutation();
  const [isActionConfirmationModalVisible, setIsActionConfirmationModalVisible] = useState(false);
  const [currentContractId, setCurrentContractId] = useState(null);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [action, setAction] = useState('');

  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);

  const handleHeaderClick = (field) => {
    if (!field) {
      return;
    }

    let direction = 'up';
    if (sortField === field && sortDirection === 'up') {
      direction = 'down';
    }
    setSortField(field);
    setSortDirection(direction);
    onColumnClick(field, direction)
  };

  const handleModalOpen = () => {
    setIsActionConfirmationModalVisible(true);
  };

  const handleModalClose = () => {
    setIsActionConfirmationModalVisible(false);
  };

  const changeContractStatus = (contractId, status) =>
    handleChangeContractStatus({
      contract_id: contractId,
      status,
    })
      .unwrap()
      .then(() => localStorage.setItem('tabKey', tabKey))
      .catch(({ data: { errors } }) => errors);

  const changeStatus = () => {
    changeContractStatus(currentContractId, currentStatus);
  };

  const keysArray =
    tabKey === 'Contracts' ? CONTRACTS_HEADERS_KEYS : ORDERS_AND_OFFERS_HEADERS_KEYS;

  return (
    <div>
      <Table className="admin-portal__table">
        <TableHead>
          <TableRow isHeaderRow={true}>
            {tableHeaders.map((title) => (
              <TableHeader
                key={title}
                textCase="capitalize"
                unavailableText={t('admin_user_table.no_data_available')}
                onClick={() => handleHeaderClick(keysArray[title])}
              >
                {title}
                {sortField === keysArray[title] && (sortDirection === 'up' ? ' ↑' : ' ↓')}
              </TableHeader>
            ))}
          </TableRow>
        </TableHead>

        {fetchingProducts ? (
          <TableBody className="position-relative padding-y-32px">
            <LoadingSpinner />
          </TableBody>
        ) : (
          <TableBody>
            {products?.[0]?.isContract
              ? // map for contracts
                products.map((contract) => {
                  const hasGreenStatus = contract?.status === COMPLETED;
                  // || contract?.listing?.status === PUBLISHED;

                  return (
                    <TableRow key={contract?.listing?.serialNumber}>
                      <TableColumn
                        content={generateSerialNumberColumnContent(
                          tabKey,
                          contract,
                          contract?.listing?.serialNumber,
                        )}
                      />
                      <TableColumn content={formatDate(contract?.createdAt)} />
                      <TableColumn
                        content={generateListedByColumnContent(
                          tabKey,
                          contract?.listing?.creator,
                          contract,
                        )}
                      />

                      {generateBuyerColumnContent(tabKey, contract) && (
                        <TableColumn content={generateBuyerColumnContent(tabKey, contract)} />
                      )}
                      {tableHeaders !== contractsTabTableHeader && (
                        <TableColumn content={contract?.product?.nutStyle} />
                      )}
                      <TableColumn
                        content={generateShippingDateContent(
                          contract?.product?.estimatedShippingDate,
                        )}
                      />
                      <TableColumn content={formatVolume(contract?.product?.offerVolume)} />
                      <TableColumn content={formatPrice(contract?.product?.offerPrice)} />

                      <TableColumn
                        className={classNames('listing-management-table--status', {
                          'listing-management-table--status-green-text': hasGreenStatus,
                        })}
                        content={generateStatusColumnContent(
                          tabKey,
                          contract?.status,
                          contract?.listing?.status,
                        )}
                      />
                      <TableColumn
                        content={
                          <div
                            className={`flex gap-20px ${
                              contract?.status === 'dissolved' ? 'justify-center' : 'justify-end'
                            } items-center`}
                          >
                            <Link
                              to={generateDetailsPageRoute(
                                tabKey,
                                contract?.listing?.productId,
                                contract?.id,
                              )}
                            >
                              <Icon
                                name="show"
                                size="normal"
                                className="admin-portal__table--icon"
                              />
                            </Link>
                            {contract?.status === 'concluded' && (
                              <div className="flex gap-20px items-center">
                                <Button
                                  className="re-invite-modal__buttons-container--button width-30"
                                  label={t('admin.listings_table.confirm_button')}
                                  type="success"
                                  size="tiny"
                                  onClick={() => {
                                    setCurrentStatus('in_progress');
                                    setCurrentContractId(contract.id);
                                    setAction('Confirm');
                                    handleModalOpen();
                                  }}
                                />
                                <Button
                                  className="re-invite-modal__buttons-container--button width-30"
                                  label={t('admin.listings_table.dissolve_button')}
                                  type="danger"
                                  size="tiny"
                                  onClick={() => {
                                    setCurrentStatus('dissolved');
                                    setCurrentContractId(contract.id);
                                    setAction('Dissolve');
                                    handleModalOpen();
                                  }}
                                />
                              </div>
                            )}
                            {contract?.status === 'in_progress' && (
                              <div className="flex gap-20px items-center">
                                <Button
                                  className="re-invite-modal__buttons-container--button width-30"
                                  label={t('admin.listings_table.complete_button')}
                                  type="success"
                                  size="tiny"
                                  onClick={() => {
                                    setCurrentStatus('completed');
                                    setCurrentContractId(contract.id);
                                    setAction('Complete');
                                    handleModalOpen();
                                  }}
                                />
                                <Button
                                  className="re-invite-modal__buttons-container--button width-30"
                                  label={t('admin.listings_table.dissolve_button')}
                                  type="danger"
                                  size="tiny"
                                  onClick={() => {
                                    setCurrentStatus('dissolved');
                                    setCurrentContractId(contract.id);
                                    setAction('Dissolve');
                                    handleModalOpen();
                                  }}
                                />
                              </div>
                            )}
                            {contract?.status === 'completed' && (
                              <div className="flex gap-20px items-center">
                                <Button
                                  className="re-invite-modal__buttons-container--button width-70"
                                  label={t('admin.listings_table.dissolve_button')}
                                  type="danger"
                                  size="tiny"
                                  onClick={() => {
                                    setCurrentStatus('dissolved');
                                    setCurrentContractId(contract.id);
                                    setAction('Dissolve');
                                    handleModalOpen();
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        }
                      />
                    </TableRow>
                  );
                })
              : // map for products
                products.map(
                  ({
                    id: productId,
                    estimatedShippingDate,
                    offerVolume,
                    offerPrice,
                    nutStyle,
                    size,
                    listing,
                    type,
                  }) => {
                    const { creator, contract, createdAt, status, serialNumber } = listing || {};

                    const hasGreenStatus = contract?.status === COMPLETED || status === PUBLISHED;

                    return (
                      <TableRow key={serialNumber}>
                        <TableColumn
                          content={generateSerialNumberColumnContent(
                            tabKey,
                            contract,
                            serialNumber,
                          )}
                        />
                        <TableColumn content={formatDate(createdAt)} />
                        <TableColumn
                          content={generateListedByColumnContent(tabKey, creator, contract)}
                        />

                        {generateBuyerColumnContent(tabKey, contract) && (
                          <TableColumn content={generateBuyerColumnContent(tabKey, contract)} />
                        )}
                        {tableHeaders !== contractsTabTableHeader && type === KERNEL_PRODUCT && (
                          <TableColumn content={nutStyle} />
                        )}
                        {tableHeaders !== contractsTabTableHeader &&
                          type === 'DriedNutInShellProduct' && <TableColumn content={size} />}
                        <TableColumn content={generateShippingDateContent(estimatedShippingDate)} />
                        <TableColumn content={formatVolume(offerVolume)} />
                        <TableColumn content={formatPrice(offerPrice)} />
                        <TableColumn
                          className={classNames('listing-management-table--status', {
                            'listing-management-table--status-green-text': hasGreenStatus,
                          })}
                          content={generateStatusColumnContent(tabKey, contract?.status, status)}
                        />
                        <TableColumn
                          content={
                            <div className="flex gap-20px justify-end items-center">
                              <Link to={generateDetailsPageRoute(tabKey, productId, contract?.id)}>
                                <Icon
                                  name="show"
                                  size="normal"
                                  className="admin-portal__table--icon"
                                />
                              </Link>
                            </div>
                          }
                        />
                      </TableRow>
                    );
                  },
                )}
          </TableBody>
        )}
      </Table>
      <Modal
        isVisible={isActionConfirmationModalVisible}
        onOutsideClick={handleModalClose}
        className="progress-card-modal"
      >
        <Modal.Content>
          <ActionConfirmationModalBody
            onModalClose={handleModalClose}
            changeStatus={changeStatus}
            action={action}
          />
        </Modal.Content>
      </Modal>
    </div>
  );
};

ProductTable.defaultProps = {
  products: [],
};

ProductTable.propTypes = {
  tabKey: PropTypes.string.isRequired,
  fetchingProducts: PropTypes.bool.isRequired,
  onColumnClick: PropTypes.func.isRequired,
  tableHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      isContract: PropTypes.bool,
      offer_volume: PropTypes.string,
      offer_price: PropTypes.string,
      boxes_quantity: PropTypes.number,
      nut_grade: PropTypes.string,
      nut_style: PropTypes.string,
      size: PropTypes.string,
      type: PropTypes.string,
      best_before_date: PropTypes.string,
      country_of_origin: PropTypes.string,
      estimated_shipping_date: PropTypes.string,
      full_batch_price: PropTypes.string,
      listing: PropTypes.shape({
        id: PropTypes.number,
        serial_number: PropTypes.string,
        status: PropTypes.string,
        category: PropTypes.string,
        highest_bid_price: PropTypes.string,
        total_bid_count: PropTypes.string,
        product_id: PropTypes.string,
        created_at: PropTypes.string,
      }),
    }),
  ),
};

export default ProductTable;
