import React, { useContext, useEffect, useState } from 'react';
import { t } from 'i18next';
import Icon from 'components/BaseComponents/Icon';
import Modal from 'components/BaseComponents/Modal';
import Tooltip from 'components/BaseComponents/Tooltip';
import { useUnPublishListingMutation } from 'api/listings';
import UnpublishOfferModal from 'components/BiddingProcess/ListingDetails/UnpublishOfferModal';
import ListingDetailsContext from 'components/BiddingProcess/ListingDetails/ListingDetailsContext';
import './styles.scss';

const UnPublishOption = () => {
  const { listingInformation } = useContext(ListingDetailsContext);

  const [unPublishListing, { isLoading, isSuccess }] = useUnPublishListingMutation();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const onModalOpen = () => {
    setIsModalVisible(true);
    unPublishListing({ id: listingInformation.id });
  };
  const onModalClose = () => setIsModalVisible(false);

  useEffect(() => {
    let timeoutId;

    if (isModalVisible && isSuccess) {
      timeoutId = setTimeout(() => {
        onModalClose();
        window.location.reload();
      }, 1500);
    }

    return () => clearTimeout(timeoutId);
  }, [isModalVisible, isSuccess]);

  return (
    <button type="button" className="listing-details-breadcrumb__un-publish">
      <span role="button" tabIndex={0} onKeyDown={() => {}} onClick={onModalOpen}>
        {t('bidding_process.unpublish')}
      </span>
      <Tooltip
        content={t('bidding_process.unpublish_warning')}
        showArrow={true}
        type="midnight-blue"
        size="small"
      >
        <Icon name="help" className="listing-details-breadcrumb__un-publish-icon" color="default" />
      </Tooltip>

      <Modal
        isVisible={isModalVisible}
        onOutsideClick={onModalClose}
        className="progress-card-modal"
      >
        <Modal.Content>
          <UnpublishOfferModal isSuccess={isSuccess} isLoading={isLoading} />
        </Modal.Content>
      </Modal>
    </button>
  );
};

export default UnPublishOption;
