import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Icon from 'components/BaseComponents/Icon';
import LoadingSpinner from 'components/BaseComponents/LoadingSpinner';
import './styles.scss';

const UnpublishOfferModal = ({ isLoading, isSuccess }) => {
  if (isLoading) {
    return (
      <div className="unpublish-offer-modal">
        <div>
          <div className="unpublish-offer-modal__loader-content">
            <LoadingSpinner />
          </div>
          <div className="unpublish-offer-modal__loader-text">
            {t('bidding_process.unpublishing_offer')}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="unpublish-offer-modal">
      {!isLoading && isSuccess && (
        <div className="unpublish-offer-modal__completed">
          <Icon
            className="unpublish-offer-modal__completed-icon"
            name="checkCircleOutline"
            size="normal"
          />
          <div className="unpublish-offer-modal__completed-text">
            {t('bidding_process.offer_unpublished')}
          </div>
        </div>
      )}
    </div>
  );
};

UnpublishOfferModal.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
};

export default UnpublishOfferModal;
