import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForgotPasswordMutation } from 'api/auth';
import useCustomNavigate from 'hooks/useCustomNavigate';
import Icon from 'components/BaseComponents/Icon';
import FormFooter from 'components/ResetPassword/Forms/FormFooter';
import 'components/ResetPassword/Forms/styles.scss';
import './styles.scss';

const SuccessfulMailSent = () => {
  const { t } = useTranslation();
  const { state: queryParams } = useLocation();
  const navigate = useCustomNavigate();
  const [forgotPassword] = useForgotPasswordMutation();

  useEffect(() => {
    if (!queryParams) {
      navigate.forgotPassword();
    }
  }, []);

  const currentEmail = queryParams?.email;

  const handleResendMail = async () => {
    const requestBody = { email: currentEmail };
    const response = await forgotPassword({ payload: requestBody });

    if (response.error) {
      return { email: t('reset_password.email_error') };
    }

    navigate.resetPasswordMailSentPage(requestBody);

    return null;
  };

  return (
    <div className="reset-password-form__container">
      <div className="reset-password-form__container--header">
        <Icon name="mailIcon" size="xl" color="none" className="reset-form-icon" />

        <div className="reset-password-form__container--header-texts">
          <h2 className="reset-password-form__container--header-texts-title">
            {t('successful_mail_sent.title')}
          </h2>

          <p className="reset-password-form__container--header-texts-message">
            {t('successful_mail_sent.welcome_message')}
            <br />
            {currentEmail}
          </p>
        </div>
      </div>

      <div className="check-email__row">
        <p>
          {t('successful_mail_sent.warn_message')}
          <button onClick={handleResendMail} type="button">{t('successful_mail_sent.resend_button_label')}</button>
        </p>
      </div>

      <FormFooter />
    </div>
  );
};

export default SuccessfulMailSent;
