import React from 'react';
import { pdf } from '@react-pdf/renderer';
import blobToFile from 'utils/helper/blobToFile';
import ContractPDFComponent from 'components/BiddingProcess/ContractPDF/ContractPDFComponent';

const generateAndUploadPdf = async (contractId, pdfResponse, uploadDraftContractPdf) => {
  const contractDocumentBlob = await pdf(
    <ContractPDFComponent contractInformation={pdfResponse} />,
  ).toBlob();

  if (contractDocumentBlob instanceof Blob) {
    const formData = new FormData();
    const file = blobToFile(contractDocumentBlob, 'draft-contract-pdf');
    formData.append('draft_pdf', file, 'contract-pdf.pdf');
    uploadDraftContractPdf({ contract_id: contractId, payload: formData });
  }
};

export default generateAndUploadPdf;
