import { useEffect } from 'react';
import { useGetCurrentUserQuery } from 'api/users';
import { useGetCompanyQuery } from 'api/companies';
import { hasAdminRole, hasPartnerRole, isVerified } from 'utils/helper/UserRoleValidation';
import useCustomNavigate from './useCustomNavigate';

const useAuth = () => {
  const {
    data: user,
    isFetching: isUserFetching,
    isSuccess: isUserSuccess,
  } = useGetCurrentUserQuery();
  const {
    data: company = {},
    isFetching: isCompanyFetching,
    isSuccess: isCompanySuccess,
  } = useGetCompanyQuery({ id: user?.company_id }, { skip: !user?.id });

  const isFetching = isUserFetching || isCompanyFetching;
  const isSuccess = isUserSuccess && isCompanySuccess;

  const isAuthenticated = !!user && isSuccess;
  const navigate = useCustomNavigate();

  const handleNavigationAfterLogin = (userDetails, companyDetails) => {
    if (hasAdminRole(userDetails)) {
      return navigate.adminProfile();
    }

    if (hasPartnerRole(userDetails)) {
      return navigate.profile();
    }

    if (isVerified(companyDetails)) {
      return navigate.listings();
    }

    return navigate.profile();
  };

  useEffect(() => {
    if (!isSuccess || isFetching) {
      return;
    }

    handleNavigationAfterLogin(user, company);
  }, [isFetching, isSuccess]);

  return { isAuthenticated, isFetching, user };
};

export default useAuth;
