import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useRequestUserVerificationMutation } from 'api/users';
import { contactUs } from 'utils/constants/Routes';
import Button from 'components/BaseComponents/Button';
import Icon from 'components/BaseComponents/Icon';
import './styles.scss';

const ProgressCardModalBody = ({ userId, handleModalClose }) => {
  const { t } = useTranslation();
  const [requestUserVerificaiton, { isSuccess, isLoading }] = useRequestUserVerificationMutation();

  const handleRequestSubmit = async (id) => {
    await requestUserVerificaiton(id);

    handleModalClose();

    return null;
  };

  return (
    <div className="progress-card-modal__body">
      <Icon name="info" size="medium" className="progress-card__icon" />
      <p className="progress-card-modal__body--title">{t('profile.progress_card.modal.title')}</p>
      <p className="progress-card-modal__body--description">
        {t('profile.progress_card.modal.description')}
      </p>
      <div className="progress-card-modal__button-container">
        <Button
          label={t('profile.progress_card.modal.contact_button')}
          className="progress-card-modal__button-container--button"
          onClick={() => window.open(contactUs)}
        />
        <Button
          className="progress-card-modal__button-container--button"
          label={t('profile.progress_card.modal.submit_button')}
          type="success"
          onClick={() => handleRequestSubmit(userId)}
          disabled={isLoading || isSuccess}
        />
      </div>
    </div>
  );
};

ProgressCardModalBody.propTypes = {
  userId: PropTypes.number.isRequired,
  handleModalClose: PropTypes.func.isRequired,
};

export default ProgressCardModalBody;
