import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { t } from 'i18next';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import Icon from '../Icon';
import Tooltip from '../Tooltip';
import './_select-box.styles.scss';

const colorOptions = {
  coolGray20: '#303030',
  coolGray45: '#707070',
  coolGray70: '#ababab',
  coolGray95: '#f1f1f1',
  attractingPeach: 'ff605d',
};

const generateClassName = (element, modifier) => {
  if (modifier) return `${element}--${modifier}`;

  return null;
};

const sizeBasedSelector = (element, size) => {
  return generateClassName(`select-box__${element}`, size);
};

function SelectBox(props) {
  const customTheme = (theme) => {
    const { primary, primary75, primary50, primary25 } = props.colors;

    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary,
        primary75,
        primary50,
        primary25,
      },
    };
  };

  const renderPlaceHolder = () => {
    const { preIcon, placeholderText, size } = props;

    return (
      <div className="select-box__placeholder">
        {preIcon && (
          <span className={classnames('select-box__pre-icon', sizeBasedSelector('pre-icon', size))}>
            {preIcon}
          </span>
        )}
        <span
          className={classnames(
            'select-box__placeholder-text',
            sizeBasedSelector('placeholder-text', size),
          )}
        >
          {placeholderText}
        </span>
      </div>
    );
  };

  const getFlags = () => {
    const { isClearable, isDisabled, isMulti, isSearchable } = props;
    return {
      isClearable,
      isDisabled,
      isMulti,
      isSearchable,
    };
  };

  const {
    autoFocus,
    className,
    colors,
    errorMsg,
    label,
    tooltip,
    name,
    options,
    size,
    touched,
    isMulti,
    isDisabled,
    isClearable,
    isCreatable,
    isAsync,
    cacheOptions,
    width,
    required,
    value,
    onBlur,
    onFocus,
    onFilter,
    onKeyDown,
    onMenuOpen,
    onMenuClose,
    onMenuScrollToTop,
    onMenuScrollToBottom,
    onCreateOption,
    loadOptions,
    defaultOptions,
    filterOption,
    maxMenuHeight,
    menuPlacement,
    highlighted,
  } = props;
  const [isFocused, setIsFocused] = useState(false);
  const [isInputFieldEmpty, setIsInputFieldEmpty] = useState(true);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [modifiedOptions, setModifiedOptions] = useState(options);
  const [isHighlightedExist, setIsHighlightedExist] = useState(false);
  const selectInputRef = useRef();
  const hasError = touched && errorMsg;
  const classNamePrefix = 'select-box';

  const handleInputChange = (inputValue) => {
    if (inputValue.length === 0) {
      setIsInputFieldEmpty(true);
    } else {
      setIsInputFieldEmpty(false);
    }
    props.onInputChange(inputValue);
  };

  const handleChange = (selectedOption) => {
    if (!isMulti) {
      setMenuIsOpen(false);
      selectInputRef.current.blur();
      setIsFocused(false);
    }

    props.onChange(selectedOption);
  };

  const valueCheckingType = Array.isArray(value) ? value.length > 0 : !!value;

  const formatOptionLabel = (innerProps) => (
    <div className="rcl-select-box__label">
      <div className="rcl-select-box__label--header">{innerProps.label}</div>
      {innerProps.hintText && (
        <div className="rcl-select-box__label--hint">{innerProps.hintText}</div>
      )}
    </div>
  );

  const CustomSingleValue = (innerProps) => (
    <components.SingleValue {...innerProps}>{innerProps.data.label}</components.SingleValue>
  );

  const selectProps = {
    ...getFlags(),
    autoFocus,
    className: classnames(
      'select-box-container',
      `select-box-container--${size}`,
      sizeBasedSelector('width', width),
      {
        'select-box-container--notMulti': !isMulti,
        'select-box-container--not-empty': valueCheckingType,
        'select-box-container--clearable': isClearable,
        'select-box-container--error': hasError,
        'select-box-container--highlighted': isHighlightedExist && isInputFieldEmpty,
      },
    ),
    classNamePrefix,
    colors,
    theme: customTheme,
    value,
    name,
    options: modifiedOptions,
    ref: selectInputRef,
    onInputChange: handleInputChange,
    onChange: handleChange,
    onFilter,
    onKeyDown,
    onMenuOpen,
    onMenuClose,
    onMenuScrollToTop,
    onMenuScrollToBottom,
    onCreateOption,
    placeholder: renderPlaceHolder(),
    required,
    maxMenuHeight,
    menuPlacement,
    formatOptionLabel,
    components: { SingleValue: CustomSingleValue },
  };

  const modifiedOptionsHandler = () => {
    const selectOptions = [...options];

    const highlightedIndex = selectOptions.findIndex(
      (option) =>
        option?.value.toString().toLowerCase() === highlighted?.value.toString().toLowerCase(),
    );

    if (highlightedIndex !== -1) {
      const removedItem = selectOptions.splice(highlightedIndex, 1)[0];

      selectOptions.unshift(removedItem);

      setIsHighlightedExist(true);
    } else {
      setIsHighlightedExist(false);
    }

    return selectOptions;
  };

  if (filterOption) {
    selectProps.filterOption = filterOption;
  }

  useEffect(() => {
    if (highlighted?.value && isInputFieldEmpty) {
      const modifiedOptionsState = modifiedOptionsHandler();

      setModifiedOptions(modifiedOptionsState);
    } else {
      setModifiedOptions(options);
      setIsHighlightedExist(false);
    }
  }, [highlighted, isInputFieldEmpty, options]);

  const onFocusHandler = (event) => {
    setIsFocused(true);
    setMenuIsOpen(true);
    onFocus(event);
  };

  const onBlurHandler = (event) => {
    setIsFocused(false);
    setMenuIsOpen(false);
    onBlur(event);
  };

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">{t('shared.select.no_option')}</span>
      </components.NoOptionsMessage>
    );
  };

  const getAppropiateSelectBoxComponent = () => {
    if (isCreatable) {
      return (
        <CreatableSelect
          {...selectProps}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          isFocused={isFocused}
          menuIsOpen={menuIsOpen}
          required={false}
          components={{ NoOptionsMessage }}
        />
      );
    }

    if (isAsync) {
      return (
        <AsyncSelect
          {...selectProps}
          cacheOptions={cacheOptions}
          defaultOptions={defaultOptions}
          loadOptions={loadOptions}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          isFocused={isFocused}
          menuIsOpen={menuIsOpen}
          required={false}
          components={{ NoOptionsMessage }}
        />
      );
    }

    return (
      <Select
        {...selectProps}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        isFocused={isFocused}
        menuIsOpen={menuIsOpen}
        required={false}
        components={{ NoOptionsMessage }}
      />
    );
  };

  useEffect(() => {
    if (isFocused) {
      if (isInputFieldEmpty && options.length === 0 && defaultOptions.length === 0) {
        setMenuIsOpen(false);
      } else {
        setMenuIsOpen(true);
      }
    } else {
      setMenuIsOpen(false);
    }
  }, [isInputFieldEmpty, isFocused, options]);

  return (
    <div className={classnames(classNamePrefix, className)}>
      <div className='flex flex-row gap-8px'>
      {label && (
        <span
          className={classnames(`select-box__label flex select-box__label--${size}`, {
            'select-box__label--required': required,
            'select-box__label--disabled': isDisabled,
          })}
        >
          {label}
        </span>
      )}
      <span>
        {tooltip && (
          <Tooltip content={tooltip} showArrow={true} type="midnight-blue" size="tiny">
            <Icon name="help" color="default" />
          </Tooltip>
        )}
      </span>
      </div>
      <div className={classnames(`select-box-wrapper select-box-wrapper--${width}`)}>
        {getAppropiateSelectBoxComponent()}
        {errorMsg && touched && (
          <div className="select-box__tooltip-wrapper">
            <Tooltip
              className="select-box__tooltip"
              content={errorMsg}
              position="bottom-right"
              type="danger"
              gap={0}
            >
              <Icon name="invalid" color="danger" />
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
}

SelectBox.defaultProps = {
  autoFocus: false,
  className: null,
  colors: {
    primary: colorOptions.coolGray20,
    primary75: colorOptions.coolGray45,
    primary50: colorOptions.coolGray70,
    primary25: colorOptions.coolGray95,
  },
  errorMsg: '',
  isClearable: true,
  isCreatable: false,
  isAsync: false,
  isDisabled: false,
  isMulti: false,
  isSearchable: true,
  cacheOptions: true,
  label: '',
  tooltip: '',
  noOptionMessage: '<div> No Options </div>',
  name: 'select-box',
  onChange: () => {},
  onInputChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  onFilter: () => {},
  onKeyDown: () => {},
  onMenuOpen: () => {},
  onMenuClose: () => {},
  onMenuScrollToTop: () => {},
  onMenuScrollToBottom: () => {},
  onCreateOption: () => {},
  loadOptions: () => {},
  filterOption: null,
  options: [],
  defaultOptions: [],
  placeholderText: 'Select...',
  preIcon: null,
  required: false,
  size: 'small',
  touched: false,
  value: null,
  width: 'small',
  maxMenuHeight: 300,
  menuPlacement: 'bottom',
  highlighted: null,
};

SelectBox.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  colors: PropTypes.shape({
    primary: PropTypes.string,
    primary75: PropTypes.string,
    primary50: PropTypes.string,
    primary25: PropTypes.string,
  }),
  errorMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isClearable: PropTypes.bool,
  isCreatable: PropTypes.bool,
  isAsync: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  cacheOptions: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  noOptionMessage: PropTypes.node,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onFilter: PropTypes.func,
  onKeyDown: PropTypes.func,
  onMenuOpen: PropTypes.func,
  onMenuClose: PropTypes.func,
  onMenuScrollToTop: PropTypes.func,
  onMenuScrollToBottom: PropTypes.func,
  onCreateOption: PropTypes.func,
  loadOptions: PropTypes.func,
  filterOption: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      hintText: PropTypes.node,
    }),
  ),
  defaultOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      hintText: PropTypes.node,
    }),
  ),
  preIcon: PropTypes.node,
  placeholderText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
  ]),
  required: PropTypes.bool,
  size: PropTypes.oneOf(['tiny', 'small', 'large', 'huge']),
  touched: PropTypes.bool,
  width: PropTypes.oneOf(['tiny', 'small', 'large', 'full']),
  maxMenuHeight: PropTypes.number,
  menuPlacement: PropTypes.oneOf(['bottom', 'auto', 'top']),
  highlighted: PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

export default SelectBox;
