// commented out due to business logics
import React from 'react';
import { t } from 'i18next';
import { Text, View } from '@react-pdf/renderer';
// import { msmContactEmail, msmContactNumber, msmWebsite } from 'utils/constants/msmContactInfo';
import { styles } from '../styles';
// import WebIcon from '../Icons/WebIcon';
// import MailIcon from '../Icons/MailIcon';
// import PhoneIcon from '../Icons/PhoneIcon';

const Footer = () => (
  <View fixed={true}>
    <View style={styles.footer}>
      {/* <View style={styles.footerList}>
        <View style={styles.footerListItem}>
          <MailIcon />
          <Text>{msmContactEmail}</Text>
        </View>
        <View style={styles.footerListItem}>
          <PhoneIcon />
          <Text>{msmContactNumber}</Text>
        </View>
        <View style={styles.footerListItem}>
          <WebIcon />
          <Text>{msmWebsite}</Text>
        </View>
      </View> */}
      <Text render={({ pageNumber }) => t('contract_pdf.page_number', { pageNumber })} />
    </View>
  </View>
);

export default Footer;
