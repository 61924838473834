import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './styles.scss';
import classNames from 'classnames';

const OverlayCTA = ({ className, heading, subHeadings, ctaButtonLink, ctaButtonText }) => (
  <div className={classNames('overlay-cta margin-x-auto', className)}>
    <div className="overlay-cta__blur-background" />
    <div className="overlay-cta__text-container flex flex-col items-center">
      <h2 className="overlay-cta__text-container--heading margin-b-20px">{heading}</h2>
      {subHeadings.map((subHeading) => (
        <p key={subHeading} className="overlay-cta__text-container--subheading">
          {subHeading}
        </p>
      ))}
      <Link
        className="overlay-cta__text-container--signup-button margin-x-auto margin-t-32px"
        to={ctaButtonLink}
      >
        {ctaButtonText}
      </Link>
    </div>
  </div>
);

OverlayCTA.defaultProps = {
  className: '',
};

OverlayCTA.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string.isRequired,
  subHeadings: PropTypes.arrayOf(PropTypes.string).isRequired,
  ctaButtonLink: PropTypes.string.isRequired,
  ctaButtonText: PropTypes.string.isRequired,
};

export default OverlayCTA;
