import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from 'components/BaseComponents/Button';
import Modal from 'components/BaseComponents/Modal';
import { useGetAllUsersQuery } from 'api/users';
import Pagination from 'components/BaseComponents/Pagination';
import { companyManagementTbaleHeader } from 'utils/constants/portals';
import { Can, USER_MANAGEMENT_SUBJECT } from 'ability';
import InvitationModal from './InvitationModal';
import UserTable from './UserTable';
import './styles.scss';

const CompanyManagementForm = ({ companyId, toastRef }) => {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const [isInvitationModalVisible, setInvitationModalVisibility] = useState(false);
  const onInvitationModalOpen = () => setInvitationModalVisibility(true);
  const onInvitationModalClose = () => setInvitationModalVisibility(false);

  const { data, isFetching } = useGetAllUsersQuery({ currentPage, companyId });

  const usersCollection = data?.collection;
  const usersPagination = data?.pagination;

  const totalPages = Number(usersPagination?.totalPages);

  return (
    <div className="company-management">
      <h3 className="company-management__title">{t('profile.company_management.title')}</h3>

      <Can I="invite" a={USER_MANAGEMENT_SUBJECT}>
        <div className="company-management__section">
          <Button
            label={t('profile.company_management.invite')}
            onClick={onInvitationModalOpen}
            className="company-management__invite-btn"
          />

          <Modal
            isVisible={isInvitationModalVisible}
            onOutsideClick={onInvitationModalClose}
            className="progress-card-modal"
          >
            <Modal.Content>
              <InvitationModal companyId={companyId} onModalClose={onInvitationModalClose} toastRef={toastRef} />
            </Modal.Content>
          </Modal>
        </div>
      </Can>

      <UserTable
        tableHeaders={companyManagementTbaleHeader}
        users={usersCollection}
        fetchingAllUsers={isFetching}
      />

      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPaginationClick={setCurrentPage}
          firstText={t('admin_user_table.first_page')}
          lastText={t('admin_user_table.last_page')}
          nextText={t('admin_user_table.next_page')}
          previousText={t('admin_user_table.previous_page')}
          className="admin-portal__table--pagination"
        />
      )}
    </div>
  )
};

CompanyManagementForm.defaultProps = {
  toastRef: null,
};

CompanyManagementForm.propTypes = {
  companyId: PropTypes.number.isRequired,
  toastRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

export default CompanyManagementForm;
