import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { contactUs } from 'utils/constants/Routes';
import { msmSupportEmail } from 'utils/constants/msmContactInfo';
import Button from 'components/BaseComponents/Button';
import Icon from 'components/BaseComponents/Icon';
import './styles.scss';

const CompanyAlreadyInvitedModal = ({ onModalClose }) => {
  const { t } = useTranslation();

  return (
    <div className="company-already-invited__body">
      <Icon name="info" size="medium" className="progress-card__icon" />
      <p className="company-already-invited__body--title">
        {t('signup.step2_form.user_already_invited_modal_title')}
      </p>
      <p className="company-already-invited__body--description">
        {t('signup.step2_form.user_already_invited_modal_text')}
      </p>
      <div className="company-already-invited__body--description-green-text">
        {msmSupportEmail}.
      </div>
      <div className="company-already-invited__button-container">
        <Button
          label={t('profile.progress_card.modal.contact_button')}
          className="company-already-invited__button-container--button"
          onClick={() => window.open(contactUs)}
        />
        <Button
          className="company-already-invited__button-container--button"
          label={t('signup.step2_form.user_already_invited_modal_button')}
          type="success"
          onClick={onModalClose}
        />
      </div>
    </div>
  );
};

CompanyAlreadyInvitedModal.propTypes = {
  onModalClose: PropTypes.func.isRequired,
};

export default CompanyAlreadyInvitedModal;
