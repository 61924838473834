import React, { useState } from 'react';
import { t } from 'i18next';
import { useLocation } from 'react-router-dom';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { admin } from 'utils/constants/Routes';
import { SideNav, SideNavBrand, SideNavLink, SideNavList } from 'components/BaseComponents/Sidenav';
import Icon from 'components/BaseComponents/Icon';
import brandLogo from 'assets/images/msm-logo.svg';
import './styles.scss';

const SideNavBar = () => {
  const navigate = useCustomNavigate();
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);

  return (
    <SideNav>
      <SideNavBrand icon={brandLogo} logo={brandLogo} url="/admin/users" />
      <SideNavList>
        <SideNavLink
          icon={<Icon name="approvalRequired" />}
          label={t('side_navbar.link1_label')}
          url="/admin/users"
          activeLink={activeLink}
          onClick={() => {
            setActiveLink('/admin/users');
            navigate.adminProfile();
          }}
        />
        <SideNavLink
          icon={<Icon name="listings" className="side-nav__link--icon" />}
          label={t('side_navbar.link2_label')}
          url={admin.productListings}
          activeLink={activeLink}
          onClick={() => {
            setActiveLink(admin.productListings);
            navigate.adminListingManagement();
          }}
        />
        <SideNavLink
          icon={<Icon name="approvalRequired" />}
          label={t('side_navbar.link3_label')}
          url={admin.affiliatePartners}
          activeLink={activeLink}
          onClick={() => {
            setActiveLink(admin.affiliatePartners);
            navigate.adminAffiliatePartnersManagement();
          }}
        />
      </SideNavList>
    </SideNav>
  );
};

export default SideNavBar;
