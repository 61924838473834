import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const ResetPasswordWrapper = ({ children }) => (
  <div className="reset-password-wrapper flex">
    <div className="reset-password-wrapper__left-container" />
    <div className="reset-password-wrapper__right-container">{children}</div>
  </div>
);

ResetPasswordWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ResetPasswordWrapper;
