import { AbilityBuilder, createMongoAbility } from "@casl/ability";
import { BUYER_ABLITY_SUBJECT, LISTING_ABILITY_SUBJECT, NEW_LISTING_ABILITY_SUBJECT } from "ability";
import { OFFER, ORDER, PUBLISHED, UNPUBLISHED } from "utils/constants/offerListings";
import { DRIED_NUT_IN_SHELL_PRODUCT, KERNEL_PRODUCT } from "utils/constants/product";
import {
  hasAdminRole,
  hasBuyerRole,
  hasCompanyPermissions,
  hasNoRoles,
  hasPartnerRole,
  hasSellerRole,
  hasSubscriptionMemberRole,
  isCompanyOwner,
  isVerified,
} from "utils/helper/UserRoleValidation";

const defineAbilityFor = ({ can, cannot, userDetails, companyDetails }) => {
  can("follow", LISTING_ABILITY_SUBJECT, { status: PUBLISHED });

  can("edit", LISTING_ABILITY_SUBJECT, { status: UNPUBLISHED, 'creator.id': userDetails.id });
  can("unpublish", LISTING_ABILITY_SUBJECT, { status: PUBLISHED, currentBidderId: null, 'creator.id': userDetails.id });

  if (isCompanyOwner(userDetails, companyDetails)) {
    can("edit", LISTING_ABILITY_SUBJECT, { status: UNPUBLISHED, companyId: companyDetails.id });
    can("unpublish", LISTING_ABILITY_SUBJECT, { status: PUBLISHED, currentBidderId: null, companyId: companyDetails.id });    
  }

  if (!hasCompanyPermissions(KERNEL_PRODUCT, companyDetails)) {
    cannot("edit", LISTING_ABILITY_SUBJECT, {
      type: KERNEL_PRODUCT,
    });
  }

  if (!hasCompanyPermissions(DRIED_NUT_IN_SHELL_PRODUCT, companyDetails)) {
    cannot("edit", LISTING_ABILITY_SUBJECT, {
      type: DRIED_NUT_IN_SHELL_PRODUCT,
      category: OFFER,
      status: UNPUBLISHED,
      companyId: companyDetails.id,
      'creator.id': userDetails.id,
    });
  }

  if (hasBuyerRole(userDetails)) {
    can("create", NEW_LISTING_ABILITY_SUBJECT, { category: ORDER });
    can("viewPortalsFooter", LISTING_ABILITY_SUBJECT, { category: OFFER });
  }

  if (hasSellerRole(userDetails)) {
    can("create", NEW_LISTING_ABILITY_SUBJECT, { category: OFFER });
    can("viewPortalsFooter", LISTING_ABILITY_SUBJECT, { category: ORDER });
  }

  if (hasSubscriptionMemberRole(userDetails) || hasAdminRole(userDetails) || hasNoRoles(userDetails)) {
    cannot("follow", LISTING_ABILITY_SUBJECT);
  }

  if (hasPartnerRole(userDetails)) {
    can("invite", BUYER_ABLITY_SUBJECT);
  }

  return null;
};

const buildListingsAbility = ({ userDetails, companyDetails }) => {
  const { can, cannot, build } = new AbilityBuilder(createMongoAbility);

  if (isVerified(companyDetails)) {
    defineAbilityFor({ can, cannot, userDetails, companyDetails })
  }

  return build();
};

export default buildListingsAbility;
