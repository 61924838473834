import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useGetUserQuery, useUpdateUserMutation } from 'api/users';
import { useGetCompanyQuery } from 'api/companies';
import { getUserVerificationLabel } from 'utils/helper/GetUserVerificationStatus';
import { processPortalFormSuccess } from 'utils/helper/FormHandlers';
import { handleServerValidationErrors } from 'utils/helper/Validations';
import { getAvatarBgColorCode, getEditStatus } from 'utils/helper/Portals';
import {
  hasBuyerRole,
  hasPartnerRole,
  hasSellerRole,
  hasSubscriptionMemberRole,
  isVerified,
  userRequestedVerification,
} from 'utils/helper/UserRoleValidation';
import { labelWithHints } from 'utils/helper/LabelWithHints';
import { OWNER, userRoleLabels } from 'utils/constants/userRole';
import { DOCUMENT_REQUESTED } from 'utils/constants/userStatus';
import { admin } from 'utils/constants/Routes';
import Avatar from 'components/BaseComponents/Avatar';
import ContentLoaderPlaceholder from 'components/BaseComponents/ContentLoader/ContentLoader';
import AvatarModal from 'components/Shared/AvatarModal';
import './style.scss';

const AvatarCard = ({ userId, adminId, containerClassName, canUploadAvatar, toastRef }) => {
  const { t } = useTranslation();
  const [updateUser] = useUpdateUserMutation();
  const { data: user = {}, isSuccess: userDataFetched } = useGetUserQuery(
    { id: userId },
    { skip: !userId },
  );
  const { data: companyDetails = {}, isSuccess: companyDataFetched } = useGetCompanyQuery(
    { id: user?.company_id },
    { skip: !userDataFetched },
  );

  const handleAvatarUpdate = (image) => {
    const formData = new FormData();
    formData.append('avatar', image);

    return updateUser({ userId, payload: formData })
      .unwrap()
      .then(() => processPortalFormSuccess(toastRef))
      .catch(({ data: { errors }, status }) =>
        handleServerValidationErrors(toastRef, status, errors),
      );
  };

  const {
    first_name: userFirstName,
    last_name: userLastName,
    email: userEmail,
    roles: userRoles,
    avatar_url: userAvatar,
    serial_number: serialNumber,
  } = user;
  const { name: companyName } = companyDetails;

  const isAdmin = !!adminId;
  const isUserVerified = isVerified(companyDetails);
  const hasRequestedVerification = userRequestedVerification(companyDetails);
  const verificationLabel = getUserVerificationLabel(companyDataFetched, isUserVerified);
  const isEditable = getEditStatus(isAdmin, isUserVerified, hasRequestedVerification);
  const documentRequested = companyDetails?.status === DOCUMENT_REQUESTED;
  const userName = `${userFirstName} ${userLastName}`;
  const mainRole = userRoles?.filter((el) => el !== OWNER)[0];

  const hasAffiliatePartner = user?.on_boarder_user !== null;
  const affiliationName = `${user?.on_boarder_user?.first_name} ${user?.on_boarder_user?.last_name}`;
  const affiliationLink = admin.affiliatePartnerProfile(user?.on_boarder_user?.id);
  const showVerificationCertificateId = isUserVerified && hasPartnerRole(user);

  const handleTooltipText = () => {
    if (hasRequestedVerification) {
      return t('profile.requested_verification_tooltip');
    }
    if (documentRequested) {
      return t('profile.document_requested_tooltip');
    }

    return t(`profile.${mainRole}_verification_label_tooltip`);
  };

  const avatarBgColorCode = getAvatarBgColorCode(user);
  const avatarClassName = classNames({
    'avatar-container__seller-profile': hasSellerRole(user),
    'avatar-container__buyer-profile': hasBuyerRole(user),
    'avatar-container__subscription_member-profile': hasSubscriptionMemberRole(user),
  });
  const avatarDescriptionClassName = classNames('avatar-container__descriptions', {
    'avatar-container__descriptions--admin': !canUploadAvatar || !isEditable,
  });

  return (
    <div className={classNames('flex items-center avatar-container', containerClassName)}>
      <div>
        {!userDataFetched ? (
          <ContentLoaderPlaceholder
            contentHeight="180px"
            contentWidth="650px"
            diameter="xxl"
            numberOfLines={5}
            shape="circle"
            showBackground={false}
          />
        ) : (
          <Fragment>
            <Avatar
              bgColor={avatarBgColorCode}
              firstName={userFirstName}
              lastName={userLastName}
              openNewTab={false}
              showName={false}
              size="xxl"
              className={avatarClassName}
              src={userAvatar}
            />
            {canUploadAvatar && (
              <AvatarModal updateAvatar={handleAvatarUpdate} isEditable={isEditable} />
            )}
          </Fragment>
        )}
      </div>

      {companyDataFetched && (
        <div className={avatarDescriptionClassName}>
          <div>
            {userRoles.map((role) => (
              <span
                key={role}
                className={classNames(`avatar-container__descriptions--user-${role}`, {
                  'avatar-container__descriptions--user-affiliate-partner': hasPartnerRole(user),
                })}
              >
                {userRoleLabels[role]}
              </span>
            ))}
            {!isUserVerified && (
              <span className="avatar-container__descriptions--user-verification">
                {labelWithHints(verificationLabel, handleTooltipText())}
              </span>
            )}
          </div>

          <h2 className="avatar-container__descriptions--user-name">{userName}</h2>
          <p className="avatar-container__descriptions--company-name">{companyName}</p>
          <p className="avatar-container__descriptions--email">{userEmail}</p>
          {isAdmin && hasAffiliatePartner && (
            <p className="avatar-container__descriptions--email">
              {t('admin.affiliate_partners.affiliation_label')}:
              <Link to={affiliationLink} className="card-link-green margin-l-8px">
                {affiliationName}
              </Link>
            </p>
          )}
          {showVerificationCertificateId && (
            <p className="avatar-container__descriptions--email">
              {t('profile.verification_certificate_label', { serialNumber })}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

AvatarCard.propTypes = {
  userId: PropTypes.number,
  adminId: PropTypes.number,
  containerClassName: PropTypes.string,
  canUploadAvatar: PropTypes.bool,
  toastRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

AvatarCard.defaultProps = {
  userId: null,
  adminId: null,
  containerClassName: null,
  canUploadAvatar: false,
  toastRef: null,
};

export default AvatarCard;
