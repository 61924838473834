import { AbilityBuilder, createMongoAbility } from "@casl/ability";
import { isCompanyOwner } from "utils/helper/UserRoleValidation";
import defineProfileTabsAbility from "./profileTabs";

const buildOwnerAbility = (owner, { userDetails, companyDetails }) => {
  const { can, cannot, build } = new AbilityBuilder(createMongoAbility);

  if (isCompanyOwner(owner, companyDetails)) {
    defineProfileTabsAbility({ can, cannot, userDetails, companyDetails });
  }

  return build();
};

export default buildOwnerAbility;
