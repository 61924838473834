import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { formatPrice, formatVolume } from 'utils/helper/Charts';
import { localizedSelectedObject } from 'utils/helper/selectBoxUtils';
import { generateDNISListingInfo } from 'utils/helper/reviewListing';
import { OFFER } from 'utils/constants/offerListings';

const InfoCard = ({ titleKey, description, descriptionClass }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'offer_creation.product_details.review',
  });
  const descriptionClassName = classNames(
    'listing-card__container--title listing-card__container--title-description',
    descriptionClass,
  );

  return (
    <div>
      <h6 className="listing-card__container--title">{t(titleKey)}</h6>
      {description ? (
        <h6 className={descriptionClassName}>{description}</h6>
      ) : (
        <h6 className="listing-card__container--title listing-card__container--title-empty">
          {t('no_data')}
        </h6>
      )}
    </div>
  );
};

InfoCard.defaultProps = {
  descriptionClass: '',
  description: null,
};

InfoCard.propTypes = {
  titleKey: PropTypes.string.isRequired,
  description: PropTypes.string,
  descriptionClass: PropTypes.string,
};

const DriedNutInShellProductListingCard = ({ productDetails }) => {
  const { t } = useTranslation();

  const { offerPrice, offerVolume, category } = productDetails;
  const isOffer = category === OFFER;
  const listingInfo = generateDNISListingInfo(productDetails);
  const paymentOption = localizedSelectedObject(
    `shared.product.payment_options.${productDetails.paymentOptions}`,
    productDetails.paymentOptions,
  );
  const price = `USD ${formatPrice(offerPrice)}`;
  const volume = `${formatVolume(offerVolume)} kg`;

  return (
    <div className="listing-card">
      <h3 className="listing-card__title">
        {t('offer_creation.product_details.review.dried_nut_in_shell.product_listing')}
      </h3>
      <div className="listing-card__container flex">
        <div className="listing-card__container--left">
          {listingInfo.map(({ key, description, className }) => (
            <InfoCard
              key={key}
              titleKey={key}
              description={description}
              descriptionClass={className}
            />
          ))}
          {isOffer && <InfoCard titleKey="payment_option" description={paymentOption?.label} />}
        </div>
        <div className="listing-card__container--right">
          <InfoCard titleKey="price" description={price} />
          <InfoCard titleKey="volume" description={volume} />
        </div>
      </div>
    </div>
  );
};

DriedNutInShellProductListingCard.propTypes = ({
  productDetails: PropTypes.shape({
    offerPrice: PropTypes.number,
    offerVolume: PropTypes.number,
    category: PropTypes.string,
    paymentOptions: PropTypes.string,
  }).isRequired,
});

export default DriedNutInShellProductListingCard;
