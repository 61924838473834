import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { t } from 'i18next';
import { unauthorized } from 'utils/constants/Routes';
import ErrorBoundary from './ErrorBoundary';

const ErrorHandling = () => {
  const location = useLocation();
  const { pathname } = location;
  const isUnauthorized = useMemo(() => pathname.includes(unauthorized), [pathname]);

  const header = isUnauthorized ? t('errors.unauthorized.header') : t('errors.not_found.header');
  const description = isUnauthorized
    ? t('errors.unauthorized.message')
    : t('errors.not_found.message');

  return <ErrorBoundary header={header} description={description} />;
};

export default ErrorHandling;
