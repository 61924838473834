import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { useContactAdminMutation } from 'api/listings';
import { useGetContractQuery } from 'api/contracts';
import { requiredField } from 'utils/helper/Validations';
import { processPortalFormSuccess } from 'utils/helper/FormHandlers';
import Textarea from 'components/BaseComponents/Textarea';
import Button from 'components/BaseComponents/Button';

const ContactSupportModal = ({ handleModalClose, toastRef }) => {
  const { id: contractId } = useParams();

  const { data: contractDetails = {} } = useGetContractQuery({
    id: contractId,
  });
  const listingId = contractDetails?.listingId;
  const [contactAdmin] = useContactAdminMutation();

  const handleContactAdmin = async (params) => {
    const requestBody = {
      body_text: params.declaration_contact_admin,
      action_type: 'request_assistance',
    };

    return contactAdmin({ listing_id: listingId, payload: requestBody })
      .unwrap()
      .then(() => {
        processPortalFormSuccess(toastRef);
        handleModalClose();
      });
  };

  return (
    <div className="additional-document__modal">
      <h4 className="additional-document__modal--title">
        {t('shared.bidding_process.contract_details.contact_support.modal.title')}
      </h4>
      <Form
        onSubmit={handleContactAdmin}
        render={({ handleSubmit, submitting, hasValidationErrors }) => (
          <form onSubmit={handleSubmit}>
            <Field name="declaration_contact_admin" validate={requiredField}>
              {({ input, meta }) => (
                <Textarea
                  className="additional-document__textarea"
                  label={t('admin.request_modal_body.text_area_label')}
                  placeholder={t(
                    'shared.bidding_process.contract_details.contact_support.modal.assist_placeholder',
                  )}
                  size="small"
                  touched={meta.touched}
                  value={input.value}
                  onChange={input.onChange}
                  error={meta?.error || meta?.submitError}
                />
              )}
            </Field>
            <Button
              type="success"
              label={t(
                'shared.bidding_process.contract_details.contact_support.modal.submit_button_label',
              )}
              submitType="submit"
              disabled={hasValidationErrors || submitting}
            />
          </form>
        )}
      />
    </div>
  );
};

ContactSupportModal.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  toastRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ContactSupportModal;
