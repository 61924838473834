import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import classNames from 'classnames';
import { formatPrice, formatVolume } from 'utils/helper/Charts';
import { formatDate } from 'utils/helper/FormatDate';
import { countryCodeToName } from 'utils/helper/selectBoxUtils';
import Icon from 'components/BaseComponents/Icon';
import './styles.scss';

const ProductCard = ({
  className,
  isBidStatusVisible,
  highestBidPrice,
  quantity,
  quantityUnit,
  quality,
  style,
  size,
  soundKernelRecovery,
  soundKernelRecoveryLabel,
  unsoundKernelRecovery,
  unsoundKernelRecoveryLabel,
  type,
  country,
  priceLabel,
  perKgPrice,
  isIdVisible,
  idLabel,
  id,
  esdLabel,
  esdDate,
  bestBeforeLabel,
  bestBeforeDate,
  batchLabel,
  batchPrice,
  currency,
  redirectLink,
  createdAtLabel,
  createdAtDate,
}) => {
  const bidStatus = () => {
    if (highestBidPrice) {
      return (
        <div className="product-card__header flex gap-4px items-center">
          <span>{t('shared.bidding_process.bid_status.active_bid')}</span>
          <h4 className="product-card__header--text padding-l-8px padding-r-8px listings__empty-content--create-offer-button">
            <span className="product-card__header--text-currency">{currency}</span>
            {formatPrice(highestBidPrice)}
          </h4>
        </div>
      );
    }

    return t('shared.bidding_process.bid_status.inactive_bid_text');
  };

  const isLinkDisable = !redirectLink;
  const productCardClassName = classNames(
    'product-card flex',
    {
      'product-card__disable-link': isLinkDisable,
    },
    className,
  );

  return (
    <Link to={redirectLink} target="_blank" className={productCardClassName}>
      <div className="width-100">
        <div className="flex justify-between">
          <div className="product-card__header flex">
            <h4 className="product-card__header--text padding-r-12px">
              {`${formatVolume(quantity)} ${quantityUnit}`}
            </h4>
            {type === 'KernelProduct' && quality && (
              <h4 className="product-card__header--text text-capitalize padding-x-12px">
                {quality}
              </h4>
            )}
            {type === 'KernelProduct' && style && (
              <h4 className="product-card__header--text product-card__header--text-truncate text-capitalize padding-x-12px">
                {style}
              </h4>
            )}
            {type === 'DriedNutInShellProduct' && size && (
              <h4 className="product-card__header--text product-card__header--text-truncate text-capitalize padding-x-12px">
                {size}
              </h4>
            )}
            {country && (
              <h4 className="product-card__header--text padding-l-12px">
                {countryCodeToName(country)}
              </h4>
            )}
          </div>
          <div className="product-card__header flex items-center">
            <p className="product-card__header--label">{priceLabel}</p>
            <h4 className="product-card__header--text padding-l-8px">
              <span className="product-card__header--text-currency">{currency}</span>
              {formatPrice(perKgPrice)}
            </h4>
          </div>
        </div>
        <div>
          <div className="flex gap-16px">
            {type === 'DriedNutInShellProduct' && soundKernelRecovery && (
              <p className="product-card__description margin-b-0">
                <span className="product-card__description--bold">{soundKernelRecoveryLabel}</span>
                <span>{soundKernelRecovery}</span>
              </p>
            )}
            {type === 'DriedNutInShellProduct' && unsoundKernelRecovery && (
              <p className="product-card__description margin-b-0">
              <span className="product-card__description--bold">{unsoundKernelRecoveryLabel}</span>
              <span>{unsoundKernelRecovery}</span>
            </p>
            )}
          </div>
          <div className="flex justify-between">
            <div className="flex gap-16px">
              {isIdVisible && (
                <p className="product-card__description margin-b-0">
                  <span className="product-card__description--bold">{idLabel}</span>
                  {id}
                </p>
              )}
              {createdAtDate && (
                <p className="product-card__description margin-b-0">
                  <span className="product-card__description--bold">{createdAtLabel}</span>
                  {formatDate(createdAtDate)}
                </p>
              )}
              {esdDate && (
                <p className="product-card__description margin-b-0">
                  <span className="product-card__description--bold">{esdLabel}</span>
                  {formatDate(esdDate)}
                </p>
              )}
              {bestBeforeDate && (
                <p className="product-card__description margin-b-0">
                  <span className="product-card__description--bold">{bestBeforeLabel}</span>
                  <span>{formatDate(bestBeforeDate)}</span>
                </p>
              )}
            </div>
            <div>
              <div className="product-card__description margin-b-0">
                {isBidStatusVisible ? (
                  <div className="margin-t-14px">{bidStatus()}</div>
                ) : (
                  <p className="product-card__description margin-b-0">
                    {batchLabel}
                    {batchPrice && (
                      <Fragment>
                        <span className="product-card__description--bolder">{currency}</span>
                        <span className="product-card__description--batch-price">
                          {formatPrice(batchPrice)}
                        </span>
                      </Fragment>
                    )}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {redirectLink && <Icon name="arrowForward" className="padding-l-16px" size="normal" />}
    </Link>
  );
};

ProductCard.defaultProps = {
  className: '',
  quantityUnit: t('shared.product_card.quantity_unit'),
  quality: null,
  style: null,
  size: null,
  soundKernelRecovery: null,
  unsoundKernelRecovery: null,
  type: null,
  country: null,
  isIdVisible: false,
  idLabel: null,
  id: null,
  esdLabel: null,
  esdDate: null,
  batchLabel: null,
  batchPrice: null,
  currency: null,
  redirectLink: null,
  isBidStatusVisible: false,
  highestBidPrice: null,
  createdAtLabel: t('shared.product_card.created_at_label'),
  createdAtDate: null,
};

ProductCard.propTypes = {
  className: PropTypes.string,
  quantity: PropTypes.string.isRequired,
  quantityUnit: PropTypes.string,
  quality: PropTypes.string,
  style: PropTypes.string,
  size: PropTypes.string,
  soundKernelRecovery: PropTypes.string,
  soundKernelRecoveryLabel: PropTypes.string.isRequired,
  unsoundKernelRecovery: PropTypes.string,
  unsoundKernelRecoveryLabel: PropTypes.string.isRequired,
  type: PropTypes.string,
  country: PropTypes.string,
  priceLabel: PropTypes.string.isRequired,
  perKgPrice: PropTypes.string.isRequired,
  isIdVisible: PropTypes.bool,
  idLabel: PropTypes.string,
  id: PropTypes.string,
  esdLabel: PropTypes.string,
  esdDate: PropTypes.string,
  bestBeforeLabel: PropTypes.string.isRequired,
  bestBeforeDate: PropTypes.string.isRequired,
  batchLabel: PropTypes.string,
  batchPrice: PropTypes.string,
  currency: PropTypes.string,
  redirectLink: PropTypes.string,
  isBidStatusVisible: PropTypes.bool,
  highestBidPrice: PropTypes.number,
  createdAtLabel: PropTypes.string,
  createdAtDate: PropTypes.string,
};

export default ProductCard;
