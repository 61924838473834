import React from 'react';
import ResetPasswordWrapper from 'components/ResetPassword/ResetPasswordWrapper';
import SuccessfulMailSent from 'components/ResetPassword/SuccessfulMailSent';

const SuccessfulMailSentPage = () => (
  <ResetPasswordWrapper>
    <SuccessfulMailSent />
  </ResetPasswordWrapper>
);

export default SuccessfulMailSentPage;
