import React from 'react';
import DetailsBody from 'components/BiddingProcess/ListingDetails/ListingInformationBody/DetailsBody';
import ActionPanel from 'components/BiddingProcess/ListingDetails/ListingInformationBody/ActionPanel';
import './styles.scss';

const ListingInformationBody = () => (
  <div className="listing-information-body">
    <DetailsBody />
    <ActionPanel />
  </div>
);

export default ListingInformationBody;
