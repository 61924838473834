import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/BaseComponents/Icon';

const NewMessageToast = (props) => {
  const iconName = props.type === 'success' ? 'checkCircle' : 'invalid';
  const iconFill = props.type === 'success' ? '#52b987' : '#ff5555';

  return (
    <div className="new-message-toast">
      <div className="new-message-toast__body">
        <div className="new-message-toast__message">
          <h3 className="new-message-toast__message-heading">
            <Icon className="new-message-toast__message-icon" name={iconName} fill={iconFill} />
            {props.heading}
          </h3>
          <p className="new-message-toast__message-body">{props.messageBody}</p>
        </div>
      </div>
    </div>
  );
};

NewMessageToast.defaultProps = {
  messageBody: null,
};

NewMessageToast.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  messageBody: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  type: PropTypes.oneOf(['success', 'error']).isRequired,
};

export default NewMessageToast;
