import React from 'react';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { admin, userProfile } from 'utils/constants/Routes';
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/BaseComponents/Table';
import LoadingSpinner from 'components/BaseComponents/LoadingSpinner';
import Icon from 'components/BaseComponents/Icon';
import TableColumn from 'components/Portals/Admin/UserManagement/TableColumn';
import { generateUserRolesContent } from 'components/Portals/Admin/UserManagement/Table/UserTable';
import { AbilityContext, Can, userProfileSubject } from 'ability';
import { useAbility } from '@casl/react';

const UserTable = ({ tableHeaders, users, fetchingAllUsers }) => {
  const ability = useAbility(AbilityContext);

  const getActionsContent = (user) => (
    <Can I="view" a={userProfileSubject(user)}>
      <Link to={ability.can('viewAsAdmin',  userProfileSubject(user)) ? admin.userProfile(user.id) : userProfile(user.id)}>
        <Icon name="show" size="normal" className="admin-portal__table--icon" />
      </Link>
    </Can>
  );

  return (
    <Table className="admin-portal__table">
      <TableHead>
        <TableRow isHeaderRow={true}>
          {tableHeaders.map((title) => (
            <TableHeader
              key={title}
              textCase="capitalize"
              unavailableText={t('admin_user_table.no_data_available')}
            >
              {title}
            </TableHeader>
          ))}
        </TableRow>
      </TableHead>

      {fetchingAllUsers ? (
        <TableBody className="position-relative padding-y-32px">
          <LoadingSpinner />
        </TableBody>
      ) : (
        <TableBody>
          {users.map(({ id, firstName, lastName, roles, email }) => (
            <TableRow key={id}>
              <TableColumn content={`${firstName} ${lastName}`} />
              <TableColumn content={generateUserRolesContent(roles)} />
              <TableColumn content={email} />
              <TableColumn content={getActionsContent({ id, roles })} />
            </TableRow>
          ))}
        </TableBody>
      )}
    </Table>
  );
};

UserTable.defaultProps = {
  users: [],
};

UserTable.propTypes = {
  fetchingAllUsers: PropTypes.bool.isRequired,
  tableHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      company: PropTypes.shape({
        name: PropTypes.string,
        verifiedAt: PropTypes.string,
        requestedVerificationAt: PropTypes.string,
      }).isRequired,
      roles: PropTypes.arrayOf(PropTypes.string.isRequired),
      email: PropTypes.string.isRequired,
    }),
  ),
};

export default UserTable;
