import React from "react";
import { PropTypes } from "prop-types";
import Icon from "components/BaseComponents/Icon";
import { t } from "i18next";

const NeedProductPermissionsIcon = ({ type }) => {
  const productTypeWording = t(`shared.need_product_permissions_icon.types.${type}`)

  return (
    <Icon
      name='info'
      iconLabel={t('shared.need_product_permissions_icon.text', { type: productTypeWording })}
      showTooltip={true}
    />
  )
};

NeedProductPermissionsIcon.propTypes = {
  type: PropTypes.string.isRequired,
};

export default NeedProductPermissionsIcon;
