import React, { useState } from 'react';
import { t as translation } from 'i18next';
import { useGetAllUsersQuery } from 'api/users';
import { getAdminaffiliationsTable } from 'utils/helper/Portals';
import { RTabs } from 'components/BaseComponents/RTabs';
import Pagination from 'components/BaseComponents/Pagination';
import EmptyContentPlaceholder from 'components/Shared/EmptyContentPlaceholder';
import { ADMIN_LISTINGS_PER_PAGE } from 'utils/constants/offerListings';
import 'components/Portals/Admin/UserManagement/Table/styles.scss';
import {
  tabFilterStatus,
  REQUESTED_VERIFICATION,
  USER_GROUP_AFFILIATE_PARTNERS,
} from 'utils/constants/userStatus';

function AffiliatePartnerManagementTable() {
  const [userStatus, setUserStatus] = useState(REQUESTED_VERIFICATION);
  const [currentPage, setCurrentPage] = useState(1);

  const { data: listings = {}, isFetching: isUsersFetching } = useGetAllUsersQuery({
    currentPage,
    userStatus,
    perPage: ADMIN_LISTINGS_PER_PAGE,
    userGroup: USER_GROUP_AFFILIATE_PARTNERS,
  });

  const isListingExists = listings?.collection?.length > 0;
  const totalPages = Number(listings?.pagination?.totalPages);
  const tabItems = getAdminaffiliationsTable(isUsersFetching, listings?.collection);

  const handleTabChange = (tabKey) => {
    setCurrentPage(1);
    setUserStatus(tabFilterStatus[tabKey]);
  };

  const handlePagination = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const tableCaption = translation('side_navbar.link3_label');

  return (
    <div className="admin-portal__table--container">
      <h5 className="admin-portal__table--caption">{tableCaption}</h5>
      <RTabs
        className="admin-portal__table--tab"
        isVertical={false}
        items={tabItems}
        selectedTabKey={tabItems[0].key}
        onChange={handleTabChange}
      />
      {!isListingExists && !isUsersFetching && (
        <EmptyContentPlaceholder
          heading={translation(
            'shared.bidding_process.empty_content_placeholder.affiliations.header',
          )}
        />
      )}
      {totalPages > 1 && !isUsersFetching && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPaginationClick={handlePagination}
          firstText={translation('admin_user_table.first_page')}
          lastText={translation('admin_user_table.last_page')}
          nextText={translation('admin_user_table.next_page')}
          previousText={translation('admin_user_table.previous_page')}
          className="admin-portal__table--pagination"
        />
      )}
    </div>
  );
}

export default AffiliatePartnerManagementTable;
