import React, { useRef, useState } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field, Form } from 'react-final-form';
import { useUploadCSVMutation } from 'api/admin';
import { requiredField } from 'utils/helper/Validations';
import ChooseFile from 'components/Shared/ChooseFile';
import Icon from 'components/BaseComponents/Icon';
import Button from 'components/BaseComponents/Button';
import './styles.scss';

const initialCSVFormState = {
  isError: false,
  errorMessages: null,
  generalErrorMessages: null,
  iconName: 'attachFile',
  formDescription: t('admin.csv_modal_body.description_default'),
};

const DRIED_NUT_IN_SHELL_IMPORT_TYPE = 'dried_nut_in_shell'
const KERNEL_IMPORT_TYPE = 'kernel'

const AggregateCSVModal = ({ userId }) => {
  const toastRef = useRef();

  const [csvFormState, setCSVFormState] = useState(initialCSVFormState);
  const { isError, errorMessages, generalErrorMessages, iconName, formDescription } = csvFormState;

  const [importType, setImportType] = useState(KERNEL_IMPORT_TYPE)

  const sampleKernelCSVFileUrl = '/Sample Aggregated Data Kernel.csv';
  const sampleDriedNutInShellCSVFileUrl = '/Sample Aggregated Data Dried Nut in Shell.csv';
  const sampleCSVFileUrl = importType === KERNEL_IMPORT_TYPE
    ? sampleKernelCSVFileUrl
    : sampleDriedNutInShellCSVFileUrl

  const [uploadCSV] = useUploadCSVMutation();

  const generateCSVFileBody = (params) => {
    const { csv_file } = params;
    const aggregatedCSVForm = new FormData();

    if (csv_file instanceof File) {
      aggregatedCSVForm.append('csv_file', csv_file);
    }
    aggregatedCSVForm.append('type', importType)

    return aggregatedCSVForm;
  };

  const formatMissingValueError = (error) =>
    t('admin.csv_modal_body.missing_value_error', {
      errorMessage: error.messages.join(', '),
      rowNumber: error.row + 1,
    });

  const handleCSVUpload = (values) => {
    const requestBody = generateCSVFileBody(values);

    return uploadCSV({ userId, payload: requestBody })
      .unwrap()
      .then(() => {
        setCSVFormState({
          ...csvFormState,
          isError: false,
          iconName: 'check',
          formDescription: t('admin.csv_modal_body.description_success'),
          errorMessages: null,
          generalErrorMessages: null,
        });
      })
      .catch((data) => {
        const invalidFileError = data?.data?.invalidFile;
        const errors = data?.data?.errors?.length > 0 ? data?.data?.errors : null;
        const generalErrors =
          data?.data?.generalErrors?.length > 0 ? data?.data?.generalErrors : null;

        setCSVFormState({
          ...csvFormState,
          isError: true,
          iconName: 'invalidOutline',
          formDescription:
            invalidFileError || t('admin.csv_modal_body.description_missing_value_error'),
          errorMessages: errors,
          generalErrorMessages: generalErrors,
        });
      });
  };

  return (
    <div className="aggregated-csv-modal">
      <Icon
        name={iconName}
        size="xxl"
        color={isError ? 'danger' : 'success'}
        className={classNames('aggregated-csv-modal__icon', {
          'aggregated-csv-modal__icon--error': isError,
        })}
      />
      <h4 className="aggregated-csv-modal__title">{t('admin.csv_modal_body.title')}</h4>
      <div className='aggregated-csv-modal__import-type'>
        <Button
          type={importType === KERNEL_IMPORT_TYPE && 'success'}
          className='aggregated-csv-modal__import-type__kernel'
          label={t('admin.csv_modal_body.kernel')}
          onClick={() => setImportType(KERNEL_IMPORT_TYPE)}
        />
        <Button
          type={importType === DRIED_NUT_IN_SHELL_IMPORT_TYPE && 'success'}
          className='aggregated-csv-modal__import-type__dried_nut_in_shell'
          label={t('admin.csv_modal_body.dried_nut_in_shell')}
          onClick={() => setImportType(DRIED_NUT_IN_SHELL_IMPORT_TYPE)}
        />
      </div>
      <p
        className={classNames('aggregated-csv-modal__description', {
          'aggregated-csv-modal__description--error': isError,
        })}
      >
        {formDescription}
      </p>
      {errorMessages && (
        <ul className="aggregated-csv-modal__errors">
          {errorMessages.map((error) => (
            <li key={error.row}>{formatMissingValueError(error)}</li>
          ))}
        </ul>
      )}
      {generalErrorMessages && (
        <p className="aggregated-csv-modal__errors">{generalErrorMessages.join(', ')}</p>
      )}

      <a href={sampleCSVFileUrl} target="_blank" rel="noreferrer">
        <Button
          label={t('admin.csv_modal_body.download_button_label', { type: t(`admin.csv_modal_body.${importType}`)})}
          className="additional-document__submit-btn margin-t-24px"
          submitType="submit"
          type="success"
        />
      </a>
      <Form
        onSubmit={handleCSVUpload}
        initialValues={{}}
        render={({ form, handleSubmit, invalid, submitting, hasValidationErrors }) => (
          <form
            className="aggregated-csv-modal__form"
            onSubmit={async (event) => {
              if (hasValidationErrors) {
                toastRef?.current?.notify();
              }
              await handleSubmit(event);
              form.reset();
            }}
          >
            <Field name="csv_file" validate={requiredField}>
              {({ input, meta }) => (
                <ChooseFile
                  className="padding-t-16px padding-b-8px"
                  fileName={input.value?.name}
                  label={t('admin.csv_modal_body.file_input_label')}
                  hint={t('admin.csv_modal_body.choose_file_label')}
                  onChange={(file) => {
                    input.onChange(file);
                  }}
                  touched={meta.touched}
                  errorMessage={meta?.error || meta?.submitError}
                />
              )}
            </Field>
            <Button
              label={t('admin.csv_modal_body.submit_button_label')}
              className="additional-document__submit-btn margin-t-24px"
              submitType="submit"
              type="success"
              disabled={invalid || submitting}
            />
          </form>
        )}
      />
    </div>
  );
};

AggregateCSVModal.propTypes = {
  userId: PropTypes.number.isRequired,
};

export default AggregateCSVModal;
