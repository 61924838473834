import React from 'react';
import { useTranslation } from 'react-i18next';
import { WholeSaleTradingSteps } from 'utils/constants/wholeSaleMarketPlace';
import { support } from 'utils/constants/Routes';
import TradingStepCard from './Card';
import './styles.scss';

const TradingSteps = () => {
  const { t } = useTranslation();

  return (
    <div className="trading-cards flex flex-col items-center">
      <h1 className="trading-cards__heading">{t('wholesale_marketplace.trading_steps.heading')}</h1>
      <div className="flex space-evenly">
        {WholeSaleTradingSteps.map(({ cardNumber, heading, description }) => (
          <TradingStepCard
            key={cardNumber}
            stepNumber={cardNumber}
            heading={heading}
            description={description}
          />
        ))}
      </div>
      <a href={support} className="trading-cards__cta">
        {t('wholesale_marketplace.trading_steps.how_trading_platform_work')}
      </a>
    </div>
  );
};

export default TradingSteps;
