import React from 'react';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import { contactUs } from 'utils/constants/Routes';
import './styles.scss';

const PortalsFooter = () => (
  <footer className="portals-footer">
    <div className="portals-footer__text">
      <Link to={contactUs} className="portals-footer__link">
        {t('shared.portals_footer.need_change')}
      </Link>
    </div>
    <div className="portals-footer__text">
      <Link to={contactUs} className="portals-footer__link">
        {t('shared.portals_footer.contact_us')}
      </Link>
    </div>
  </footer>
);

export default PortalsFooter;
