import i18n from 'i18n';

export const HUNDRED_PERCENT = 100;

export const buyerProgressCardDetails = ({
  personalDetailsProgressPercentage,
  businessDetailsProgressPercentage,
  businessContactProgressPercentage,
}) => [
  {
    id: 1,
    title: i18n.t('profile.progress_card.personal_details'),
    progressPercentage: personalDetailsProgressPercentage,
  },
  {
    id: 2,
    title: i18n.t('profile.progress_card.business_details'),
    progressPercentage: businessDetailsProgressPercentage,
  },
  {
    id: 3,
    title: i18n.t('profile.progress_card.business_contacts'),
    progressPercentage: businessContactProgressPercentage,
  },
];

export const sellerProgressCardDetails = ({
  personalDetailsProgressPercentage,
  businessDetailsProgressPercentage,
  businessContactProgressPercentage,
  foodSafetyProgressPercentage,
}) => [
  {
    id: 1,
    title: i18n.t('profile.progress_card.personal_details'),
    progressPercentage: personalDetailsProgressPercentage,
  },
  {
    id: 2,
    title: i18n.t('profile.progress_card.business_details'),
    progressPercentage: businessDetailsProgressPercentage,
  },
  {
    id: 3,
    title: i18n.t('profile.progress_card.business_contacts'),
    progressPercentage: businessContactProgressPercentage,
  },
  {
    id: 4,
    title: i18n.t('profile.progress_card.food_safety'),
    progressPercentage: foodSafetyProgressPercentage,
  },
];

export const traderProgressCardDetails = ({
  personalDetailsProgressPercentage,
  businessDetailsProgressPercentage,
  businessContactProgressPercentage,
  foodSafetyProgressPercentage,
}) => [
  {
    id: 1,
    title: i18n.t('profile.progress_card.personal_details'),
    progressPercentage: personalDetailsProgressPercentage,
  },
  {
    id: 2,
    title: i18n.t('profile.progress_card.business_details'),
    progressPercentage: businessDetailsProgressPercentage,
  },
  {
    id: 3,
    title: i18n.t('profile.progress_card.business_contacts'),
    progressPercentage: businessContactProgressPercentage,
  },
  {
    id: 4,
    title: i18n.t('profile.progress_card.food_safety'),
    progressPercentage: foodSafetyProgressPercentage,
  },
];

export const affiliatePartnerProgressCardDetails = ({
  personalDetailsProgressPercentage,
  businessDetailsProgressPercentage,
  businessContactProgressPercentage,
  bankingDetailsProgressPercentage,
}) => [
  {
    id: 1,
    title: i18n.t('profile.progress_card.personal_details'),
    progressPercentage: personalDetailsProgressPercentage,
  },
  {
    id: 2,
    title: i18n.t('profile.progress_card.business_details'),
    progressPercentage: businessDetailsProgressPercentage,
  },
  {
    id: 3,
    title: i18n.t('profile.progress_card.business_contacts'),
    progressPercentage: businessContactProgressPercentage,
  },
  {
    id: 4,
    title: i18n.t('profile.progress_card.banking_details'),
    progressPercentage: bankingDetailsProgressPercentage,
  },
];

export const subscriptionMemberProgressCardDetails = ({
  personalDetailsProgressPercentage,
  businessDetailsProgressPercentage,
}) => [
  {
    id: 1,
    title: i18n.t('profile.progress_card.personal_details'),
    progressPercentage: personalDetailsProgressPercentage,
  },
  {
    id: 2,
    title: i18n.t('profile.progress_card.business_details'),
    progressPercentage: businessDetailsProgressPercentage,
  },
];
