import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { useRequestAdditionalDocMutation } from 'api/admin';
import { requiredField } from 'utils/helper/Validations';
import Textarea from 'components/BaseComponents/Textarea';
import Button from 'components/BaseComponents/Button';
import './styles.scss';

const UserAdditionalDocumentModal = ({ handleModalClose }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const userId = Number(id);
  const [requestAdditionalDoc] = useRequestAdditionalDocMutation();

  const handleRequestDocSubmit = async (params) => {
    const requestBody = {
      body_text: params.additionalDoc,
    };

    const response = await requestAdditionalDoc({ userId, payload: requestBody });

    if (response.data) {
      handleModalClose();
    }
  };

  return (
    <div className="additional-document__modal">
      <h4 className="additional-document__modal--title">{t('admin.request_modal_body.title')}</h4>
      <Form
        onSubmit={handleRequestDocSubmit}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Field name="additionalDoc" validate={requiredField}>
              {({ input, meta }) => (
                <Textarea
                  className="additional-document__textarea"
                  label={t('admin.request_modal_body.text_area_label')}
                  placeholder={t('admin.request_modal_body.text_area_placeholder')}
                  size="small"
                  touched={meta.touched}
                  value={input.value}
                  onChange={input.onChange}
                  error={meta?.error || meta?.submitError}
                />
              )}
            </Field>
            <Button
              type="success"
              label={t('admin.request_modal_body.title')}
              submitType="submit"
              disabled={submitting}
            />
          </form>
        )}
      />
    </div>
  );
};

UserAdditionalDocumentModal.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
};

export default UserAdditionalDocumentModal;
