import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from 'components/BaseComponents/Icon';
import IconButton from 'components/BaseComponents/IconButton';
import Modal from 'components/BaseComponents/Modal';
import DeleteConfirmationModalBody from '../DeleteConfirmationModal';

function DocumentManagement({
  fileName,
  fileUrl,
  isEditable,
  onDelete,
  onChange,
  disableDelete,
  hint,
  documentsFormSubmit,
  handleDocuments,
  handleClose,
  handleEdit,
}) {
  const inputFileRef = useRef(null);
  const [uploadedFileUrl, setUploadedFileUrl] = useState(fileUrl);
  const [isDeleteConfirmationModalVisible, setIsDeleteConfirmationModalVisible] = useState(false);

  useEffect(() => {
    setUploadedFileUrl(fileUrl);
  }, [fileUrl]);

  const handleFileChange = (e) => {
    if (documentsFormSubmit) {
      handleDocuments(e.target.files);
      documentsFormSubmit();
    }
    onChange(e.target.files[0]);
    setUploadedFileUrl(URL.createObjectURL(e.target.files[0]));
  };

  const handleDelete = () => {
    setUploadedFileUrl(null);
    onDelete(null);
  };

  const handleModalOpen = () => {
    setIsDeleteConfirmationModalVisible(true);
  };

  const handleModalClose = () => {
    setIsDeleteConfirmationModalVisible(false);
  };

  const openFileWindow = () => {
    inputFileRef.current.click();
  };

  const showDownlaodButton = uploadedFileUrl && fileName;

  const uploadIcon = <Icon name="cloudUpload" size="medium" color="success" />;
  const deleteIcon = <Icon name="deleteLine" size="medium" color="danger" />;
  const editIcon = <Icon name="edit" size="medium" color="success" />;
  const downloadIcon = <Icon name="download" />;
  const closeIcon = <Icon name="close" />;

  return (
    <Fragment>
      {showDownlaodButton && (
        <Link to={uploadedFileUrl} target="_blank">
          <IconButton icon={downloadIcon} size="large" color="success" isIconOnly />
        </Link>
      )}
      {isEditable &&
        (fileName ? (
          !disableDelete && (
            <Fragment>
              <IconButton
                icon={editIcon}
                size="large"
                color="success"
                isIconOnly
                onClick={handleEdit}
              />
              <IconButton
                icon={deleteIcon}
                size="large"
                color="danger"
                isIconOnly
                onClick={handleModalOpen}
              />
            </Fragment>
          )
        ) : (
          <Fragment>
            <IconButton
              icon={uploadIcon}
              size="large"
              color="success"
              isIconOnly
              onClick={openFileWindow}
            />
            <span
              className="choose-file__hint"
              tabIndex={0}
              onClick={openFileWindow}
              onKeyPress={openFileWindow}
              role="button"
            >
              {hint}
            </span>
            <input
              ref={inputFileRef}
              id="file-input"
              type="file"
              className="choose-file__input"
              placeholder="file-input"
              onChange={handleFileChange}
            />
          </Fragment>
        ))}
      {isEditable && !fileName && (
        <IconButton icon={closeIcon} size="small" isIconOnly onClick={handleClose} />
      )}
      <Modal
        isVisible={isDeleteConfirmationModalVisible}
        onOutsideClick={handleModalClose}
        className="progress-card-modal"
      >
        <Modal.Content>
          <DeleteConfirmationModalBody
            onModalClose={handleModalClose}
            handleDelete={handleDelete}
          />
        </Modal.Content>
      </Modal>
    </Fragment>
  );
}

DocumentManagement.defaultProps = {
  fileName: null,
  fileUrl: null,
  hint: null,
  isEditable: true,
  disableDelete: false,
  documentsFormSubmit: null,
  handleDocuments: null,
  handleClose: null,
  handleEdit: null,
};

DocumentManagement.propTypes = {
  fileName: PropTypes.string,
  fileUrl: PropTypes.string,
  isEditable: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  documentsFormSubmit: PropTypes.func,
  handleDocuments: PropTypes.func,
  hint: PropTypes.string,
  disableDelete: PropTypes.bool,
  handleClose: PropTypes.func,
  handleEdit: PropTypes.func,
};

export default DocumentManagement;
