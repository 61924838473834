import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { LISTINGS_COUNT_ZERO, filterOptions } from 'utils/constants/offerListings';
import SelectBox from 'components/BaseComponents/SelectBox';
import './styles.scss';

const AggregateDataSort = ({ listingType, totalListings, selectedSort, onSort, showText }) => (
  <div className="sort-filter flex justify-between items-center">
    {showText && (
      <h4 className="sort-filter__result">
        {t(`offer_listing.marketplace.search_result.${listingType}`, { totalListings })}
      </h4>
    )}
    <div className="sort-filter__dropdown">
      <SelectBox
        width="full"
        options={filterOptions}
        isClearable={false}
        isSearchable={false}
        onChange={onSort}
        value={selectedSort}
      />
    </div>
  </div>
);

AggregateDataSort.defaultProps = {
  listingType: 'concluded_contracts',
  showText: true,
  totalListings: LISTINGS_COUNT_ZERO,
  selectedSort: filterOptions[0],
};

AggregateDataSort.propTypes = {
  listingType: PropTypes.string,
  showText: PropTypes.bool,
  totalListings: PropTypes.number,
  selectedSort: PropTypes.shape({
    label: PropTypes.string,
    column: PropTypes.string,
    direction: PropTypes.string,
  }),
  onSort: PropTypes.func.isRequired,
};

export default AggregateDataSort;
