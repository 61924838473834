import i18n from 'i18n';
import {
  aboutUs,
  affiliatePartnerStaticPage,
  aggregateSalesStatistics,
  contactUs,
  cookies,
  home,
  jurisdiction,
  landingPage,
  login,
  privacy,
  services,
  signup,
  support,
  terms,
  wholeSaleMarketPlace,
  affiliatePartnerSignup,
  retailsPage,
} from './Routes';

export const footerData = [
  {
    name: i18n.t('landing_page.footer.name1'),
    links: [
      { label: i18n.t('landing_page.footer.name1-link1'), href: aboutUs },
      { label: i18n.t('landing_page.footer.name1-link2'), href: services },
      { label: i18n.t('landing_page.footer.name1-link3'), href: wholeSaleMarketPlace },
      { label: i18n.t('landing_page.footer.name1-link4'), href: retailsPage },

      // commenting since MSM wanted to hide this for the time being, might need in the future.
      // { label: i18n.t('landing_page.footer.name1-link5'), href: landingPage, isDisabled: true },
    ],
  },
  {
    name: i18n.t('landing_page.footer.name2'),
    links: [
      { label: i18n.t('landing_page.footer.name2-link1'), href: contactUs },
      { label: i18n.t('landing_page.footer.name2-link2'), href: support },
      { label: i18n.t('landing_page.footer.name2-link3'), href: aggregateSalesStatistics },
      { label: i18n.t('landing_page.footer.name2-link4'), href: jurisdiction },
    ],
  },
  {
    name: i18n.t('landing_page.footer.name3'),
    links: [
      { label: i18n.t('landing_page.footer.name3-link1'), href: terms },
      { label: i18n.t('landing_page.footer.name3-link2'), href: privacy },
      { label: i18n.t('landing_page.footer.name3-link3'), href: cookies },
    ],
  },
];

export const buyingProcessData = [
  {
    stepNumber: '1',
    title: i18n.t('landing_page.process_section.title1a'),
    description: i18n.t('landing_page.process_section.description1a'),
  },
  {
    stepNumber: '2',
    title: i18n.t('landing_page.process_section.title1b'),
    description: i18n.t('landing_page.process_section.description1b'),
  },
  {
    stepNumber: '3',
    title: i18n.t('landing_page.process_section.title1c'),
    description: i18n.t('landing_page.process_section.description1c'),
  },
  {
    stepNumber: '4',
    title: i18n.t('landing_page.process_section.title1d'),
    description: i18n.t('landing_page.process_section.description1d'),
  },
];

export const sellingProcessData = [
  {
    stepNumber: '1',
    title: i18n.t('landing_page.process_section.title2a'),
    description: i18n.t('landing_page.process_section.description2a'),
  },
  {
    stepNumber: '2',
    title: i18n.t('landing_page.process_section.title2b'),
    description: i18n.t('landing_page.process_section.description2b'),
  },
  {
    stepNumber: '3',
    title: i18n.t('landing_page.process_section.title2c'),
    description: i18n.t('landing_page.process_section.description2c'),
  },
  {
    stepNumber: '4',
    title: i18n.t('landing_page.process_section.title2d'),
    description: i18n.t('landing_page.process_section.description2d'),
  },
  {
    stepNumber: '5',
    title: i18n.t('landing_page.process_section.title2e'),
    description: i18n.t('landing_page.process_section.description2e'),
  },
];

export const checkItems = [
  i18n.t('landing_page.msm_facts.fact1'),
  i18n.t('landing_page.msm_facts.fact2'),
  i18n.t('landing_page.msm_facts.fact3'),
];

export const publicUrls = [
  landingPage,
  home,
  aboutUs,
  wholeSaleMarketPlace,
  services,
  support,
  aggregateSalesStatistics,
  contactUs,
  affiliatePartnerStaticPage,
  terms,
  privacy,
  jurisdiction,
  cookies,
  login,
  signup,
  affiliatePartnerSignup,
];
