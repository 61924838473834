import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import AvatarUpload from 'components/BaseComponents/AvatarUpload';
import Modal from 'components/BaseComponents/Modal';
import Icon from 'components/BaseComponents/Icon';
import IconButton from 'components/BaseComponents/IconButton';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import './style.scss';

const AvatarModal = (props) => {
  const { className, updateAvatar, iconName, isEditable } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { t } = useTranslation();

  const onModalOpen = () => setIsModalVisible(true);
  const onModalClose = () => setIsModalVisible(false);

  const saveAvatar = async (image) => {
    updateAvatar(image);
  };

  const onAvatarSave = (image) => {
    const file = new File([image], image.name, { type: image.type });
    saveAvatar(file);
  };

  return (
    <>
      {isEditable && (
        <IconButton
          className="avatar-container__upload--icon"
          version="v2"
          isIconOnly
          icon={<Icon name={iconName || 'cameraAlt'} showBGColor />}
          size="small"
          color="tertiary"
          onClick={onModalOpen}
        />
      )}
      <Modal
        isVisible={isModalVisible}
        onOutsideClick={onModalClose}
        className={classNames('avatar-upload-modal', className)}
      >
        <Modal.Content>
          <AvatarUpload
            onAvatarUpload={onAvatarSave}
            onClose={onModalClose}
            title={t('profile.avatar_upload.title')}
            fileSizeErrorMsg={t('profile.avatar_upload.file_size_error_msg')}
            fileTypeErrorMsg={t('profile.avatar_upload.file_type_error_msg')}
            uploadTitle={t('profile.avatar_upload.upload_title')}
            uploadSubtitle={t('profile.avatar_upload.upload_subtitle')}
            setButtonLabel={t('profile.avatar_upload.set')}
            cancelButtonLabel={t('profile.avatar_upload.cancel')}
            active={t('profile.avatar_upload.active')}
          />
        </Modal.Content>
      </Modal>
    </>
  );
};

AvatarModal.defaultProps = {
  className: null,
  iconName: null,
  isEditable: true,
};

AvatarModal.propTypes = {
  className: PropTypes.string,
  updateAvatar: PropTypes.func.isRequired,
  iconName: PropTypes.string,
  isEditable: PropTypes.bool,
};

export default AvatarModal;
