import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Button from 'components/BaseComponents/Button';
import Icon from 'components/BaseComponents/Icon';
import './styles.scss';

const FoodSafetySkipModalBody = ({ onModalClose, onSkipSubmit }) => (
  <div className="food-safety-skip-modal">
    <Icon className="food-safety-skip-modal__icon" name="info" size="normal" />
    <h3 className="food-safety-skip-modal__title">
      {t('offer_creation.food_safety.skip_modal_body.title')}
    </h3>
    <p className="food-safety-skip-modal__description">
      {t('offer_creation.food_safety.skip_modal_body.description')}
    </p>
    <div className="food-safety-skip-modal__buttons-container">
      <Button
        className="food-safety-skip-modal__buttons-container--button"
        label={t('offer_creation.food_safety.skip_modal_body.cancel_button_label')}
        onClick={onModalClose}
      />
      <Button
        className="food-safety-skip-modal__buttons-container--button"
        label={t('offer_creation.food_safety.skip_modal_body.submit_button_label')}
        type="success"
        onClick={onSkipSubmit}
      />
    </div>
  </div>
);

FoodSafetySkipModalBody.propTypes = {
  onModalClose: PropTypes.func.isRequired,
  onSkipSubmit: PropTypes.func.isRequired,
};

export default FoodSafetySkipModalBody;
