import { t } from 'i18next';

export const contractPDFSignatureContents = (userRole, userDetails) => {
  const role = userRole.charAt(0).toUpperCase() + userRole.slice(1);

  const fullName = `${userDetails.firstName} ${userDetails.lastName}`;

  return [
    {
      fieldLabel: t('contract_pdf.user_company', { role }),
      fieldSize: 'small',
      textContent: userDetails?.companyName,
    },
    {
      fieldLabel: t('contract_pdf.name_of_signee'),
      fieldSize: 'small',
      textContent: fullName,
    },
    {
      fieldLabel: t('contract_pdf.signature_for_user', { role }),
      fieldSize: 'large',
      textContent: '',
    },
    {
      fieldLabel: t('contract_pdf.date_of_signature'),
      fieldSize: 'small',
      textContent: '',
    },
    {
      fieldLabel: t('contract_pdf.place_of_signature'),
      fieldSize: 'small',
      textContent: '',
    },
    {
      fieldLabel: t('contract_pdf.witness_signature_1'),
      fieldSize: 'small',
      textContent: '',
    },
    {
      fieldLabel: t('contract_pdf.witness_signature_2'),
      fieldSize: 'small',
      textContent: '',
    },
  ];
};
