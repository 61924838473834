import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { signup } from 'utils/constants/Routes';
import './styles.scss';

const FormFooter = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="form-footer__content-divider flex items-center">
        <div className="form-footer__content-divider--box" />
        <div className="form-footer__content-divider--text">{t('login.divider_text')}</div>
        <div className="form-footer__content-divider--box" />
      </div>

      <div>
        <p className="form-footer__login-message">
          <span>{t('login.footer_message')}</span>
          <Link to={signup} className="form-footer__login-message--redirect">
            {t('login.footer_button_label')}
          </Link>
        </p>
      </div>
    </>
  );
};

export default FormFooter;
