import React, { Fragment, useRef, useState } from 'react';
import { t } from 'i18next';
import { useParams } from 'react-router-dom';
import { FieldArray } from 'react-final-form-arrays';

import useCustomNavigate from 'hooks/useCustomNavigate';
import {
  useUpdateContractDocumentsMutation,
  useGetContractQuery,
  useDeleteAttachmentMutation,
  useRenameContractDocumentMutation,
} from 'api/contracts';
import { useGetCurrentUserQuery } from 'api/users';
import { errorToastConfig } from 'utils/constants/ToastConfig';

import { Form, Field } from 'react-final-form';
import Icon from 'components/BaseComponents/Icon';
import Button from 'components/BaseComponents/Button';
import ChooseDocument from 'components/Shared/ChooseDocument';
import Toastify from 'components/Shared/ToastNotification/Toastify';
import arrayMutators from 'final-form-arrays';
import { generateContractDocumentsForm } from 'utils/helper/RequestBody';
import { hasAdminRole, hasNoAdminRole } from 'utils/helper/UserRoleValidation';
import { handleContractDocumentsServerValidationErrors } from 'utils/helper/Validations';
import './styles.scss';

const AttachedDocuments = () => {
  const toastRef = useRef();
  const navigate = useCustomNavigate();
  const [deleteAttachment] = useDeleteAttachmentMutation();
  const [updateContractDocuments] = useUpdateContractDocumentsMutation();
  const [renameContractDocument] = useRenameContractDocumentMutation();
  const { id } = useParams();
  const { data: user = {} } = useGetCurrentUserQuery();
  const { data: contractDetails = {} } = useGetContractQuery({ id });
  const { documents, draftContract } = contractDetails;
  const [currentDocuments, setCurrentDocuments] = useState(documents);

  const handleDocumentsFormSubmit = async (params) => {
    const requestBody = generateContractDocumentsForm(params);

    return updateContractDocuments({ contract_id: id, payload: requestBody })
      .unwrap()
      .then((updatedContract) => {
        setCurrentDocuments(updatedContract.documents);
        navigate.contractDetails(id);
      })
      .catch(({ data: { errors }, status }) => {
        handleContractDocumentsServerValidationErrors(toastRef, status, errors);
        return errors;
      });
  };

  const handleDocuments = (docs) => {
    handleDocumentsFormSubmit(docs);
  };

  const handleRename = (newFileName, fields, index) => {
    const doc = fields.value[index];
    const format = doc.filename.split('.').pop();

    return renameContractDocument({
      contract_id: id,
      new_name: `${newFileName}.${format}`,
      document_id: doc.id,
    })
      .unwrap()
      .then((updatedContract) => {
        setCurrentDocuments(updatedContract.documents);
        navigate.adminContractDetails(id);
      })
      .catch(({ data: { errors }, status }) => {
        handleContractDocumentsServerValidationErrors(toastRef, status, errors);
        return errors;
      });
  };

  const handleDelete = (fields, index) => {
    const attachment = fields.value[index];
    const attachmentId = attachment.id;

    fetch(attachment.url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.download = attachment.filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error fetching the file:', error);
      });

    deleteAttachment({ attachmentId });
  };

  return (
    <Form
      onSubmit={() => handleDocumentsFormSubmit}
      initialValues={currentDocuments ? { documents: currentDocuments } : { documents }}
      mutators={{
        ...arrayMutators,
        setFormAttribute: ([fieldName, fieldVal], state, { changeValue }) => {
          changeValue(state, fieldName, () => fieldVal);
        },
      }}
      render={({ handleSubmit, hasValidationErrors }) => (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <form
          // onKeyDown={(event) => preventSubmissionOnEnter(event)}
          onSubmit={() => {
            if (hasValidationErrors) {
              toastRef?.current?.notify(<Toastify type="error" />, errorToastConfig);
            }
          }}
        >
          <div className={`test-certificates ${hasAdminRole(user) ? '' : 'default-block'}`}>
            <div className="test-certificates__header">
              {t('admin.contract_details.attached_documents')}
            </div>
            <div className="margin-t-16px padding-b-16px test-certificates__form">
              <div className="flex">
                {hasNoAdminRole(user) && (
                  <ChooseDocument
                    userRole={t('admin.contract_details.admin_label')}
                    fileName={draftContract?.filename}
                    fileUploadedAt={draftContract?.createdAt}
                    fileUrl={draftContract?.url}
                    label={t('admin.contract_details.file_label')}
                    hint={t('profile.file.upload')}
                    isEditable={true}
                    disableDelete={true}
                    className="margin-b-16px"
                  />
                )}
              </div>
              <FieldArray name="documents">
                {({ fields }) => (
                  <Fragment>
                    {fields.map((name, index) => (
                      <Field key={name} name={`${name}`}>
                        {({ input, meta }) => (
                          <div className="flex">
                            <ChooseDocument
                              userRole={input.value?.userRole}
                              fileName={input.value?.filename}
                              fileUploadedAt={input.value?.createdAt}
                              fileUrl={input.value?.url}
                              label={t('admin.contract_details.file_label')}
                              handleDocuments={handleDocuments}
                              documentsFormSubmit={handleSubmit}
                              hint={t('profile.file.upload')}
                              onChange={(params) => {
                                input.onChange(params);
                              }}
                              onDelete={() => handleDelete(fields, index)}
                              handleClose={() => fields.remove(index)}
                              handleRename={(newFileName) =>
                                handleRename(newFileName, fields, index)
                              }
                              touched={meta?.touched}
                              isEditable={true}
                              errorMessage={meta?.error || meta?.submitError}
                              disableDelete={hasNoAdminRole(user)}
                              className="margin-b-16px"
                            />
                          </div>
                        )}
                      </Field>
                    ))}

                    {(contractDetails.status !== 'completed' || hasAdminRole(user)) && (
                      <Button
                        submitType="button"
                        className="flex items-center gap-8px padding-x-24px business-contacts__add-fields"
                        label={t('offer_creation.food_safety.add_another_document')}
                        icon={<Icon name="add" color="success" />}
                        onClick={() => {
                          fields.push({});
                        }}
                      />
                    )}
                  </Fragment>
                )}
              </FieldArray>
            </div>
          </div>
        </form>
      )}
    />
  );
};

export default AttachedDocuments;
