import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CountrySelect from 'components/BaseComponents/CountrySelect';

const CountryFilter = ({ className, title, country, error, onChange }) => (
  <div className={classNames('aggregate-data-filter__box', className)}>
    <h4 className="aggregate-data-filter__box--title margin-b-16px">{title}</h4>
    <CountrySelect country={country} error={error} onChange={onChange} />
  </div>
);

CountryFilter.defaultProps = {
  className: '',
  title: null,
  country: null,
  error: null,
  onChange: () => {},
};

CountryFilter.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  country: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
};

export default CountryFilter;
