import { t } from 'i18next';
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import IconButton from 'components/BaseComponents/IconButton';
import { getTitleHeader } from 'utils/constants/RequestAssistance';

import Icon from 'components/BaseComponents/Icon';

const RequestAssistanceTitle = ({ handleModalClose, hasRequestedAssistance }) => (
  <div className="request-assistance__modal--title">
    <div
      className={classNames('flex', {
        'items-center justify-between': hasRequestedAssistance,
        'items-start justify-between margin-b-8px': !hasRequestedAssistance,
      })}
    >
      <h4 className="additional-document__modal--title">
        {getTitleHeader(hasRequestedAssistance)}
      </h4>
      <IconButton
        icon={<Icon name="close" size="small" />}
        onClick={() => handleModalClose()}
        className={classNames({ 'margin-none': !hasRequestedAssistance })}
      />
    </div>
    {!hasRequestedAssistance && (
      <p className="margin-none request-assistance__modal--subtitle">
        {t('shared.bidding_process.contract_details.request_assistance.sub_title')}
      </p>
    )}
  </div>
);

RequestAssistanceTitle.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  hasRequestedAssistance: PropTypes.bool.isRequired,
};

export default RequestAssistanceTitle;
