import React, { Fragment, useState, useReducer } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { useGetMyListingsQuery, useGetCompanyListingsQuery } from 'api/listings';
import ContentLoaderPlaceholder from 'components/BaseComponents/ContentLoader/ContentLoader';
import { completedTab, inProgressTab, concludedTab } from 'utils/constants/portals';
import Pagination from 'components/BaseComponents/Pagination';
import {
  ADMIN_LISTINGS_PER_PAGE,
  ACCEPTED,
  CONCLUDED,
  IN_PROGRESS,
  HANDLE_MY_LISTINGS_SORT_CHANGE,
} from 'utils/constants/offerListings';
import { RTabs } from 'components/BaseComponents/RTabs';
import PortalsNavbar from 'components/NavBar/PortalsNavBar';
import { getContracts } from 'utils/helper/Portals';
import SortFilter from 'components/BiddingProcess/OfferListings/AggregateDataSort';
import filterReducer, { initialFilterState } from '../OfferListings/Container/filterReducer';

const Contracts = ({ isCompany }) => {
  const [productStatus, setProductStatus] = useState(completedTab);
  const [currentPage, setCurrentPage] = useState(1);

  const [filterState, dispatch] = useReducer(filterReducer, initialFilterState);
  const {
    sort: { label, column, direction },
  } = filterState;

  const selectedSort = { label, column, direction };

  const {
    data: completedContracts = {},
    isFetching: isFetchingCompletedContracts,
    isSuccess: completedContractsFetched,
    isLoading: completedContractsLoading,
  } = isCompany ? useGetCompanyListingsQuery({
    column,
    direction,
    currentPage,
    productStatus: CONCLUDED,
    perPage: ADMIN_LISTINGS_PER_PAGE,
  }) : useGetMyListingsQuery({
    column,
    direction,
    currentPage,
    productStatus: CONCLUDED,
    perPage: ADMIN_LISTINGS_PER_PAGE,
  });
  const { collection: completedContractList = [] } = completedContracts;

  const {
    data: concludedContracts = {},
    isFetching: isFetchingConcludedContracts,
    isSuccess: concludedContractsFetched,
    isLoading: concludedContractsLoading,
  } = isCompany ? useGetCompanyListingsQuery({
    column,
    direction,
    currentPage,
    productStatus: ACCEPTED,
    perPage: ADMIN_LISTINGS_PER_PAGE,
  }) : useGetMyListingsQuery({
    column,
    direction,
    currentPage,
    productStatus: ACCEPTED,
    perPage: ADMIN_LISTINGS_PER_PAGE,
  });
  const { collection: concludedContractList = [] } = concludedContracts;

  const {
    data: inProgressContracts = {},
    isFetching: isFetchingInProgressContracts,
    isSuccess: inProgressContractsFetched,
    isLoading: inProgressContractsLoading,
  } = isCompany ? useGetCompanyListingsQuery({
    column,
    direction,
    currentPage,
    productStatus: IN_PROGRESS,
    perPage: ADMIN_LISTINGS_PER_PAGE,
  }) : useGetMyListingsQuery({
    column,
    direction,
    currentPage,
    productStatus: IN_PROGRESS,
    perPage: ADMIN_LISTINGS_PER_PAGE,
  });
  const { collection: inProgressContractList = [] } = inProgressContracts;

  const handleTabChange = (tabKey) => {
    setProductStatus(tabKey);
    setCurrentPage(1);
  };

  const handlePagination = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const title = isCompany ? t(`navbar.default.links.company.contracts`) : t(`navbar.default.links.contracts`);

  let totalPages;
  let listingType;
  if (productStatus === completedTab && !isFetchingCompletedContracts) {
    totalPages = Number(completedContracts?.pagination?.totalPages);
    listingType = 'completed_contracts';
  }

  if (productStatus === concludedTab && !isFetchingConcludedContracts) {
    totalPages = Number(concludedContracts?.pagination?.totalPages);
    listingType = 'concluded_contracts';
  }

  if (productStatus === inProgressTab && !isFetchingInProgressContracts) {
    totalPages = Number(inProgressContracts?.pagination?.totalPages);
    listingType = 'in_progress_contracts';
  }

  const completedContractCount = completedContracts?.pagination?.totalCount;
  const inProgressContractCount = inProgressContracts?.pagination?.totalCount;
  const concludedContractCount = concludedContracts?.pagination?.totalCount;

  const offerTabs = getContracts({
    completedContractCount,
    inProgressContractCount,
    concludedContractCount,
    completedContractList,
    concludedContractList,
    inProgressContractList,
    completedContractsLoading,
    concludedContractsLoading,
    inProgressContractsLoading,
    productStatus,
  });

  return (
    <Fragment>
      <PortalsNavbar />
      <div className="listings-wrapper">
        <div className="listings">
          <h1>{title}</h1>
          <SortFilter
            listingType={listingType}
            showText={false}
            selectedSort={selectedSort}
            onSort={(value) => {
              dispatch({ type: HANDLE_MY_LISTINGS_SORT_CHANGE, payload: value });
            }}
          />
          <RTabs
            allowRemove={false}
            items={offerTabs}
            selectedTabKey={productStatus}
            controlled={true}
            onChange={handleTabChange}
          />

          {!completedContractsFetched ||
            ((!concludedContractsFetched || !inProgressContractsFetched) && (
              <ContentLoaderPlaceholder numberOfLines={5} />
            ))}

          {totalPages > 1 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPaginationClick={handlePagination}
              firstText={t('admin_user_table.first_page')}
              lastText={t('admin_user_table.last_page')}
              nextText={t('admin_user_table.next_page')}
              previousText={t('admin_user_table.previous_page')}
              className="admin-portal__table--pagination"
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};

Contracts.defaultProps = {
  isCompany: false,
};

Contracts.propTypes = {
  isCompany: PropTypes.bool,
};

export default Contracts;
