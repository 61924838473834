import { apiSlice } from 'api';
import routes from 'utils/routes';
import { ALL_USER, USER, VERIFICATION_STATUS } from 'utils/tagTypes';

const adminApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    requestAdditionalDoc: builder.mutation({
      query: ({ userId, payload }) => ({
        url: routes.admin.requestAdditionalDoc({ userId }),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [VERIFICATION_STATUS],
    }),
    verifyUser: builder.mutation({
      query: ({ userId }) => ({
        url: routes.admin.verifyUser({ userId }),
        method: 'PATCH',
      }),
      invalidatesTags: [VERIFICATION_STATUS, ALL_USER],
    }),
    rejectUser: builder.mutation({
      query: ({ userId }) => ({
        url: routes.admin.rejectUser({ userId }),
        method: 'DELETE',
      }),
      invalidatesTags: [VERIFICATION_STATUS, ALL_USER],
    }),
    revokeUser: builder.mutation({
      query: ({ userId }) => ({
        url: routes.admin.revokeUser({ userId }),
        method: 'PATCH',
      }),
      invalidatesTags: [VERIFICATION_STATUS, ALL_USER],
    }),
    getProducts: builder.query({
      query: ({ currentPage, productListingType, productProductType }) => ({
        url: routes.admin.getProducts({ currentPage, productListingType, productProductType }),
        method: 'GET',
      }),
    }),
    uploadCSV: builder.mutation({
      query: ({ userId, payload }) => ({
        url: routes.admin.uploadCSV({ userId }),
        method: 'POST',
        body: payload,
      }),
    }),
    updateRoles: builder.mutation({
      query: ({ userId, payload }) => ({
        url: routes.admin.updateRoles({ userId }),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [USER, ALL_USER],
    }),
  }),
});

export const {
  useRequestAdditionalDocMutation,
  useVerifyUserMutation,
  useRejectUserMutation,
  useRevokeUserMutation,
  useGetProductsQuery,
  useUploadCSVMutation,
  useUpdateRolesMutation,
} = adminApi;
