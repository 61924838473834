import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { OWNER } from 'utils/constants/userRole';
import Icon from 'components/BaseComponents/Icon';
import { profile } from 'utils/constants/Routes';
import './styles.scss';

const PendingVerification = ({ className, roles }) => {
  const { t } = useTranslation();
  const role = roles.filter((el) => el !== OWNER)[0];

  return (
    <div
      className={classNames('pending-verification flex justify-between items-center', className)}
    >
      <div className="flex items-center gap-14px">
        <Icon name="neutralOutline" className="pending-verification__warning-icon" />
        <div>
          <p className="pending-verification__heading">
            {t('shared.bidding_process.pending_verification.heading')}
          </p>
          <p className="pending-verification__description">
            {t(`shared.bidding_process.pending_verification.${role}_description`)}
          </p>
        </div>
      </div>
      <Link to={profile} className="pending-verification__redirect-button">
        {t('shared.bidding_process.pending_verification.profile_update')}
      </Link>
    </div>
  );
};

PendingVerification.defaultProps = {
  className: null,
  roles: [],
};

PendingVerification.propTypes = {
  className: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.string),
};

export default PendingVerification;
