/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-return-assign */
import React, { Component } from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';
import Tooltip from 'components/BaseComponents/Tooltip';

export default class Tab extends Component {
  shouldComponentUpdate(nextProps) {
    const { children, selected, classNames } = this.props;

    return (
      children !== nextProps.children ||
      selected !== nextProps.selected ||
      classNames !== nextProps.classNames
    );
  }

  onTabClick = () => {
    const { onClick, originalKey } = this.props;
    onClick(originalKey);
  };

  renderRemovableTab = () => {
    const { children, onRemove } = this.props;
    return (
      <div className="rtabs__removable">
        <div className="rtabs__removable-text">{children}</div>
        <div className="rtabs__removable-icon" onClick={onRemove}>
          x
        </div>
      </div>
    );
  };

  renderTab = () => {
    const { children, allowRemove } = this.props;

    if (allowRemove) {
      return this.renderRemovableTab();
    }

    return children;
  };

  render() {
    const {
      id,
      classNames,
      selected,
      disabled,
      disabledHintTitle,
      disabledHintContent,
      panelId,
      onFocus,
      onBlur,
      originalKey,
      transform,
    } = this.props;

    return (
      <div className="rtabs-tab-container">
        {disabled ? (
          <div ref={(e) => (this.tab = e)} className={classNames}>
            {disabledHintTitle || disabledHintContent ? (
              <Tooltip
                title={disabledHintTitle}
                position="top"
                type="dark"
                content={disabledHintContent}
                showArrow={false}
              >
                {this.renderTab()}
              </Tooltip>
            ) : (
              this.renderTab()
            )}
          </div>
        ) : (
          <div
            ref={(e) => (this.tab = e)}
            role="tab"
            className={classNames}
            id={id}
            aria-selected={selected ? 'true' : 'false'}
            aria-expanded={selected ? 'true' : 'false'}
            aria-disabled={disabled ? 'true' : 'false'}
            aria-controls={panelId}
            tabIndex="0"
            onClick={this.onTabClick}
            onFocus={onFocus(originalKey)}
            onBlur={onBlur}
          >
            {this.renderTab()}
            {transform && (
              <KeyboardArrowDown
                className={cs({ 'keyboard-arrow-down': !selected, 'keyboard-arrow-up': selected })}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

Tab.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  disabled: PropTypes.bool,
  disabledHintTitle: PropTypes.string,
  disabledHintContent: PropTypes.string,
  transform: PropTypes.bool,
  panelId: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  allowRemove: PropTypes.bool,
  id: PropTypes.string.isRequired,
  originalKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  classNames: PropTypes.string.isRequired,
};

Tab.defaultProps = {
  children: undefined,
  onRemove: () => {},
  allowRemove: false,
  disabled: false,
  disabledHintTitle: '',
  disabledHintContent: '',
  transform: false,
};
