import React from 'react';
import { t } from 'i18next';
import './styles.scss';

const Divider = () => (
  <div className="divider">
    <div className="divider-line" />
    <div className="divider-text">{t('bidding_process.or')}</div>
    <div className="divider-line" />
  </div>
);

export default Divider;
