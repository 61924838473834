import React, { Fragment } from 'react';
import { t } from 'i18next';
import Tooltip from 'components/BaseComponents/Tooltip';
import Icon from 'components/BaseComponents/Icon';

export const labelWithHints = (label, message) => (
  <div className="flex items-center gap-4px">
    <span>{label}</span>
    <Tooltip
      content={message}
      showArrow={true}
      type="midnight-blue"
      size="tiny"
      className="user__action--tooltip"
    >
      <Icon name="help" color="default" />
    </Tooltip>
  </div>
);

export const requiredSectionHeading = (label) => (
  <h5 className="business-details__section-header">
    {label}
    <span className="business-details__section-header--required">*</span>
  </h5>
);

export const optionalSectionHeading = (label) => (
  <h5 className="business-details__section-header">
    {t('shared.heading_optional', { headingLabel: label })}
  </h5>
);

export const requiredText = (label) => (
  <Fragment>
    {label}
    <span className="business-details__section-header--required">*</span>
  </Fragment>
);
