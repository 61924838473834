import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { Field, Form } from 'react-final-form';
import { requiredField } from 'utils/helper/Validations';
import Textarea from 'components/BaseComponents/Textarea';
import Button from 'components/BaseComponents/Button';

const ContactSupportModalForm = ({ handleFormSubmit }) => (
  <Fragment>
    <h4 className="additional-document__modal--title">
      {t('offer_creation.declaration.contact_support_modal.form_title')}
    </h4>
    <Form
      onSubmit={handleFormSubmit}
      render={({ handleSubmit, submitting, hasValidationErrors }) => (
        <form onSubmit={handleSubmit}>
          <Field name="declaration_contact_admin" validate={requiredField}>
            {({ input, meta }) => (
              <Textarea
                className="additional-document__textarea"
                label={t('offer_creation.declaration.contact_support_modal.form_label')}
                placeholder={t('offer_creation.declaration.contact_support_modal.form_placeholder')}
                size="small"
                touched={meta.touched}
                value={input.value}
                onChange={input.onChange}
                error={meta?.error || meta?.submitError}
              />
            )}
          </Field>
          <Button
            type="success"
            label={t('offer_creation.declaration.contact_support_modal.submit_button_label')}
            submitType="submit"
            disabled={submitting || hasValidationErrors}
          />
        </form>
      )}
    />
  </Fragment>
);

ContactSupportModalForm.propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
};

export default ContactSupportModalForm;
