import { useNavigate } from 'react-router-dom';

const useCustomNavigate = () => {
  const navigate = useNavigate();
  const openRootPage = () => navigate('/');
  const registrationSuccessPage = (verificationEmail) =>
    navigate('/registration-success', { state: { verificationEmail } });
  const signupPage = () => navigate('/signup');
  const loginPage = () => navigate('/login');
  const forgotPassword = () => navigate('/forgot-password');
  const resetPasswordMailSentPage = (email) =>
    navigate('/reset-password-check-email', { state: email });
  const resetPasswordSuccess = (token) => navigate('/reset-password-success', { state: token });
  const profile = () => navigate('/profile');
  const listings = () => navigate(`/listings`);
  const createProduct = () => navigate(`/create-listing/product-details`);
  const updateProduct = (productId) =>
    navigate(`/create-listing/${productId}/product-details`);
  const reviewListing = () => navigate(`/create-listing/review-listing`);
  const reviewListingById = (listingId) =>
    navigate(`/create-listing/${listingId}/review-listing`);
  const foodSafety = (productId) => navigate(`/create-listing/${productId}/food-safety`);
  const declaration = (productId) =>
    navigate(`/create-listing/${productId}/declaration`);
  const adminProfile = () => navigate('/admin/users');
  const adminListingManagement = () => navigate('/admin/listing');
  const adminAffiliatePartnersManagement = () => navigate('/admin/partners');
  const affiliatePartnerPortal = () => navigate('/partner/portal');
  const affiliatePartnerRegistrationSuccess = (verificationEmail) =>
    navigate('/partner-registration-success', { state: { verificationEmail } });
  const affiliatePartnerInviteBuyer = () => navigate('/partner/invite-buyer');
  const affiliatePartnerStaticPage = () => navigate('/affiliate-partner');
  const buyerInvitationSuccessPage = (verificationEmail) =>
    navigate('/invitation-success', { state: { verificationEmail } });
  const unauthorizedPage = () => navigate('/unauthorized');
  const adminOfferOrderDetails = (id) => navigate(`/admin/listing/${id}`);
  const adminContractDetails = (contractId) => navigate(`/admin/contracts/${contractId}`);
  const contractDetails = (contractId) => navigate(`/contracts/${contractId}`);
  const myOffers = () => navigate('/offers');
  const myOrders = () => navigate('/orders');
  const bids = () => navigate('/bids')

  return {
    openRootPage,
    registrationSuccessPage,
    signupPage,
    loginPage,
    forgotPassword,
    resetPasswordMailSentPage,
    resetPasswordSuccess,
    profile,
    listings,
    createProduct,
    updateProduct,
    reviewListing,
    reviewListingById,
    foodSafety,
    declaration,
    adminProfile,
    adminListingManagement,
    adminAffiliatePartnersManagement,
    affiliatePartnerRegistrationSuccess,
    affiliatePartnerPortal,
    affiliatePartnerInviteBuyer,
    affiliatePartnerStaticPage,
    buyerInvitationSuccessPage,
    unauthorizedPage,
    adminOfferOrderDetails,
    adminContractDetails,
    contractDetails,
    myOffers,
    myOrders,
    bids
  };
};

export default useCustomNavigate;
