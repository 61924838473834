import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Field, Form, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useGetContractQuery } from 'api/contracts';
import { getFormTitle } from 'utils/constants/RequestAssistance';
import { getFormCheckboxInformation } from 'utils/constants/Contract';
import CheckBox from 'components/BaseComponents/CheckBox';
import RequestAssistanceTitle from './RequestAssistanceTitle';
import RequestAssistanceFormFooter from './RequestAssistanceFormFooter';
import { RequestSentModal } from './RequestSentModal';
import './styles.scss';

const RequestAssistanceModalBody = ({
  handleModalClose,
  onContactModalOpen,
  handleRequestAssistanceSubmit,
  isRequestSent,
  isSuccess,
}) => {
  const { id: contractId } = useParams();
  const [isAnyCheckBoxChecked, setIsAnyCheckboxChecked] = useState(false);
  const { data: contractDetails = {} } = useGetContractQuery({
    id: contractId,
  });
  const { requestAssistances = [] } = contractDetails;
  const requestAssistanceSubmitted = isRequestSent && isSuccess;
  const hasRequestedAssistance = !!requestAssistances.length;

  return (
    <div>
      {requestAssistanceSubmitted ? (
        <RequestSentModal />
      ) : (
        <div className="request-assistance__modal">
          <RequestAssistanceTitle
            handleModalClose={handleModalClose}
            hasRequestedAssistance={hasRequestedAssistance}
          />
          <Form
            initialValues={requestAssistances[0]}
            onSubmit={handleRequestAssistanceSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <FormSpy subscription={{ values: true }}>
                  {({ values }) => {
                    const anyCheckboxChecked = Object.values(values || {}).some(Boolean);
                    setIsAnyCheckboxChecked(anyCheckboxChecked);
                    return null;
                  }}
                </FormSpy>
                <hr className="signed-document__modal--divider" />
                <div className="request-assistance__modal--form-container ">
                  <p
                    className={classNames('margin-b-8px', {
                      'margin-none margin-b-8px': !hasRequestedAssistance,
                      'request-assistance--form-container-text margin-b-8px margin-t-0':
                        hasRequestedAssistance,
                    })}
                  >
                    {getFormTitle(hasRequestedAssistance)}
                  </p>
                  {getFormCheckboxInformation.map((item) => (
                    <Field name={item.id} type="checkbox">
                      {({ input, meta }) => (
                        <CheckBox
                          id={item.id}
                          label={item.label}
                          className={item.className}
                          hintText={meta.touched && meta.error}
                          isChecked={input.checked}
                          onChange={input.onChange}
                        />
                      )}
                    </Field>
                  ))}
                </div>
                <hr className="signed-document__modal--divider" />
                <RequestAssistanceFormFooter
                  handleModalClose={handleModalClose}
                  isAnyCheckBoxChecked={isAnyCheckBoxChecked}
                  onContactModalOpen={onContactModalOpen}
                />
              </form>
            )}
          />
        </div>
      )}
    </div>
  );
};

RequestAssistanceModalBody.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  onContactModalOpen: PropTypes.func.isRequired,
  handleRequestAssistanceSubmit: PropTypes.func.isRequired,
  isRequestSent: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
};

export default RequestAssistanceModalBody;
