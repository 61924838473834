import React from 'react';
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';
import breadcrumbRoutes from 'utils/breadcrumb-helper/route-names';
import AdminNavBar from 'components/NavBar/AdminNavBar';
import ListingManagementTable from './ListingManagementTable';
import ExportButton from './ExportAllDataButton';
import 'styles/utils.scss';

const ProductListing = () => {
  const breadcrumbs = useReactRouterBreadcrumbs(breadcrumbRoutes);

  return (
    <AdminNavBar breadcrumbs={breadcrumbs}>
      <section className="padding-l-45px padding-b-48px">
        <ExportButton />
        <ListingManagementTable />
      </section>
    </AdminNavBar>
  );
};

export default ProductListing;
