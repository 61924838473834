import React, { Fragment } from 'react';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import { affiliateTermsAndConditionPDF } from 'utils/constants/pdf';

const TermsSection = () => (
  <Fragment>
    <span className="sign-up-form__policy-content--content1">
      {t('affiliate_partners.terms_body.content1')}
    </span>
    <Link
      to={affiliateTermsAndConditionPDF}
      target="_blank"
      className="sign-up-form__policy-content--redirect-link"
    >
      {t('affiliate_partners.terms_body.redirect_link')}
    </Link>
  </Fragment>
);

export default TermsSection;
