import {
  appliedTab,
  allUsersTab,
  approvedTab,
  documentRequestedTab,
  unapprovedTab,
} from './portals';

export const VERIFIED = 'verified';
export const UNVERIFIED = 'unverified';
export const REQUESTED_VERIFICATION = 'requested_verification';
export const DOCUMENT_REQUESTED = 'document_requested';
export const INVITED = 'invited';

export const USER_GROUP_BUYERS_SELLERS = 'buyers_and_sellers';
export const USER_GROUP_AFFILIATE_PARTNERS = 'affiliate_partners';

export const tabFilterStatus = {
  [allUsersTab]: '',
  [appliedTab]: REQUESTED_VERIFICATION,
  [approvedTab]: VERIFIED,
  [documentRequestedTab]: DOCUMENT_REQUESTED,
  [unapprovedTab]: UNVERIFIED,
};
