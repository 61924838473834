import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { landingPage } from 'utils/constants/Routes';
import Button from 'components/BaseComponents/Button';
import FormFooter from 'components/ResetPassword/Forms/FormFooter';
import Icon from 'components/BaseComponents/Icon';
import '../Forms/styles.scss';

const ResetPasswordSuccess = () => {
  const { t } = useTranslation();
  const { state: queryParams } = useLocation();
  const navigate = useCustomNavigate();

  useEffect(() => {
    if (!queryParams) {
      navigate.forgotPassword();
    }
  }, []);

  return (
    <div className="reset-password-form__container">
      <div className="reset-password-form__container--header">
        <Icon name="checkCircleIcon" size="xl" color="none" className="reset-form-icon" />

        <div className="reset-password-form__container--header-texts">
          <h2 className="reset-password-form__container--header-texts-title">
            {t('reset_password_success.title')}
          </h2>

          <p className="reset-password-form__container--header-texts-message">
            {t('reset_password_success.welcome_message')}
          </p>
        </div>
      </div>

      <div className="reset-password-form__container--form">
        <Button
          className="reset-password-form__container--submit-btn"
          type="success"
          label={t('reset_password_success.submit_button_label')}
          onClick={() => window.open(landingPage, '_blank')}
        />
      </div>

      <FormFooter />
    </div>
  );
};

export default ResetPasswordSuccess;
