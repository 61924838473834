import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { login } from 'utils/constants/Routes';
import './style.scss';

const FormFooter = () => {
  const { t } = useTranslation();

  return (
    <div className="form-footer">
      <div className="form-footer__content-divider flex items-center">
        <div className="form-footer__content-divider--box" />
        <div className="form-footer__content-divider--text">{t('signup.footer.divider')}</div>
        <div className="form-footer__content-divider--box" />
      </div>
      <p className="form-footer__login-message">
        {t('signup.footer.existing_account')}
        <Link to={login} className="form-footer__login-message--redirect">
          {t('signup.footer.redirect_message')}
        </Link>
      </p>
    </div>
  );
};

export default FormFooter;
