import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const DetailsInformationItem = ({ label, data }) => (
  <div className="details-information-card__body-item">
    <div className="details-information-card__body-item--label">{label}</div>
    <div className="details-information-card__body-item--separator">:</div>
    <div className="details-information-card__body-item--data">{data}</div>
  </div>
);

DetailsInformationItem.propTypes = {
  label: PropTypes.string,
  data: PropTypes.string,
};

DetailsInformationItem.defaultProps = {
  label: '',
  data: '',
};

const DetailsInformationCard = ({ detailsData, cardHeader }) => (
  <div className="details-information-card">
    <div className="details-information-card__header">{cardHeader}</div>
    <div className="details-information-card__body">
      {detailsData.map((item) => (
        item.data && (
          <DetailsInformationItem key={item.label} label={item.label} data={item.data} />
        )
      ))}
    </div>
  </div>
);

DetailsInformationCard.propTypes = {
  detailsData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      data: PropTypes.string,
    }),
  ),
  cardHeader: PropTypes.string.isRequired,
};

DetailsInformationCard.defaultProps = {
  detailsData: [],
};

export default DetailsInformationCard;
