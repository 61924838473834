import React from 'react';
import i18n from 'i18n';
import { ALLOWED_FILE_EXTENSIONS_DOCUMENT, MAX_FILE_SIZE_DOCUMENT } from 'utils/constants/file';
import { errorToastConfig } from 'utils/constants/ToastConfig';
import Toastify from 'components/Shared/ToastNotification/Toastify';
import { toSnakecaseKeys } from './ObjectKeyConverter';

const requiredField = (value) => {
  if (!value) {
    return i18n.t('shared.form_validation.required_field');
  }
  return null;
};

const requiredCheckbox = (value) => {
  if (!value) {
    return i18n.t('shared.form_validation.required_checkbox');
  }
  return null;
};

const validateEmail = (email) => {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  if (!emailRegex.test(email)) {
    return i18n.t('shared.form_validation.invalid_email');
  }
  return null;
};

const validateOptionalEmail = (email) => (
  email ? (validateEmail(email)) : null
);

export const isFileEmpty = (value) => !value;

export const isFileFormatAllowed = (file, allowedFileExtensions) => {
  const fileExtension = file?.name?.split('.').pop().toLowerCase();

  return allowedFileExtensions.includes(`.${fileExtension}`);
};

export const isFileSizeWithinLimit = (file, maxSize) => !(file?.size > maxSize);

const validatePortalDocuments = (file) => {
  const requiredError = isFileEmpty(file);
  const fileFormatError = !isFileFormatAllowed(file, ALLOWED_FILE_EXTENSIONS_DOCUMENT);
  const fileSizeError = !isFileSizeWithinLimit(file, MAX_FILE_SIZE_DOCUMENT);

  if (requiredError) {
    return i18n.t('shared.form_validation.required_file');
  }

  if (!(file instanceof File)) {
    return undefined;
  }

  if (fileFormatError) {
    return i18n.t('shared.form_validation.file_format_document');
  }

  if (fileSizeError) {
    return i18n.t('shared.form_validation.file_size_document');
  }

  return null;
};

const validateOptionalPortalDocuments = (file) => {
  if (!(file instanceof File)) {
    return null;
  }

  const fileFormatError = !isFileFormatAllowed(file, ALLOWED_FILE_EXTENSIONS_DOCUMENT);
  const fileSizeError = !isFileSizeWithinLimit(file, MAX_FILE_SIZE_DOCUMENT);

  if (fileFormatError) {
    return i18n.t('shared.form_validation.file_format_document');
  }

  if (fileSizeError) {
    return i18n.t('shared.form_validation.file_size_document');
  }

  return null;
};

const validateTestCertificates = (value, isOptional) => {
  if (isOptional) {
    return validateOptionalPortalDocuments(value);
  }

  return validatePortalDocuments(value);
};

const handleServerValidationErrors = (
  toastRef,
  errorStatus,
  errorMessage,
  toastConfig = errorToastConfig,
) => {
  if (errorStatus === 422) {
    // eslint-disable-next-line react/jsx-filename-extension
    toastRef?.current?.notify(<Toastify type="error" />, toastConfig);

    return toSnakecaseKeys(errorMessage);
  }

  return null;
};

const handleFoodSafetyServerValidationErrors = (
  toastRef,
  errorStatus,
  errorMessage,
  toastConfig = errorToastConfig,
) => {
  if (errorStatus === 422) {
    // eslint-disable-next-line react/jsx-filename-extension
    toastRef?.current?.notify(
      <Toastify
        type="error"
        errorMessage={i18n.t('offer_creation.food_safety.server_error_message')}
      />,
      toastConfig,
    );

    return toSnakecaseKeys(errorMessage);
  }

  return null;
};

const handleContractDocumentsServerValidationErrors = (
  toastRef,
  errorStatus,
  errorMessage,
  toastConfig = errorToastConfig,
) => {
  if (errorStatus === 422) {
    // eslint-disable-next-line react/jsx-filename-extension
    toastRef?.current?.notify(
      <Toastify
        type="error"
        errorMessage={i18n.t('admin.contract_details.server_error_message')}
      />,
      toastConfig,
    );

    return toSnakecaseKeys(errorMessage);
  }

  return null;
};

const handlePasswordValidationErrors = (
  toastRef,
  errorStatus,
  errorMessage,
  toastConfig = errorToastConfig,
) => {
  if (errorStatus === 422) {
    toastRef?.current?.notify(<Toastify type="error" />, toastConfig);

    return { password: errorMessage };
  }

  return null;
};

export {
  requiredField,
  requiredCheckbox,
  validatePortalDocuments,
  validateOptionalPortalDocuments,
  validateTestCertificates,
  handleServerValidationErrors,
  handleFoodSafetyServerValidationErrors,
  validateEmail,
  validateOptionalEmail,
  handlePasswordValidationErrors,
  handleContractDocumentsServerValidationErrors,
};
