import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ProgressBar = (props) => {
  const { bgColor, classNameForProgressBar, completed, hintText, size, showText, completedText } =
    props;

  const resetCompletion = () => {
    if (completed < 0) {
      return 0;
    }
    if (completed > 100) {
      return 100;
    }
    return completed;
  };

  const fillerStyles = {
    height: '100%',
    width: `${resetCompletion()}%`,
    backgroundColor: bgColor,
    borderRadius: 'inherit',
  };

  return (
    <>
      <>
        <div
          className={classNames('progress-bar', `progress-bar--${size}`, classNameForProgressBar)}
        >
          <div style={fillerStyles} />
        </div>
      </>
      <div>
        {showText && (
          <div className="progress-bar__label-container">
            <span className="progress-bar__complete-label">
              <span className="progress-bar__complete-label-text">{completedText}</span>
              <span>{` ${resetCompletion()}%`}</span>
            </span>
            {hintText && <span className="progress-bar__hint-label">{hintText}</span>}
          </div>
        )}
      </div>
    </>
  );
};

ProgressBar.defaultProps = {
  classNameForProgressBar: null,
  hintText: null,
  size: 'small',
  completed: 0,
  bgColor: '#000000',
  showText: false,
  completedText: 'completed',
};

ProgressBar.propTypes = {
  classNameForProgressBar: PropTypes.string,
  hintText: PropTypes.node,
  size: PropTypes.oneOf(['large', 'small', 'medium']),
  bgColor: PropTypes.string,
  completed: PropTypes.number,
  showText: PropTypes.bool,
  completedText: PropTypes.node,
};

export default ProgressBar;
