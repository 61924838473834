import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetProductDetailsQuery } from 'api/products';
import { useContactAdminMutation } from 'api/listings';
import ContactSupportModalForm from './ModalForm';
import ContactSupportModalSuccess from './ModalSuccess';
import './styles.scss';

const ContactSupportModal = () => {
  const [isMailSent, setIsMailSent] = useState(false);
  const { productId } = useParams();
  const { data: productDetails = {} } = useGetProductDetailsQuery(
    { product_id: productId },
    { skip: !productId },
  );
  const listingId = productDetails?.listingId;
  const [contactAdmin] = useContactAdminMutation();

  const handleContactAdmin = async (params) => {
    const requestBody = {
      body_text: params.declaration_contact_admin,
      action_type: 'listing',
    };

    return contactAdmin({ listing_id: listingId, payload: requestBody })
      .unwrap()
      .then(() => {
        setIsMailSent(true);
      });
  };

  return (
    <div className="additional-document__modal">
      {isMailSent ? (
        <ContactSupportModalSuccess />
      ) : (
        <ContactSupportModalForm handleFormSubmit={handleContactAdmin} />
      )}
    </div>
  );
};

export default ContactSupportModal;
