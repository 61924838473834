import {
  USER_ABILITY_SUBJECT,
  USER_ROLES_FORM_SUBJECT,
  PERMISSIONS_TAB_SUBJECT,
  PERSONAL_DETAILS_TAB_SUBJECT
} from "ability";
import { BUYER, OWNER, SELLER } from "utils/constants/userRole";
import { hasAnyRole } from "utils/helper/UserRoleValidation";

// ability to manage company member profile as company owner
const defineProfileTabsAbility = ({ can, userDetails }) => {
  can('reject', USER_ABILITY_SUBJECT, { roles: { $nin: [OWNER] } });

  can('view', PERSONAL_DETAILS_TAB_SUBJECT);
  can('view', USER_ROLES_FORM_SUBJECT);

  if (hasAnyRole(userDetails, [SELLER, BUYER])) {
    can('view', PERMISSIONS_TAB_SUBJECT);
  }

  return null;
};

export default defineProfileTabsAbility;
