import React from 'react';
import Button from 'components/BaseComponents/Button';
import i18n from '../../../../../i18n';

const ExportButton = () => {
  const handleClick = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/export/all_tables`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Accept-Version': 'v1',
            'X-LOCALE': i18n.language,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error, status = ${response.status}`);
        }

        const blob = await response.blob();

        // Generate a URL for the Blob and trigger the download
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `all_data-${new Date().toISOString().slice(0,10)}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        // Revoke the object URL to free memory
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error:', error);
      }
  };

  return (
    <Button
      label='Export All Tables as XLSX'
      type="success"
      onClick={handleClick}
    />
  );
};

export default ExportButton;
