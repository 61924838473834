import { useGetListingDetailsQuery } from 'api/listings';

const useIsListingUnpublished = ({ listingId }) => {
  const { data: listingDetails = {} } = useGetListingDetailsQuery(
    {
      listing_id: listingId,
    },
    { skip: !listingId },
  );

  return listingDetails?.status === 'unpublished';
};

export default useIsListingUnpublished;
