import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  HANDLE_BEST_BEFORE_CHANGE,
  HANDLE_SHIPPING_START_DATE_CHANGE,
  HANDLE_SHIPPING_END_DATE_CHANGE,
  HANDLE_DNIS_CERTIFICATION_GLOBAL_GAP_CHANGE,
  HANDLE_DNIS_CERTIFICATION_PPECB_CHANGE,
  HANDLE_DNIS_CERTIFICATION_ESG_CHANGE,
  HANDLE_FCL_CONTAINER_LOAD_CHANGE,
  HANDLE_LCL_CONTAINER_LOAD_CHANGE,
  HANDLE_COUNTRY_CHANGE,
  HANDLE_HARVEST_SEASON_CHANGE,
  HANDLE_LISTING_DATE_CHANGE,
  HANDLE_LISTING_TYPE_CHANGE,
  HANDLE_PRICE_CHANGE,
  HANDLE_QUANTITY_CHANGE,
  HANDLE_REGION_CHANGE,
  HANDLE_SOUND_KERNEL_RECOVERY_CHANGE,
  HANDLE_UNSOUND_KERNEL_RECOVERY_CHANGE,
  HANDLE_TREE_VARIANT_BEAUMONT_CHANGE,
  HANDLE_TREE_VARIANT_HYBRID_CHANGE,
  HANDLE_TREE_VARIANT_INTEGRIFOLIA_CHANGE,
  HANDLE_TREE_VARIANT_OTHER_CHANGE,
  HANDLE_PREMIUM_NUT_GRADE_CHANGE,
  HANDLE_COMMERCIAL_NUT_GRADE_CHANGE,
  HANDLE_REJECTED_NUT_GRADE_CHANGE,
  HANDLE_DAP_PAYMENT_OPTION_CHANGE,
  HANDLE_DAYS_30_PAYMENT_OPTION_CHANGE,
  HANDLE_DAYS_60_PAYMENT_OPTION_CHANGE,
  HANDLE_DAYS_90_PAYMENT_OPTION_CHANGE,
  HANDLE_DAYS_180_PAYMENT_OPTION_CHANGE,
  HANDLE_CUSTOM_PAYMENT_OPTION_CHANGE,
  HANDLE_RESET_CLICK,
  HANDLE_MY_LISTINGS_CHANGE,
  HANDLE_ORGANIC_CHANGE,
  HANDLE_NUT_GRADE_RESET_CLICK,
  HANDLE_DNIS_CERTIFICATIONS_RESET_CLICK,
  HANDLE_PAYMENT_OPTION_RESET_CLICK,
  HANDLE_TREE_VARIANT_RESET_CLICK,
  HANDLE_NUT_SIZE_RESET_CLICK,
  HANDLE_NUT_SIZE_LESS_THAN_18_CHANGE,
  HANDLE_NUT_SIZE_18_20_CHANGE,
  HANDLE_NUT_SIZE_20_22_CHANGE,
  HANDLE_NUT_SIZE_22_25_CHANGE,
  HANDLE_NUT_SIZE_25_PLUS_CHANGE,
  HANDLE_NUT_SIZE_22_PLUS_CHANGE,
  HANDLE_NUT_SIZE_20_PLUS_CHANGE,
  HANDLE_NUT_SIZE_18_PLUS_CHANGE,
  HANDLE_OTHER_NUT_SIZE_CHANGE,
  listingDateRangeOptions,
  listingTypeOptions,
  soundKernelRecoveryOptions,
  unsoundKernelRecoveryOptions,
} from 'utils/constants/offerListings';

import {
  regions,
  seasons,
  PPECB,
  GLOBAL_GAP,
  ESG,
  DRIED_NUT_IN_SHELL_PRODUCT,
  BEAUMONT,
  HYBRID,
  INTEGRIFOLIA,
  OTHER_TREE_VARIANT,
  PREMIUM,
  COMMERCIAL,
  REJECTED,
  DAP,
  DAYS_30,
  DAYS_60,
  DAYS_90,
  DAYS_180,
  CUSTOM_PAYMENT_OPTION,
  ORGANIC,
} from 'utils/constants/product';
import Button from 'components/BaseComponents/Button';
import RangeSlider from 'components/BaseComponents/RangeSlider';
import { MY_LISTINGS } from 'utils/tagTypes';
import CheckBoxFilter from '../AggregateDataFilter/CheckBoxFilter';
import RadioFilter from '../AggregateDataFilter/RadioFilter';
import SelectFilter from '../AggregateDataFilter/SelectFilter';
import BestBeforeFilter from '../AggregateDataFilter/BestBeforeFilter';
import CountryFilter from '../AggregateDataFilter/CountryFilter';
import DateRangeFilter from '../AggregateDataFilter/DateRangeFilter';
import './styles.scss';

const DriedNutInShellAggregateDataFilter = ({ filterState, dispatch, priceRange, volumeRange }) => {
  const { t } = useTranslation();
  const {
    myListings,
    listingType,
    listingDateRange,
    fcl,
    lcl,
    lessThan18mm,
    size1820mm,
    size2022mm,
    size2225mm,
    size25mmPlus,
    size22mmPlus,
    size20mmPlus,
    size18mmPlus,
    otherSize,
    soundKernelRecovery,
    unsoundKernelRecovery,
    hybrid,
    beaumont,
    integrifolia,
    otherTreeVariant,
    premium,
    commercial,
    rejected,
    dap,
    days30,
    days60,
    days90,
    days180,
    customPaymentOption,
    harvestSeason,
    bestBeforeDate,
    shippingStartDate,
    shippingEndDate,
    region,
    country,
    globalGap,
    ppecb,
    esg,
    organic,
  } = filterState;
  const { min: minPriceBound, max: maxPriceBound } = priceRange;
  const { min: minVolumeBound, max: maxVolumeBound } = volumeRange;
  const [isPickYourDateActive, setIsPickYourDateActive] = useState(false);
  const [dateRange, setDateRange] = useState(listingDateRange);

  const handlePriceInputChange = (price) => dispatch({ type: HANDLE_PRICE_CHANGE, payload: price });
  const handleQuantityInputChange = (quantity) =>
    dispatch({ type: HANDLE_QUANTITY_CHANGE, payload: quantity });

  return (
    <div className="aggregate-data-filter">
      <div className="filters-green border-filter">
        <CheckBoxFilter
          title={t('offer_listing.filter.my_listings.title')}
          options={[
            {
              name: MY_LISTINGS,
              label: t('offer_listing.filter.my_listings.label'),
              isChecked: myListings,
              onChange: () => dispatch({ type: HANDLE_MY_LISTINGS_CHANGE, payload: !myListings }),
            },
          ]}
        />
        <RadioFilter
          className="aggregate-data-filter__box--radio-options"
          title={t('offer_listing.filter.listing_type.title')}
          options={listingTypeOptions}
          selectedOption={listingType}
          onChange={(value) => {
            dispatch({ type: HANDLE_LISTING_TYPE_CHANGE, payload: value });
            if (value === 'offers' || value === 'orders') {
              dispatch({ type: HANDLE_LISTING_DATE_CHANGE, payload: 'this_year' });
            }
          }}
        />
        <RadioFilter
          className="aggregate-data-filter__box--radio-options border-b-none"
          title={t('offer_listing.filter.shipping_date_range.title')}
          options={listingDateRangeOptions}
          selectedOption={dateRange}
          onChange={(value) => {
            setDateRange(value);
            if (value === 'your_date') {
              setIsPickYourDateActive(true);
            } else {
              setIsPickYourDateActive(false);
              dispatch({ type: HANDLE_LISTING_DATE_CHANGE, payload: value });
            }
          }}
        />
        {isPickYourDateActive && (
          <DateRangeFilter
            className="aggregate-data-filter__box--radio-options border-b-none"
            title={t('offer_listing.filter.shipping_date_range.title')}
            startDate={shippingStartDate}
            endDate={shippingEndDate}
            onStartDateChange={(value) => dispatch({ type: HANDLE_SHIPPING_START_DATE_CHANGE, payload: value })}
            onEndDateChange={(value) => dispatch({ type: HANDLE_SHIPPING_END_DATE_CHANGE, payload: value }) }
          />
        )}
      </div>
      <div className="aggregate-data-filter__box flex justify-between ">
        <h3 className="aggregate-data-filter__box--title aggregate-data-filter__box--title-large">
          {t('offer_listing.filter.title')}
        </h3>
        <Button
          label={t('offer_listing.filter.reset_button')}
          className="aggregate-data-filter__box--reset-button"
          onClick={() => dispatch({ type: HANDLE_RESET_CLICK })}
        />
      </div>
      <CheckBoxFilter
        title={t('offer_listing.filter.container_load.title')}
        options={[
          {
            name: 'FCL',
            label: t('offer_listing.filter.container_load.options.fcl'),
            isChecked: fcl,
            onChange: () => {
              dispatch({ type: HANDLE_FCL_CONTAINER_LOAD_CHANGE, payload: !fcl });
            },
          },
          {
            name: 'LCL',
            label: t('offer_listing.filter.container_load.options.lcl'),
            isChecked: lcl,
            onChange: () => {
              dispatch({ type: HANDLE_LCL_CONTAINER_LOAD_CHANGE, payload: !lcl });
            },
          },
        ]}
      />
      <CheckBoxFilter
        title={t('offer_listing.filter.nut_size.title')}
        dispatch={dispatch}
        resetButtonType={HANDLE_NUT_SIZE_RESET_CLICK}
        options={[
          {
            name: 'less_than_18mm',
            label: t('offer_listing.filter.nut_size.options.size_18p'),
            isChecked: lessThan18mm,
            onChange: () => {
              dispatch({ type: HANDLE_NUT_SIZE_LESS_THAN_18_CHANGE, payload: !lessThan18mm });
            },
          },
          {
            name: 'size_18_20mm',
            label: t('offer_listing.filter.nut_size.options.size_18_20'),
            isChecked: size1820mm,
            onChange: () => {
              dispatch({ type: HANDLE_NUT_SIZE_18_20_CHANGE, payload: !size1820mm });
            },
          },
          {
            name: 'size_20_22mm',
            label: t('offer_listing.filter.nut_size.options.size_20_22'),
            isChecked: size2022mm,
            onChange: () => {
              dispatch({ type: HANDLE_NUT_SIZE_20_22_CHANGE, payload: !size2022mm });
            },
          },
          {
            name: 'size_22_25mm',
            label: t('offer_listing.filter.nut_size.options.size_22_25'),
            isChecked: size2225mm,
            onChange: () => {
              dispatch({ type: HANDLE_NUT_SIZE_22_25_CHANGE, payload: !size2225mm });
            },
          },
          {
            name: 'size_25mm_plus',
            label: t('offer_listing.filter.nut_size.options.size_25'),
            isChecked: size25mmPlus,
            onChange: () => {
              dispatch({ type: HANDLE_NUT_SIZE_25_PLUS_CHANGE, payload: !size25mmPlus });
            },
          },
          {
            name: 'size_22mm_plus',
            label: t('offer_listing.filter.nut_size.options.size_22'),
            isChecked: size22mmPlus,
            onChange: () => {
              dispatch({ type: HANDLE_NUT_SIZE_22_PLUS_CHANGE, payload: !size22mmPlus });
            },
          },
          {
            name: 'size_20mm_plus',
            label: t('offer_listing.filter.nut_size.options.size_20'),
            isChecked: size20mmPlus,
            onChange: () => {
              dispatch({ type: HANDLE_NUT_SIZE_20_PLUS_CHANGE, payload: !size20mmPlus });
            },
          },
          {
            name: 'size_18mm_plus',
            label: t('offer_listing.filter.nut_size.options.size_18'),
            isChecked: size18mmPlus,
            onChange: () => {
              dispatch({ type: HANDLE_NUT_SIZE_18_PLUS_CHANGE, payload: !size18mmPlus });
            },
          },
          {
            name: 'other_size',
            label: t('offer_listing.filter.nut_size.options.other'),
            isChecked: otherSize,
            onChange: () => {
              dispatch({ type: HANDLE_OTHER_NUT_SIZE_CHANGE, payload: !otherSize });
            },
          },
        ]}
      />

      <SelectFilter
        title={t('offer_listing.filter.sound_kernel_recovery.title')}
        placeholderText={t('offer_listing.filter.sound_kernel_recovery.placeholder')}
        options={soundKernelRecoveryOptions}
        selectedOption={soundKernelRecovery}
        onChange={(value) =>
          dispatch({ type: HANDLE_SOUND_KERNEL_RECOVERY_CHANGE, payload: value })
        }
      />

      <SelectFilter
        title={t('offer_listing.filter.unsound_kernel_recovery.title')}
        placeholderText={t('offer_listing.filter.unsound_kernel_recovery.placeholder')}
        options={unsoundKernelRecoveryOptions}
        selectedOption={unsoundKernelRecovery}
        onChange={(value) =>
          dispatch({ type: HANDLE_UNSOUND_KERNEL_RECOVERY_CHANGE, payload: value })
        }
      />
      <CheckBoxFilter
        title={t('offer_listing.filter.tree_variant.title')}
        dispatch={dispatch}
        resetButtonType={HANDLE_TREE_VARIANT_RESET_CLICK}
        options={[
          {
            name: BEAUMONT,
            label: t('offer_listing.filter.tree_variant.options.beaumont'),
            isChecked: beaumont,
            onChange: () => {
              dispatch({ type: HANDLE_TREE_VARIANT_BEAUMONT_CHANGE, payload: !beaumont });
            },
          },
          {
            name: HYBRID,
            label: t('offer_listing.filter.tree_variant.options.hybrid'),
            isChecked: hybrid,
            onChange: () => {
              dispatch({ type: HANDLE_TREE_VARIANT_HYBRID_CHANGE, payload: !hybrid });
            },
          },
          {
            name: INTEGRIFOLIA,
            label: t('offer_listing.filter.tree_variant.options.integrifolia'),
            isChecked: integrifolia,
            onChange: () => {
              dispatch({ type: HANDLE_TREE_VARIANT_INTEGRIFOLIA_CHANGE, payload: !integrifolia });
            },
          },
          {
            name: OTHER_TREE_VARIANT,
            label: t('offer_listing.filter.tree_variant.options.other'),
            isChecked: otherTreeVariant,
            onChange: () => {
              dispatch({ type: HANDLE_TREE_VARIANT_OTHER_CHANGE, payload: !otherTreeVariant });
            },
          },
        ]}
      />
      <CheckBoxFilter
        title={t('offer_listing.filter.nut_grade.title')}
        dispatch={dispatch}
        resetButtonType={HANDLE_NUT_GRADE_RESET_CLICK}
        options={[
          {
            name: PREMIUM,
            label: t('offer_listing.filter.nut_grade.options.premium'),
            isChecked: premium,
            onChange: () => {
              dispatch({ type: HANDLE_PREMIUM_NUT_GRADE_CHANGE, payload: !premium });
            },
          },
          {
            name: COMMERCIAL,
            label: t('offer_listing.filter.nut_grade.options.commercial'),
            isChecked: commercial,
            onChange: () => {
              dispatch({ type: HANDLE_COMMERCIAL_NUT_GRADE_CHANGE, payload: !commercial });
            },
          },
          {
            name: REJECTED,
            label: t('offer_listing.filter.nut_grade.options.rejected'),
            isChecked: rejected,
            onChange: () => {
              dispatch({ type: HANDLE_REJECTED_NUT_GRADE_CHANGE, payload: !rejected });
            },
          },
        ]}
      />
      <SelectFilter
        title={t('offer_listing.filter.harvest_season.title')}
        placeholderText={t('offer_listing.filter.harvest_season.placeholder')}
        options={seasons}
        selectedOption={harvestSeason}
        onChange={(value) => dispatch({ type: HANDLE_HARVEST_SEASON_CHANGE, payload: value })}
      />
      <BestBeforeFilter
        date={bestBeforeDate}
        onDateChange={(value) => dispatch({ type: HANDLE_BEST_BEFORE_CHANGE, payload: value })}
      />
      <RangeSlider
        min={Number(minPriceBound)}
        max={Number(maxPriceBound)}
        defaultMinValue={Number(minPriceBound)}
        defaultMaxValue={Number(maxPriceBound)}
        allowCross={false}
        onAfterChange={handlePriceInputChange}
        onChange={handlePriceInputChange}
        color="success"
        titleText={t('offer_listing.filter.price.title')}
        minInputPlaceholder={t('offer_listing.filter.price.min_price')}
        maxInputPlaceholder={t('offer_listing.filter.price.max_price')}
        className="aggregate-data-filter__box aggregate-data-filter__box--range-slider"
      />
      <RangeSlider
        min={Number(minVolumeBound)}
        max={Number(maxVolumeBound)}
        defaultMinValue={Number(minVolumeBound)}
        defaultMaxValue={Number(maxVolumeBound)}
        allowCross={false}
        onAfterChange={handleQuantityInputChange}
        onChange={handleQuantityInputChange}
        color="success"
        titleText={t('offer_listing.filter.quantity.title')}
        minInputPlaceholder={t('offer_listing.filter.quantity.min')}
        maxInputPlaceholder={t('offer_listing.filter.quantity.max')}
        className="aggregate-data-filter__box aggregate-data-filter__box--range-slider"
      />
      <CheckBoxFilter
        title={t('offer_listing.filter.payment.title')}
        dispatch={dispatch}
        resetButtonType={HANDLE_PAYMENT_OPTION_RESET_CLICK}
        options={[
          {
            name: DAP,
            label: t('offer_listing.filter.payment.options.dap'),
            isChecked: dap,
            onChange: () => {
              dispatch({ type: HANDLE_DAP_PAYMENT_OPTION_CHANGE, payload: !dap });
            },
          },
          {
            name: DAYS_30,
            label: t('offer_listing.filter.payment.options.30_days'),
            isChecked: days30,
            onChange: () => {
              dispatch({ type: HANDLE_DAYS_30_PAYMENT_OPTION_CHANGE, payload: !days30 });
            },
          },
          {
            name: DAYS_60,
            label: t('offer_listing.filter.payment.options.60_days'),
            isChecked: days60,
            onChange: () => {
              dispatch({ type: HANDLE_DAYS_60_PAYMENT_OPTION_CHANGE, payload: !days60 });
            },
          },
          {
            name: DAYS_90,
            label: t('offer_listing.filter.payment.options.90_days'),
            isChecked: days90,
            onChange: () => {
              dispatch({ type: HANDLE_DAYS_90_PAYMENT_OPTION_CHANGE, payload: !days90 });
            },
          },
          {
            name: DAYS_180,
            label: t('offer_listing.filter.payment.options.180_days'),
            isChecked: days180,
            onChange: () => {
              dispatch({ type: HANDLE_DAYS_180_PAYMENT_OPTION_CHANGE, payload: !days180 });
            },
          },
          {
            name: CUSTOM_PAYMENT_OPTION,
            label: t('offer_listing.filter.payment.options.custom_payment'),
            isChecked: customPaymentOption,
            onChange: () => {
              dispatch({
                type: HANDLE_CUSTOM_PAYMENT_OPTION_CHANGE,
                payload: !customPaymentOption,
              });
            },
          },
        ]}
      />
      <CheckBoxFilter
        title={t('offer_listing.filter.dnis_certifications.title')}
        dispatch={dispatch}
        resetButtonType={HANDLE_DNIS_CERTIFICATIONS_RESET_CLICK}
        options={[
          {
            name: GLOBAL_GAP,
            label: t('offer_listing.filter.dnis_certifications.options.global_gap'),
            isChecked: globalGap,
            onChange: () => {
              dispatch({ type: HANDLE_DNIS_CERTIFICATION_GLOBAL_GAP_CHANGE, payload: !globalGap });
            },
          },
          {
            name: PPECB,
            label: t('offer_listing.filter.dnis_certifications.options.ppecb'),
            isChecked: ppecb,
            onChange: () => {
              dispatch({ type: HANDLE_DNIS_CERTIFICATION_PPECB_CHANGE, payload: !ppecb });
            },
          },
          {
            name: ESG,
            label: t('offer_listing.filter.dnis_certifications.options.esg'),
            isChecked: esg,
            onChange: () => {
              dispatch({ type: HANDLE_DNIS_CERTIFICATION_ESG_CHANGE, payload: !esg });
            },
          },
        ]}
      />

      <SelectFilter
        title={t('offer_listing.filter.origin_region.title')}
        placeholderText={t('offer_listing.filter.origin_region.placeholder')}
        options={regions}
        selectedOption={region}
        onChange={(value) => dispatch({ type: HANDLE_REGION_CHANGE, payload: value })}
      />
      <CountryFilter
        className="border-b-none"
        title={t('offer_listing.filter.origin_country.title')}
        country={country}
        onChange={(value) => dispatch({ type: HANDLE_COUNTRY_CHANGE, payload: value })}
      />
      <CheckBoxFilter
        title={t('offer_listing.filter.other_filters')}
        options={[
          {
            name: ORGANIC,
            label: t('offer_listing.filter.organic.options.true'),
            isChecked: organic,
            onChange: () => dispatch({ type: HANDLE_ORGANIC_CHANGE, payload: !organic }),
          },
        ]}
      />
    </div>
  );
};

DriedNutInShellAggregateDataFilter.defaultProps = {
  priceRange: { min: 0, max: 0 },
  volumeRange: { min: 0, max: 0 },
};

DriedNutInShellAggregateDataFilter.propTypes = {
  filterState: PropTypes.shape({
    myListings: PropTypes.bool,
    listingType: PropTypes.string,
    productType: PropTypes.oneOf([DRIED_NUT_IN_SHELL_PRODUCT]),
    listingDateRange: PropTypes.string,
    fcl: PropTypes.bool,
    lcl: PropTypes.bool,
    lessThan18mm: PropTypes.bool,
    size1820mm: PropTypes.bool,
    size2022mm: PropTypes.bool,
    size2225mm: PropTypes.bool,
    size25mmPlus: PropTypes.bool,
    size22mmPlus: PropTypes.bool,
    size20mmPlus: PropTypes.bool,
    size18mmPlus: PropTypes.bool,
    otherSize: PropTypes.bool,
    soundKernelRecovery: PropTypes.string,
    unsoundKernelRecovery: PropTypes.string,
    treeVariant: PropTypes.string,
    hybrid: PropTypes.bool,
    beaumont: PropTypes.bool,
    integrifolia: PropTypes.bool,
    otherTreeVariant: PropTypes.bool,
    premium: PropTypes.bool,
    commercial: PropTypes.bool,
    rejected: PropTypes.bool,
    dap: PropTypes.bool,
    days30: PropTypes.bool,
    days60: PropTypes.bool,
    days90: PropTypes.bool,
    days180: PropTypes.bool,
    organic: PropTypes.bool,
    customPaymentOption: PropTypes.string,
    shippingStartDate: PropTypes.string,
    shippingEndDate: PropTypes.string,
    harvestSeason: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string,
        label: PropTypes.string,
      }),
    ]),
    bestBeforeDate: PropTypes.string,
    region: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string,
        label: PropTypes.string,
      }),
    ]),
    country: PropTypes.string,
    globalGap: PropTypes.bool,
    ppecb: PropTypes.bool,
    esg: PropTypes.bool,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  priceRange: PropTypes.shape({
    min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  volumeRange: PropTypes.shape({
    min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

export default DriedNutInShellAggregateDataFilter;
