import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { requiredField } from 'utils/helper/Validations';
import { useForgotPasswordMutation } from 'api/auth';
import Button from 'components/BaseComponents/Button';
import Input from 'components/BaseComponents/Input';
import Icon from 'components/BaseComponents/Icon';
import FormFooter from 'components/ResetPassword/Forms/FormFooter';
import '../styles.scss';

const ForgotPasswordForm = () => {
  const navigate = useCustomNavigate();
  const { t } = useTranslation();
  const [forgotPassword] = useForgotPasswordMutation();

  const handleRequestResetSubmit = async (params) => {
    const response = await forgotPassword({ payload: params });

    if (response.error) {
      return { email: response.error.data.error };
    }

    navigate.resetPasswordMailSentPage(params);

    return null;
  };

  return (
    <div className="reset-password-form__container">
      <div className="reset-password-form__container--header">
        <Icon name="keyLine" size="xl" color="none" className="reset-form-icon" />

        <div className="reset-password-form__container--header-texts">
          <h2 className="reset-password-form__container--header-texts-title">
            {t('forgot_password.title')}
          </h2>

          <p className="reset-password-form__container--header-texts-message">
            {t('forgot_password.welcome_message')}
          </p>
        </div>
      </div>

      <div className="reset-password-form__container--form">
        <Form
          onSubmit={handleRequestResetSubmit}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} className="margin-t-16px">
              <Field name="email" validate={requiredField}>
                {({ input, meta }) => (
                  <>
                    <Input
                      className="margin-t-20px margin-b-16px"
                      label={t('forgot_password.email_label')}
                      placeholder={t('forgot_password.email_placeholder')}
                      required
                      error={meta?.error || meta?.submitError}
                      touched={meta.touched}
                      size="small"
                      value={input.value}
                      onChange={input.onChange}
                    />
                    <span className="submit-error">{meta?.submitError}</span>
                  </>
                )}
              </Field>

              <Button
                className="reset-password-form__container--submit-btn"
                type="success"
                label={t('reset_password.submit_button_label')}
                submitType="submit"
                disabled={submitting}
              />
            </form>
          )}
        />
      </div>

      <FormFooter />
    </div>
  );
};

export default ForgotPasswordForm;
