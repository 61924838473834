import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';
import { BUYER, SELLER } from 'utils/constants/userRole';
import { styles } from '../styles';
import SignatureBox from '../SignatureBox';
import { contractPDFSignatureContents } from './constants';

const SignatureAuthorization = ({ buyerDetails, sellerDetails }) => (
  <View wrap={false}>
    <Text style={styles.signatureTitle}>{t('contract_pdf.signature_and_authorization')}</Text>
    <View style={styles.signatureBoxContainer}>
      <View style={styles.signatureBoxContainerLeft}>
        <Text style={styles.signatureContractTitle}>
          {t('contract_pdf.contract_of_sale_by_seller')}
        </Text>
        {contractPDFSignatureContents(SELLER, sellerDetails).map(
          ({ textContent, fieldLabel, fieldSize }) => (
            <View key={fieldLabel} style={styles.signatureBoxField}>
              <SignatureBox text={textContent} size={fieldSize} />
              <Text style={styles.signatureBoxLabel}>{fieldLabel}</Text>
            </View>
          ),
        )}
      </View>
      <View style={styles.signatureBoxContainerRight}>
        <Text style={styles.signatureContractTitle}>
          {t('contract_pdf.contract_of_sale_by_buyer')}
        </Text>
        {contractPDFSignatureContents(BUYER, buyerDetails).map(
          ({ textContent, fieldLabel, fieldSize }) => (
            <View key={fieldLabel} style={styles.signatureBoxField}>
              <SignatureBox text={textContent} size={fieldSize} />
              <Text style={styles.signatureBoxLabel}>{fieldLabel}</Text>
            </View>
          ),
        )}
      </View>
    </View>
  </View>
);

SignatureAuthorization.propTypes = {
  sellerDetails: PropTypes.shape({
    companyName: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  buyerDetails: PropTypes.shape({
    companyName: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
};

export default SignatureAuthorization;
