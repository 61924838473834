import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Slider from 'rc-slider';
import { parseToTwoDecimals } from 'utils/helper/formatNumber';
import Input from '../Input';
import 'rc-slider/assets/index.css';

const RangeSlider = (props) => {
  const {
    color,
    className,
    defaultMinValue,
    defaultMaxValue,
    max,
    min,
    minInputPlaceholder,
    maxInputPlaceholder,
    onAfterChange,
    onBeforeChange,
    titleText,
    step,
    disabled,
    allowCross,
    onChange,
  } = props;

  const [state, setState] = useState({
    currentValues: [defaultMinValue, defaultMaxValue],
  });

  useEffect(() => {
    setState({
      currentValues: [parseToTwoDecimals(defaultMinValue), parseToTwoDecimals(defaultMaxValue)],
    });
  }, [defaultMinValue, defaultMaxValue]);

  const onChangeHandler = (values) => {
    return setState({ currentValues: values });
  };

  const handleSliderChange = (values) => {
    const decimalFormatterRange = values.map(parseToTwoDecimals);
    onChangeHandler(decimalFormatterRange);
  };

  const handleOutSideClick = () => {
    const decimalFormatterRange = state.currentValues.map(parseToTwoDecimals);
    onChangeHandler(decimalFormatterRange);
    onChange(decimalFormatterRange);
  };

  const onMinOutSideClick = () => {
    const [minValue, maxValue] = state.currentValues.map(Number);

    if (minValue <= 0) {
      onChangeHandler([0, maxValue]);
      onChange([0, maxValue]);

      return null;
    }

    if (minValue > maxValue) {
      onChangeHandler([maxValue, maxValue]);
      onChange([maxValue, maxValue]);

      return null;
    }

    handleOutSideClick();
  };

  const onMaxOutSideClick = () => {
    const [minValue, maxValue] = state.currentValues.map(Number);

    if (maxValue <= 0) {
      onChangeHandler([minValue, minValue]);
      onChange([minValue, minValue]);

      return null;
    }

    if (maxValue < minValue) {
      onChangeHandler([minValue, minValue]);
      onChange([minValue, minValue]);

      return null;
    }

    handleOutSideClick();
  };

  const handleMaxKeyDown = (event) => {
    if (event.key === 'Enter') {
      onMaxOutSideClick();
    }
  };

  const handleMinKeyDown = (event) => {
    if (event.key === 'Enter') {
      onMinOutSideClick();
    }
  };

  const [currentMinValue, currentMaxValue] = state.currentValues;

  return (
    <div className={classNames('range-slider', `range-slider--${color}`, className)}>
      <div className="range-slider__title">
        <span className="range-slider__title-text">{titleText}</span>
      </div>
      <Slider
        range
        max={max}
        min={min}
        step={step}
        disabled={disabled}
        allowCross={allowCross}
        value={state.currentValues}
        defaultValue={state.currentValues}
        onChange={handleSliderChange}
        onChangeComplete={onAfterChange}
        onBeforeChange={onBeforeChange}
      />
      <div className="flex gap-24px margin-t-28px">
        <Input
          type="number"
          value={currentMinValue}
          placeholder={minInputPlaceholder}
          isClearable={false}
          onChange={(value) => onChangeHandler([value, currentMaxValue])}
          onBlur={onMinOutSideClick}
          onKeyDown={handleMinKeyDown}
        />
        <Input
          type="number"
          value={currentMaxValue}
          placeholder={maxInputPlaceholder}
          isClearable={false}
          onChange={(value) => onChangeHandler([currentMinValue, value])}
          onBlur={onMaxOutSideClick}
          onKeyDown={handleMaxKeyDown}
        />
      </div>
    </div>
  );
};

RangeSlider.defaultProps = {
  color: 'neutral',
  className: null,
  defaultMaxValue: 0,
  defaultMinValue: 0,
  max: 100,
  min: 0,
  minInputPlaceholder: '',
  maxInputPlaceholder: '',
  onChange: () => {},
  onAfterChange: () => {},
  onBeforeChange: () => {},
  titleText: 'Title',
  step: 1,
  disabled: false,
  allowCross: true,
};

RangeSlider.propTypes = {
  color: PropTypes.oneOf(['warning', 'danger', 'success', 'neutral']),
  className: PropTypes.string,
  defaultMaxValue: PropTypes.number,
  defaultMinValue: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  minInputPlaceholder: PropTypes.string,
  maxInputPlaceholder: PropTypes.string,
  onChange: PropTypes.func,
  onAfterChange: PropTypes.func,
  onBeforeChange: PropTypes.func,
  titleText: PropTypes.node,
  step: PropTypes.number,
  disabled: PropTypes.bool,
  allowCross: PropTypes.bool,
};

export default RangeSlider;
