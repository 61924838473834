import React from 'react';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import classNames from 'classnames';
import Icon from 'components/BaseComponents/Icon';
import IconButton from 'components/BaseComponents/IconButton';

class Toast extends React.Component {
  notify = (customChildren = this.props.children, customOption ) =>
      toast(customChildren, customOption);
    

  render() {
    const closeButton = ({ closeToast }) => {
      return (
        <div
          className={classNames('Toastify__close-button', {
            'Toastify__close-button--rtl': this.props.rtl,
          })}
        >
          <IconButton
            icon={<Icon name="close" />}
            color="primary-text"
            size="xs"
            onClick={closeToast}
          />
        </div>
      );
    };

    return (
      <div className={this.props.className}>
        <ToastContainer {...this.props} closeButton={closeButton} />
      </div>
    );
  }
}

Toast.defaultProps = {
  position: 'bottom-right',
  autoClose: 5000,
  hideProgressBar: true,
  newestOnTop: true,
  closeOnClick: false,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: false,
  pauseOnHover: true,
  children: 'Wow so easy !',
  className: 'toast',
};

Toast.propTypes = {
  position: PropTypes.oneOf([
    toast.POSITION.BOTTOM_CENTER,
    toast.POSITION.BOTTOM_LEFT,
    toast.POSITION.BOTTOM_RIGHT,
    toast.POSITION.TOP_CENTER,
    toast.POSITION.TOP_LEFT,
    toast.POSITION.TOP_RIGHT,
  ]),
  autoClose: PropTypes.number,
  hideProgressBar: PropTypes.bool,
  newestOnTop: PropTypes.bool,
  closeOnClick: PropTypes.bool,
  rtl: PropTypes.bool,
  pauseOnFocusLoss: PropTypes.bool,
  draggable: PropTypes.bool,
  pauseOnHover: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default Toast;
