import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';
import { formatPrice, formatVolume } from 'utils/helper/Charts';
import { DRIED_NUT_IN_SHELL_PRODUCT, KERNEL_PRODUCT } from 'utils/constants/product';
import { styles } from '../styles';

const ProductInfoTable = ({ productDetails }) => {
  const isKernelProduct = productDetails.type === KERNEL_PRODUCT;
  const isDriedNutInShellProduct = productDetails.type === DRIED_NUT_IN_SHELL_PRODUCT;

  return (
    <Fragment>
      <Text style={styles.boldText}>{t('contract_pdf.product_information')}</Text>
      <View style={styles.table}>
        <View style={styles.tableHeader}>
          <View style={{ ...styles.tableCol, ...styles.tableColLeftMost }}>
            <Text style={styles.tableCell}>{t('contract_pdf.quantity')}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{t('contract_pdf.packaging')}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{t('contract_pdf.total_ctn')}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{t('contract_pdf.description')}</Text>
          </View>
          <View style={styles.tableCol}>
            {isKernelProduct ? (
              <Text style={styles.tableCell}>{t('contract_pdf.style')}</Text>
            ) : null}
            {isDriedNutInShellProduct ? (
              <Text style={styles.tableCell}>{t('contract_pdf.size')}</Text>
            ) : null}
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{t('contract_pdf.year')}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{t('contract_pdf.usd_per_kg')}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{t('contract_pdf.usd_total')}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={{ ...styles.tableCol, ...styles.tableColLeftMost }}>
            <Text style={styles.tableCell}>{formatVolume(productDetails?.offerVolume)}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{t('contract_pdf.carton')}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{productDetails.containerLoad}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{t(`contract_pdf.type.${productDetails.type}`)}</Text>
          </View>
          <View style={styles.tableCol}>
            {isKernelProduct ? (
              <Text style={styles.tableCell}>{productDetails.nutStyle}</Text>
            ) : null}
            {isDriedNutInShellProduct ? (
              <Text style={styles.tableCell}>{productDetails.size}</Text>
            ) : null}
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{productDetails.seasonHarvested}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{formatPrice(productDetails?.offerPrice)}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{formatPrice(productDetails.fullBatchPrice)}</Text>
          </View>
        </View>
        {productDetails.boxesQuantity && (
          <>
            <View style={styles.tableHeader}>
              <View style={{ ...styles.tableColWide, ...styles.tableColLeftMost }}>
                <Text style={styles.tableCell}>{t('contract_pdf.boxes_quantity')}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell} />
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell} />
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell} />
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell} />
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell} />
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={{ ...styles.tableColWide, ...styles.tableColLeftMost }}>
                <Text style={styles.tableCell}>{formatVolume(productDetails.boxesQuantity)}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell} />
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell} />
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell} />
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell} />
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell} />
              </View>
            </View>
          </>
        )}
        <View style={styles.tableRow}>
          <View style={{ ...styles.tableCol, ...styles.tableColLeftMost }}>
            <Text style={styles.tableCell} />
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell} />
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell} />
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell} />
          </View>
          <View style={styles.tableColSpan}>
            <Text style={styles.tableCell}>{t('contract_pdf.msm_commission')}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{formatPrice(productDetails.msmCommissionOnProduct)}</Text>
          </View>
        </View>
      </View>
    </Fragment>
  );
};

ProductInfoTable.propTypes = {
  productDetails: PropTypes.shape({
    offerVolume: PropTypes.string,
    boxesQuantity: PropTypes.number,
    packaging: PropTypes.string,
    containerLoad: PropTypes.string,
    description: PropTypes.string,
    nutStyle: PropTypes.string,
    seasonHarvested: PropTypes.string,
    offerPrice: PropTypes.string,
    fullBatchPrice: PropTypes.string,
    msmCommissionOnProduct: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.string,
  }).isRequired,
};

export default ProductInfoTable;
