import React from 'react';
import { useParams } from 'react-router-dom';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { DRIED_NUT_IN_SHELL_PRODUCT, KERNEL_PRODUCT } from 'utils/constants/product';
import { OFFER } from 'utils/constants/offerListings';
import { useGetProductDetailsQuery } from 'api/products';
import CreateListingLayout from 'components/BiddingProcess/CreateListing/Layout';
import { offerOrderPageIndex } from 'utils/constants/listingCreation';
import SubmitButtons from '../StepperButtons/SubmitButtons';
import DriedNutInShellProductListingCard from './DriedNutInShellProductListingCard';
import KernelProductListingCard from './KernelProductListingCard';
import SimilarListings from './SimilarListings';
import './styles.scss';

const ReviewListing = () => {
  const navigate = useCustomNavigate();
  const { productId } = useParams();

  const { data: productDetails = {} } = useGetProductDetailsQuery(
    { product_id: productId },
    { skip: !productId },
  );

  const { category, type } = productDetails;
  const isOffer = category === OFFER;

  const handleSubmitClick = () => (
    isOffer ? navigate.foodSafety(productId) : navigate.declaration(productId)
  );

  return (
    <CreateListingLayout activeStep={offerOrderPageIndex.reviewListing} category={category} >
      <SubmitButtons
        currentStepIndex={2}
        onSaveAsDraft={() => (isOffer ? navigate.myOffers() : navigate.myOrders())}
        onPrevButtonClick={() => navigate.updateProduct(productId)}
        onSubmitClick={handleSubmitClick}
      />
      {type === DRIED_NUT_IN_SHELL_PRODUCT && (
        <DriedNutInShellProductListingCard productDetails={productDetails} />
      )}
      {type === KERNEL_PRODUCT && (
        <KernelProductListingCard productDetails={productDetails} />
      )}
      <SimilarListings productDetails={productDetails} />
    </CreateListingLayout>
  );
};

export default ReviewListing;
