import React from 'react';
import PropTypes from 'prop-types';
import useListingMiddleWare from 'hooks/useListingMiddleWare';
import LoadingSpinner from 'components/BaseComponents/LoadingSpinner';

const CreateListingAuthorizer = ({ children }) => {
  const isLoading = useListingMiddleWare();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return children;
};

CreateListingAuthorizer.defaultProps = {
  children: null,
};

CreateListingAuthorizer.propTypes = {
  children: PropTypes.node,
};

export default CreateListingAuthorizer;
