import React from 'react';
import { FOLLOWING_TAB, LEADING_BID_TAB, OUTBID_TAB, biddingBasketFilter } from "utils/constants/biddingBasket";
import CardsContainer from 'components/BiddingProcess/BiddingBasket/CardsContainer';
import { getTabTitle } from "./Portals";

export const getBiddingBasketTabs = ({
  followingListingsContractCount,
  leadingBidListingsCount,
  outbidListingsCount,
  followingListingsList,
  leadingBidListingsList,
  outbidListingsList,
  isFollowingListingsLoading,
  isLeadingBidListingsLoading,
  isOutbidListingsLoading,
  currentTab,
}) => [
  {
    key: FOLLOWING_TAB,
    title: getTabTitle(biddingBasketFilter[FOLLOWING_TAB], followingListingsContractCount),
    getContent: () => (
      <CardsContainer
        listings={followingListingsList}
        isLoading={isFollowingListingsLoading}
        currentTab={currentTab}
      />
    ),
  },
  {
    key: LEADING_BID_TAB,
    title: getTabTitle(biddingBasketFilter[LEADING_BID_TAB], leadingBidListingsCount),
    getContent: () => (
      <CardsContainer
        listings={leadingBidListingsList}
        isLoading={isLeadingBidListingsLoading}
        currentTab={currentTab}
      />
    ),
  },
  {
    key: OUTBID_TAB,
    title: getTabTitle(biddingBasketFilter[OUTBID_TAB], outbidListingsCount),
    getContent: () => (
      <CardsContainer
        listings={outbidListingsList}
        isLoading={isOutbidListingsLoading}
        currentTab={currentTab}
      />
    ),
  },
];
