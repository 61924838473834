import { t } from 'i18next';

export const greenText = 'contract-details-header-information__value--green-text';

export const getFormCheckboxInformation = [
  {
    label: t(
      'shared.bidding_process.contract_details.request_assistance.form.transportation_checkbox_label',
    ),
    id: 'transportation',
    className: 'request-assistance--form-container-text flex items-center margin-b-8px',
  },
  {
    label: t(
      'shared.bidding_process.contract_details.request_assistance.form.freight_checkbox_label',
    ),
    id: 'freight',
    className: 'request-assistance--form-container-text flex items-center margin-b-8px',
  },
  {
    label: t(
      'shared.bidding_process.contract_details.request_assistance.form.finance_checkbox_label',
    ),
    id: 'finance',
    className: 'request-assistance--form-container-text flex items-center margin-b-8px',
  },
  {
    label: t(
      'shared.bidding_process.contract_details.request_assistance.form.insurance_checkbox_label',
    ),
    id: 'insurance',
    className: 'request-assistance--form-container-text flex items-center margin-b-8px',
  },
  {
    label: t(
      'shared.bidding_process.contract_details.request_assistance.form.forex_checkbox_label',
    ),
    id: 'forexServices',
    className: 'request-assistance--form-container-text flex items-center',
  },
];
