import React, { useState } from 'react';
import { t } from 'i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'components/BaseComponents/Icon';
import Tooltip from 'components/BaseComponents/Tooltip';
import Input from 'components/BaseComponents/Input';
import Button from 'components/BaseComponents/Button';
import DocumentManagement from './DocumentManagement';
import './style.scss';

const ChooseDocument = ({
  className,
  label,
  hint,
  userRole,
  fileName,
  fileUploadedAt,
  fileUrl,
  errorMessage,
  touched,
  onChange,
  onDelete,
  isEditable,
  disableDelete,
  handleDocuments,
  handleClose,
  handleRename,
  documentsFormSubmit,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [newFileName, setNewFileName] = useState(fileName);

  const handleEdit = () => {
    setNewFileName(fileName);
    setIsEdit(!isEdit);
  };

  const changeFileName = (filename) => {
    setNewFileName(filename);
  };

  const submitNameChange = () => {
    setIsEdit(!isEdit);
    handleRename(newFileName);
  };

  return (
    <div className={classNames('flex justify-between items-center choose-file', className)}>
      <div className="flex items-center gap-8px">
        {fileName ? (
          <Icon name="preference" size="medium" color="success" />
        ) : (
          <Icon name="content" size="medium" color="none" />
        )}

        <div className="flex items-start gap-8px">
          <div>
            {fileName ? (
              <div>
                {isEdit ? (
                  <div className="flex gap-8px">
                    <Input
                      required
                      className="sign-up-form__first-name"
                      value={newFileName.split('.').shift()}
                      onChange={changeFileName}
                    />
                    <Button
                      size="tiny"
                      label={t('admin.delete_confirmation_modal_body.cancel_button_label')}
                      onClick={() => setIsEdit(false)}
                    />
                    <Button
                      label={t('admin.contract_details.rename_button')}
                      size="tiny"
                      type="success"
                      onClick={() => submitNameChange()}
                    />
                  </div>
                ) : (
                  <div>
                    <p className="choose-file__file-name margin-y-0">{fileName}</p>
                    <p className="choose-file__file-name margin-y-0">{`uploaded at ${moment
                      .parseZone(fileUploadedAt)
                      .locale('en')
                      .format('HH:mm UTC, DD MMM YYYY')} by ${userRole}`}</p>
                  </div>
                )}
              </div>
            ) : (
              <p className="choose-file__label margin-y-0">{label}</p>
            )}
          </div>
          {touched && errorMessage && (
            <Tooltip content={errorMessage} type="danger" position="bottom-right" gap={0}>
              <Icon className="margin-t-2px" name="invalid" color="danger" />
            </Tooltip>
          )}
        </div>
      </div>
      <div className="flex items-center">
        <DocumentManagement
          fileName={fileName}
          fileUrl={fileUrl}
          isEditable={isEditable}
          onChange={onChange}
          onDelete={(e) => {
            onChange(e);
            onDelete(e);
          }}
          handleClose={handleClose}
          handleEdit={handleEdit}
          handleDocuments={handleDocuments}
          documentsFormSubmit={documentsFormSubmit}
          disableDelete={disableDelete}
          hint={hint}
        />
      </div>
    </div>
  );
};

ChooseDocument.defaultProps = {
  className: null,
  label: null,
  hint: null,
  fileName: null,
  fileUploadedAt: null,
  fileUrl: null,
  userRole: null,
  errorMessage: null,
  documentsFormSubmit: null,
  handleDocuments: null,
  handleClose: null,
  handleRename: null,
  touched: false,
  isEditable: true,
  disableDelete: false,
  onChange: () => {},
  onDelete: () => {},
};

ChooseDocument.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  hint: PropTypes.string,
  fileName: PropTypes.string,
  fileUploadedAt: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fileUrl: PropTypes.string,
  userRole: PropTypes.string,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  touched: PropTypes.bool,
  isEditable: PropTypes.bool,
  disableDelete: PropTypes.bool,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  documentsFormSubmit: PropTypes.func,
  handleDocuments: PropTypes.func,
  handleRename: PropTypes.func,
  handleClose: PropTypes.func,
};

export default ChooseDocument;
