import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { TableData } from 'components/BaseComponents/Table';

const TableColumn = ({ content, className }) => {
  const { t } = useTranslation();
  return (
    <TableData
      align="left"
      className={className}
      heading={null}
      smallScreenConfig={{
        isFullWidth: false,
        isHeaderHidden: false,
        order: 1,
        showInTop: null,
      }}
      textCase="capitalize"
      unavailableText={t('admin_user_table.no_data_available')}
    >
      {content}
    </TableData>
  );
};

TableColumn.defaultProps = {
  content: null,
  className: '',
};

TableColumn.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
};

export default TableColumn;
