import React from 'react';
import ResetPasswordWrapper from 'components/ResetPassword/ResetPasswordWrapper';
import ResetPasswordForm from 'components/ResetPassword/Forms/ResetPasswordForm';

const ResetPasswordPage = () => (
  <ResetPasswordWrapper>
    <ResetPasswordForm />
  </ResetPasswordWrapper>
);

export default ResetPasswordPage;
