import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'components/BaseComponents/Icon';
import Tooltip from 'components/BaseComponents/Tooltip';
import FileManagement from './FileManagement';
import './style.scss';

const ChooseFile = ({
  className,
  label,
  hint,
  fileName,
  fileUrl,
  errorMessage,
  touched,
  onChange,
  onDelete,
  isEditable,
  disableDelete,
}) => (
  <div className={classNames('flex justify-between items-center choose-file', className)}>
    <div className="flex items-center gap-8px">
      {fileName ? (
        <Icon name="preference" size="medium" color="success" />
      ) : (
        <Icon name="content" size="medium" color="none" />
      )}

      <div className="flex items-start gap-8px">
        <div>
          <p className="choose-file__label margin-y-0">{label}</p>
          {fileName && <p className="choose-file__file-name margin-y-0">{fileName}</p>}
        </div>

        {touched && errorMessage && (
          <Tooltip content={errorMessage} type="danger" position="bottom-right" gap={0}>
            <Icon className="margin-t-2px" name="invalid" color="danger" />
          </Tooltip>
        )}
      </div>
    </div>
    <div className="flex items-center">
      <FileManagement
        fileName={fileName}
        fileUrl={fileUrl}
        isEditable={isEditable}
        onChange={onChange}
        onDelete={(e) => {
          onChange(e);
          onDelete(e);
        }}
        disableDelete={disableDelete}
        hint={hint}
      />
    </div>
  </div>
);

ChooseFile.defaultProps = {
  className: null,
  label: null,
  hint: null,
  fileName: null,
  fileUrl: null,
  errorMessage: null,
  touched: false,
  isEditable: true,
  disableDelete: false,
  onChange: () => {},
  onDelete: () => {},
};

ChooseFile.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  hint: PropTypes.string,
  fileName: PropTypes.string,
  fileUrl: PropTypes.string,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  touched: PropTypes.bool,
  isEditable: PropTypes.bool,
  disableDelete: PropTypes.bool,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};

export default ChooseFile;
