import React from 'react';
import { toast } from 'react-toastify';
import Toastify from 'components/Shared/ToastNotification/Toastify';
import { errorToastConfig, successToastConfig } from 'utils/constants/ToastConfig';

const TIMEOUT_DELAY = 500;

export const processPortalFormSuccess = (ref, formKey, toastConfig = successToastConfig) => {
  const { toastId } = errorToastConfig;

  toast.dismiss(toastId);
  ref?.current?.notify(<Toastify type="success" />, toastConfig);

  if (formKey) {
    const tabButton = document.querySelector(`[aria-controls=panel-${formKey}]`);
    if (tabButton) {
      setTimeout(() => {
        tabButton.click();
      }, TIMEOUT_DELAY);
    }
  }
};
