const SELLER = 'seller';
const BUYER = 'buyer';
const ADMIN = 'admin';
const AFFILIATE_PARTNER = 'affiliate_partner';
const SUBSCRIPTION_MEMBER = 'subscription_member';
const OWNER = 'owner';

const userRoleLabels = {
  [SELLER]: 'Seller',
  [BUYER]: 'Buyer',
  [ADMIN]: 'Admin',
  [OWNER]: 'Company Owner',
  [AFFILIATE_PARTNER]: 'Affiliate Partner',
  [SUBSCRIPTION_MEMBER]: 'Subscription Member',
};

export {
  SELLER,
  BUYER,
  ADMIN,
  AFFILIATE_PARTNER,
  SUBSCRIPTION_MEMBER,
  OWNER,
  userRoleLabels,
};
