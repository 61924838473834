import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import PropTypes from 'prop-types';
import RoleCheckbox from 'components/BaseComponents/CustomCheckbox';
import Button from 'components/BaseComponents/Button';
import FormFooter from 'components/SignUp/FormFooter';
import { BUYER, SELLER, SUBSCRIPTION_MEMBER } from 'utils/constants/userRole';
import './style.scss';

const Step1Form = ({ onFormSubmit }) => {
  const { t } = useTranslation();

  return (
    <div className="signup-form">
      <h2 className="signup-form__title">{t('signup.step1_form.title')}</h2>
      <p className="signup-form__message">{t('signup.step1_form.message')}</p>
      <p className="signup-form__heading">{t('signup.step1_form.card_heading')}</p>
      <Form
        onSubmit={onFormSubmit}
        validate={(state) => Object.values(state).some((checked) => checked)
          ? null
          : t('shared.form_validation.required_field')
        }
        render={({ handleSubmit, invalid, values }) => (
          <form onSubmit={handleSubmit} className="flex flex-col gap-12px">
            <Field type="checkbox" name={SELLER}>
              {({ input }) => (
                <RoleCheckbox
                  label={t('signup.step1_form.seller_label')}
                  containerClassName="flex justify-between"
                  iconName="seller"
                  name={input.name}
                  checked={input.checked}
                  onChange={input.onChange}
                  disabled={values[SUBSCRIPTION_MEMBER]}
                />
              )}
            </Field>
            <Field type="checkbox" name={BUYER}>
              {({ input }) => (
                <RoleCheckbox
                  label={t('signup.step1_form.buyer_label')}
                  name={input.name}
                  iconName="buyer"
                  containerClassName="flex justify-between"
                  checked={input.checked}
                  onChange={input.onChange}
                  disabled={values[SUBSCRIPTION_MEMBER]}
                />
              )}
            </Field>
            <Field type="checkbox" name={SUBSCRIPTION_MEMBER}>
              {({ input }) => (
                <RoleCheckbox
                  label={t('signup.step1_form.subscription_member_label')}
                  name={input.name}
                  iconName="tradingIcon"
                  containerClassName="flex justify-between"
                  checked={input.checked}
                  onChange={input.onChange}
                  disabled={values[SELLER] || values[BUYER]}
                />
              )}
            </Field>
            <Button
              className="signup-form__submit-button"
              label={t('signup.step1_form.submit_button')}
              disabled={invalid}
              type="success"
              submitType="submit"
            />
            <FormFooter />
          </form>
        )}
      />
    </div>
  );
};

Step1Form.defaultProps = {
  onFormSubmit: () => {},
};

Step1Form.propTypes = {
  onFormSubmit: PropTypes.func,
};

export default Step1Form;
