import React from 'react';
import { useTranslation } from 'react-i18next';
import { wholeSaleTradingCards } from 'utils/constants/wholeSaleMarketPlace';
import TradingCard from './Card';

const TradingCards = () => {
  const { t } = useTranslation();

  return (
    <div className="wholesale-marketplace__trading-cards">
      <p className="wholesale-marketplace__trading-cards--subheading">
        {t('wholesale_marketplace.trading_cards.subheading')}
      </p>
      <h2 className="wholesale-marketplace__trading-cards--heading">
        {t('wholesale_marketplace.trading_cards.heading')}
      </h2>
      <div className="wholesale-marketplace__trading-cards--container flex justify-between margin-x-auto">
        <div className="wholesale-marketplace__trading-cards--container-left flex flex-col justify-between">
          {wholeSaleTradingCards.slice(0, 3).map(({ heading, description, list }) => (
            <TradingCard
              key={heading}
              className="margin-t-24px"
              heading={heading}
              description={description}
              listItems={list}
            />
          ))}
        </div>
        <div className="wholesale-marketplace__trading-cards--container-right flex flex-col justify-between">
          {wholeSaleTradingCards.slice(3).map(({ heading, description, list }) => (
            <TradingCard
              key={heading}
              className="margin-t-24px"
              heading={heading}
              description={description}
              listItems={list}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TradingCards;
