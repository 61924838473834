import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { landingPage } from 'utils/constants/Routes';
import './styles.scss';

const BannerWithButton = ({
  className,
  subHeading,
  heading,
  ctaText,
  ctaRedirectLink,
  isRedirectLinkVisible,
}) => {
  const BannerContainerClassName = classNames(
    'banner-with-button flex flex-col items-center justify-center',
    className,
  );

  return (
    <div className={BannerContainerClassName}>
      <div className="banner-with-button__sub-heading">
        <div className="banner-with-button__sub-heading--text">{subHeading}</div>
      </div>
      <div className="banner-with-button__heading">
        <div className="banner-with-button__heading--text">{heading}</div>
      </div>
      {isRedirectLinkVisible && (
        <Link className="banner-with-button__cta-button" to={ctaRedirectLink}>
          {ctaText}
        </Link>
      )}
    </div>
  );
};

BannerWithButton.defaultProps = {
  className: '',
  subHeading: '',
  heading: '',
  ctaText: '',
  ctaRedirectLink: landingPage,
  isRedirectLinkVisible: true,
};

BannerWithButton.propTypes = {
  className: PropTypes.string,
  subHeading: PropTypes.string,
  heading: PropTypes.string,
  ctaText: PropTypes.string,
  ctaRedirectLink: PropTypes.string,
  isRedirectLinkVisible: PropTypes.bool,
};

export default BannerWithButton;
