/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '../Button';

const Pagination = (props) => {
  const {
    firstText,
    lastText,
    nextText,
    previousText,
    truncateText,
    currentPage,
    totalPages,
    windowSize,
  } = props;
  if (!totalPages || !currentPage) {
    return null;
  }
  if (totalPages === 1) {
    return null;
  }

  const pageWindow = [];
  const currentWindow = [];

  let i = currentPage - windowSize;
  while (i < currentPage) {
    if (i >= 1) {
      pageWindow.push(i);
    }
    i += 1;
  }

  pageWindow.push(currentPage);

  i = currentPage + 1;
  while (i <= currentPage + windowSize && i <= totalPages) {
    pageWindow.push(i);
    i += 1;
  }

  pageWindow.forEach((page) => {
    const content = (
      <Button
        key={page}
        label={page}
        onClick={() => (currentPage === page ? null : props.onPaginationClick(page))}
        type={currentPage === page ? 'success' : 'default'}
        size="small"
      />
    );

    currentWindow.push(content);
  });

  return (
    <nav className={classNames('pagination', props.className)}>
      <Button
        label={firstText}
        onClick={() => props.onPaginationClick(1)}
        type="default"
        size="small"
        disabled={currentPage <= 1}
      />

      <Button
        disabled={currentPage <= 1}
        label={previousText}
        onClick={() => props.onPaginationClick(currentPage - 1)}
        type="default"
        size="small"
      />

      {currentPage > windowSize + 1 && (
        <Button label={truncateText} onClick={() => {}} type="default" size="small" />
      )}

      {currentWindow}

      {currentPage + windowSize < totalPages && (
        <Button label={truncateText} onClick={() => {}} type="default" size="small" />
      )}

      <Button
        disabled={currentPage === totalPages}
        label={nextText}
        onClick={() => props.onPaginationClick(currentPage + 1)}
        type="default"
        size="small"
      />

      <Button
        label={lastText}
        onClick={() => props.onPaginationClick(totalPages)}
        type="default"
        size="small"
        disabled={currentPage === totalPages}
      />
    </nav>
  );
};

Pagination.defaultProps = {
  windowSize: 4,
  currentPage: null,
  totalPages: null,
  className: '',
  firstText: '',
  lastText: '',
  nextText: 'Next',
  previousText: 'Prev',
  truncateText: '....',
};

Pagination.propTypes = {
  windowSize: PropTypes.number,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  onPaginationClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  firstText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  lastText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  nextText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  previousText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  truncateText: PropTypes.string,
};

export default Pagination;
