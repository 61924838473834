import { t } from 'i18next';

export const contractPDFtermsAndConditions = [
  {
    title: t('contract_pdf.general_description_title'),
    description: [
      t('contract_pdf.general_description_1'),
      t('contract_pdf.general_description_2'),
      t('contract_pdf.general_description_3'),
      t('contract_pdf.general_description_4'),
    ],
  },
  {
    title: t('contract_pdf.representation_and_liability_of_msm_title'),
    description: [
      t('contract_pdf.representation_and_liability_of_msm_1'),
      t('contract_pdf.representation_and_liability_of_msm_2'),
    ],
  },
  {
    title: t('contract_pdf.representation_and_liability_of_trader_title'),
    description: [
      t('contract_pdf.representation_and_liability_of_trader_1'),
      t('contract_pdf.representation_and_liability_of_trader_2'),
      t('contract_pdf.representation_and_liability_of_trader_3'),
      t('contract_pdf.representation_and_liability_of_trader_4'),
      t('contract_pdf.representation_and_liability_of_trader_5'),
      t('contract_pdf.representation_and_liability_of_trader_6'),
    ],
  },
  {
    title: t('contract_pdf.warrants_title'),
    description: [
      t('contract_pdf.warrants_1'),
      t('contract_pdf.warrants_2'),
      t('contract_pdf.warrants_3'),
    ],
  },
  {
    title: t('contract_pdf.insurance_title'),
    description: [t('contract_pdf.insurance_1'), t('contract_pdf.insurance_2')],
  },
  {
    title: t('contract_pdf.arbitration_title'),
    description: [t('contract_pdf.arbitration')],
  },
  {
    title: t('contract_pdf.dispute_title'),
    description: [t('contract_pdf.dispute')],
  },
  {
    title: t('contract_pdf.force_majeure_title'),
    description: [t('contract_pdf.force_majeure')],
  },
  {
    title: t('contract_pdf.terms_remarks_title'),
    description: [t('contract_pdf.terms_remarks')],
  },
  {
    title: t('contract_pdf.changes_in_duty_title'),
    description: [t('contract_pdf.changes_in_duty_1'), t('contract_pdf.changes_in_duty_2')],
  },
  {
    title: t('contract_pdf.customs_requirement_title'),
    description: [t('contract_pdf.customs_requirement')],
  },
];
