import React from 'react';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { formatDate } from 'utils/helper/FormatDate';
import { appliedTab } from 'utils/constants/portals';
import { VERIFIED, REQUESTED_VERIFICATION } from 'utils/constants/userStatus';
import { admin } from 'utils/constants/Routes';
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/BaseComponents/Table';
import Icon from 'components/BaseComponents/Icon';
import TableColumn from 'components/Portals/Admin/UserManagement/TableColumn';
import LoadingSpinner from 'components/BaseComponents/LoadingSpinner';

const formattedUserStatus = {
  [VERIFIED]: t('admin_user_table.approved_tab'),
  [REQUESTED_VERIFICATION]: t('admin_user_table.unapproved_tab'),
};

const generateUserStatusColumnContent = (company) => formattedUserStatus[company.status];
const generateUserIdContent = (tabkey, id, serialNumber) => {
  if (tabkey === appliedTab) {
    return id;
  }
  return serialNumber;
};
const generateDateColumnContent = (tabkey, approvedDate, appliedDate) => {
  if (tabkey === appliedTab) {
    return formatDate(appliedDate);
  }

  return formatDate(approvedDate);
};

const PartnerTable = ({ isUsersFetching, tabKey, tableHeaders, users }) => (
  <Table className="admin-portal__table">
    <TableHead>
      <TableRow isHeaderRow={true}>
        {tableHeaders.map((title) => (
          <TableHeader
            key={title}
            textCase="capitalize"
            unavailableText={t('admin_user_table.no_data_available')}
          >
            {title}
          </TableHeader>
        ))}
      </TableRow>
    </TableHead>

    {isUsersFetching ? (
      <TableBody className="position-relative padding-y-32px">
        <LoadingSpinner />
      </TableBody>
    ) : (
      <TableBody>
        {users?.map(({ firstName, lastName, company, id, email, serialNumber, confirmedAt }) => {
          const { name: companyName, status: companyStatus, requestedVerificationAt } = company;
          const hasGreenStatus = companyStatus === VERIFIED;
          const userStatusColumnContent = generateUserStatusColumnContent(company);

          return (
            <TableRow key={id}>
              <TableColumn content={generateUserIdContent(tabKey, id, serialNumber)} />
              <TableColumn content={`${firstName} ${lastName}`} />
              <TableColumn content={companyName} />
              <TableColumn content={email} />
              <TableColumn
                className={classNames('admin-portal__table--role', {
                  'admin-portal__table--role-green-text': hasGreenStatus,
                })}
                content={userStatusColumnContent}
              />
              <TableColumn
                content={generateDateColumnContent(tabKey, confirmedAt, requestedVerificationAt)}
              />
              <TableColumn
                content={
                  <Link to={admin.affiliatePartnerProfile(id)}>
                    <Icon name="show" size="normal" className="admin-portal__table--icon" />
                  </Link>
                }
              />
            </TableRow>
          );
        })}
      </TableBody>
    )}
  </Table>
);
PartnerTable.defaultProps = {
  users: [],
};

PartnerTable.propTypes = {
  tableHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  isUsersFetching: PropTypes.bool.isRequired,
  tabKey: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      offer_volume: PropTypes.string,
      offer_price: PropTypes.string,
      nut_grade: PropTypes.string,
      nut_style: PropTypes.string,
      best_before_date: PropTypes.string,
      country_of_origin: PropTypes.string,
      estimated_shipping_date: PropTypes.string,
      full_batch_price: PropTypes.string,
      listing: PropTypes.shape({
        id: PropTypes.number,
        serial_number: PropTypes.string,
        status: PropTypes.string,
        category: PropTypes.string,
        highest_bid_price: PropTypes.string,
        total_bid_count: PropTypes.string,
        product_id: PropTypes.string,
        created_at: PropTypes.string,
      }),
    }),
  ),
};

export default PartnerTable;
