import React, { useState } from 'react';
import { t as translation } from 'i18next';
import { useGetProductsQuery } from 'api/products';
import { getProductsTableTabs } from 'utils/helper/Portals';
import { useGetContractsQuery } from 'api/contracts';
import { RTabs } from 'components/BaseComponents/RTabs';
import Pagination from 'components/BaseComponents/Pagination';
import Button from 'components/BaseComponents/Button';
import './styles.scss';

import 'components/Portals/Admin/UserManagement/Table/styles.scss';
import EmptyContentPlaceholder from 'components/Shared/EmptyContentPlaceholder';
import { ADMIN_LISTINGS_PER_PAGE, OFFERS, ORDERS, CONTRACTS } from 'utils/constants/offerListings';
import { DRIED_NUT_IN_SHELL_PRODUCT, KERNEL_PRODUCT } from 'utils/constants/product';

function ListingManagementTable() {
  const [productListingType, setProductListingType] = useState(CONTRACTS);
  const [currentPage, setCurrentPage] = useState(1);
  const [productType, setProducttType] = useState(KERNEL_PRODUCT);
  const [clickedColumn, setClickedColumn] = useState(null);
  const [clickedDirection, setClickedDirection] = useState(null);

  const [queryKey, setQueryKey] = useState(0);

  const { data: listings = {}, isFetching: fetchingProducts } = useGetProductsQuery({
    key: queryKey,
    page: currentPage,
    listing_type: productListingType,
    product_type: productType,
    per_page: ADMIN_LISTINGS_PER_PAGE,
    column: clickedColumn,
    direction: clickedDirection,
  });

  const { data: contracts = {}, isFetching: fetchingContracts } = useGetContractsQuery({
    key: queryKey,
    page: currentPage,
    product_type: productType,
    per_page: ADMIN_LISTINGS_PER_PAGE,
    column: clickedColumn,
    direction: clickedDirection,
  });

  const handleColumnClick = (column) => {
    if (column !== clickedColumn) {
      setClickedColumn(column);
      setClickedDirection('up');
    } else {
      setClickedDirection(clickedDirection === 'up' ? 'down' : 'up');
    }
    setQueryKey(prevKey => prevKey + 1); // increment queryKey to trigger a re-fetch
  };

  const isListingExists = listings?.collection?.collection?.length > 0;

  const totalPages = Number(listings?.pagination?.totalPages);
  const tableCaption = translation('admin.listings_table.title');
  const filterTabs = {
    [translation('admin.listings_table.contracts_tab')]: CONTRACTS,
    [translation('admin.listings_table.offers_tab')]: OFFERS,
    [translation('admin.listings_table.orders_tab')]: ORDERS,
  };

  const isFetching = fetchingProducts || fetchingContracts;

  const tabItems = getProductsTableTabs(
    handleColumnClick,
    isFetching,
    productType,
    listings?.collection,
    contracts?.collection,
  );

  const handleTabChange = (tabKey) => {
    setCurrentPage(1);
    setProductListingType(filterTabs[tabKey]);
  };

  const handlePagination = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  return (
    <div className="admin-portal__table--container">
      <h5 className="admin-portal__table--caption">{tableCaption}</h5>
      <div className="listing-management-table__product-type">
        <Button
          type={productType === KERNEL_PRODUCT ? 'success' : null}
          className="listing-management-table__product-type__kernel"
          label={translation('admin.listings_table.kernel_tab')}
          onClick={() => setProducttType(KERNEL_PRODUCT)}
        />
        <Button
          type={productType === DRIED_NUT_IN_SHELL_PRODUCT ? 'success' : null}
          className="listing-management-table__product-type__dried_nut_in_shell"
          label={translation('admin.listings_table.dried_nut_in_shell_tab')}
          onClick={() => setProducttType(DRIED_NUT_IN_SHELL_PRODUCT)}
        />
      </div>
      <RTabs
        className="admin-portal__table--tab"
        isVertical={false}
        items={tabItems}
        selectedTabKey={tabItems[0].key}
        onChange={handleTabChange}
      />
      {!isListingExists && !fetchingProducts && (
        <EmptyContentPlaceholder
          heading={translation('shared.bidding_process.empty_content_placeholder.heading')}
        />
      )}
      {totalPages > 1 && !fetchingProducts && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPaginationClick={handlePagination}
          firstText={translation('admin_user_table.first_page')}
          lastText={translation('admin_user_table.last_page')}
          nextText={translation('admin_user_table.next_page')}
          previousText={translation('admin_user_table.previous_page')}
          className="admin-portal__table--pagination"
        />
      )}
    </div>
  );
}

export default ListingManagementTable;
