import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { Link, useSearchParams } from 'react-router-dom';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { generateSetPasswordBody } from 'utils/helper/RequestBody';
import { handlePasswordValidationErrors, requiredField } from 'utils/helper/Validations';
import { termsAndConditionPDF } from 'utils/constants/pdf';
import Button from 'components/BaseComponents/Button';
import CheckBox from 'components/BaseComponents/CheckBox';
import PasswordInput from 'components/Shared/PasswordInput';
import ToastNotification from 'components/Shared/ToastNotification';
import LeftBanner from 'components/SignUp/LeftBanner';
import { useCompleteSignUpMutation } from 'api/users';

const JoinCompany = () => {
  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const [searchParams] = useSearchParams();
  const verificationToken = searchParams.get('token');
  const toastRef = useRef();
  const [setInvitedBuyerPassword] = useCompleteSignUpMutation();

  const handleSetPassword = (params) => {
    if (params.password !== params.password_confirmation) {
      return {
        password: t('signup.step2_form.validation_error.password_not_matched'),
        password_confirmation: t('signup.step2_form.validation_error.password_not_matched'),
      };
    }

    const requestBody = generateSetPasswordBody(params, verificationToken);

    return setInvitedBuyerPassword(requestBody)
      .unwrap()
      .then(() => navigate.profile())
      .catch((err) => {
        const errorStatus = err.status;
        const errorMessage = err.data?.errors?.password;

        return handlePasswordValidationErrors(toastRef, errorStatus, errorMessage);
      });
  };

  const termsLabel = (
    <>
      <span className="signup-form__policy-content--content1">
        {t('affiliate_partners.invite_buyer.acknowledgement_text1')}
      </span>
      <Link
        to={termsAndConditionPDF}
        target="_blank"
        className="signup-form__policy-content--redirect-link"
      >
        {t('signup.step2_form.terms_body.redirect_link')}
      </Link>
      <span className="signup-form__policy-content--content2">
        {t('affiliate_partners.invite_buyer.acknowledgement_text2')}
      </span>
    </>
  );

  return (
    <div className="signup flex">
      <div className="signup__left-container">
        <LeftBanner title={t('affiliate_partners.invite_buyer.take_your_business_to_global')} />
      </div>
      <div className="signup__right-container">
        <div className="form-header">
          <div className="flex justify-between items-center margin-b-32px">
            <h4 className="form-header__title">
              {t('signup.header.title', { currentStep: 1, totalSteps: 1 })}
            </h4>
            <Button
              className="form-header__cancel-button"
              label={t('signup.header.button_content')}
              onClick={() => navigate.loginPage()}
            />
          </div>
          <div className="flex gap-12px">
            <div className="form-header__steps form-header__steps--completed-full" />
          </div>
        </div>
        <div className="sign-up-form">
          <h2 className="sign-up-form__title">{t('affiliate_partners.invite_buyer.welcome')}</h2>
          <p className="sign-up-form__message">
            {t('affiliate_partners.invite_buyer.please_set_password')}
          </p>
          <Form
            onSubmit={handleSetPassword}
            render={({ handleSubmit, invalid, dirtySinceLastSubmit, submitting }) => (
              <form onSubmit={handleSubmit} className="margin-t-16px">
                <Field name="password" validate={requiredField}>
                  {({ input, meta }) => (
                    <PasswordInput
                      label={t('signup.step2_form.password.label')}
                      placeholder={t('signup.step2_form.password.placeholder')}
                      required
                      size="small"
                      className="margin-t-20px margin-b-32px"
                      error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      value={input.value}
                      onChange={input.onChange}
                    />
                  )}
                </Field>
                <Field name="password_confirmation" validate={requiredField}>
                  {({ input, meta }) => (
                    <PasswordInput
                      label={t('signup.step2_form.confirm_password.label')}
                      placeholder={t('signup.step2_form.confirm_password.placeholder')}
                      required
                      size="small"
                      className="margin-t-20px margin-b-40px"
                      error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      value={input.value}
                      onChange={input.onChange}
                    />
                  )}
                </Field>
                <h5 className="signup-form__form-title">
                  {t('signup.step2_form.checkbox_card_title')}
                  <span className="signup-form__form-title--required">*</span>
                </h5>

                <Field name="is_accept_terms" type="checkbox" validate={requiredField}>
                  {({ input, meta }) => (
                    <CheckBox
                      id="terms&condition"
                      label={termsLabel}
                      className="signup-form__policy-content margin-t-16px margin-b-16px"
                      hintText={meta.touched && meta.error}
                      isChecked={input.checked}
                      onChange={(checked) => {
                        input.onChange(checked);
                      }}
                    />
                  )}
                </Field>
                <Field name="is_accept_credit_check" type="checkbox" validate={requiredField}>
                  {({ input, meta }) => (
                    <CheckBox
                      id="creditcheck"
                      label={t('signup.step2_form.credit_check_body')}
                      className="signup-form__policy-content margin-t-16px margin-b-32px"
                      size="small"
                      hintText={meta.touched && meta.error}
                      isChecked={input.checked}
                      onChange={(checked) => {
                        input.onChange(checked);
                      }}
                    />
                  )}
                </Field>

                <Button
                  className="margin-b-20px margin-t-32px sign-up-form__submit-btn"
                  type="success"
                  label={t('affiliate_partners.invite_buyer.register')}
                  submitType="submit"
                  disabled={(!dirtySinceLastSubmit && invalid) || submitting}
                />
              </form>
            )}
          />

          <ToastNotification toastRef={toastRef} />
        </div>
      </div>
    </div>
  );
};

export default JoinCompany;
