import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { handleServerValidationErrors } from "utils/helper/Validations";
import { Form, Field } from "react-final-form";
import { generateUpdateRolesBody } from "utils/helper/RequestBody";
import { processPortalFormSuccess } from "utils/helper/FormHandlers";
import Button from "components/BaseComponents/Button";
import CheckBox from "components/BaseComponents/CheckBox";
import { useGetUserQuery } from "api/users";
import { useUpdateRolesMutation } from "api/admin";
import "./styles.scss";

const UserRolesForm = ({ userId, toastRef }) => {
  const { t } = useTranslation();

  const { data: userDetails = {}, isFetching } = useGetUserQuery({ id: userId });
  const [updateRoles] = useUpdateRolesMutation();

  const handleFormSubmit = (values) => {
    const requestBody = generateUpdateRolesBody(values);

    return updateRoles({ userId, payload: requestBody })
      .unwrap()
      .then(() => processPortalFormSuccess(toastRef))
      .catch(({ data: { message }, status }) =>
        handleServerValidationErrors(toastRef, status, message),
      );
  };

  const initValue = {
    buyer: userDetails.roles.includes("buyer"),
    seller: userDetails.roles.includes("seller"),
    owner: userDetails.roles.includes("owner"),
  }

  return (
    <div className="user-roles-form padding-t-32px">
      <h3 className="user-roles-form__title padding-x-24px">
        {t("admin.user_roles.heading")}
      </h3>
      <Form
        onSubmit={handleFormSubmit}
        initialValues={initValue}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} className="padding-y-32px">
            <fieldset>
              <div className="padding-x-24px">
                <Field name="buyer" type="checkbox">
                  {({ input, meta }) => (
                    <CheckBox
                      className="user-roles-form__role-checkbox"
                      label={t("admin.user_roles.buyer")}
                      isChecked={input.checked}
                      onChange={input.onChange}
                      hintText={meta.submitError}
                      disabled={isFetching && submitting}
                    />
                  )}
                </Field>

                <Field name="seller" type="checkbox">
                  {({ input, meta }) => (
                    <CheckBox
                      className="user-roles-form__role-checkbox"
                      label={t("admin.user_roles.seller")}
                      isChecked={input.checked}
                      onChange={input.onChange}
                      hintText={meta.submitError}
                      disabled={isFetching || submitting}
                    />
                  )}
                </Field>

                <Field name="owner" type="checkbox">
                  {({ input, meta }) => (
                    <CheckBox
                      className="user-roles-form__role-checkbox"
                      label={t("admin.user_roles.owner")}
                      isChecked={input.checked}
                      onChange={input.onChange}
                      hintText={meta.submitError}
                      disabled={isFetching || submitting}
                    />
                  )}
                </Field>

                <Button
                  type="success"
                  label={t("admin.user_roles.submit")}
                  submitType="submit"
                  className="user-roles-form__submit-btn margin-t-24px"
                  disabled={submitting || isFetching}
                />
              </div>
            </fieldset>
          </form>
        )}
      />
    </div>
  );
};

UserRolesForm.propTypes = {
  userId: PropTypes.number.isRequired,
  toastRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default UserRolesForm;
