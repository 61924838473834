import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';
import { countryCodeToName } from 'utils/helper/selectBoxUtils';
import { styles } from '../styles';

const getFullAddress = (addressLine1, addressLine2) => {
  if (addressLine2) return `${addressLine1} ${addressLine2}`;
  return addressLine1;
};

const UserCards = ({ sellerDetails, buyerDetails }) => {
  const buyerCompanyAddress = buyerDetails?.companyDomicileAddress;
  const sellerCompanyAddress = sellerDetails?.companyDomicileAddress;
  const buyerCountry = countryCodeToName(buyerCompanyAddress?.countryCode);
  const sellerCountry = countryCodeToName(sellerCompanyAddress?.countryCode);
  const buyerAddress = getFullAddress(buyerCompanyAddress?.line1, buyerCompanyAddress?.line2);
  const sellerAddress = getFullAddress(sellerCompanyAddress?.line1, sellerCompanyAddress?.line2);

  return (
    <Fragment>
      <View style={styles.userCardContainer}>
        <View style={styles.userCard}>
          <Text style={styles.userCardTitle}>{t('contract_pdf.seller')}</Text>
          <View>
            <Text style={styles.userCardCompany}>{sellerDetails.companyName}</Text>
            <Text style={styles.userCardRegistration}>
              {sellerDetails.companyRegistrationNumber}
            </Text>
          </View>
          <View style={styles.userCardList}>
            <Text>{sellerAddress}</Text>
            <Text>{sellerCompanyAddress?.city}</Text>
            <Text>{sellerCompanyAddress?.zipCode}</Text>
            <Text>{sellerCountry}</Text>
          </View>
        </View>

        <View style={styles.userCard}>
          <Text style={styles.userCardTitle}>{t('contract_pdf.buyer')}</Text>
          <View>
            <Text style={styles.userCardCompany}>{buyerDetails.companyName}</Text>
            <Text style={styles.userCardRegistration}>
              {buyerDetails.companyRegistrationNumber}
            </Text>
          </View>
          <View style={styles.userCardList}>
            <Text>{buyerAddress}</Text>
            <Text>{buyerCompanyAddress?.city}</Text>
            <Text>{buyerCompanyAddress?.zipCode}</Text>
            <Text>{buyerCountry}</Text>
          </View>
        </View>
      </View>
      <hr style={styles.lineBreak} />
    </Fragment>
  );
};

UserCards.propTypes = {
  sellerDetails: PropTypes.shape({
    companyName: PropTypes.string,
    companyRegistrationNumber: PropTypes.string,
    companyDomicileAddress: PropTypes.shape({
      line1: PropTypes.string,
      line2: PropTypes.string,
      city: PropTypes.string,
      zipCode: PropTypes.string,
      countryCode: PropTypes.string,
    }),
  }).isRequired,
  buyerDetails: PropTypes.shape({
    companyName: PropTypes.string,
    companyRegistrationNumber: PropTypes.string,
    companyDomicileAddress: PropTypes.shape({
      line1: PropTypes.string,
      line2: PropTypes.string,
      city: PropTypes.string,
      zipCode: PropTypes.string,
      countryCode: PropTypes.string,
    }),
  }).isRequired,
};

export default UserCards;
