import { createContext } from 'react';
import { createContextualCan } from '@casl/react';
import { subject } from "@casl/ability";

export const LISTING_ABILITY_SUBJECT = 'LISTING_ABILITY_SUBJECT';
export const listingSubject = (listing) => subject(LISTING_ABILITY_SUBJECT, {  ...listing });

export const NEW_LISTING_ABILITY_SUBJECT = 'NEW_LISTING_ABILITY_SUBJECT';
export const newListingSubject = (listing) => subject(NEW_LISTING_ABILITY_SUBJECT, {  ...listing });

export const USER_ABILITY_SUBJECT = 'USER_ABILITY_SUBJECT';
export const USER_PROFILE_SUBJECT = 'USER_PROFILE_SUBJECT';
export const userSubject = (user) => subject(USER_ABILITY_SUBJECT, {  ...user });
export const userProfileSubject = (user) => subject(USER_PROFILE_SUBJECT, {  ...user });

export const COMPANY_ABILITY_SUBJECT = 'COMPANY_ABILITY_SUBJECT';
export const companySubject = (company) => subject(COMPANY_ABILITY_SUBJECT, {  ...company });

export const USER_MANAGEMENT_SUBJECT = "USER_MANAGEMENT_SUBJECT";
export const BUYER_ABLITY_SUBJECT = "BUYER_ABLITY_SUBJECT";

export const BUSINESS_CONTACTS_TAB_SUBJECT = 'BUSINESS_CONTACTS_TAB_SUBJECT';
export const BUSINESS_DETAILS_TAB_SUBJECT = 'BUSINESS_DETAILS_TAB_SUBJECT';
export const FOOD_SAFETY_TAB_SUBJECT = 'FOOD_SAFETY_TAB_SUBJECT';
export const PERMISSIONS_TAB_SUBJECT = 'PERMISSIONS_TAB_SUBJECT';
export const ADDITIONAL_DOCUMENT_TAB_SUBJECT = 'ADDITIONAL_DOCUMENT_TAB_SUBJECT';
export const BANKING_DETAILS_TAB_SUBJECT = 'BANKING_DETAILS_TAB_SUBJECT';
export const COMPANY_MANAGEMENT_TAB_SUBJECT = 'COMPANY_MANAGEMENT_TAB_SUBJECT';

export const PERSONAL_DETAILS_TAB_SUBJECT = 'PERSONAL_DETAILS_TAB_SUBJECT';
export const USER_ROLES_FORM_SUBJECT = 'USER_ROLES_FORM_SUBJECT';
export const COMPANY_PPERMISSIONS_FORM_SUBJECT = 'COMPANY_PPERMISSIONS_FORM_SUBJECT';

export const AbilityContext = createContext();
export const Can = createContextualCan(AbilityContext.Consumer);
