import { t } from 'i18next';

export const FOLLOWING_TAB = 'followingTab';
export const OUTBID_TAB = 'outbidTab';
export const LEADING_BID_TAB = 'leadingBidTab';

export const biddingBasketFilter = {
  followingTab: t('shared.bidding_basket.following'),
  outbidTab: t('shared.bidding_basket.outbid'),
  leadingBidTab: t('shared.bidding_basket.leading_bid'),
};

export const BIDDING_BASKET_PER_PAGE = 10
