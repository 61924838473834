import { useGetCurrentUserQuery } from 'api/users';
import { useGetCompanyQuery } from 'api/companies';
import { useGetFoodSafetyTestQuery, useGetProductDetailsQuery } from 'api/products';
import { hasSellerRole } from 'utils/helper/UserRoleValidation';
import { formatDate, getDatePriorDays } from 'utils/helper/FormatDate';
import {
  declarationContentsBuyerCompletedDeclaration,
  declarationContentsSellerCompletedDeclaration,
  getDeclarationContentsBuyerAuthorization,
  getDeclarationContentsSellerAuthorization,
  getDeclarationContentsSkippedDeclaration,
} from 'utils/constants/listingCreation';

const useGetDeclarationContents = ({ productId }) => {
  const { data: user = {} } = useGetCurrentUserQuery();
  const { data: company = {} } = useGetCompanyQuery({ id: user?.company_id }, { skip: !user?.id });
  const { data: foodSafetyTestDetails = {} } = useGetFoodSafetyTestQuery({ productId });
  const { data: productDetails = {} } = useGetProductDetailsQuery(
    { product_id: productId },
    { skip: !productId },
  );

  const isSeller = hasSellerRole(user);
  const isFoodSafetySkipped = isSeller && !foodSafetyTestDetails?.productId;

  const estimatedShippingDate = formatDate(productDetails?.estimatedShippingDate);
  const priorDate = getDatePriorDays(productDetails?.estimatedShippingDate, 14);

  const declarationContentsSkippedDeclaration = getDeclarationContentsSkippedDeclaration(
    priorDate,
    estimatedShippingDate,
  );

  const declarationContentsCompletedDeclaration = isSeller
    ? declarationContentsSellerCompletedDeclaration
    : declarationContentsBuyerCompletedDeclaration;

  const declarationSubSectionContents = isFoodSafetySkipped
    ? declarationContentsSkippedDeclaration
    : declarationContentsCompletedDeclaration;

  const declarationContentsAuthorization = isSeller
    ? getDeclarationContentsSellerAuthorization(user?.first_name, user?.last_name, company?.name)
    : getDeclarationContentsBuyerAuthorization(user?.first_name, user?.last_name, company?.name);

  return [declarationSubSectionContents, declarationContentsAuthorization];
};

export default useGetDeclarationContents;
