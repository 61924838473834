import React from 'react';
import { t } from 'i18next';
import Icon from 'components/BaseComponents/Icon';

export const RequestSentModal = () => (
  <div className="request-assistance__modal--request-sent flex flex-col items-center">
    <Icon
      name="checkCircleOutline"
      className="request-assistance__request-sent-icon"
      size="medium"
    />
    <p>{t('shared.bidding_process.contract_details.request_assistance.request_sent')}</p>
  </div>
);
