import { React } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { labelWithHints } from 'utils/helper/LabelWithHints';
import { ppecbCertificate, globalGapCertificate, esgCertificate } from 'utils/constants/listingCreation';
import { useGetFoodSafetyDocumentsQuery } from 'api/FoodSafety';
import TestCertificates from '../TestCertificates';
import './styles.scss';

const DriedNutInShellSection = ({ companyId }) => {
  const { data: foodSafetyDocuments = {} } = useGetFoodSafetyDocumentsQuery(
    { id: companyId },
    {
      skip: !companyId,
    },
  );

  return (
    <div className="batch-details offer-food-safety-padding flex flex-col">
      <span className="flex flex-row">
        {labelWithHints(
          t('offer_creation.food_safety.dried_nut_in_shell_section.title'),
          t('offer_creation.food_safety.dried_nut_in_shell_section.tooltip'),
        )}
        {(foodSafetyDocuments?.ppecb_company_certificate?.id === undefined &&
          foodSafetyDocuments?.global_gap_certificate?.id === undefined &&
          foodSafetyDocuments?.esg_company_certificate?.id === undefined) && (
          <span className="business-details__tooltip-section--required">*</span>
        )}
      </span>

      <TestCertificates testType={ppecbCertificate} isOptional={true} />
      <TestCertificates testType={globalGapCertificate} isOptional={true} />
      <TestCertificates testType={esgCertificate} isOptional={true} />
    </div>
  );
};

DriedNutInShellSection.defaultProps = {
  companyId: 0,
};

DriedNutInShellSection.propTypes = {
  companyId: PropTypes.number,
};

export default DriedNutInShellSection;
