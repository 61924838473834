import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Button from 'components/BaseComponents/Button';
import useCustomNavigate from 'hooks/useCustomNavigate';
import './style.scss';

const FormHeader = ({ currentStep }) => {
  const { t } = useTranslation();
  const navigate = useCustomNavigate();

  return (
    <div className="form-header">
      <div className="flex justify-between items-center margin-b-32px">
        <h4 className="form-header__title">
          {t('signup.header.title', { currentStep, totalSteps: 2 })}
        </h4>
        <Button
          className="form-header__cancel-button"
          label={t('signup.header.button_content')}
          onClick={() => navigate.loginPage()}
        />
      </div>
      <div className="flex gap-12px">
        <div
          className={classNames('form-header__steps', {
            'form-header__steps--completed': currentStep === 1 || currentStep === 2,
          })}
        />
        <div
          className={classNames('form-header__steps form-header__steps--in-completed', {
            'form-header__steps--completed': currentStep === 2,
            'form-header__steps--in-completed': currentStep !== 2,
          })}
        />
      </div>
    </div>
  );
};

FormHeader.propTypes = {
  currentStep: PropTypes.number,
};

FormHeader.defaultProps = {
  currentStep: 1,
};

export default FormHeader;
