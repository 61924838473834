import { t } from 'i18next';
import { PRICE_CHART_DATALINE_KEY, VOLUME_CHART_DATALINE_KEY } from './Charts';

const chartAxis = {
  x: true,
  y: true,
};

const chartMargin = {
  bottom: 0,
  left: 0,
  right: 0,
  top: 0,
};

const priceChartDataLines = [PRICE_CHART_DATALINE_KEY];

const priceChartColors = {
  avg: 'green',
};

const priceChartData = [
  {
    avg: 3.1,
    name: 'Jan',
  },
  {
    avg: 5.0,
    name: 'Mar',
  },
  {
    avg: 5.5,
    name: 'May',
  },
  {
    avg: 3.5,
    name: 'Jul',
  },
  {
    avg: 5.0,
    name: 'Sep',
  },
  {
    avg: 4.8,
    name: 'Nov',
  },
  {
    avg: 4.2,
    name: 'Dec',
  },
];

const volumeChartDataLines = [VOLUME_CHART_DATALINE_KEY];

const volumeChartColors = {
  avg: 'red-500',
};

const volumeChartData = [
  {
    avg: 12000,
    name: 'Jan',
  },
  {
    avg: 25000,
    name: 'Mar',
  },
  {
    avg: 33000,
    name: 'May',
  },
  {
    avg: 39000,
    name: 'Jul',
  },
  {
    avg: 22000,
    name: 'Sep',
  },
  {
    avg: 15000,
    name: 'Nov',
  },
  {
    avg: 12000,
    name: 'Dec',
  },
];

export const priceChartOptions = {
  [PRICE_CHART_DATALINE_KEY]: {
    name: t("aggregate_sales.price_chart.legend_text"),
    color: "green",
    legendType: "rect",
  }
};

export const volumeChartOptions = {
  [VOLUME_CHART_DATALINE_KEY]: {
    name: t("aggregate_sales.volume_chart.legend_text"),
    color: "red-500",
    legendType: "rect",
  }
};

export const priceChartCollection = {
  chartAxis,
  chartMargin,
  chartDataLines: priceChartDataLines,
  chartColors: priceChartColors,
  chartData: priceChartData,
};

export const volumeChartCollection = {
  chartAxis,
  chartMargin,
  chartDataLines: volumeChartDataLines,
  chartColors: volumeChartColors,
  chartData: volumeChartData,
};
