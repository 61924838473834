import React, { useContext, useState } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'components/BaseComponents/Icon';
import Input from 'components/BaseComponents/Input';
import { useUpdateOfferPriceMutation } from 'api/listings';
import IconButton from 'components/BaseComponents/IconButton';
import { formatPrice, formatVolume } from 'utils/helper/Charts';
import ListingDetailsContext from 'components/BiddingProcess/ListingDetails/ListingDetailsContext';
import './styles.scss';

const AdjustOfferOrderPriceModal = ({ onModalClose, highestBidPrice = 0, offerVolume }) => {
  const { listingInformation, isOfferListing } = useContext(ListingDetailsContext);
  const [offeredPrice, setOfferedPrice] = useState('');

  const [updateOfferPrice, { isLoading }] = useUpdateOfferPriceMutation();

  const totalBatchPrice = `USD ${formatPrice(offerVolume * offeredPrice)}`;
  const changePriceNotice = t('bidding_process.change_price_note', {
    highestBidPrice: formatPrice(highestBidPrice),
  });
  const totalBatchPriceBreakdown = ` (${formatVolume(offerVolume)} kg x USD ${
    formatPrice(offeredPrice) || 0
  } ${t('bidding_process.per_kg')})`;
  const isAdjustOfferButtonDisabled = !offeredPrice?.length || isLoading;
  const inputLabel = isOfferListing
    ? t('bidding_process.new_offer_price_label')
    : t('bidding_process.new_order_price_label');
  const inputPlaceholder = isOfferListing
    ? t('bidding_process.new_offer_price_placeholder')
    : t('bidding_process.new_order_price_placeholder');

  const offeredPriceInputHandler = (value) => setOfferedPrice(value);
  const onPlacingBid = () =>
    updateOfferPrice({ id: listingInformation.id, payload: { amount: offeredPrice } });

  return (
    <div className="adjust-offer-price-modal">
      <div className="adjust-offer-price-modal__header">
        <div className="flex justify-between">
          <div className="adjust-offer-price-modal__header--text">
            {t('bidding_process.propose_new_offer_price')}
          </div>
          <IconButton icon={<Icon name="close" />} size="tiny" onClick={onModalClose} />
        </div>
        {highestBidPrice ? (
          <div className="adjust-offer-price-modal__header--note">{changePriceNotice}</div>
        ) : null}
      </div>
      <div className="adjust-offer-price-modal__offer-price">
        <Input
          className="adjust-offer-price-modal__offer-price--input"
          size="small"
          label={inputLabel}
          placeholder={inputPlaceholder}
          required={true}
          value={offeredPrice}
          onChange={offeredPriceInputHandler}
          touched={offeredPrice?.length > 0}
          type="number"
        />
      </div>
      <div className="adjust-offer-price-modal__total">
        <div className="flex justify-between">
          <div className="adjust-offer-price-modal__total--text">{t('bidding_process.total')}</div>
          <div className="adjust-offer-price-modal__total--price">{totalBatchPrice}</div>
        </div>
        <div className="adjust-offer-price-modal__total--breakdown">{totalBatchPriceBreakdown}</div>
      </div>

      <div className="adjust-offer-price-modal__cta">
        <button
          className="adjust-offer-price-modal__cta--cancel"
          type="button"
          onClick={onModalClose}
        >
          {t('bidding_process.cancel')}
        </button>
        <button
          className={classNames('adjust-offer-price-modal__cta--bid', {
            'adjust-offer-price-modal__cta--bid--disabled': isAdjustOfferButtonDisabled,
          })}
          type="button"
          disabled={isAdjustOfferButtonDisabled}
          onClick={onPlacingBid}
        >
          {t('bidding_process.update_price')}
        </button>
      </div>
    </div>
  );
};
AdjustOfferOrderPriceModal.propTypes = {
  onModalClose: PropTypes.func.isRequired,
  highestBidPrice: PropTypes.number,
  offerVolume: PropTypes.string.isRequired,
};

AdjustOfferOrderPriceModal.defaultProps = {
  highestBidPrice: null,
};

export default AdjustOfferOrderPriceModal;
