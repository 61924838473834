import React from "react";
import PropTypes from "prop-types";
import { Can, COMPANY_PPERMISSIONS_FORM_SUBJECT, USER_ROLES_FORM_SUBJECT } from "ability";
import CompanyPermissionsForm from "./CompanyPermissionsForm";
import UserRolesForm from "./UserRolesForm";

const PermissionsForm = ({ companyId, userId, toastRef }) => (
  <>
    <Can I="view" a={USER_ROLES_FORM_SUBJECT}>
      <UserRolesForm
        userId={userId}
        toastRef={toastRef}
      />
    </Can>
    <Can I="view" a={COMPANY_PPERMISSIONS_FORM_SUBJECT}>
      <CompanyPermissionsForm
        companyId={companyId}
        toastRef={toastRef}
      />
    </Can>
  </>
);

PermissionsForm.propTypes = {
  userId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  toastRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default PermissionsForm;
