import { useGetCompanyQuery } from 'api/companies';
import { useGetCurrentUserQuery, useGetUserQuery } from 'api/users';
import { getEditStatus } from 'utils/helper/Portals';
import {
  isVerified,
  userRequestedVerification,
} from 'utils/helper/UserRoleValidation';

const useUserManagement = (userId) => {
  const { data: admin = {} } = useGetCurrentUserQuery();
  const { data: userDetails = {} } = useGetUserQuery({ id: userId });

  const { company_id: companyId, role: userType } = userDetails;

  const { data: companyDetails = {}, isSuccess: companyDataFetched } = useGetCompanyQuery(
    { id: companyId },
    { skip: !companyId },
  );

  const { id: adminId } = admin;

  const isAdmin = !!adminId;
  const isCompanyVerified = isVerified(companyDetails);
  const hasUserRequestedVerification = userRequestedVerification(companyDetails);
  const canEditForm = getEditStatus(isAdmin, isCompanyVerified, hasUserRequestedVerification);

  return {
    isAdmin,
    isCompanyVerified,
    adminId,
    admin,
    companyId,
    companyDetails,
    userDetails,
    canEditForm,
    userType,
    userId,
    companyDataFetched,
  };
};

export default useUserManagement;
