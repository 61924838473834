import React from 'react';
import ResetPasswordWrapper from 'components/ResetPassword/ResetPasswordWrapper';
import ResetPasswordSuccess from 'components/ResetPassword/ResetPasswordSuccess';

const ResetPasswordSuccessPage = () => (
  <ResetPasswordWrapper>
    <ResetPasswordSuccess />
  </ResetPasswordWrapper>
);

export default ResetPasswordSuccessPage;
