import React from 'react';
import { t } from 'i18next';
import { RTabs } from 'components/BaseComponents/RTabs';
import ContractDetails from 'components/BiddingProcess/ContractDetails';
import ListingDetails from 'components/BiddingProcess/AdminContractDetails/ListingDetails';
import ContractDocuments from 'components/BiddingProcess/AdminContractDetails/ContractDocuments';
import './styles.scss';

const LISTING_DETAILS = 'listing-details';
const CONTRACT_DETAILS = 'contract-details';
const CONTRACT_DOCUMENTS = 'contract-documents';

const sidebarContents = [
  {
    disabled: false,
    getContent: () => <ListingDetails />,
    key: LISTING_DETAILS,
    title: t('admin.contract_details.listing_details'),
  },
  {
    disabled: false,
    getContent: () => <ContractDetails />,
    key: CONTRACT_DETAILS,
    title: t('admin.contract_details.contract_details'),
  },
  {
    disabled: false,
    getContent: () => <ContractDocuments />,
    key: CONTRACT_DOCUMENTS,
    title: t('admin.contract_details.contract_documents'),
  },
];

const ContractDetailsSidebar = () => (
  <div className="sidebar-details">
    <RTabs
      containerClass="sidebar-details__tabs margin-x-auto margin-t-24px"
      isVertical={true}
      items={sidebarContents}
      selectedTabKey={LISTING_DETAILS}
    />
  </div>
);

export default ContractDetailsSidebar;
