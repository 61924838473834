import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { landingPage } from 'utils/constants/Routes';
import Icon from 'components/BaseComponents/Icon';
import LeftBanner from 'components/SignUp/LeftBanner';
import './style.scss';

const RegistrationSuccess = () => {
  const { t } = useTranslation();
  const { state: queryParams } = useLocation();
  const navigate = useCustomNavigate();
  useEffect(() => {
    if (!queryParams) {
      navigate.signupPage();
    }
  }, []);

  return (
    <div className="flex registration-success">
      <div className="registration-success__left-content">
        <LeftBanner
          title={t('signup.left_banner.title')}
          message={t('signup.left_banner.message')}
        />
      </div>
      <div className="registration-success__right-content flex items-center space-around">
        <div className="flex flex-col items-center">
          <div className="registration-success__right-content--mail-logo-container">
            <Icon name="mail" color="none" size="huge" className="flex justify-center mail-logo" />
          </div>
          <h2 className="registration-success__right-content--title">
            {t('signup.registration_success.title')}
          </h2>
          <p className="registration-success__right-content--message">
            {t('signup.registration_success.message')}
          </p>
          <p className="registration-success__right-content--email">
            {queryParams?.verificationEmail}
          </p>
          <Link
            to={landingPage}
            className="flex items-center gap-8px registration-success__right-content--redirect-link"
          >
            {t('signup.registration_success.redirect_message')}
            <Icon name="arrowRight" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RegistrationSuccess;
