import React, { useState } from 'react';
import { t } from 'i18next';
import Icon from 'components/BaseComponents/Icon';
import Button from 'components/BaseComponents/Button';
import Modal from 'components/BaseComponents/Modal';
import ContactSupportModal from '../ContactModal';

const DeclarationFooter = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onModalOpen = () => setIsModalVisible(true);
  const onModalClose = () => setIsModalVisible(false);

  return (
    <div className="offer-declaration__footer">
      <div className="flex">
        <Icon name="help" size="medium" />
        <p className="offer-declaration__footer--text">
          <span className="offer-declaration__footer--text-bold">
            {t('offer_creation.declaration.footer.footer_text_1')}
          </span>
          {t('offer_creation.declaration.footer.footer_text_2')}
        </p>
      </div>
      <Button
        label={t('offer_creation.declaration.footer.contact_admin_button_label')}
        onClick={onModalOpen}
        className="offer-declaration__footer--button"
      />
      <Modal
        isVisible={isModalVisible}
        onOutsideClick={onModalClose}
        className="progress-card-modal"
      >
        <Modal.Content>
          <ContactSupportModal />
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default DeclarationFooter;
