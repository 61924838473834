import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';
import { countryCodeToName } from 'utils/helper/selectBoxUtils';
import { styles } from '../styles';

const Remarks = ({ buyerDetails }) => {
  const countryOfBuyer = countryCodeToName(buyerDetails?.companyDomicileAddress?.countryCode);

  return (
    <View style={styles.remarksCard}>
      <Text style={styles.remarksCardText}>
        {t('contract_pdf.remarks_text_1', { countryOfBuyer })}
      </Text>
      <Text style={styles.normalText}>{t('contract_pdf.remarks_text_2')}</Text>
    </View>
  );
};

Remarks.propTypes = {
  buyerDetails: PropTypes.shape({
    companyDomicileAddress: PropTypes.shape({
      countryCode: PropTypes.string,
    }),
  }).isRequired,
};

export default Remarks;
