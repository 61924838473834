// commented out due to business logic
import React from 'react';
// import { t } from 'i18next';
// import { Image, Text } from '@react-pdf/renderer';
import { View } from '@react-pdf/renderer';
// import msmLogo from 'assets/images/msm-globe-logo-in-png.png';
import { styles } from '../styles';

const Header = () => (
  <View style={styles.header} fixed={true}>
    {/* <Image style={styles.msmLogo} src={msmLogo} />
    <View style={styles.headerTextContainer}>
      <Text style={styles.title}>{t('contract_pdf.header_title_1')}</Text>
      <Text style={styles.subtitle}>{t('contract_pdf.header_title_2')}</Text>
    </View> */}
  </View>
);

export default Header;
