import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import msmLogo from 'assets/images/msms-logo.svg';
import { footerData } from 'utils/constants/landingPage';
import { landingPage } from 'utils/constants/Routes';
import { socialMediaUrls } from 'utils/constants/SocialMedia';
import Icon from 'components/BaseComponents/Icon';
import './styles.scss';

const Footer = () => {
  const { t } = useTranslation();
  const year = moment().format('YYYY');

  const content = footerData.map(({ name, links }) => (
    <div key={name + links} className="footer__content--links-container-links">
      <div className="footer__content--links-container-links-group-header margin-b-16px">
        {name}
      </div>
      <div className="footer__content--links-container-links">
        {links.map(({ label, href, isDisabled }) => (
          <span key={label}>
            <NavLink
              to={href}
              className={classNames('custom-link', { 'custom-link__disabled': isDisabled })}
              onClick={(e) => {
                if (isDisabled) {
                  e.preventDefault();
                }
              }}
            >
              {label}
            </NavLink>
          </span>
        ))}
      </div>
    </div>
  ));

  return (
    <section className="footer">
      <div className="footer__content">
        <div className="footer__content--img" role="button" tabIndex="0">
          <Link to={landingPage}>
            <img className="footer__content--img__icon" src={msmLogo} alt="MSM Logo" />
          </Link>
          <p className="footer__content--img__title">{t('landing_page.footer.title')}</p>
        </div>
        <div className="footer__content--links-container">{content}</div>
      </div>
      <div className="footer__bottom">
        <p>{t('landing_page.footer.bottom-text', { year })}</p>
        {socialMediaUrls.map(({ name, link, iconName }) => (
          <NavLink
            key={name}
            className="footer__bottom--social-icon"
            to={link}
            target="_blank"
            rel="noreferrer"
          >
            <Icon name={iconName} size="medium" color="none" />
          </NavLink>
        ))}
      </div>
    </section>
  );
};

export default Footer;
