import { useParams } from 'react-router-dom';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { useGetCurrentUserQuery } from 'api/users';
import { useGetProductDetailsQuery } from 'api/products';
import { useGetListingDetailsQuery } from 'api/listings';

const useListingMiddleWare = () => {
  const navigate = useCustomNavigate();
  const { productId } = useParams();
  const { data: user = {}, isFetching: isUserFetching } = useGetCurrentUserQuery();
  const { data: productDetails = {}, isFetching: isProductFetching } = useGetProductDetailsQuery(
    { product_id: productId },
    { skip: !productId },
  );

  const listingId = productDetails?.listingId;

  const { data: listingDetails = {}, isFetching: isListingDetailsFetching } =
    useGetListingDetailsQuery({ listing_id: listingId }, { skip: !listingId });

  const isListingAuthor = user?.id === listingDetails?.creator?.id;

  const isLoading = isUserFetching || isProductFetching || isListingDetailsFetching;

  if (!isLoading) {
    const isListingUnpublished = listingDetails.status === 'unpublished';

    if (!isListingUnpublished || !isListingAuthor) {
      navigate.listings();
    }
  }

  return isLoading;
};

export default useListingMiddleWare;
