import React from 'react';
import PropTypes from 'prop-types';
import { useGetCurrentUserQuery } from 'api/users';
import { t } from 'i18next';
import classNames from 'classnames';
import {
  isContractCompleted,
  getContractInformation,
  isContractDissolved,
} from 'utils/helper/Contract';
import { hasBuyerRole } from 'utils/helper/UserRoleValidation';
import Button from 'components/BaseComponents/Button';
import { greenText } from 'utils/constants/Contract';
import { Link } from 'react-router-dom';

const ContractDetailsHeaderTitle = ({
  setIsRequestAssistantModalVisible,
  openModal,
  contractDetails,
  listingDetails,
}) => {
  const { data: currentUser = {} } = useGetCurrentUserQuery();

  const { serialNumber: contractSerialNumber = '', status = '' } = contractDetails;

  const { serialNumber: listingSerialNumber, contract = {} } = listingDetails;

  const contractCreatedAt = contract?.createdAt;
  const isAggregatedData = listingDetails?.aggregatedData;

  const contractDetailsInformation = getContractInformation(contractCreatedAt, status);
  const isSubHeaderNotVisible = isAggregatedData || isContractDissolved(status);
  const subHeader = (
    <p className="contract-details-header__sub-header">
      {t('shared.bidding_process.contract_details.contracts_header.sub_header')}
    </p>
  );
  const header = (
    <div
      className={classNames('contract-details-header__title margin-none', {
        'margin-t-16px': isSubHeaderNotVisible,
      })}
    >
      {t('shared.bidding_process.contract_details.contracts_header.title', {
        contractSerialNumber,
      })}
    </div>
  );
  const buttonContent = (
    <div className="contract-details-header__container--button-container">
      {hasBuyerRole(currentUser) && isContractCompleted(status) && (
        <Button
          label={t('shared.bidding_process.contract_details.button.assistance')}
          className="contract-details-header__container--button-container-contracts-button"
          onClick={() => openModal(setIsRequestAssistantModalVisible)}
        />
      )}
    </div>
  );

  return (
    <div className="contract-details-header__container">
      <div>
        {!isSubHeaderNotVisible && subHeader}
        {header}
        <div className="contract-details-header__information margin-b-8x">
          <p>
            {t('shared.bidding_process.contract_details.label.reference')}
            <Link
              to={listingDetails}
              className={classNames('contract-details-header__information--value', greenText)}
            >
              {listingSerialNumber}
            </Link>
          </p>
          {contractDetailsInformation.map(
            ({ label, value, className, isDisabled }) =>
              !isDisabled && (
                <p key={label}>
                  {label}
                  <span
                    className={classNames('contract-details-header__information--value', className)}
                  >
                    {value}
                  </span>
                </p>
              ),
          )}
        </div>
      </div>
      {buttonContent}
    </div>
  );
};

ContractDetailsHeaderTitle.propTypes = {
  setIsRequestAssistantModalVisible: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  listingDetails: PropTypes.shape({
    serialNumber: PropTypes.string,
    contract: PropTypes.shape({}),
    aggregatedData: PropTypes.bool,
    productId: PropTypes.number,
  }).isRequired,
  contractDetails: PropTypes.shape({
    serialNumber: PropTypes.string,
    status: PropTypes.string,
    listingId: PropTypes.number,
    draftContract: PropTypes.shape({}),
  }).isRequired,
};

export default ContractDetailsHeaderTitle;
