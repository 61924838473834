import React, { useMemo } from 'react';
import Icon from 'components/BaseComponents/Icon';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

const AccordionItem = (props) => {
  const { id, className, onClick, children, header, isActive } = props;
  const appliedId = id || useMemo(() => uuidv4(), []);

  const handleOnClick = () => {
    onClick(appliedId);
  };

  return (
    <div className={classNames('accordion__item', className)}>
      <div
        role="tab"
        tabIndex={0}
        className="accordion__item-header"
        onKeyDown={handleOnClick}
        onClick={handleOnClick}
      >
        <div className="accordion__item-header-text">{header}</div>
        <Icon color="tertiary" name="iconUpChevron" rotate={isActive ? 0 : 180} />
      </div>
      <div
        className={classNames('accordion__item-body', {
          'accordion__item-body--opened': isActive,
        })}
        style={{
          height: isActive ? 'auto' : '0px',
        }}
      >
        {children}
      </div>
    </div>
  );
};

AccordionItem.defaultProps = {
  id: null,
  className: null,
  isActive: false,
  header: null,
};

AccordionItem.propTypes = {
  className: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.oneOf([null])]),
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node.isRequired,
};

export default AccordionItem;
