import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/BaseComponents/Button';
import Icon from 'components/BaseComponents/Icon';
import Footer from 'components/Shared/Footer';
import useScroll from 'hooks/useScroll';
import PrimaryNavBar from 'components/NavBar/PrimaryNavBar';
import DevelopmentAccordion from 'components/DevelopmentGoing/AccordionHeader';
import SmallerScreenPopup from 'components/DevelopmentGoing/SmallerScreenPopup';
import './styles.scss';

const Layout = ({ navbarClassName, children }) => {
  const { showElement, scrollToTop } = useScroll(600, 3);

  return (
    <div>
      <DevelopmentAccordion />
      <SmallerScreenPopup />
      <PrimaryNavBar className={navbarClassName} />
      {children}
      <Footer />
      {showElement && (
        <Button
          type="success"
          icon={<Icon name="arrowUp" size="medium" className="stb--icon" />}
          onClick={scrollToTop}
          className="stb border-none"
        />
      )}
    </div>
  );
};

Layout.defaultProps = {
  navbarClassName: null,
};

Layout.propTypes = {
  navbarClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Layout;
