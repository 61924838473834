import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../Icon';

const colors = [
  'leaky-rust-light',
  'bright-leaf',
  'comestic-chartreuse',
  'committed-tawny',
  'fantastic-royal',
  'lofty-ashen',
  'losing-rust',
  'outer-bronze',
  'predictable-shark',
  'shiny-cobalt',
  'unruly-chartreuse',
  'buyer-avatar',
  'seller-avatar',
  'affiliate-avatar',
];

const colorIndex = (length) => {
  return length > 0 ? (length % 10) + 1 : 0;
};

const Avatar = (props) => {
  const {
    className,
    src,
    size,
    bgColor,
    firstName,
    lastName,
    description,
    showName,
    href,
    rel,
    openNewTab,
    tooltip: Tooltip,
    badge: Badge,
  } = props;
  const [isLoading, setIsLoading] = useState(true);
  const derivedFirstName = firstName || '';
  const derivedLastName = lastName || '';
  const fullName = `${derivedFirstName} ${derivedLastName}`.trim();
  const initials = `${derivedFirstName.charAt(0)}${derivedLastName.charAt(0)}`.toUpperCase();
  const calculatedColorIndex = useMemo(() => {
    return bgColor !== null ? bgColor : colorIndex(fullName.length);
  }, [bgColor, fullName.length]);

  const renderAvatarContent = () => {
    return (
      <div
        className={classNames('rcl-avatar__content', `rcl-avatar__content--${size}`, {
          'rcl-avatar__content--with-img': src,
          [`rcl-avatar__content--${colors[calculatedColorIndex]}`]: !src,
        })}
      >
        {src ? (
          <>
            {isLoading && <Icon name="person" />}
            <img
              src={src}
              alt="avatar"
              className="rcl-avatar__content-image"
              style={{ display: isLoading ? 'none' : 'inline-block' }}
              onLoad={() => {
                setIsLoading(false);
              }}
              loading="lazy"
            />
          </>
        ) : (
          <div className="rcl-avatar__content-initials">
            {fullName.length > 0 ? (
              initials
            ) : (
              <Icon className="rcl-avatar__placeholder-icon" name="null" />
            )}
          </div>
        )}
        {Badge
          ? React.cloneElement(Badge, {
              ...Badge.props,
              classNameForBadge: 'rcl-avatar__content-badge',
            })
          : null}
      </div>
    );
  };

  return (
    <div className={classNames('rcl-avatar', className)}>
      {Tooltip
        ? React.cloneElement(Tooltip, {
            ...Tooltip.props,
            children: renderAvatarContent(),
          })
        : renderAvatarContent()}
      {showName || description ? (
        <div className="rcl-avatar__text">
          {showName && fullName.length > 0 ? (
            <a
              className={classNames('rcl-avatar-link__content')}
              target={openNewTab ? '_blank' : '_self'}
              rel={openNewTab ? 'noopener noreferrer' : rel}
              href={href || undefined}
            >
              <div
                className={classNames('rcl-avatar__text-name', `rcl-avatar__text-name--${size}`)}
              >
                {fullName}
              </div>
            </a>
          ) : null}
          {description ? (
            <div
              className={classNames(
                'rcl-avatar__text-description',
                `rcl-avatar__text-description--${size}`,
              )}
            >
              {description}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

Avatar.defaultProps = {
  className: '',
  size: 'normal',
  src: null,
  bgColor: null,
  firstName: '',
  lastName: '',
  showName: false,
  href: '',
  rel: '',
  openNewTab: false,
  description: null,
  tooltip: null,
  badge: null,
};

Avatar.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['tiny', 'normal', 'large', 'huge', 'xl', 'xxl']),
  src: PropTypes.string,
  bgColor: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]),
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  showName: PropTypes.bool,
  href: PropTypes.string,
  rel: PropTypes.string,
  openNewTab: PropTypes.bool,
  description: PropTypes.node,
  tooltip: PropTypes.node,
  badge: PropTypes.node,
};

export default Avatar;
