import React, { useContext } from 'react';
import { t } from 'i18next';
import { Link, useNavigate } from 'react-router-dom';
import Icon from 'components/BaseComponents/Icon';
import { listings, offers, orders } from 'utils/constants/Routes';
import IconButton from 'components/BaseComponents/IconButton';
import ListingDetailsContext from 'components/BiddingProcess/ListingDetails/ListingDetailsContext';
import UnPublishOption from 'components/BiddingProcess/ListingDetails/ListingDetailsBreadCrumb/UnPublishOption';
import EditListingOption from 'components/BiddingProcess/ListingDetails/ListingDetailsBreadCrumb/EditListingOption';
import FollowListing from 'components/BiddingProcess/ListingDetails/ListingDetailsBreadCrumb/FollowListing';
import { Can, listingSubject } from 'ability';
import './styles.scss';

const ListingDetailsBreadCrumb = () => {
  const navigate = useNavigate();

  const {
    listingInformation,
    isListingUnPublished,
    isListingCreator,
    isUserCompanyOwner,
    isUserSeller,
    isListingConcluded,
  } = useContext(ListingDetailsContext);

  const { serialNumber } = listingInformation;
  const hasNoActiveBid = !listingInformation.currentBidderId;
  const showEditListingOption = isListingUnPublished && hasNoActiveBid && (isListingCreator || isUserCompanyOwner);
  const toMyListingsPage = isUserSeller ? offers : orders;
  const listingInformationId = isListingConcluded
    ? listingInformation?.contract?.serialNumber
    : serialNumber;
  const arrowBackIcon = <Icon name="arrowBackAlt" />;

  const getMyListingsPathName = () => {
    if (isListingCreator || isUserCompanyOwner) {
      if (isUserSeller) {
        return t('bidding_process.my_offers');
      }
      return t('bidding_process.my_orders');
    }
    if (isUserSeller) {
      return t('bidding_process.offer_listings');
    }
    return t('bidding_process.order_listings');
  };

  const getRoute = () => {
    if (showEditListingOption) {
      return toMyListingsPage;
    }
    return listings;
  };

  return (
    <div className="listing-details-breadcrumb">
      <div className="flex align-center">
        <IconButton icon={arrowBackIcon} size="tiny" isIconOnly onClick={() => navigate(-1)} />
        <Link to={getRoute()} className="padding-r-12px decoration-none">
          {getMyListingsPathName()}
        </Link>
        <div className="padding-r-12px listing-details-breadcrumb__oblique">/</div>
        {showEditListingOption && (
          <>
            <Link to={toMyListingsPage} className="padding-r-12px decoration-none">
              {t('admin.contract_details.in_draft')}
            </Link>
            <div className="padding-r-12px listing-details-breadcrumb__oblique">/</div>
          </>
        )}
        <div className="listing-details-breadcrumb__serial-id">{listingInformationId}</div>
      </div>
      <div className="listing-details-breadcrumb__actions">
        <Can I="follow" a={listingSubject(listingInformation)}>
          <FollowListing />
        </Can>

        <Can I="unpublish" a={listingSubject(listingInformation)}>
          <UnPublishOption />
        </Can>

        <Can I="edit" a={listingSubject(listingInformation)}>
          <EditListingOption />
        </Can>
      </div>
    </div>
  );
};

export default ListingDetailsBreadCrumb;
