import i18n, { t } from 'i18next';

export const generateSelectObject = (value) => ({
  label: value,
  value,
});

export const localizedSelectedObject = (labelKey, value) => {
  if (!labelKey) {
    return generateSelectObject(value);
  }

  const convertedlabelKey = labelKey.toLowerCase().replace(/ /g, '_');

  if (i18n.exists(convertedlabelKey)) {
    return {
      label: t(convertedlabelKey),
      value,
    };
  }

  return generateSelectObject(value);
};

export const countryCodeToName = (countryCode) => {
  const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

  try {
    return regionNames.of(countryCode);
  } catch (error) {
    return countryCode;
  }
};
