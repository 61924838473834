import {
  ACCEPTED,
  CONCLUDED,
  IN_PROGRESS,
  OFFER,
  ORDER,
  PUBLISHED,
  UNPUBLISHED,
} from 'utils/constants/offerListings';
import {
  hasAdminRole,
  hasBuyerRole,
  hasSellerRole,
  hasSubscriptionMemberRole,
} from 'utils/helper/UserRoleValidation';

const computeListingFlags = (currentUserInformation, listingInformation) => {
  const isListingCreator = currentUserInformation.id === listingInformation?.creator?.id;

  const isUserAdmin = hasAdminRole(currentUserInformation);
  const isUserSeller = hasSellerRole(currentUserInformation);
  const isUserBuyer = hasBuyerRole(currentUserInformation);
  const isUserSubscriptionMember = hasSubscriptionMemberRole(currentUserInformation);
  const isUserCompanyOwner =
    currentUserInformation?.roles?.includes('owner') &&
    currentUserInformation?.company_id === listingInformation?.companyId;

  const isListingPublished = listingInformation.status === PUBLISHED;
  const isListingUnPublished = listingInformation.status === UNPUBLISHED;
  const isListingAccepted = listingInformation.status === ACCEPTED;
  const isListingConcluded = listingInformation.status === CONCLUDED;

  const isListingInContract =
    listingInformation.status === CONCLUDED ||
    listingInformation.status === ACCEPTED ||
    listingInformation.status === IN_PROGRESS;
  const isUserInContract =
    isListingInContract &&
    (currentUserInformation.id === listingInformation?.contract?.buyer?.id ||
      currentUserInformation.id === listingInformation?.contract?.seller?.id);

  const isOfferListing = listingInformation.category === OFFER;
  const isOrderListing = listingInformation.category === ORDER;

  return {
    isListingCreator,
    isUserAdmin,
    isUserSeller,
    isUserBuyer,
    isUserSubscriptionMember,
    isUserCompanyOwner,
    isListingPublished,
    isListingInContract,
    isListingConcluded,
    isListingUnPublished,
    isOfferListing,
    isOrderListing,
    isListingAccepted,
    isUserInContract,
  };
};

export default computeListingFlags;
