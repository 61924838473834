import React, { useRef } from 'react';
import classNames from 'classnames';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import ReactFlagsSelect from 'react-flags-select';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { useInviteBuyerMutation } from 'api/affiliatePartner';
import { labelWithHints } from 'utils/helper/LabelWithHints';
import { generateInviteBuyerBody } from 'utils/helper/RequestBody';
import { handleServerValidationErrors, requiredField } from 'utils/helper/Validations';
import Input from 'components/BaseComponents/Input';
import Button from 'components/BaseComponents/Button';
import ToastNotification from 'components/Shared/ToastNotification';
import './style.scss';

const FormBody = () => {
  const { t } = useTranslation();
  const toastRef = useRef();
  const [inviteBuyer] = useInviteBuyerMutation();
  const navigate = useCustomNavigate();

  const handleSignup = async (params) => {
    const requestBody = generateInviteBuyerBody(params);

    const response = await inviteBuyer({
      payload: { user_attributes: requestBody },
    });

    if (response.error) {
      const {
        error: {
          data: { message },
          status,
        },
      } = response;

      const errors = handleServerValidationErrors(toastRef, status, message);

      if (errors.company_user_base) {
        return {
          email: errors.company_user_base,
        };
      }
      return errors;
    }

    navigate.buyerInvitationSuccessPage(params.email);

    return null;
  };

  return (
    <div className="sign-up-form">
      <h2 className="sign-up-form__title">{t('signup.step2_form.title')}</h2>
      <p className="sign-up-form__message">{t('signup.step2_form.tell_us_about_your_trader')}</p>
      <h5 className="sign-up-form__form-title">
        {t('signup.step2_form.trader_form_header')}
        <span className="sign-up-form__form-title--required">*</span>
      </h5>
      <Form
        onSubmit={handleSignup}
        render={({ handleSubmit, invalid, dirtySinceLastSubmit, submitting }) => (
          <form onSubmit={handleSubmit} className="margin-t-16px">
            <Field name="first_name" validate={requiredField}>
              {({ input, meta }) => (
                <Input
                  label={t('signup.step2_form.first_name.label')}
                  placeholder={t('signup.step2_form.first_name.placeholder')}
                  required
                  className="sign-up-form__first-name margin-b-20px"
                  error={meta?.error || meta?.submitError}
                  touched={meta.touched}
                  value={input.value}
                  onChange={input.onChange}
                />
              )}
            </Field>
            <Field name="last_name" validate={requiredField}>
              {({ input, meta }) => (
                <Input
                  label={t('signup.step2_form.last_name.label')}
                  placeholder={t('signup.step2_form.last_name.placeholder')}
                  required
                  size="small"
                  className="sign-up-form__last-name"
                  error={meta?.error || meta?.submitError}
                  touched={meta.touched}
                  value={input.value}
                  onChange={input.onChange}
                />
              )}
            </Field>
            <Field name="company_name" validate={requiredField}>
              {({ input, meta }) => (
                <Input
                  label={labelWithHints(
                    t('signup.step2_form.company_name.label'),
                    t('affiliate_partners.signup.tooltip.company_name'),
                    'top',
                  )}
                  placeholder={t('signup.step2_form.company_name.placeholder')}
                  required
                  size="small"
                  className="margin-t-20px"
                  error={meta?.error || meta?.submitError}
                  touched={meta.touched}
                  value={input.value}
                  onChange={input.onChange}
                />
              )}
            </Field>
            <Field name="country_code_of_registration" validate={requiredField}>
              {({ input, meta }) => (
                <div className="margin-t-20px">
                  <p className="sign-up-form__country--title">
                    {t('signup.step2_form.country.label')}
                    <span className="sign-up-form__country--title--required">*</span>
                  </p>
                  <ReactFlagsSelect
                    selectButtonClassName={classNames('sign-up-form__country--dropdown', {
                      'sign-up-form__country--dropdown--error': meta?.touched && meta?.error,
                    })}
                    searchable
                    placeholder={t('signup.step2_form.country.placeholder')}
                    selected={input.value}
                    onSelect={(code) => input.onChange(code)}
                  />
                </div>
              )}
            </Field>
            <Field name="company_registration_number" validate={requiredField}>
              {({ input, meta }) => (
                <Input
                  label={labelWithHints(
                    t('signup.step2_form.company_registration_number.label'),
                    t('affiliate_partners.signup.tooltip.registration_number'),
                    'top',
                  )}
                  placeholder={t('signup.step2_form.company_registration_number.placeholder')}
                  required
                  size="small"
                  className="margin-t-20px"
                  error={meta?.error || meta?.submitError}
                  touched={meta.touched}
                  value={input.value}
                  onChange={input.onChange}
                />
              )}
            </Field>
            <Field name="email" validate={requiredField}>
              {({ input, meta }) => (
                <Input
                  label="Trader Email"
                  placeholder={t('signup.step2_form.user_email.placeholder')}
                  required
                  size="small"
                  className="margin-t-20px"
                  error={meta?.error || meta?.submitError}
                  touched={meta.touched}
                  value={input.value}
                  onChange={input.onChange}
                />
              )}
            </Field>

            <Button
              className="margin-b-20px margin-t-32px sign-up-form__submit-btn"
              type="success"
              label="Invite Now"
              submitType="submit"
              disabled={(!dirtySinceLastSubmit && invalid) || submitting}
            />
          </form>
        )}
      />

      <ToastNotification toastRef={toastRef} />
    </div>
  );
};

export default FormBody;
