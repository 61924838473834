import React from 'react';
import PropTypes from 'prop-types';
import { BreadcrumbItem, Breadcrumbs } from 'components/BaseComponents/Breadcrumbs';

const BreadCrumbComponent = ({ breadcrumbs }) => (
  <Breadcrumbs dividerSymbol="/">
    {breadcrumbs
        && breadcrumbs.map(({ breadcrumb, key, match }) => (
          <BreadcrumbItem key={key} linkUrl={match.pathname}>
            {breadcrumb}
          </BreadcrumbItem>
        ))}
  </Breadcrumbs>
);

BreadCrumbComponent.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
    path: PropTypes.string,
    breadcrumb: PropTypes.node,
  })).isRequired,
};

export default BreadCrumbComponent;
