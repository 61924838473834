import i18n from 'i18n';

export const getUserVerificationLabel = (companyDataFetched, isVerified) => {
  if (!companyDataFetched) {
    return null;
  }

  const verificationLabel = isVerified ? '' : i18n.t('profile.verification_label_unapproved');

  return verificationLabel;
};
