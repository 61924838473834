import React from 'react';
import { ErrorBoundary, Provider } from '@rollbar/react';
import PropTypes from 'prop-types';

const rollbarEnabledEnv = ['production', 'staging'];
const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  environment: process.env.REACT_APP_ENV,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: rollbarEnabledEnv.includes(process.env.REACT_APP_ENV.toLowerCase()),
};

const RollbarProvider = ({ children }) => (
  <Provider config={rollbarConfig}>
    <ErrorBoundary>{children}</ErrorBoundary>
  </Provider>
);

RollbarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RollbarProvider;
