import React from 'react';
import PropTypes from 'prop-types';
import Toast from 'components/BaseComponents/Toast';

const ToastNotification = ({ toastRef }) => (
  <Toast
    autoClose={5000}
    hideProgressBar
    newestOnTop
    pauseOnFocusLoss
    pauseOnHover
    position="top-right"
    ref={toastRef}
  />
);

ToastNotification.propTypes = {
  toastRef: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(null)]).isRequired,
};

export default ToastNotification;
