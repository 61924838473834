import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'components/BaseComponents/Icon';

const Step = (props) => {
  const { stepName, isActive, isDone, stepIndex } = props;

  return (
    <div
      role="presentation"
      className={classNames('stepper__body-step', {
        'stepper__body-step--active': isActive,
        'stepper__body-step--done': isDone,
      })}
    >
      <div className="stepper__body-step-index">
        {isDone ? (
          <Icon fill="#fff" className="stepper__body-step-check-icon" name="check" />
        ) : (
          stepIndex + 1
        )}
      </div>
      <div className="stepper__body-step-name">{stepName}</div>
      {stepIndex > 0 ? <div className="stepper__body-step-connector" /> : null}
    </div>
  );
};

Step.defaultProps = {
  isActive: false,
  isDone: false,
};

Step.propTypes = {
  stepName: PropTypes.string.isRequired,
  stepIndex: PropTypes.number.isRequired,
  isActive: PropTypes.bool,
  isDone: PropTypes.bool,
};

export default Step;
