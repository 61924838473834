import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import msmLogo from 'assets/images/msm-logo.svg';
import { landingPage } from 'utils/constants/Routes';
import './style.scss';

const ErrorBoundary = ({ header, description }) => {
  const year = moment().format('YYYY');

  return (
    <div className="unauthorized">
      <div className="unauthorized__centered-content">
        <Link to={landingPage}>
          <img className="unauthorized__centered-content--logo" src={msmLogo} alt="msm logo" />
        </Link>
        <div className="unauthorized__centered-content--title">{header}</div>
        <div className="unauthorized__centered-content--sub-text">{description}</div>
        <button
          className="unauthorized__centered-content--button"
          onClick={() => window.open(landingPage, '_blank')}
          size="medium"
          type="button"
        >
          {t('errors.unauthorized.button_text')}
        </button>
      </div>
      <footer className="unauthorized__footer">
        <p>{t('landing_page.footer.bottom-text', { year })}</p>
      </footer>
    </div>
  );
};

ErrorBoundary.propTypes = {
  header: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default ErrorBoundary;
