// Commented out codes are there because of updated business logics

import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { t } from 'i18next';
import { useGetContractQuery, useRequestAssistanceMutation } from 'api/contracts';
import { useGetListingDetailsQuery } from 'api/listings';
// import { useGetCurrentUserQuery } from 'api/users';
// import { getContractClassName, getContractStatus, isContractSigned } from 'utils/helper/Contract';
// import { isMSMBuyer, isMSMSeller } from 'utils/helper/UserRoleValidation';
import { handleServerValidationErrors } from 'utils/helper/Validations';
import ToastNotification from 'components/Shared/ToastNotification';
import Modal from 'components/BaseComponents/Modal';
import LoadingSpinner from 'components/BaseComponents/LoadingSpinner';
import EmptyContentPlaceholder from 'components/Shared/EmptyContentPlaceholder';
// import ContractStatusCard from '../ContractStatusCard';
import ContractDetailsHeaderTitle from './ContractDetailsHeaderTitle';
import UploadSignedContractModalBody from '../UploadSignedContractModalBody';
import RequestAssistanceModalBody from '../RequestAssistanceModal';
import ContactSupportModalBody from '../ContactSupportModalBody';
import './styles.scss';

const ContractDetailsHeader = () => {
  const toastRef = useRef();
  const { id: contractId } = useParams();
  // const { data: currentUser = {} } = useGetCurrentUserQuery();

  // const [isContractStatusVisible, setIsContractStatusVisible] = useState(true);
  const [isUploadSignedContractModalVisible, setIsUploadSignedContractModalVisible] =
    useState(false);
  const [isRequestAssistantModalVisible, setIsRequestAssistantModalVisible] = useState(false);
  const [isContactModalVisible, setIsContactModalVisible] = useState(false);

  const {
    data: contractDetails = {},
    isFetching: isFetchingContractDetails,
    error,
  } = useGetContractQuery({
    id: contractId,
  });

  const { listingId = '' } = contractDetails;
  const { data: listingDetails = {} } = useGetListingDetailsQuery(
    { listing_id: listingId },
    { skip: !listingId },
  );
  // const { serialNumber: listingSerialNumber } = listingDetails;

  const [isRequestSent, setIsRequestSent] = useState(false);

  const [requestAssistance, { isSuccess }] = useRequestAssistanceMutation();

  // const buyerSignedAt = contractDetails?.buyerSignedAt;
  // const sellerSignedAt = contractDetails?.sellerSignedAt;

  // const hascurrentUserUploadedDocument =
  //   (isMSMBuyer(currentUser) && buyerSignedAt) || (isMSMSeller(currentUser) && sellerSignedAt);
  // const contractStatus = getContractStatus(status, hascurrentUserUploadedDocument);

  const openModal = (modalSetter) => modalSetter(true);
  const closeModal = () => {
    if (isUploadSignedContractModalVisible) {
      setIsUploadSignedContractModalVisible(false);
    }
    if (isContactModalVisible) {
      setIsContactModalVisible(false);
    }
    if (isRequestAssistantModalVisible) {
      setIsRequestAssistantModalVisible(false);
      if (isSuccess) {
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    let timeoutId;
    if (isRequestAssistantModalVisible && isSuccess) {
      timeoutId = setTimeout(() => {
        closeModal();
        window.location.reload();
      }, 1500);
    }
    return () => clearTimeout(timeoutId);
  }, [isRequestAssistantModalVisible, isSuccess]);

  const handleRequestAssistanceSubmit = async (params) => {
    const requestBody = {
      transportation: params.transportation || false,
      freight: params.freight || false,
      finance: params.finance || false,
      insurance: params.insurance || false,
      forex_services: params.forexServices || false,
    };

    return requestAssistance({ contract_id: contractId, payload: requestBody })
      .unwrap()
      .then(() => {
        setIsRequestSent(true);
      })
      .catch(({ data: { errors }, status: errorContractStatus }) =>
        handleServerValidationErrors(toastRef, errorContractStatus, errors),
      );
  };

  if (isFetchingContractDetails) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <EmptyContentPlaceholder
        heading={t('shared.bidding_process.empty_content_placeholder.single_contract_heading')}
      />
    );
  }

  return (
    <div className="contract-details-header">
      <ContractDetailsHeaderTitle
        setIsRequestAssistantModalVisible={setIsRequestAssistantModalVisible}
        setIsUploadSignedContractModalVisible={setIsUploadSignedContractModalVisible}
        openModal={openModal}
        contractDetails={contractDetails}
        listingDetails={listingDetails}
      />

      {/* {isContractStatusVisible && !isContractSigned(status) && (
        <ContractStatusCard
          className={getContractClassName(contractStatus)}
          contractStatus={contractStatus}
          setIsContractStatusVisible={setIsContractStatusVisible}
          contractDetails={contractDetails}
          listingSerialNumber={listingSerialNumber}
        />
      )} */}

      <Modal
        isVisible={
          isUploadSignedContractModalVisible ||
          isRequestAssistantModalVisible ||
          isContactModalVisible
        }
        onOutsideClick={() => closeModal()}
        className="progress-card-modal"
      >
        <Modal.Content>
          {isUploadSignedContractModalVisible && (
            <UploadSignedContractModalBody handleModalClose={() => closeModal()} ç />
          )}

          {isRequestAssistantModalVisible && (
            <RequestAssistanceModalBody
              handleModalClose={() => closeModal()}
              onContactModalOpen={() => openModal(setIsContactModalVisible)}
              handleRequestAssistanceSubmit={handleRequestAssistanceSubmit}
              setIsRequestSent={setIsRequestSent}
              isRequestSent={isRequestSent}
              isSuccess={isSuccess}
            />
          )}

          {isContactModalVisible && (
            <ContactSupportModalBody handleModalClose={() => closeModal()} toastRef={toastRef} />
          )}
        </Modal.Content>
      </Modal>
      <ToastNotification toastRef={toastRef} />
    </div>
  );
};

export default ContractDetailsHeader;
