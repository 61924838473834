import React, { useState } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { formatPrice } from 'utils/helper/Charts';
import './styles.scss';
import CheckBox from 'components/BaseComponents/CheckBox';
import { Link } from 'react-router-dom';
import IconButton from 'components/BaseComponents/IconButton';
import Icon from 'components/BaseComponents/Icon';

const BidAcknowledgementModal = ({ onModalClose, onAcknowledged, price, volume }) => {
  const [isTermsAcknowledged, setIsTermsAcknowledged] = useState(false);
  const [isPriceAcknowledged, setIsPriceAcknowledged] = useState(false);

  const onTermsCheckboxClick = () => setIsTermsAcknowledged((val) => !val);
  const onPriceCheckboxClick = () => setIsPriceAcknowledged((val) => !val);

  const isConfirmButtonDisabled = !isTermsAcknowledged || !isPriceAcknowledged;

  const totalBatchPriceBreakdown = `(${volume} kg x USD ${price} ${t('bidding_process.per_kg')})`
  const counterBatchPriceTotal = `USD ${formatPrice(volume * price)}`

  const onConfirm = () => {
    onModalClose();
    onAcknowledged();
  };

  return (
    <div className="bid-acknowledgement-modal">
      <div className="place-counter-bid-modal__header">
        <div className="place-counter-bid-modal__header--text">
          {t('bidding_process.acknowledgement_header')}
        </div>
        <IconButton icon={<Icon name="close" />} size="tiny" onClick={onModalClose} />
      </div>

      <div className="bid-acknowledgement-modal__total">
        <div className="flex justify-between">
          <div className="bid-acknowledgement-modal__total--text">{t('bidding_process.total')}</div>
          <div className="bid-acknowledgement-modal__total--price">{counterBatchPriceTotal}</div>
        </div>
        <div className="bid-acknowledgement-modal__total--breakdown">{totalBatchPriceBreakdown}</div>
      </div>

      <div className="bid-acknowledgement-modal__acknowledgement">
        <div className="bid-acknowledgement-modal__acknowledgement-row">
          <CheckBox
            className="bid-acknowledgement-modal__acknowledgement-checkbox"
            disabled={false}
            isChecked={isPriceAcknowledged}
            onChange={onPriceCheckboxClick}
            size="small"
          />
          <div className="bid-acknowledgement-modal__acknowledgement-text">
            {t('bidding_process.price_acknowledgement_text')}
          </div>
        </div>

        <div className="bid-acknowledgement-modal__acknowledgement-row">
          <CheckBox
            className="bid-acknowledgement-modal__acknowledgement-checkbox"
            disabled={false}
            isChecked={isTermsAcknowledged}
            onChange={onTermsCheckboxClick}
            size="small"
          />
          <div className="bid-acknowledgement-modal__acknowledgement-text">
            {t('bidding_process.terms_acknowledgement_text')}
            <Link to="/terms" target="_blank">
              <span className="bid-acknowledgement-modal__acknowledgement-text--bold">
                {t('bidding_process.terms_acknowledgement')}
              </span>
            </Link>
          </div>
        </div>

      </div>

      <div className="bid-acknowledgement-modal__cta">
        <button
          className="bid-acknowledgement-modal__cta-back"
          type="button"
          onClick={onModalClose}
        >
          {t('bidding_process.back')}
        </button>
        <button
          className={classNames('bid-acknowledgement-modal__cta-confirm', {
            'bid-acknowledgement-modal__cta-confirm--disabled': isConfirmButtonDisabled,
          })}
          type="button"
          disabled={isConfirmButtonDisabled}
          onClick={onConfirm}
        >
          {t('bidding_process.confirm')}
        </button>
      </div>
    </div>
  );
};

BidAcknowledgementModal.propTypes = {
  onModalClose: PropTypes.func.isRequired,
  onAcknowledged: PropTypes.func.isRequired,
  price: PropTypes.string.isRequired,
  volume: PropTypes.string.isRequired,
};

export default BidAcknowledgementModal;
