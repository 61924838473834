import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { chartCollectionShape } from 'utils/constants/PropTypes';
import { PRICE_CHART_TYPE, VOLUME_CHART_TYPE } from 'utils/constants/Charts';
import { LineChart } from 'components/BaseComponents/Charts';
import './styles.scss';
import { formatPrice, formatVolume } from 'utils/helper/Charts';
import { priceChartOptions, volumeChartOptions } from 'utils/constants/AggregateSalesStatistics';

const LineChartComponent = ({
  heading,
  description,
  chartTitle,
  chartSubtitle,
  chartCollection,
  className,
  chartType,
}) => {
  const { chartAxis, chartData, chartDataLines, chartMargin } = chartCollection;
  const hasDescription = description !== null;
  const formatter = chartType === PRICE_CHART_TYPE ? formatPrice : formatVolume
  const lineOptions = chartType === PRICE_CHART_TYPE ? priceChartOptions : volumeChartOptions

  return (
    <div className={classNames('line-chart-component', className)}>
      {heading && (
        <h2
          className={classNames('line-chart-component__heading', {
            'margin-b-4px': hasDescription,
          })}
        >
          {heading}
        </h2>
      )}
      {description && (
        <h3 className="line-chart-component__chart--title margin-b-32px">{description}</h3>
      )}
      <div className="line-chart-component__chart">
        <h4 className="line-chart-component__chart--title">{chartTitle}</h4>
        <h3 className="line-chart-component__chart--subtitle">{chartSubtitle}</h3>
        <LineChart

          axis={chartAxis}
          data={chartData}
          dataLines={chartDataLines}
          margin={chartMargin}
          chartLineOptions={lineOptions}
          formatter={formatter}
        />
      </div>
    </div>
  );
};

LineChartComponent.defaultProps = {
  className: null,
  heading: null,
  description: null,
  chartSubtitle: null,
  isGraphUpdated: false,
};

LineChartComponent.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  chartTitle: PropTypes.string.isRequired,
  chartSubtitle: PropTypes.string,
  chartCollection: chartCollectionShape.isRequired,
  className: PropTypes.string,
  chartType: PropTypes.oneOf([PRICE_CHART_TYPE, VOLUME_CHART_TYPE]).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  isGraphUpdated: PropTypes.bool,
};

const graphUpdated = (_, newProps) => {
  const { isGraphUpdated } = newProps;
  return !isGraphUpdated;
};
const MemoizedLineChart = memo(LineChartComponent, graphUpdated);

export default MemoizedLineChart;
