import React from 'react';
import PropTypes from 'prop-types';

export default function InkBar({ left, width }) {
  return (
    <div className="rtabs__inkbar-wrapper">
      <div className="rtabs__inkbar" style={{ left, width }} />
    </div>
  );
}

InkBar.propTypes = {
  left: PropTypes.number,
  width: PropTypes.number,
};

InkBar.defaultProps = {
  left: 0,
  width: 0,
};
