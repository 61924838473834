import React, { useContext } from 'react';
import { t } from 'i18next';
import EmptyContentPlaceholder from 'components/Shared/EmptyContentPlaceholder';
import ListingDetailsContext from 'components/BiddingProcess/ListingDetails/ListingDetailsContext';
import './styles.scss';

const ContractDetails = () => {
  const { contractInformation, listingInformation } = useContext(ListingDetailsContext);

  const contractPdfUrl =
    contractInformation?.signedContract?.url || contractInformation?.draftContract?.url;

  return (
    <div className="contract-details">
      {!listingInformation?.aggregatedData ? (
        contractInformation?.draftContract?.url && (
          <iframe
            className="contract-details__pdf-viewer"
            src={`${contractPdfUrl}#zoom=100`}
            title={t('admin.contract_details.draft_contract')}
          />
        )
      ) : (
        <EmptyContentPlaceholder
          heading={t('admin.contract_details.no_pdf_available_for_aggregated_data')}
        />
      )}
    </div>
  );
};

export default ContractDetails;
