import React from 'react';
import PropTypes from 'prop-types';
import { formatVolume } from 'utils/helper/Charts';
import { countryCodeToName } from 'utils/helper/selectBoxUtils';
import horizontalDivider from 'assets/images/horizontal-divider.svg';
import './styles.scss';

const HorizontalDivider = () => (
  <img className="listing-details-header__divider" src={horizontalDivider} alt="divider" />
);

const ListingDetailsHeader = ({ offerVolume, nutGrade, nutStyle, countryOfOrigin }) => {
  const volume = `${formatVolume(offerVolume)} kg`;

  return (
    <div className="listing-details-header">
      {volume}

      {nutGrade && (
        <>
          <HorizontalDivider />
          {nutGrade}
        </>
      )}

      {nutStyle && (
        <>
          <HorizontalDivider />
          {nutStyle}
        </>
      )}

      {countryOfOrigin && (
        <>
          <HorizontalDivider />
          {countryCodeToName(countryOfOrigin)}
        </>
      )}
    </div>
  );
};

ListingDetailsHeader.defaultProps = {
  nutStyle: null,
};

ListingDetailsHeader.propTypes = {
  offerVolume: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  nutGrade: PropTypes.string.isRequired,
  nutStyle: PropTypes.string,
  countryOfOrigin: PropTypes.string.isRequired,
};

export default ListingDetailsHeader;
