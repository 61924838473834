import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { generateLoginBody } from 'utils/helper/RequestBody';
import { useLoginMutation } from 'api/auth';
import { useLazyGetCurrentUserQuery } from 'api/users';
import { landingPage } from 'utils/constants/Routes';
import { requiredField } from 'utils/helper/Validations';
import Button from 'components/BaseComponents/Button';
import CheckBox from 'components/BaseComponents/CheckBox';
import Input from 'components/BaseComponents/Input';
import FormFooter from 'components/Login/FormFooter';
import PasswordInput from 'components/Shared/PasswordInput';
import msmLogo from 'assets/images/msm-logo.svg';
import './styles.scss';

const LoginForm = () => {
  const { t } = useTranslation();
  const [login] = useLoginMutation();
  const [getCurrentUser] = useLazyGetCurrentUserQuery();

  const handleLoginSubmit = async (params) => {
    const requestBody = generateLoginBody(params);
    const response = await login({ payload: { session: requestBody } });

    if (response.error) {
      const { error } = response.error.data;
      return { email: error, password: error };
    }

    await getCurrentUser();

    return null;
  };

  return (
    <div className="login-form__container">
      <div className="login-form__container--header">
        <Link to={landingPage}>
          <img className="login-form__container--header-logo" src={msmLogo} alt="msm logo" />
        </Link>

        <div className="login-form__container--header-texts">
          <h2 className="login-form__container--header-texts-title">{t('login.title')}</h2>

          <p className="login-form__container--header-texts-message">
            {t('login.welcome_message')}
          </p>
        </div>
      </div>

      <div className="login-form__container--form">
        <Form
          onSubmit={handleLoginSubmit}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} className="margin-t-16px">
              <Field name="email" validate={requiredField}>
                {({ input, meta }) => (
                  <Input
                    className="margin-t-20px"
                    label={t('login.email_label')}
                    placeholder={t('login.email_placeholder')}
                    required
                    error={!meta.dirtySinceLastSubmit && (meta?.error || meta?.submitError)}
                    touched={meta.touched}
                    size="small"
                    value={input.value}
                    onChange={input.onChange}
                  />
                )}
              </Field>

              <Field name="password" validate={requiredField}>
                {({ input, meta }) => (
                  <>
                    <PasswordInput
                      className="margin-t-20px margin-b-16px"
                      label={t('login.password_label')}
                      placeholder={t('login.password_placeholder')}
                      required
                      error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      size="small"
                      value={input.value}
                      onChange={input.onChange}
                    />
                    <span className="submit-error">{meta?.submitError}</span>
                  </>
                )}
              </Field>

              <div className="login-form__container--row">
                <Field name="remember_me" type="checkbox" initialValue={false}>
                  {({ input }) => (
                    <CheckBox
                      id="remember_me"
                      label={t('login.password_checkbox_label')}
                      hint={t('login.password_checkbox_tooltip')}
                      hintPlacement="top-left"
                      className="login-form__container--form-checkbox"
                      isChecked={input.checked}
                      onChange={(checked) => {
                        input.onChange(checked);
                      }}
                    />
                  )}
                </Field>

                <Link to="/forgot-password" className="login-form__container--forgot-password">
                  {t('login.forgot_password_label')}
                </Link>
              </div>

              <Button
                className="login-form__container--submit-btn"
                type="success"
                label={t('login.submit_button_label')}
                submitType="submit"
                disabled={submitting}
              />
            </form>
          )}
        />
      </div>

      <FormFooter />
    </div>
  );
};

export default LoginForm;
