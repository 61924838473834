import React, { useContext } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Icon from 'components/BaseComponents/Icon';
import IconButton from 'components/BaseComponents/IconButton';
import ListingDetailsContext from 'components/BiddingProcess/ListingDetails/ListingDetailsContext';
import './styles.scss';
import AttachedDocuments from 'components/BiddingProcess/Contract/ContractDetails/AttachedDocuments';

const ContractDocument = ({ titleKey, documentKey }) => {
  const { contractInformation } = useContext(ListingDetailsContext);
  const document = contractInformation?.[documentKey];
  const downloadIcon = <Icon name="download" size="medium" color="success" />;

  return (
    <div className="contract-documents-file">
      <div className="contract-documents-file__title">
        {t(`admin.contract_details.${titleKey}`)}
      </div>
      <div className="contract-documents-file__signed-contract">
        <div className="contract-documents-file__signed-contract--title">
          {document?.url ? (
            <Icon name="preference" size="medium" color="success" />
          ) : (
            <Icon name="content" size="medium" color="none" />
          )}
          <div className="contract-documents-file__signed-contract--icon">
            {t(`admin.contract_details.${titleKey}`)}
          </div>
        </div>
        {document?.url && (
          <IconButton
            onClick={() => window.open(document.url, '_blank')}
            icon={downloadIcon}
            size="large"
            color="success"
            isIconOnly
          />
        )}
      </div>
    </div>
  );
};

ContractDocument.propTypes = {
  titleKey: PropTypes.string.isRequired,
  documentKey: PropTypes.string.isRequired,
};

const ContractDocuments = () => (
  <div className="contract-documents">
    <h3 className="contract-documents__title">{t('admin.contract_details.contract_documents')}</h3>
    <ContractDocument titleKey="draft_document" documentKey="draftContract" />
    <AttachedDocuments />
  </div>
);

export default ContractDocuments;
