import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRejectUserMutation } from 'api/admin';
import useUserManagement from 'hooks/useUserManagement';
import { PERSONAL_DETAILS_FORM_KEY } from 'utils/constants/portals';
import Modal from 'components/BaseComponents/Modal';
import Button from 'components/BaseComponents/Button';
import { RTabs } from 'components/BaseComponents/RTabs';
import AvatarCard from 'components/Shared/Portals/Avatar';
import ToastNotification from 'components/Shared/ToastNotification';
import { getProfileTabs } from 'utils/helper/Profile';
import { AbilityContext, Can, userSubject } from 'ability';
import PortalsNavbar from 'components/NavBar/PortalsNavBar';
import ActionConfirmationModalBody from 'components/Portals/Admin/UserManagement/Profile/ActionConfirmationModal';
import buildOwnerAbility from 'ability/owner';
import useCustomNavigate from 'hooks/useCustomNavigate';

const ProfileAsOwner = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const userId = Number(id);
  const toastRef = useRef();
  const navigate = useCustomNavigate();

  const profileData = useUserManagement(userId);
  const { userDetails, companyDetails, admin: owner } = profileData;

  const ability = buildOwnerAbility(owner, { userDetails, companyDetails });

  const profileTabs = getProfileTabs({ ...profileData, toastRef }, ability);

  const [rejectUserMutation, { isLoading: isRejectUserSubmitting }] = useRejectUserMutation();
  const [isActionConfirmationModalVisible, setIsActionConfirmationModalVisible] = useState(false);

  const handleActionConfirmationModalOpen = () => setIsActionConfirmationModalVisible(true);
  const handleActionConfirmationModalClose = () => setIsActionConfirmationModalVisible(false);

  const rejectUser = () =>
    rejectUserMutation({ userId })
      .unwrap()
      .then(() => {
        window.location.href = '/';
      })
      .catch(({ data: { errors } }) => errors);

  return (
    <AbilityContext.Provider value={ability}>
      <PortalsNavbar />

      <div className="portal-profile">
        <div className="flex justify-between portal-profile__container border-b-1 border-gray-200 border-b-solid">
          <AvatarCard
            userId={userId}
            isEditable={true}
            containerClassName="portal-profile__avatar-container"
            canUploadAvatar={false}
          />

          <div className="portal-profile__verification-buttons flex items-center gap-12px">
            <Can I="reject" a={userSubject(userDetails)} >
              <Button
                label={t('admin.request_modal_body.reject_button_label')}
                type="danger"
                onClick={() => {
                  handleActionConfirmationModalOpen();
                }}
                disabled={isRejectUserSubmitting}
                className="portal-profile__verification-buttons--reject"
              />
              <Button
                label={t('profile.back_to_profile')}
                onClick={() => navigate.profile()}
                className="portal-profile__verification-buttons--back"
              />
            </Can>
          </div>
        </div>

        <RTabs
          containerClass="portal-profile__tabs margin-x-auto margin-t-24px"
          isVertical={true}
          items={profileTabs}
          selectedTabKey={PERSONAL_DETAILS_FORM_KEY}
        />

        <Modal
          isVisible={isActionConfirmationModalVisible}
          onOutsideClick={handleActionConfirmationModalClose}
          className="progress-card-modal"
        >
          <Modal.Content>
            <ActionConfirmationModalBody
              onModalClose={handleActionConfirmationModalClose}
              action={rejectUser}
              actionText={t('admin.action_confirmation_modal_body.reject_action')}
              type="danger"
            />
          </Modal.Content>
        </Modal>

        <ToastNotification toastRef={toastRef} />
      </div>
    </AbilityContext.Provider>
  );
};

export default ProfileAsOwner;
