import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import './styles.scss';

const DetailsInformationTextCard = ({ complianceInstitute }) => (
  <div className="test-results">
    <div className="test-results__header">
      {t('bidding_process.maximum_residue_level_test_results')}
    </div>
    <div className="test-results__body">
      <div className="test-results__body-item">
        {t('bidding_process.comply_as_per')}
        <span className="test-results__body-item--bold">{complianceInstitute}</span>
      </div>
    </div>
  </div>
);

DetailsInformationTextCard.propTypes = {
  complianceInstitute: PropTypes.string.isRequired,
};

export default DetailsInformationTextCard;
