import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'components/BaseComponents/Icon';
import Step from './componets/Step';

const Stepper = (props) => {
  const {
    className,
    steps,
    activeStepIndex,
    direction,
    onFinish,
    onMoveNext,
    onMoveBack,
    nextButtonLabel,
    previousButtonLabel,
    finishButtonLabel,
    disableMoveForward,
    disableMoveBackward,
    showActionButtons,
    showStepTitle,
    isOptionalButtonVisible,
    optionalButtonLabel,
    disableOptionalButton,
    onOptionalButtonClick,
  } = props;

  const getStepTitle = () => {
    if (steps[activeStepIndex]) {
      if (steps[activeStepIndex].stepTitle) {
        return steps[activeStepIndex].stepTitle;
      }

      if (steps[activeStepIndex].stepHint) {
        return `${steps[activeStepIndex].stepName} (${steps[activeStepIndex].stepHint})`;
      }

      return `${steps[activeStepIndex].stepName}`;
    }

    return null;
  };

  const stepTitle = getStepTitle();

  return (
    <div className={classNames('stepper', `stepper--${direction}`, className)}>
      {showStepTitle && direction === 'vertical' ? (
        <div className="stepper__header">
          <div className="stepper__header-step-description">{stepTitle}</div>
        </div>
      ) : null}
      <div className="stepper__body">
        {steps.map((step, index) => (
          <Step
            key={step.stepName}
            stepName={step.stepName}
            stepIndex={index}
            isActive={index === activeStepIndex}
            isDone={index < activeStepIndex}
          />
        ))}
      </div>
      {((showStepTitle && steps[activeStepIndex]) || showActionButtons) && (
        <div className="stepper__footer">
          {showStepTitle && direction === 'horizontal' ? (
            <div className="stepper__footer-step-description">{stepTitle}</div>
          ) : null}
          {showActionButtons && (
            <div className="stepper__footer-actions">
              {isOptionalButtonVisible && (
                <Button
                  className="stepper__footer-actions-button stepper__footer-actions-button--optional"
                  version="v2"
                  label={optionalButtonLabel}
                  disabled={disableOptionalButton}
                  onClick={onOptionalButtonClick}
                />
              )}
              <Button
                className="stepper__footer-actions-button stepper__footer-actions-button--pre"
                version="v2"
                label={previousButtonLabel}
                disabled={
                  disableMoveBackward || activeStepIndex === 0 || activeStepIndex === steps.length
                }
                onClick={onMoveBack}
              />
              {activeStepIndex < steps.length - 1 ? (
                <Button
                  className="stepper__footer-actions-button stepper__footer-actions-button--next"
                  version="v2"
                  type="tertiary"
                  label={nextButtonLabel}
                  disabled={disableMoveForward}
                  onClick={onMoveNext}
                />
              ) : (
                <Button
                  className="stepper__footer-actions-button stepper__footer-actions-button--finish"
                  version="v2"
                  type="primary"
                  label={finishButtonLabel}
                  disabled={disableMoveForward || activeStepIndex === steps.length}
                  onClick={onFinish}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

Stepper.defaultProps = {
  className: null,
  direction: 'horizontal',
  finishButtonLabel: 'Finish',
  nextButtonLabel: 'Next Step',
  previousButtonLabel: 'Previous Step',
  optionalButtonLabel: 'Optional Step',
  disableMoveForward: false,
  disableMoveBackward: false,
  disableOptionalButton: false,
  showActionButtons: true,
  showStepTitle: true,
  isOptionalButtonVisible: false,
  onFinish: () => {},
  onMoveBack: () => {},
  onMoveNext: () => {},
  onOptionalButtonClick: () => {},
};

Stepper.propTypes = {
  className: PropTypes.string,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      stepName: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
      stepHint: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      stepTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    }),
  ).isRequired,
  activeStepIndex: PropTypes.number.isRequired,
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  finishButtonLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  nextButtonLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  previousButtonLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  optionalButtonLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  disableMoveForward: PropTypes.bool,
  disableMoveBackward: PropTypes.bool,
  disableOptionalButton: PropTypes.bool,
  showActionButtons: PropTypes.bool,
  showStepTitle: PropTypes.bool,
  isOptionalButtonVisible: PropTypes.bool,
  onFinish: PropTypes.func,
  onMoveBack: PropTypes.func,
  onMoveNext: PropTypes.func,
  onOptionalButtonClick: PropTypes.func,
};

export default Stepper;
