import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';
import { styles } from '../styles';

const SignatureBox = ({ text, size }) => {
  const boxStyle = size === 'large' ? styles.signatureBoxLarge : styles.signatureBox;

  return (
    <View style={boxStyle}>
      <Text>{text}</Text>
    </View>
  );
};

SignatureBox.defaultProps = {
  size: 'small',
};

SignatureBox.propTypes = {
  text: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'large']),
};

export default SignatureBox;
