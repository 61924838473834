import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import NewMessageToast from 'components/BaseComponents/Toast/customToasts/NewMessageToast';

const Toastify = ({ type, errorMessage }) => {
  const heading =
    type === 'success' ? t('shared.form_validation.saved_successfully') : errorMessage;

  return <NewMessageToast heading={heading} type={type} />;
};

Toastify.defaultProps = {
  errorMessage: t('shared.form_validation.error_occurred'),
};

Toastify.propTypes = {
  type: PropTypes.oneOf(['success', 'error']).isRequired,
  errorMessage: PropTypes.string,
};

export default Toastify;
