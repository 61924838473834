import React from 'react';
import { t } from 'i18next';
import { Text, View } from '@react-pdf/renderer';
import { styles } from '../styles';

const LogisticsInformation = () => (
  <View style={styles.logisticsCard}>
    <View style={styles.logisticsCardLeft}>
      <View style={styles.flexRow}>
        <Text style={styles.logisticsCardBoldText}>{t('contract_pdf.position')}</Text>
        <Text style={styles.logisticsCardText}>{t('contract_pdf.ex_works')}</Text>
      </View>
      <View style={styles.flexRow}>
        <Text style={styles.logisticsCardBoldText}>{t('contract_pdf.shipment')}</Text>
        <Text style={styles.logisticsCardText}>{t('contract_pdf.prompt')}</Text>
      </View>
      <View style={styles.flexRow}>
        <Text style={styles.logisticsCardBoldText}>{t('contract_pdf.delivery_instructions')}</Text>
        <Text style={styles.logisticsCardText}>:</Text>
        <Text style={styles.logisticsCardText}>
          {t('contract_pdf.delivery_instructions_description')}
        </Text>
      </View>
    </View>

    <View style={styles.logisticsCardRight}>
      <Text style={styles.logisticsCardBoldText}>{t('contract_pdf.specification')}</Text>
      <Text style={styles.normalText}>{t('contract_pdf.specification_text')}</Text>
    </View>
  </View>
);

export default LogisticsInformation;
