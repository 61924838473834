import React, { useState, useEffect } from 'react';
import { t } from 'i18next';
import { Field } from 'react-final-form';
import { requiredField } from 'utils/helper/Validations';
import { requiredSectionHeading, requiredText } from 'utils/helper/LabelWithHints';
import Icon from 'components/BaseComponents/Icon';
import Input from 'components/BaseComponents/Input';
import Tooltip from 'components/BaseComponents/Tooltip';
import Radio from 'components/BaseComponents/Radio';
import './styles.scss';

const BatchDetails = () => {
  const [showAdditionalField, setShowAdditionalField] = useState(
    localStorage.getItem('showAdditionalField') === 'true'
  );

  useEffect(() => {
    localStorage.setItem('showAdditionalField', showAdditionalField);
  }, [showAdditionalField]);

  const [showSpan, setShowSpan] = useState(false);

  return (
    <div className="batch-details offer-food-safety-padding">
      {requiredSectionHeading(t('offer_creation.food_safety.batch_details.title'))}
      <div className="grid">
        <div className="col-6 col-bleed margin-t-20px">
          <Field
            type="radio"
            name="consignment_value"
            validate={requiredField}
            className="col-6 col-bleed margin-t-20px"
          >
            {({ meta }) => (
              <div className="flex items-center gap-8px business-details__delivery-option batch-details__radio">
                {requiredText(t('offer_creation.food_safety.batch_details.radio_input_label'))}
                {meta.touched && (meta?.error || meta?.submitError) && (
                  <Tooltip
                    content={meta?.error || meta?.submitError}
                    type="danger"
                    position="bottom-right"
                    gap={0}
                  >
                    <Icon name="invalid" color="danger" />
                  </Tooltip>
                )}
              </div>
            )}
          </Field>

          <div className="batch-details__radio-block">
            <div className="batch-details__radio--options">
              <Field type="radio" value="true" name="consignment_value" validate={requiredField}>
                {({ input }) => (
                  <Radio
                    id="consignment_pasteurised_yes"
                    label={t('offer_creation.food_safety.batch_details.radio_input_option_yes')}
                    name={input.name}
                    size="large"
                    isChecked={input.checked}
                    withCheckIcon={input.checked}
                    onChange={() => {
                      input.onChange(input.value);
                      setShowAdditionalField(false);
                    }}
                  />
                )}
              </Field>
              <Field type="radio" value="false" name="consignment_value" validate={requiredField}>
                {({ input }) => (
                  <Radio
                    id="consignment_pasteurised_no"
                    label={t('offer_creation.food_safety.batch_details.radio_input_option_no')}
                    name={input.name}
                    size="large"
                    isChecked={input.checked}
                    withCheckIcon={input.checked}
                    onChange={() => {
                      input.onChange(input.value);
                      setShowAdditionalField(true);
                    }}
                  />
                )}
              </Field>
            </div>
            {showAdditionalField && (
              <div className="batch-details__radio--options--access-to-pasteurization">
                <span>
                  {requiredText(
                    t(
                      'offer_creation.food_safety.batch_details.access_to_pasteurization.radio_input_label',
                    ),
                  )}
                </span>
                <div className="batch-details__radio--options--access-to-pasteurization--block">
                  <Field
                    type="radio"
                    value="true"
                    name="access_to_pasteurization"
                    validate={requiredField}
                  >
                    {({ input }) => (
                      <Radio
                        id="access_to_pasteurization_yes"
                        label={t('offer_creation.food_safety.batch_details.radio_input_option_yes')}
                        name={input.name}
                        size="large"
                        isChecked={input.checked}
                        withCheckIcon={input.checked}
                        onChange={() => {
                          input.onChange(input.value);
                          setShowSpan(true);
                        }}
                      />
                    )}
                  </Field>
                  <Field
                    type="radio"
                    value="false"
                    name="access_to_pasteurization"
                    validate={requiredField}
                  >
                    {({ input }) => (
                      <Radio
                        id="access_to_pasteurization_no"
                        label={t('offer_creation.food_safety.batch_details.radio_input_option_no')}
                        name={input.name}
                        size="large"
                        isChecked={input.checked}
                        withCheckIcon={input.checked}
                        onChange={() => {
                          input.onChange(input.value);
                          setShowSpan(false);
                        }}
                      />
                    )}
                  </Field>
                </div>
                {showSpan && (
                  <span className="margin-b-16px">
                    {t(
                      'offer_creation.food_safety.batch_details.access_to_pasteurization.want_access_to_pasteurization',
                    )}
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
        <Field name="num_of_batch" validate={requiredField}>
          {({ input, meta }) => (
            <Input
              type="number"
              label={t('offer_creation.food_safety.batch_details.input_field_label')}
              placeholder={t('offer_creation.food_safety.batch_details.input_field_placeholder')}
              required
              size="small"
              className="col-6 col-bleed padding-l-10px margin-t-20px margin-b-20px"
              error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
              touched={meta.touched}
              value={input.value}
              onChange={input.onChange}
            />
          )}
        </Field>
      </div>
      <div className="batch-details__description">
        <Icon name="help" size="medium" />
        <div className="batch-details__description--text">
          <p>{t('offer_creation.food_safety.batch_details.description1')}</p>
          <p>{t('offer_creation.food_safety.batch_details.description2')}</p>
          <p>{t('offer_creation.food_safety.batch_details.description3')}</p>
        </div>
      </div>
      <div className="batch-details__description batch-details__hint-text">
        <Icon name="info" size="medium" />
        <div className="batch-details__description--text">
          <p>{t('offer_creation.food_safety.batch_details.user_hint_text1')}</p>
          <p>{t('offer_creation.food_safety.batch_details.user_hint_text2')}</p>
        </div>
      </div>
    </div>
  );
};

export default BatchDetails;
