import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';
import { styles } from '../styles';

const ContractSale = ({ serialNumber, creationDate, creationTime }) => (
  <Fragment>
    <View style={styles.contractSaleNo}>
      <Text style={styles.contractSaleNoBoldText}>{t('contract_pdf.contract_of_sale_no')}</Text>
      <Text>{serialNumber}</Text>
    </View>
    <View style={styles.contractSaleDate}>
      <View style={styles.flexRow}>
        <Text style={styles.contractSaleDateText}>{t('contract_pdf.contract_date')}</Text>
        <Text style={styles.normalText}>{creationDate}</Text>
      </View>
      <View style={styles.flexRow}>
        <Text style={styles.contractSaleDateText}>{t('contract_pdf.contract_time')}</Text>
        <Text style={styles.normalText}>{creationTime}</Text>
      </View>
    </View>
    <hr style={styles.lineBreak} />
  </Fragment>
);

ContractSale.propTypes = {
  serialNumber: PropTypes.string.isRequired,
  creationDate: PropTypes.string.isRequired,
  creationTime: PropTypes.string.isRequired,
};

export default ContractSale;
