import { AbilityBuilder, createMongoAbility } from "@casl/ability";
import defineProfileTabsAbility from "./profileTabs";

const buildProfileAbility = ({ userDetails, companyDetails }) => {
  const { can, cannot, build } = new AbilityBuilder(createMongoAbility);

  defineProfileTabsAbility({ userDetails, companyDetails, can, cannot });

  return build();
};

export default buildProfileAbility;
