import React from 'react';
import PropTypes from 'prop-types';

const TradingStepCard = ({ stepNumber, heading, description }) => (
  <div className="trading-cards__card">
    <div className="trading-cards__card--card-number flex items-center justify-center">
      {stepNumber}
    </div>
    <h4 className="trading-cards__card--heading">{heading}</h4>
    <p className="trading-cards__card--description">{description}</p>
  </div>
);

TradingStepCard.propTypes = {
  stepNumber: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default TradingStepCard;
