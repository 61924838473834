import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { landingPage } from 'utils/constants/Routes';

function SideNavLink(props) {
  const { url, icon, label, isPrivate, isLoggedIn, activeLink, onClick } = props;
  const shouldShow = isPrivate ? isLoggedIn : true;

  return shouldShow ? (
    <li>
      <div
        className={classNames('side-nav__list-item', {
          'side-nav__list-item--active': url === activeLink,
        })}
        onClick={onClick}
      >
        <div className="side-nav__list-item-icon">{icon}</div>
        <div className="side-nav__list-item-label">{label}</div>
      </div>
    </li>
  ) : null;
}

SideNavLink.defaultProps = {
  isPrivate: false,
  label: '',
  notificationsCount: 0,
  url: '',
  isLoggedIn: false,
  activeLink: landingPage,
  onClick: () => {},
};

SideNavLink.propTypes = {
  icon: PropTypes.node.isRequired,
  isPrivate: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  notificationsCount: PropTypes.number,
  url: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  activeLink: PropTypes.string,
  onClick: PropTypes.func,
};

export default SideNavLink;
