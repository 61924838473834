import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Accordion = props => {
  const { className, children } = props;

  return <div className={classNames('accordion', className)}>{children}</div>;
};

Accordion.defaultProps = {
  className: null,
};

Accordion.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Accordion;
