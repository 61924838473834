import PropTypes from 'prop-types';

export const cardItemsShape = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  }),
);

export const documentShape = PropTypes.shape({
  name: PropTypes.string,
  url: PropTypes.string,
});

export const chartCollectionShape = PropTypes.shape({
  chartAxis: PropTypes.shape({
    x: PropTypes.bool,
    y: PropTypes.bool,
  }),
  chartMargin: PropTypes.shape({
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
    top: PropTypes.number,
  }),
  chartDataLines: PropTypes.arrayOf(PropTypes.string),
  chartColors: PropTypes.objectOf(PropTypes.string),
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
});
