import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { t } from 'i18next';
import {
  useGetCurrentUserQuery,
  useGetMyAffiliationsQuery,
  useGetOnboardedUsersQuery,
} from 'api/users';
import { getMyAffiliationsTable } from 'utils/helper/Portals';
import { hasAdminRole } from 'utils/helper/UserRoleValidation';
import { affiliatePartner } from 'utils/constants/Routes';
import { ARCHIVED } from 'utils/constants/offerListings';
import { INVITED, VERIFIED } from 'utils/constants/userStatus';
import { RTabs } from 'components/BaseComponents/RTabs';
import Pagination from 'components/BaseComponents/Pagination';
import EmptyContentPlaceholder from 'components/Shared/EmptyContentPlaceholder';
import 'components/Portals/Admin/UserManagement/Table/styles.scss';

const MyAffiliationsTable = () => {
  const [userStatus, setUserStatus] = useState(VERIFIED);
  const [currentPage, setCurrentPage] = useState(1);
  const { id: affiliateId } = useParams();

  const { data: user = {} } = useGetCurrentUserQuery();

  const { data: myAffiliationsList, isFetching: isMyAffiliationsFetching } =
    useGetMyAffiliationsQuery(
      {
        userStatus,
        page: currentPage,
      },
      { skip: hasAdminRole(user) },
    );

  const { data: affiliatePartnersList, isFetching: isAffiliatePartnersListFetching } =
    useGetOnboardedUsersQuery(
      {
        id: affiliateId,
        userStatus,
        page: currentPage,
      },
      { skip: !hasAdminRole(user) },
    );

  const listings = myAffiliationsList || affiliatePartnersList;
  const isFetching = isMyAffiliationsFetching || isAffiliatePartnersListFetching;

  const isListingExists = listings?.collection?.length > 0;
  const totalPages = Number(listings?.pagination?.totalPages);
  const tableCaption = t('affiliate_partners.affiliations_table.header');
  const showInviteButton = userStatus !== ARCHIVED && !hasAdminRole(user);

  const tabFilterStatus = {
    [t('affiliate_partners.affiliations_table.onboarded_tab')]: VERIFIED,
    [t('affiliate_partners.affiliations_table.invited_tab')]: INVITED,
    [t('affiliate_partners.affiliations_table.archived_tab')]: ARCHIVED,
  };

  const tabItems = getMyAffiliationsTable(isFetching, hasAdminRole(user), listings?.collection);

  const handleTabChange = (tabKey) => {
    setCurrentPage(1);
    setUserStatus(tabFilterStatus[tabKey]);
  };

  const handlePagination = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  return (
    <div className="admin-portal__table--container">
      <h5 className="admin-portal__table--caption">{tableCaption}</h5>
      <RTabs
        className="admin-portal__table--tab"
        isVertical={false}
        items={tabItems}
        selectedTabKey={tabItems[0].key}
        onChange={handleTabChange}
      />
      {!isListingExists && !isFetching && (
        <div className="flex flex-col items-center margin-b-16px ">
          <EmptyContentPlaceholder
            heading={t('shared.bidding_process.empty_content_placeholder.affiliations.header')}
            description={t(
              `shared.bidding_process.empty_content_placeholder.affiliations.${userStatus}`,
            )}
          />
          {showInviteButton && (
            <Link
              to={affiliatePartner.inviteBuyer}
              className="avatar-container__card--redirect-button"
            >
              {t('shared.bidding_process.avatar.create_listing.affiliate_partner')}
            </Link>
          )}
        </div>
      )}
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPaginationClick={handlePagination}
          firstText={t('admin_user_table.first_page')}
          lastText={t('admin_user_table.last_page')}
          nextText={t('admin_user_table.next_page')}
          previousText={t('admin_user_table.previous_page')}
          className="admin-portal__table--pagination"
        />
      )}
    </div>
  );
};

export default MyAffiliationsTable;
