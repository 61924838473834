import React, { useContext } from 'react';
import { t } from 'i18next';
import useCustomNavigate from 'hooks/useCustomNavigate';
import ListingDetailsContext from 'components/BiddingProcess/ListingDetails/ListingDetailsContext';
import NeedProductPermissionsIcon from 'components/Shared/BiddingProcess/NeedProductPermissionsIcon';
import { hasCompanyPermissions } from 'utils/helper/UserRoleValidation';
import classNames from 'classnames';
import './styles.scss';

const EditListingOption = () => {
  const navigate = useCustomNavigate();

  const { productInformation, isOfferListing, companyDetails } = useContext(ListingDetailsContext);
  const { type: productType } = productInformation;
  const onClickEditListing = () => navigate.updateProduct(productInformation.id);

  const needProductPermissions = isOfferListing && !hasCompanyPermissions(productType, companyDetails);

  return (
    <button
      onClick={onClickEditListing}
      className={classNames("listing-details-breadcrumb__edit-listing", {
        "listing-details-breadcrumb__edit-listing-disabled": needProductPermissions,
      })}
      disabled={needProductPermissions}
      type="button"
    >
      {t('bidding_process.edit_listing')}
      {needProductPermissions && <NeedProductPermissionsIcon type={productType} />}
    </button>
  );
};

export default EditListingOption;
