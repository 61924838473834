import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';
import { OFFER, ORDER } from 'utils/constants/offerListings';
import { styles } from '../styles';

const Platforms = ({ listingDetails }) => (
  <Fragment>
    {listingDetails.category === OFFER && (
      <View style={styles.flexRow}>
        <Text style={styles.boldText}>{t('contract_pdf.platform_offer_number')}</Text>
        <Text style={styles.normalText}>{listingDetails.serialNumber}</Text>
      </View>
    )}

    {listingDetails.category === ORDER && (
      <View style={styles.flexRow}>
        <Text style={styles.boldText}>{t('contract_pdf.platform_order_number')}</Text>
        <Text style={styles.normalText}>{listingDetails.serialNumber}</Text>
      </View>
    )}
    <hr style={styles.lineBreak} />
  </Fragment>
);

Platforms.propTypes = {
  listingDetails: PropTypes.shape({
    serialNumber: PropTypes.string,
    category: PropTypes.string,
  }).isRequired,
};

export default Platforms;
