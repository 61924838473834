import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import camelcaseKeys from 'camelcase-keys';
import * as tagTypes from 'utils/tagTypes';
import { publicUrls } from 'utils/constants/landingPage';
import i18n from '../i18n';

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_BACKEND_URL}/api`,
  prepareHeaders: (headers) => {
    headers.set('Accept-Version', 'v1');
    headers.set('X-LOCALE', i18n.language);
    return headers;
  },
  credentials: 'include',
});

const customizeBaseQuery = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  const errorCode = result?.error?.status;

  if (errorCode === 403 && !publicUrls.includes(window.location.pathname)) {
    // eslint-disable-next-line
    return (window.location = '/login?error=unauthenticated');
  }

  if (errorCode === 401) {
    return window.location.replace('/unauthorized');
  }

  // HTTP response status code for server errors starts with number 5 following two digits
  const serverErrorStatusCodeRegex = /^5[0-9][0-9]$/;

  if (serverErrorStatusCodeRegex.test(errorCode)) {
    throw result;
  }

  if (result.data) {
    // eslint-disable-next-line
    result.data = camelcaseKeys(result.data, { deep: true }); // function used to transform global response
  }

  const { response } = result.meta;

  if (response && response.headers.get('X-Total') !== null) {
    const pagination = {
      totalPages: response.headers.get('x-Total-Pages'),
      currentPage: response.headers.get('x-Page'),
      totalCount: response.headers.get('X-Total'),
    };

    result.data = { collection: result.data, pagination };
  }

  if (result.error && result.error.data) {
    result = camelcaseKeys(result, { deep: true });
  }

  return result;
};

const allowedTagTypes = Object.values(tagTypes);

// eslint-disable-next-line
export const apiSlice = createApi({
  baseQuery: customizeBaseQuery,
  keepUnusedDataFor: 30,
  refetchOnReconnect: true,
  tagTypes: allowedTagTypes,
  endpoints: () => ({}),
});
