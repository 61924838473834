export const transformFoodSafetyTestCertificateResponse = (backendResponse) => {
  const defaultCertificate = [{}];

  if (backendResponse) {
    return backendResponse.map((certificate) => ({
      fileName: {
        name: certificate.filename,
        url: certificate.url,
        fileId: certificate.id,
        numberOfCertificates: backendResponse.length,
      },
    }));
  }

  return defaultCertificate;
};

export const generateFoodSafetyTestTableDefaultValues = (testDetailsCollection) => {
  const defaultTestMethodValues = {};

  testDetailsCollection.forEach((testDetail) => {
    const key = testDetail.substanceValue;
    const { value } = testDetail.testMethods[0];

    defaultTestMethodValues[key] = value;
  });

  return defaultTestMethodValues;
};
