import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { requiredSectionHeading } from 'utils/helper/LabelWithHints';
import {
  chemicalTestCertificate,
  chemicalTestTableContents,
  chemicalTestTableHeaders,
} from 'utils/constants/listingCreation';
import TestResultsTable from 'components/BiddingProcess/CreateListing/FoodSafety/TestResultsTable';
import TestCertificates from '../TestCertificates';

const ChemicalTest = ({ form }) => (
  <div className="offer-food-safety-padding">
    {requiredSectionHeading(
      t('offer_creation.food_safety.test_result_title', {
        testName: t('offer_creation.food_safety.chemical_test_title'),
      }),
    )}
    <TestResultsTable
      tableHeaders={chemicalTestTableHeaders}
      tableContentCollection={chemicalTestTableContents}
      form={form}
      isOptional={false}
    />
    <TestCertificates testType={chemicalTestCertificate} />
  </div>
);

ChemicalTest.propTypes = {
  form: PropTypes.shape({
    batch: PropTypes.func,
    change: PropTypes.func,
    getState: PropTypes.func,
  }).isRequired,
};

export default ChemicalTest;
