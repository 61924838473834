import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { useUploadSignedDocumentMutation } from 'api/contracts';
import { errorToastConfig } from 'utils/constants/ToastConfig';
import { generateSignedDocumentBody } from 'utils/helper/RequestBody';
import { processPortalFormSuccess } from 'utils/helper/FormHandlers';
import { validatePortalDocuments, handleServerValidationErrors } from 'utils/helper/Validations';
import Button from 'components/BaseComponents/Button';
import ChooseFile from 'components/Shared/ChooseFile';
import Toastify from 'components/Shared/ToastNotification/Toastify';
import './styles.scss';

const UploadSignedContractModalBody = ({ handleModalClose, toastRef }) => {
  const { t } = useTranslation();
  const { id: contractId } = useParams();
  const [uploadSignedDocument] = useUploadSignedDocumentMutation();

  const handleFormSubmit = (values) => {
    const signedContractFileName = values?.signed_contract?.name;
    if (!signedContractFileName) {
      toastRef?.current?.notify(<Toastify type="error" />, errorToastConfig);
      return {
        signed_contract: t('shared.form_validation.required_field'),
      };
    }

    const signedDocument = values?.signed_contract;

    if (!(signedDocument instanceof File)) {
      return null;
    }

    const requestBody = generateSignedDocumentBody(values);

    return uploadSignedDocument({ contract_id: contractId, payload: requestBody })
      .unwrap()
      .then(() => {
        processPortalFormSuccess(toastRef);
        handleModalClose();
      })
      .catch(({ data: { errors }, status }) =>
        handleServerValidationErrors(toastRef, status, errors),
      );
  };

  return (
    <div className="signed-document__modal">
      <h4 className="signed-document__modal--title">
        {t('shared.bidding_process.contract_details.contracts_header.upload_title')}
      </h4>
      <Form
        initialValues={null}
        onSubmit={handleFormSubmit}
        render={({ handleSubmit, hasValidationErrors, submitting }) => (
          <form
            onSubmit={(event) => {
              if (hasValidationErrors) {
                toastRef?.current?.notify(<Toastify type="error" />, errorToastConfig);
              }

              handleSubmit(event);
            }}
          >
            <hr className="margin-b-8px signed-document__modal--divider" />
            <div className="margin-x-24px">
              <Field name="signed_contract" validate={validatePortalDocuments}>
                {({ input, meta }) => (
                  <ChooseFile
                    fileName={input.value?.name}
                    label={t(
                      'shared.bidding_process.contract_details.contracts_header.upload_title',
                    )}
                    hint={t('profile.file.upload')}
                    onChange={(file) => {
                      input.onChange(file);
                    }}
                    touched={meta?.touched}
                    errorMessage={meta?.error || meta?.submitError}
                    isEditable={true}
                  />
                )}
              </Field>
            </div>
            <hr className="margin-t-24px signed-document__modal--divider" />
            <div className="flex gap-16px signed-document__modal--button-wrapper">
              <Button
                label={t('signup.header.button_content')}
                onClick={() => handleModalClose()}
              />
              <Button
                type="success"
                label={t('profile.progress_card.modal.submit_button')}
                submitType="submit"
                disabled={submitting || hasValidationErrors}
              />
            </div>
          </form>
        )}
      />
    </div>
  );
};

UploadSignedContractModalBody.defaultProps = {
  toastRef: null,
};

UploadSignedContractModalBody.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  toastRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

export default UploadSignedContractModalBody;
