// --- RCL Component ---
import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  LineChart as RechartLineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import { LineChartCustomTooltip } from './CustomTooltip';

const LineChart = (props) => {
  const {
    className,
    axis,
    lineGapInSameCatagory,
    chartHeight,
    data,
    dataLines,
    margin,
    onLineClick,
    chartLineOptions,
    formatter,
  } = props;

  const initDataLinesInvisibility = dataLines.reduce((lines, line) => ({ ...lines, [line]: false }), {}) 
  const [dataLinesInvisibility, setDataLinesInvisibility] = useState(initDataLinesInvisibility)
  const onLegendClick = (e) => {
    setDataLinesInvisibility({ ...dataLinesInvisibility, [e.dataKey]: !dataLinesInvisibility[e.dataKey] })
  }

  return (
    <ResponsiveContainer
      className={classNames('line-chart', className)}
      width="100%"
      height={chartHeight}
    >
      <RechartLineChart
        className="line-chart__chart"
        lineGap={lineGapInSameCatagory}
        data={data}
        margin={margin}
      >
        <CartesianGrid className="line-chart__chart-grid" />
        {axis.x && <XAxis dataKey="name" />}
        {axis.y && <YAxis tickFormatter={formatter} />}
        <Tooltip content={<LineChartCustomTooltip formatter={formatter} />} />
        <Legend
          verticalAlign="top"
          layout="vertical"
          iconSize={24}
          align="center"
          wrapperStyle={{ right: 0, top: -15 }}
          height={36}
          onClick={onLegendClick}
        />
        {dataLines.map((line) => (
          <Line
            name={chartLineOptions[line]?.name}
            connectNulls={true}
            className="line-chart__chart-line"
            key={line}
            type="monotone"
            stroke={`var(--${chartLineOptions[line]?.color})`}
            legendType={chartLineOptions[line]?.legendType || "rect"}
            strokeWidth={2}
            label={"adsa"}
            dot={chartLineOptions[line]?.dot}
            activeDot={chartLineOptions[line]?.activeDot}
            hide={dataLinesInvisibility[line]}
            dataKey={line}
            onClick={onLineClick}
          />
        ))}
      </RechartLineChart>
    </ResponsiveContainer>
  );
};

LineChart.defaultProps = {
  className: null,
  margin: {
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
  axis: {
    x: true,
    y: true,
  },
  chartHeight: 300,
  lineGapInSameCatagory: 0,
  chartLineOptions: {},
  onLineClick: () => {},
};

LineChart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  ).isRequired,
  dataLines: PropTypes.arrayOf(PropTypes.string).isRequired,
  margin: PropTypes.shape({
    top: PropTypes.number,
    right: PropTypes.number,
    left: PropTypes.number,
    bottom: PropTypes.number,
  }),
  axis: PropTypes.shape({
    x: PropTypes.bool,
    y: PropTypes.bool,
  }),
  chartHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lineGapInSameCatagory: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onLineClick: PropTypes.func,
  chartLineOptions: PropTypes.objectOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool
      ])
    )
  ),
  formatter: PropTypes.func.isRequired,
};

export default LineChart;
