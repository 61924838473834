import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon from 'components/BaseComponents/Icon';
import { login } from 'utils/constants/Routes';
import '../styles.scss';

const FormFooter = () => {
  const { t } = useTranslation();

  return (
    <div className="reset-password-form__footer">
      <Link to={login} className="reset-password-form__footer--login-link">
        <Icon name="arrowBackAlt" />
        {t('reset_password.back_to_login')}
      </Link>
    </div>
  );
};

export default FormFooter;
