import React, { Component } from 'react';
import ResizeDetector from 'react-resize-detector';
import cs from 'classnames';
import throttle from 'lodash.throttle';
import PropTypes from 'prop-types';
import ShowMore from './components/ShowMore';
import Tab from './components/Tab';
import TabPanel from './components/TabPanel';
import InkBar from './components/InkBar';

const screenSizes = {
  largeDesktop: 1200,
  desktopTab: 991,
  tabMobile: 767,
  mobile: 480,
  smallMobile: 360,
};
const tabPrefix = 'tab-';
const panelPrefix = 'panel-';

export default class Tabs extends Component {
  constructor(props) {
    super(props);

    this.tabRefs = {};
    this.selectedTabKeyProp = props.selectedTabKey;

    this.state = {
      tabDimensions: {},
      blockWidth: 0,
      tabsTotalWidth: 0,
      showMoreWidth: 40,
      selectedTabKey: props.selectedTabKey,
      focusedTabKey: null,
    };

    this.onResizeThrottled = throttle(this.onResize, props.resizeThrottle, { trailing: true });
  }

  componentDidMount() {
    this.setTabsDimensions();

    document.fonts.ready.then(() => {
      this.setTabsDimensions();
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { selectedTabKey, blockWidth, showMoreWidth } = this.state;
    const { items, transform, showMore, showInkBar, allowRemove, removeActiveOnly } = this.props;

    return (
      items !== nextProps.items ||
      nextProps.transform !== transform ||
      nextProps.showMore !== showMore ||
      nextProps.showInkBar !== showInkBar ||
      nextProps.allowRemove !== allowRemove ||
      nextProps.removeActiveOnly !== removeActiveOnly ||
      nextState.blockWidth !== blockWidth ||
      nextState.showMoreWidth !== showMoreWidth ||
      nextProps.selectedTabKey !== this.selectedTabKeyProp ||
      nextState.selectedTabKey !== selectedTabKey
    );
  }

  componentDidUpdate(prevProps) {
    const { items, selectedTabKey } = this.props;

    if (this.selectedTabKeyProp !== selectedTabKey) {
      this.setState({ selectedTabKey });
    }

    if (items.length !== prevProps.items.length) {
      this.setState({ selectedTabKey });
      this.setTabsDimensions();
    }

    if (items[0] && items[0]?.title.length !== prevProps?.items[0]?.title.length) {
      this.setTabsDimensions();
    }

    this.selectedTabKeyProp = selectedTabKey;
  }

  onResize = () => {
    if (this.tabsWrapper) {
      const currentIsCollapsed = this.getIsCollapsed();
      this.setState({ blockWidth: this.tabsWrapper.offsetWidth }, () => {
        const { items } = this.props;
        const { selectedTabKey } = this.state;
        const nextIsCollapsed = this.getIsCollapsed();
        if (
          currentIsCollapsed &&
          !nextIsCollapsed &&
          selectedTabKey === -1 &&
          items &&
          items.length
        ) {
          const firstTabKey = items[0].key || 0;
          this.setState({ selectedTabKey: firstTabKey });
        }
      });
    }
  };

  onChangeTab = (nextTabKey) => {
    const { onChange } = this.props;
    const { selectedTabKey } = this.state;
    const isCollapsed = this.getIsCollapsed();
    if (isCollapsed && selectedTabKey === nextTabKey) {
      // hide on mobile
      this.setState({ selectedTabKey: -1 });
    } else {
      // change active tab
      this.setState({ selectedTabKey: nextTabKey });
    }

    if (onChange) {
      onChange(nextTabKey);
    }
  };

  onFocusTab = (focusedTabKey) => () => this.setState({ focusedTabKey });

  onBlurTab = () => this.setState({ focusedTabKey: null });

  onKeyDown = (event) => {
    const { focusedTabKey } = this.state;
    if (event.keyCode === 13 && focusedTabKey !== null) {
      this.setState({ selectedTabKey: focusedTabKey });
    }
  };

  setTabsDimensions = () => {
    if (!this.tabsWrapper) {
      return;
    }

    const blockWidth = this.tabsWrapper.offsetWidth;
    let tabsTotalWidth = 0;
    const tabDimensions = {};

    Object.keys(this.tabRefs).forEach((key) => {
      if (this.tabRefs[key]) {
        const width = this.tabRefs[key].tab.offsetWidth;
        tabDimensions[key.replace(tabPrefix, '')] = { width, offset: tabsTotalWidth };
        tabsTotalWidth += width;
      }
    });

    this.setState({ tabDimensions, tabsTotalWidth, blockWidth });
  };

  getTabs = () => {
    const { showMore, transform, transformWidth, items, allowRemove, removeActiveOnly, onRemove } =
      this.props;
    const { blockWidth, tabsTotalWidth, tabDimensions, showMoreWidth } = this.state;
    const selectedTabKey = this.getSelectedTabKey();
    const collapsed = blockWidth && transform && blockWidth < transformWidth;
    let tabIndex = 0;
    let availableWidth = blockWidth - (tabsTotalWidth > blockWidth ? showMoreWidth : 0);

    return items.reduce(
      (result, item, index) => {
        const {
          key = index,
          title,
          content,
          getContent,
          disabled,
          disabledHintTitle,
          disabledHintContent,
          tabClassName,
          panelClassName,
        } = item;

        const selected = selectedTabKey === key;
        const payload = {
          tabIndex,
          collapsed,
          selected,
          disabled,
          disabledHintTitle,
          disabledHintContent,
          key,
          transform,
        };
        const tabPayload = {
          ...payload,
          title,
          onRemove: (evt) => {
            if (typeof onRemove === 'function') {
              onRemove(key, evt);
            }
          },
          allowRemove: allowRemove && (!removeActiveOnly || selected),
          className: tabClassName,
        };

        const panelPayload = {
          ...payload,
          content,
          getContent,
          className: panelClassName,
        };

        const tabWidth = tabDimensions[key] ? tabDimensions[key].width : 0;
        tabIndex += 1;

        if (this.isDesktopVertical()) {
          result.tabsVisible.push(tabPayload);
        } else if (
          !showMore ||
          !blockWidth ||
          collapsed ||
          blockWidth > tabsTotalWidth ||
          availableWidth - tabWidth > 0
        ) {
          result.tabsVisible.push(tabPayload);
        } else {
          result.tabsHidden.push(tabPayload);
          if (selected) result.isSelectedTabHidden = true;
        }

        result.panels[key] = panelPayload;
        availableWidth -= tabWidth;

        return result;
      },
      { tabsVisible: [], tabsHidden: [], panels: {}, isSelectedTabHidden: false },
    );
  };

  getTabProps = ({
    title,
    key,
    selected,
    collapsed,
    tabIndex,
    disabled,
    disabledHintTitle,
    disabledHintContent,
    className,
    onRemove,
    allowRemove,
    transform,
  }) => ({
    transform,
    selected,
    allowRemove,
    children: title,
    disabled,
    disabledHintTitle,
    disabledHintContent,
    key: tabPrefix + key,
    id: tabPrefix + key,
    ref: (e) => (this.tabRefs[tabPrefix + key] = e),
    originalKey: key,
    onClick: this.onChangeTab,
    onFocus: this.onFocusTab,
    onBlur: this.onBlurTab,
    onRemove,
    panelId: panelPrefix + key,
    classNames: this.getClassNamesFor('tab', {
      selected,
      collapsed,
      tabIndex,
      disabled,
      className,
    }),
  });

  getPanelProps = ({ key, content, getContent, className }) => ({
    getContent,
    children: content,
    key: panelPrefix + key,
    id: panelPrefix + key,
    tabId: tabPrefix + key,
    classNames: this.getClassNamesFor('panel', { className }),
    onTabChange: this.onChangeTab,
  });

  getShowMoreProps = (isShown, isSelectedTabHidden) => ({
    onShowMoreChanged: this.showMoreChanged,
    isShown: isShown && !this.isDesktopVertical(),
    hasChildSelected: isSelectedTabHidden,
  });

  getClassNamesFor = (type, { selected, collapsed, tabIndex, disabled, className = '' }) => {
    const { tabClass, panelClass } = this.props;
    switch (type) {
      case 'tab':
        return cs('rtabs__tab', className, tabClass, {
          'rtabs__tab--first': !tabIndex,
          'rtabs__tab--selected': selected,
          'rtabs__tab--disabled': disabled,
          'rtabs__tab--collapsed': collapsed,
          'rtabs__tab--vertical': this.isDesktopVertical(),
        });
      case 'panel':
        return cs('rtabs__panel', className, panelClass);
      default:
        return '';
    }
  };

  getSelectedTabKey = () => {
    const { items } = this.props;
    const { selectedTabKey } = this.state;

    if (typeof selectedTabKey === 'undefined') {
      if (!items[0]) {
        return undefined;
      }

      return items[0].key || 0;
    }

    return selectedTabKey;
  };

  getIsCollapsed = () => {
    const { transform, transformWidth } = this.props;
    const { blockWidth } = this.state;
    const isCollapsed = blockWidth && transform && blockWidth < transformWidth;

    return isCollapsed;
  };

  showMoreChanged = (element) => {
    if (!element) {
      return;
    }

    const { showMoreWidth } = this.state;
    const { offsetWidth } = element;

    if (showMoreWidth === offsetWidth) {
      return;
    }

    this.setState({
      showMoreWidth: offsetWidth,
    });
  };

  isDesktopVertical = () => {
    return this.props.isVertical;
  };

  render() {
    const { showInkBar, containerClass, tabsWrapperClass, showMore, transform } = this.props;
    const { tabDimensions } = this.state;
    const { tabsVisible, tabsHidden, panels, isSelectedTabHidden } = this.getTabs();
    const isCollapsed = this.getIsCollapsed();
    const selectedTabKey = this.getSelectedTabKey();
    const selectedTabDimensions = tabDimensions[selectedTabKey] || {};
    const containerClasses = cs('rtabs__container', containerClass, {
      'rtabs__container--vertical': this.isDesktopVertical(),
    });
    const tabsClasses = cs('rtabs__tabs', tabsWrapperClass, {
      rtabs__accordion: isCollapsed,
      'rtabs__tabs--vertical': this.isDesktopVertical(),
    });

    return (
      <div
        className={containerClasses}
        ref={(e) => (this.tabsWrapper = e)}
        onKeyDown={this.onKeyDown}
      >
        <div className={tabsClasses}>
          {tabsVisible.reduce((result, tab) => {
            result.push(<Tab {...this.getTabProps(tab)} />);

            if (transform) {
              result.push(<span className="rtabs__divider" />);
            }

            if (isCollapsed && selectedTabKey === tab.key) {
              result.push(<TabPanel {...this.getPanelProps(panels[tab.key])} />);
            }
            return result;
          }, [])}

          {!isCollapsed && (
            <ShowMore {...this.getShowMoreProps(showMore, isSelectedTabHidden)}>
              {tabsHidden.map((tab) => {
                const tabProps = this.getTabProps(tab);
                return <Tab {...tabProps} key={tabProps.key} />;
              })}
            </ShowMore>
          )}
        </div>

        {showInkBar && !this.isDesktopVertical() && !isCollapsed && !isSelectedTabHidden && (
          <InkBar
            left={selectedTabDimensions.offset || 0}
            width={selectedTabDimensions.width || 0}
          />
        )}

        {!isCollapsed && panels[selectedTabKey] && (
          <TabPanel {...this.getPanelProps(panels[selectedTabKey])} />
        )}

        {(showMore || transform) && (
          <ResizeDetector handleWidth={true} onResize={this.onResizeThrottled} />
        )}
      </div>
    );
  }
}

Tabs.propTypes = {
  items: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  selectedTabKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  allowRemove: PropTypes.bool,
  removeActiveOnly: PropTypes.bool,
  showMore: PropTypes.bool,
  showInkBar: PropTypes.bool,
  isVertical: PropTypes.bool,
  transform: PropTypes.bool,
  transformWidth: PropTypes.number,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  resizeThrottle: PropTypes.number,
  containerClass: PropTypes.string,
  tabsWrapperClass: PropTypes.string,
  tabClass: PropTypes.string,
  panelClass: PropTypes.string,
};

Tabs.defaultProps = {
  items: [],
  selectedTabKey: 0,
  showMore: true,
  showInkBar: true,
  isVertical: false,
  allowRemove: false,
  removeActiveOnly: false,
  transform: false,
  transformWidth: 2000,
  resizeThrottle: 100,
  containerClass: undefined,
  tabsWrapperClass: undefined,
  tabClass: undefined,
  panelClass: undefined,
  onChange: () => null,
  onRemove: () => null,
};
