import { FOLLOWING_TAB } from 'utils/constants/biddingBasket';
import {
  FIRST_PAGE,
  filterOptions,
} from 'utils/constants/offerListings';

export const HANDLE_TAB_CHANGE = 'HANDLE_TAB_CHANGE';
export const HANDLE_PAGE_CHANGE = 'HANDLE_PAGE_CHANGE';
export const HANDLE_SORT_CHANGE = 'HANDLE_SORT_CHANGE';

export const initialFilterState = {
  currentTab: FOLLOWING_TAB,
  sort: filterOptions[0],
  currentPage: FIRST_PAGE,
};

const filterReducer = (state, action) => {
  switch (action.type) {
    case HANDLE_TAB_CHANGE: {
      return { ...state, currentTab: action.payload, currentPage: FIRST_PAGE };
    }
    case HANDLE_SORT_CHANGE: {
      return {
        ...state,
        sort: {
          ...state.sort,
          label: action?.payload?.label,
          column: action?.payload?.column,
          direction: action?.payload?.direction,
        },
      };
    }
    case HANDLE_PAGE_CHANGE: {
      return { ...state, currentPage: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default filterReducer;
