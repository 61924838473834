import { t } from 'i18next';

export const getTitleHeader = (hasRequestedAssistance) => {
  if (!hasRequestedAssistance) {
    return t('shared.bidding_process.contract_details.request_assistance.title');
  }

  return t('shared.bidding_process.contract_details.request_assistance.title2');
};

export const getFormTitle = (hasRequestedAssistance) => {
  if (!hasRequestedAssistance) {
    return t('shared.bidding_process.contract_details.request_assistance.form.title');
  }

  return t('shared.bidding_process.contract_details.request_assistance.form.title2');
};
