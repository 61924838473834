import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { optionalSectionHeading } from 'utils/helper/LabelWithHints';
import {
  heavyMetalTestCertificate,
  heavyMetalTestTableContents,
  heavyMetalTestTableHeaders,
} from 'utils/constants/listingCreation';
import TestResultsTable from 'components/BiddingProcess/CreateListing/FoodSafety/TestResultsTable';
import TestCertificates from '../TestCertificates';

const HeavyMetalTest = ({ form }) => (
  <div className="offer-food-safety-padding">
    {optionalSectionHeading(t('offer_creation.food_safety.heavy_metal_test_section_title'))}
    <TestResultsTable
      tableHeaders={heavyMetalTestTableHeaders}
      tableContentCollection={heavyMetalTestTableContents}
      form={form}
      isOptional={true}
    />
    <TestCertificates testType={heavyMetalTestCertificate} isOptional={true} />
  </div>
);

HeavyMetalTest.propTypes = {
  form: PropTypes.shape({
    batch: PropTypes.func,
    change: PropTypes.func,
    getState: PropTypes.func,
  }).isRequired,
};

export default HeavyMetalTest;
